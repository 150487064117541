import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Notaspese } from '../../models/notaspese';
import { Persona } from '../../models/persona';
import { Azienda } from '../../models/azienda';
import { Statons } from '../../models/statons';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { configurazione } from '../../../configurazioni/configurazione';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-notespese',
  templateUrl: './notespese.component.html',
  styleUrls: ['./notespese.component.css']
})
export class NotespeseComponent implements OnInit {

  IPAddress:string;
  Port:string;
  Protocol: string;

  currentPersona:Persona;
  currentStatons:Statons;
  rolename:string;
  displayedColumns: string[] = [
  "Nomepersona",
  "Cognomepersona",
  "Nome",
  "Descrizione",
  "Data",
  "Importo",
  "Stato",
  "Ricevute",
  "Approva",
  "Rigetta"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();


  notespese: Notaspese[]=[];
  cachednotespese:Notaspese[]=[];
  dataSource = new MatTableDataSource<Notaspese>(this.notespese);
  persone:Persona[];
  aziende: Azienda[];
  currentAzienda:Azienda;
  selectedValueAzienda: string='';
  statins:Statons[];
  selectedValueStatons: string='';


  @ViewChild('paginator') paginator: MatPaginator;

  constructor(private httpClient: HttpClient, private router: Router, private dialog: MatDialog, private commonService: CommonService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.sortedDirection.set("Nomepersona",true);
    this.sortedDirection.set("Cognomepersona",true);
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);
    this.sortedDirection.set("Data",true);
    this.sortedDirection.set("Importo",true);
    this.sortedDirection.set("Stato",true);
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.dataSource.paginator = this.paginator;

    this.commonService.getAllAziende().subscribe((res) => {
        this.aziende = res;
        if (this.aziende.length>0){
          this.currentAzienda=this.aziende[0];
          this.selectedValueAzienda=this.aziende[0].Nome;
        }
        this.commonService.getAllStatins().subscribe((res) => {
            this.statins = res;
        });
        this.aggiornaLista();

    });
/*
    this.commonService.getAllNotespese().subscribe((res) => {
        this.notespese = res;
    });
*/


    this.rolename=sessionStorage.getItem("rolename");

  }

  ngAfterInit(): void {
//    this.navigazione.goToRoot();
    this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
  }

  manageChangeAzienda(){
    if (this.selectedValueAzienda===undefined){
     return;
    }
    this.aziende.forEach(element => {
      if (this.selectedValueAzienda === element.Nome){
        this.currentAzienda=element;
      }
    });
    this.aggiornaLista();
  }

  manageChangeStatons(){
    if (this.selectedValueStatons===undefined){
     return;
    }
    this.statins.forEach(element => {
      if (this.selectedValueStatons === element.Nome){
        this.currentStatons=element;
      }
    });
    this.aggiornaLista();
  }


  cancel(){
    this.selectedValueStatons=undefined;
  //  this.selectedValueAzienda=undefined;
    this.notespese=[];
  //  this.cachednotespese=[];
    this.aggiornaLista();
  }

  aggiornaLista(){
    let nomeazienda:String="";
    if (this.selectedValueAzienda!=undefined && this.selectedValueAzienda!=""){
     nomeazienda=this.selectedValueAzienda;
      if (this.rolename==="HR" || this.rolename==="Amministratore"){
        if (this.currentAzienda!=undefined){
          this.commonService.getAllNotespeseByAziendaid(this.currentAzienda.Codice).subscribe((res) => {
    //        if (this.selectedValueStatons===undefined || this.selectedValueStatons===''){
                this.notespese=res;
                //this.cachednotespese=res;
                this.refreshLista();

      //      }else{
              //this.refreshLista();
      //      }
          });
        }
      }
    }else{
  //    this.refreshLista(templistanotespese);
    }
  }

  refreshLista(){
     //console.log(this.currentStato.Nome);
     let tempnotespese:Notaspese[]=this.notespese;
     this.notespese=[];
     tempnotespese.forEach(element => {
       if (this.selectedValueStatons === element.Nomestatons){
         this.notespese.push(element);
       }
     });
     this.dataSource = new MatTableDataSource<Notaspese>(this.notespese);
     this.dataSource.paginator = this.paginator;
   }


  vediRicevute(notaspese:Notaspese): any {
    this.commonService.currentNotaspese=notaspese;
    this.router.navigate(['ricevute']);
  }


  approvaNotaspese(notaspese: Notaspese, valore: string){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione',description:'Confermi'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        if (valore==='YES'){
          notaspese.Nomestatons="Approvato";
        }else{
          notaspese.Nomestatons="Rigettato";
        }
      //  const notaspeseData = new FormData();
      //  notaspeseData.append('codice', notaspeseData.Codice.toString());
        this.httpClient.put<Notaspese>(this.Protocol+"://"+this.IPAddress+":"+this.Port+'/api/notespese/approva',  notaspese)
        .subscribe((response) => {
            this.aggiornaLista();
        });
      }
    });
  }


  getConfigurazione(){
    return configurazione.persona.fb;
  }

  ngDoCheck(){
  //  alert("ngDoCheck");
  }
  ngAfterContentInit(){
  //  alert("ngAfterContentInit");
  }

  ngAfterContentChecked(){
//    alert("ngAfterContentChecked");
  }
  ngAfterViewInit(){
//    alert("ngAfterViewInit");
  }
  ngAfterViewChecked(){
//    alert("ngAfterViewChecked");
  }

  ngOnDestroy(){


  }

  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Notaspese[];
    tempList=this.notespese;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Notaspese>(this.notespese);

   }

}
