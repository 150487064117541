import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-addmaster',
  templateUrl: './addmaster.component.html',
  styleUrls: ['./addmaster.component.css']
})
export class AddmasterComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol:string;
  master: Master;
  hide = true;
  selectedValue:string;
  moduli: Modulo[];
  masters: Master[];
  rc:number;
  modulotoedit: boolean;
  registerForm: FormGroup;
  submitted = false;
  selectedFile: File;
  retrievedImage: any;
  imagehaschanged=false;
  base64Data: any;
  retrieveResonse: any;

  constructor(private commonService: CommonService, private httpClient: HttpClient,
    private router: Router, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
          Nome:['', Validators.required],
          Descrizione: [''],
          Abilitato: [''],
          Codice: [0],
          Codicecorso: ['', Validators.required],
      });
    }
    if (this.commonService.selectedMaster!=undefined){
      this.master=this.commonService.selectedMaster;
      this.getImage(this.master.Codice);
      this.commonService.selectedMaster=undefined;

      this.registerForm.setValue({
                Nome:this.master.Nome,
                Descrizione:this.master.Descrizione,
                Abilitato:this.master.Abilitato,
                Codice:this.master.Codice,
                Codicecorso:this.master.Codicecorso
      });
    }else{
      this.master=new Master();
    }


//    this.commonService.getAllMasters().subscribe((res) => {
//        this.masters = res;
//    });
  }

  save() {
          this.submitted = true;
          if (this.registerForm.invalid) {
              alert("Errore: verifica i campi indicati con *");
              return;
          }else{
            this.commonService.saveMaster(this.registerForm.value).subscribe(data => {
              this.rc=Number(data);
              if (this.rc===0){
                alert("Master già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione master");
              }else{
                  console.log("----> addImageToMaster");
                  if (this.imagehaschanged){
                    this.addImageToMaster(this.rc);
                  }else{
                    alert("Master salvato con successo");
                    this.router.navigate(['masters']);
                  }
              }
            });
          }
        }

  addImageToMaster(uid:number){
          const uploadImageData = new FormData();
          if (this.selectedFile!=undefined){
            uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
          }else{
            uploadImageData.append('imageFile', new File([], '', { type: 'image/jpeg' }), '');
          }
          uploadImageData.append('masterid', uid.toString());

          //Make a call to the Spring Boot Application to save the image
            this.httpClient.post(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/master/upload', uploadImageData, { observe: 'response' })
              .subscribe((response) => {
                console.log("--->AddImage"+response.status.toString());
                if (response.status === 200) {
                  alert("Master salvato con successo");
                  this.imagehaschanged=false;
                  this.router.navigate(['masters']);
                } else {
                  alert('Errore salvataggio immagine');
                }
              }
              );
  }

  public onFileChanged(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (files[0].size>200000){
      alert("Immagine troppo grande (max 200KB)");
    }else if (mimeType.match(/image\/*/) == null) {
        alert("Sono supportate solo immagini.");
    }else{
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
          this.retrievedImage = reader.result;
      }
      this.selectedFile = files[0];
      this.imagehaschanged=true;
      console.log("Image changed");
    }
  }

  cancel(){
    this.master=new Master();
    this.router.navigate(['masters']);
  }

  removeImg(){
    this.selectedFile=null;
    this.retrievedImage = null;
  }

  getImage(masterid:number) {

  this.httpClient.get(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/master/picture/' + masterid)
    .subscribe(
      res => {
        this.retrieveResonse = res;
        if (this.retrieveResonse!=null && this.retrieveResonse.Picbyte!=null){
          this.base64Data = this.retrieveResonse.Picbyte;
          this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
        }
      }
    );
  }
}
