import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Gruppo } from '../../models/gruppo';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-gruppi',
  templateUrl: './gruppi.component.html',
  styleUrls: ['./gruppi.component.css']
})
export class GruppiComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  gruppi: Gruppo[]=[];
  displayedColumns: string[] = [
  "Nome",
  "Descrizione",
  "newdocument",
  "documents",
  "edit"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  dataSource = new MatTableDataSource<Gruppo>(this.gruppi);
  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService) {}

  ngOnInit() {
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.aggiornaLista();
  }

  cancel(){
    this.aggiornaLista();
  }

  deleteGruppo(gruppo: Gruppo): void {
    this.commonService.deleteGruppo(gruppo).subscribe(data => {
      this.gruppi = this.gruppi.filter(g => g !== gruppo);
    });
  }



  aggiornaLista(){
//    let nomeruolo:String="";
//    if (this.selectedValueRuolo!=undefined){
//     nomeruolo=this.selectedValueRuolo;
//    }
    this.commonService
      .getAllGruppi()
      .subscribe(response => {

        let tempgruppi:Gruppo[]=response;
  //      let templistausers:User[]=[];
  //      tempusers.forEach(element => {
  //        const user:User=element;
  //        user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
          //console.log("Nome ruolo="+user.Nomeruolo);
  //        if (nomeruolo==="" || nomeruolo===user.Nomeruolo){
  //          templistausers.push(user);
  //        }
  //      });
        this.refreshLista(tempgruppi);
      });
   }
   refreshLista(listagruppi:Gruppo[]){
     this.gruppi=listagruppi;
     this.dataSource = new MatTableDataSource<Gruppo>(this.gruppi);
   }
/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/
  newGruppo(){
    this.router.navigate(['addgruppo']);
  }
  editGruppo(gruppo: Gruppo): void {
      this.commonService.selectedGruppo=gruppo;
      this.router.navigate(['addgruppo']);
  }

  viewDocumentiGruppo(gruppo: Gruppo): void {
      this.commonService.tipoDestinatario="Gruppo";
      this.commonService.selectedGruppo=gruppo;
      if (gruppo!=undefined){
      //  alert(persona.Codiceazienda)
//        this.commonService.currentAziendaCodice=persona.Codiceazienda;
        this.commonService.currentTipocategoria="Gruppo";
        this.router.navigate(['documenti']);
      }else{
        alert("E' necessario selezionare un gruppo")
      }
  }

  newDocumentoGruppo(gruppo: Gruppo): void {
      this.commonService.tipoDestinatario="Gruppo";
      this.commonService.selectedGruppo=gruppo;
      if (gruppo!=undefined){
//        this.commonService.currentAziendaCodice=persona.Codiceazienda;
        this.commonService.currentTipocategoria="Persona";
        this.router.navigate(['newdocumento']);
      }else{
        alert("E' necessario selezionare un gruppo")
      }
  }

  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Gruppo[];
    tempList=this.gruppi;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Gruppo>(this.gruppi);

   }
}
