import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Lezione } from '../../models/lezione';
import { CommonService } from "../../service/common.service";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-newattachment',
  templateUrl: './newattachment.component.html',
  styleUrls: ['./newattachment.component.css']
})
export class NewattachmentComponent implements OnInit {

  attachmentname = ''
  invalidFolder = false
  lezione:Lezione;
  selectedFile: File;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;
  message: string;
  imageName: any;
  IPAddress:String;
  Port:String;
  Protocol:string;
  lezioni:Lezione[];


  @Input() error: string | null;

  constructor(private router: Router, private httpClient: HttpClient, private commonService: CommonService) {
      if (this.commonService.currentLezione!=null){
        this.lezione=this.commonService.currentLezione;
        console.log("Newattachment-Lezione code:"+this.lezione.Codice.toString());
      }
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }
   }

  ngOnInit() {
    this.commonService.getAllLezioni().subscribe((res) => {
      this.lezioni=res;
    })
  }

  public onFileChanged(event) {
    //Select File
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;
    if (event.target.files[0].size>50000000){
      alert("Immagine troppo grande (max 50MB)");
//    }else if (mimeType.match(/image\/*/) == null) {
//        alert("Sono supportate solo immagini.");
    }else{
      this.selectedFile = event.target.files[0];
    }
  }
  //Gets called when the user clicks on submit to upload the image
  salva() {
    if (this.attachmentname===undefined || this.attachmentname===""){
      alert("E' necessario specificato il nome del file");
    } else {
    //console.log("File:"+this.selectedFile);
console.log("--"+this.selectedFile.name);
console.log("--"+this.commonService.currentLezioneCodice.toString());
console.log("--"+this.attachmentname);
console.log("--"+this.commonService.currentMateriale.Codice.toString());

    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();
    uploadImageData.append('attachmentFile', this.selectedFile, this.selectedFile.name);
    uploadImageData.append('codice', this.commonService.currentLezioneCodice.toString());
    uploadImageData.append('nome', this.attachmentname);
    uploadImageData.append('tipo', 'DOCUMENTO');
    uploadImageData.append('materialeid', this.commonService.currentMateriale.Codice.toString());

    //Make a call to the Spring Boot Application to save the image


    this.httpClient.post(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/attachment/upload", uploadImageData, { observe: 'response' })
      .subscribe((response) => {

        if (response.status === 200) {
          this.message = 'Image uploaded successfully';
        } else {
          this.message = 'Image not uploaded successfully';
        }
        this.router.navigate(['materiale']);
      }
      );
    }
  }


  cancella(){
   this.router.navigate(['materiale']);
  }

  }
