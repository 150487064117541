import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Lezione } from '../../models/lezione';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { ConfirmData } from '../../models/confirmdata';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.css']
})
export class LessonsComponent implements OnInit {
  selectedValueMaster:string;
  selectedValueModulo:string;
  IPAddress:string;
  Port:string;
  Protocol: string;
  masters: Master[];
  moduli: Modulo[];
  lezioni: Lezione[];
  currentMaster:Master;
  currentModulo:Modulo;
  displayedColumns: string[] = [
  //  'Codicecorso',
    'Nome',
    'Descrizione',
    'Abilitato',
    'Url',
    'Datainizio',
    'Orainizio',
    'Orafine',
    'Modifica',
    'Elimina'];

    sortedDirection:Map<string, boolean>=new Map<string, boolean>();

    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource:MatTableDataSource<Lezione>;

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService,
  private dialog: MatDialog) {}

  ngOnInit() {

  //  corsothis.sortedDirection.set("Codicecorso",true);
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);
    this.sortedDirection.set("Abilitato",true);
    this.sortedDirection.set("Url",true);
    this.sortedDirection.set("Datainizio",true);
    this.sortedDirection.set("Orainizio",true);
    this.sortedDirection.set("Orafine",true);


    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllMasters().subscribe((res) => {
        this.masters = res;
    });

    this.lezioni=[];

  }
  ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
  }
  manageChangeMaster(){
//    if (this.selectedValueMaster===undefined || this.selectedValueMaster===''){
     this.lezioni=[];
     this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);
     this.selectedValueModulo="";
//     return;
//    }
    this.masters.forEach(element => {
      if (this.selectedValueMaster === element.Nome){
        this.currentMaster=element;
      }
    });
    this.commonService
      .getModuliByMaster(this.currentMaster)
      .subscribe(response => {
        this.moduli=response;
      });
  }

  manageChangeModulo(){
    if (this.selectedValueModulo==undefined){
     this.lezioni=[];
     return;
    }
    this.moduli.forEach(element => {
      if (this.selectedValueModulo === element.Nome){
        this.currentModulo=element;
      }
    });

    this.commonService
      .getLezioniByModulo(this.currentModulo)
      .subscribe(response => {
        this.lezioni=response;
        this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);
        this.dataSource.paginator = this.paginator;
  //      this.dataSource.sort = this.sort;
      });
  }


  getNomeByCodicemaster(codicemaster: number):String{
    let nomemaster:String="";
    this.masters.forEach(element => {
      if (element.Codice === codicemaster){
        nomemaster=element.Nome;

      }
    });
    return nomemaster;
  }

  getNomeByCodicemodulo(codicemodulo: number):String{
    let nomemodulo:string="";
    this.moduli.forEach(element => {
      if (element.Codice === codicemodulo){
        nomemodulo=element.Nome;

      }
    });
    return nomemodulo;
  }

  toggle(lezione: Lezione){
    const lezioneData = new FormData();
    lezioneData.append('codice', lezione.Codice.toString());
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/lezioni/toggle", lezioneData, { observe: 'response' })
    .subscribe((response) => {
        this.aggiornaLista();
    });
  }

  editLesson(lezione: Lezione): void {
    lezione.Master=this.currentMaster;
    lezione.Modulo=this.currentModulo;
    this.commonService.selectedLezione=lezione;
    this.router.navigate(['addlesson']);
  }

  aggiornaLista(){
    if (this.selectedValueModulo!=undefined && this.selectedValueModulo!=''){
      this.commonService
        .getLezioniByModulo(this.currentModulo)
        .subscribe(response => {
          this.lezioni=response;
          this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);
        });
    }else{
      this.commonService
        .getLezioni()
        .subscribe(response => {
          this.lezioni=response;
          this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);
        });
    }
   }

  deleteLesson(lezione: Lezione){

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione',description:'Confermi la cancellazione?'}
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result){
        this.commonService.deleteLesson(lezione.Codice).subscribe(data => {
          if (data===1){
            alert("Non si può eseguire la cancellazione in quanto sono presenti dei materiali collegati");
          }else{
            this.lezioni = this.lezioni.filter(u => u !== lezione);
            this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);
          }
        });
      }
    });
  }
    newLesson(){
      this.router.navigate(['addlesson']);
    }

    cancel(){
      this.selectedValueMaster=undefined;
      this.selectedValueModulo=undefined;
      this.lezioni=[];
      this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);
    }

    manageClick(attributo: string){
      //alert(ev.target)
  /*
      switch (_case) {
           case 'number':
             this.elements2.sort((a, b) => {
               return a.position - b.position;
             });
             break;
           case 'text':
    */
      let tempList:Lezione[];
      tempList=this.lezioni;
      let direction=this.sortedDirection.get(attributo);

      tempList.sort((a, b) => {
         var typeA = "";
         var typeB = "";
         if (a[attributo]!=null){
           if (isNaN(b[attributo]))
              typeA = a[attributo].toLowerCase();
           else
              typeA = a[attributo].toString();
         }
         if (b[attributo]!=null){
           if (isNaN(b[attributo]))
              typeB = b[attributo].toLowerCase();
            else
              typeB = b[attributo].toString();
         }
           if (direction){
             if (typeA > typeB){
               return -1;
             }else if (typeA < typeB){
                return 1;
             }else{
               return 0;
             }
           }else{
             if (typeA < typeB){
               return -1;
             }else if (typeA > typeB){
                return 1;
             }else{
               return 0;
             }
           }
       });
       direction=!direction;
       this.sortedDirection.set(attributo,direction);

       this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);

     }
}
