import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Ordineantur } from '../../models/ordineantur';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { ConfirmData } from '../../models/confirmdata';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ordiniantur',
  templateUrl: './ordiniantur.component.html',
  styleUrls: ['./ordiniantur.component.css']
})
export class OrdinianturComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  ordiniantur: Ordineantur[];
  displayedColumns: string[] = [
    'Numeroordine',
    'Dataordine',
    'Statoordine',
    'Nomecorso',
    'Codicecorso',
    'Telefono',
    'Provincia',
    'Comune',
    'Cap',
    'Indirizzo',
    'Cognome',
    'Nome',
    'Email'
  ];

    sortedDirection:Map<string, boolean>=new Map<string, boolean>();

    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource:MatTableDataSource<Ordineantur>;

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService,
  private dialog: MatDialog) {}


  ngOnInit() {

    this.sortedDirection.set("Numeroordine",true);
    this.sortedDirection.set("Dataordine",true);
    this.sortedDirection.set("Statoordine",true);
    this.sortedDirection.set("Nomecorso",true);
    this.sortedDirection.set("Codicecorso",true);
    this.sortedDirection.set("Telefono",true);
    this.sortedDirection.set("Provincia",true);
    this.sortedDirection.set("Comune",true);
    this.sortedDirection.set("Cap",true);
    this.sortedDirection.set("Indirizzo",true);
    this.sortedDirection.set("Cognome",true);
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Email",true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.ordiniantur=[];
    this.aggiornaLista();

  }
  ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
  }

  /*
  manageChangeModulo(){
    if (this.selectedValueModulo==undefined){
     this.lezioni=[];
     return;
    }
    this.moduli.forEach(element => {
      if (this.selectedValueModulo === element.Nome){
        this.currentModulo=element;
      }
    });

    this.commonService
      .getLezioniByModulo(this.currentModulo)
      .subscribe(response => {
        this.lezioni=response;
        this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);
        this.dataSource.paginator = this.paginator;
  //      this.dataSource.sort = this.sort;
      });
  }
*/

  /*

  getNomeByCodicemodulo(codicemodulo: number):String{
    let nomemodulo:string="";
    this.moduli.forEach(element => {
      if (element.Codice === codicemodulo){
        nomemodulo=element.Nome;

      }
    });
    return nomemodulo;
  }
*/



  aggiornaLista(){
    this.commonService.getOrdiniantur().subscribe(response => {
        this.ordiniantur=response;
        this.dataSource = new MatTableDataSource<Ordineantur>(this.ordiniantur);
    });
  }


    cancel(){
      this.ordiniantur=[];
      this.dataSource = new MatTableDataSource<Ordineantur>(this.ordiniantur);
    }

    manageClick(attributo: string){
      //alert(ev.target)
  /*
      switch (_case) {
           case 'number':
             this.elements2.sort((a, b) => {
               return a.position - b.position;
             });
             break;
           case 'text':
    */
      let tempList:Ordineantur[];
      tempList=this.ordiniantur;
      let direction=this.sortedDirection.get(attributo);

      tempList.sort((a, b) => {
         var typeA = "";
         var typeB = "";
         if (a[attributo]!=null){
           if (isNaN(b[attributo]))
              typeA = a[attributo].toLowerCase();
           else
              typeA = a[attributo].toString();
         }
         if (b[attributo]!=null){
           if (isNaN(b[attributo]))
              typeB = b[attributo].toLowerCase();
            else
              typeB = b[attributo].toString();
         }
           if (direction){
             if (typeA > typeB){
               return -1;
             }else if (typeA < typeB){
                return 1;
             }else{
               return 0;
             }
           }else{
             if (typeA < typeB){
               return -1;
             }else if (typeA > typeB){
                return 1;
             }else{
               return 0;
             }
           }
       });
       direction=!direction;
       this.sortedDirection.set(attributo,direction);

       this.dataSource = new MatTableDataSource<Ordineantur>(this.ordiniantur);

     }
}
