  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista utenti</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--
              <mat-form-field appearance="fill">
                <mat-label class="text-sm">Istanza</mat-label>
                <mat-select [(ngModel)]="selectedValueIstanza" name="istanza" (selectionChange)="manageChangeIstanza()" class="text-sm">
                  <mat-option *ngFor="let istanza of istanze" [value]="istanza.Nome">
                    {{istanza.Nome}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
-->
              <mat-form-field appearance="fill">
                <mat-label class="text-sm">Ruolo</mat-label>
                <mat-select [(ngModel)]="selectedValueRuolo" name="ruolo" (selectionChange)="manageChangeRuolo()" class="text-sm">
                   <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.Codice">
                     {{ruolo.Nome}}
                   </mat-option>
                </mat-select>
              </mat-form-field>

      <p>Per visualizzare nome, cognome e codice fiscale è necessario a associare una persona all'utenza</p>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="Isactive">
            <th mat-header-cell *matHeaderCellDef> Attivo
              <img src="./assets/images/sort.png" (click)="manageClick('Isactive')"/>
            </th>
            <td mat-cell *matCellDef="let user">
              <mat-icon *ngIf="user.Isactive===true && !isTheSameUser(user)"  color="primary" (click)="toggleEnable(user)">check_circle</mat-icon>
              <mat-icon *ngIf="user.Isactive===false && !isTheSameUser(user)"  color="primary" (click)="toggleEnable(user)">check_circle_outline</mat-icon>
              <mat-icon *ngIf="isTheSameUser(user)"  color="primary">not_interested</mat-icon>

           </td>
          </ng-container>

          <ng-container matColumnDef="Username">
              <th mat-header-cell *matHeaderCellDef> Username
                <img src="./assets/images/sort.png" (click)="manageClick('Username')"/>
              </th>
              <td mat-cell *matCellDef="let user"> {{user.Username}} </td>
          </ng-container>

          <ng-container matColumnDef="Email">
              <th mat-header-cell *matHeaderCellDef> Email
                <img src="./assets/images/sort.png" (click)="manageClick('Email')"/>
              </th>
              <td mat-cell *matCellDef="let user"> {{user.Email}} </td>
          </ng-container>

          <ng-container matColumnDef="Cellphone">
              <th mat-header-cell *matHeaderCellDef> Cellulare
                <img src="./assets/images/sort.png" (click)="manageClick('Cellphone')"/>
              </th>
              <td mat-cell *matCellDef="let user"> {{user.Cellphone}} </td>
          </ng-container>

          <ng-container matColumnDef="Nomeruolo">
              <th mat-header-cell *matHeaderCellDef> Ruolo
                <img src="./assets/images/sort.png" (click)="manageClick('Nomeruolo')"/>
              </th>
              <td mat-cell *matCellDef="let user"> {{user.Nomeruolo}} </td>
          </ng-container>

          <ng-container matColumnDef="Nomeistanza">
              <th [hidden]="!show" mat-header-cell *matHeaderCellDef> Istanza
                <img src="./assets/images/sort.png" (click)="manageClick('Nomeistanza')"/>
              </th>
              <td [hidden]="!show" mat-cell *matCellDef="let user"> {{user.Nomeistanza}} </td>
          </ng-container>

          <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> Modifica </th>
              <td mat-cell *matCellDef="let user">
                <mat-icon color="primary" (click)="editUser(user)">create</mat-icon>
              </td>
          </ng-container>

          <ng-container matColumnDef="addPersona">
              <th mat-header-cell *matHeaderCellDef> Persona </th>
              <td mat-cell *matCellDef="let user">
                <mat-icon *ngIf="user.Firstname===null && user.Lastname===null && user.Fiscalcode===null" color="primary" (click)="addPersona(user)">person_add</mat-icon>
                <mat-icon *ngIf="!(user.Firstname===null && user.Lastname===null && user.Fiscalcode===null)" color="primary" (click)="removePersona(user)">delete</mat-icon>

              </td>
          </ng-container>

          <ng-container matColumnDef="Firstname">
              <th mat-header-cell *matHeaderCellDef> Nome
                <img src="./assets/images/sort.png" (click)="manageClick('Firstname')"/>
              </th>
              <td mat-cell *matCellDef="let user"> {{user.Firstname}} </td>
          </ng-container>

          <ng-container matColumnDef="Lastname">
              <th mat-header-cell *matHeaderCellDef> Cognome
                <img src="./assets/images/sort.png" (click)="manageClick('Lastname')"/>
              </th>
              <td mat-cell *matCellDef="let user"> {{user.Lastname}} </td>
          </ng-container>

          <ng-container matColumnDef="Fiscalcode">
              <th mat-header-cell *matHeaderCellDef> Codice fiscale
                <img src="./assets/images/sort.png" (click)="manageClick('Fiscalcode')"/>
              </th>
              <td mat-cell *matCellDef="let user"> {{user.Fiscalcode}} </td>
          </ng-container>

    <!--
          <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef> Elimina </th>
              <td mat-cell *matCellDef="let user"><button class="btn btn-danger" (click)="deleteUser(user)">Elimina</button> </td>
          </ng-container>

          <ng-container matColumnDef="Codiceruolo">
              <th mat-header-cell *matHeaderCellDef> Codice ruolo </th>
              <td mat-cell *matCellDef="let user"> {{user.Codiceruolo}} </td>
          </ng-container>
-->


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>


    </mat-card-content>

    <mat-card-actions>
      <table>
        <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="newUser()">Nuovo utente</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
        </table>
    </mat-card-actions>

</mat-card>
