import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { EventEmitterService } from '../service/event-emitter.service';
import { Istanza } from '../models/istanza';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  selectedValue:string;
  username:string = ''
  password:string = ''
  Codiceistanza:number = 0;
  invalidLogin = false
  istanze: Istanza[];

  @Input() error: string | null;

  constructor(private router: Router, private eventEmitterService: EventEmitterService,
    public loginservice: AuthenticationService, private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.getAllIstanze().subscribe((res) => {
        this.istanze = res;
    });
  }

  checkLogin() {

    if (this.commonService.currentIstanza===undefined){
      this.commonService.currentIstanza=new Istanza();
    }

      this.loginservice.authenticate(this.username, this.password, this.commonService.currentIstanza.Codice).subscribe(
        data => {
          sessionStorage.setItem('username', this.username);
          this.eventEmitterService.onLogin();
          this.invalidLogin = false
        },
        error => {
          this.invalidLogin = true
          alert("Errore autenticazione utente:");
          console.log(error.message);
          //this.error = error.message;
        }
    )
  }
 cancelLogin(){
   //console.log('Esci');
   if (this.commonService.currentIstanza!=undefined){
     if (this.commonService.currentIstanza.Codice === 1){
       this.router.navigate(['whiteboard']);
     }else if (this.commonService.currentIstanza.Codice === 2){
       this.router.navigate(['lul']);
     }else if (this.commonService.currentIstanza.Codice === 3){
       this.router.navigate(['test']);
     }else if (this.commonService.currentIstanza.Codice === 4){
       this.router.navigate(['fbservice']);
     }else if (this.commonService.currentIstanza.Codice === 5){
       this.router.navigate(['iossa']);
     }else if (this.commonService.currentIstanza.Codice === 7){
       this.router.navigate(['dthink']);
     }
   }else{
     this.router.navigate(['']);
   }
 }
 manageChange(){
   if (this.selectedValue===undefined){
    return;
   }
   this.istanze.forEach(element => {
     if (this.selectedValue === element.Nome){
       this.commonService.currentIstanza=element;
     }
   });
 }
 getCurrentIstanza(){
   if (this.commonService.currentIstanza!=undefined){
     return this.commonService.currentIstanza;
   }else{
     return new Istanza();
   }
 }
}
