import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Sede } from '../../models/sede';
import { Progetto } from '../../models/progetto';
import { Attivita } from '../../models/attivita';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-attivita',
  templateUrl: './attivita.component.html',
  styleUrls: ['./attivita.component.css']
})
export class AttivitaComponent implements OnInit {
  //IPAddress:string;
  //Port:string;
  //Protocol: string;
  attivita: Attivita[]=[];

  selectedValueStato:string;
  displayedColumns: string[] = [
  "Nome",
  "Descrizione",
  "edit"];


  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  dataSource = new MatTableDataSource<Attivita>(this.attivita);

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService) {}

  ngOnInit() {
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);
    /*
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
*/

    this.aggiornaLista();

  }

  cancel(){
    this.aggiornaLista();
  }
/*
  manageChangeStato(){
    if (this.selectedValueStato===undefined){
     return;
    }
    this.progetti.forEach(element => {
      if (this.selectedValueStato === element.Nome){
        this.currentStato=element;
      }
    });
    this.aggiornaLista();
  }
*/
  aggiornaLista(){
    this.commonService.getAllAttivita().subscribe((res) => {
          this.attivita = res;
          this.refreshLista();
   });
 }
refreshLista(){
  this.dataSource = new MatTableDataSource<Attivita>(this.attivita);
}

  newAttivita(){
    this.router.navigate(['addattivita']);
  }
  editAttivita(attivita: Attivita): void {
      this.commonService.selectedAttivita=attivita;
      this.router.navigate(['addattivita']);
  }
  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Attivita[];
    tempList=this.attivita;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Attivita>(this.attivita);

   }
}
