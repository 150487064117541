import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from "../../../service/common.service";
import { Progettoattivita } from '../../../models/progettoattivita';
import { startWith, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'giorno',
  templateUrl: './giorno.component.html',
  styleUrls: ['./giorno.component.css']
})
export class GiornoComponent implements OnInit {

  constructor(private commonService: CommonService) { }

attivitas:Progettoattivita[]=[];
colore="Medium";
data: string='';
currentYear;
currentMonth;
currentDay;

@Input() valore:any;

  ngOnInit(): void {

    let now:Date=new Date();
    this.currentYear=now.getFullYear();
    this.currentMonth=now.getMonth()+1;
    this.currentDay=now.getDate();
    if (this.commonService.currentProgettiattivita!=undefined){
      this.attivitas=this.commonService.currentProgettiattivita;
    }

    console.log("--->"+this.attivitas);
  }
  getColoreHeader():string{
    if(this.valore.yyyy===this.currentYear && this.valore.mm===this.currentMonth && this.valore.dd===this.currentDay){
      return "Orange";
    }else{
      return "White";
    }
  }
  getColore(pa:Progettoattivita):string{

    let dd=this.valore.dd.toString();
    let mm=this.valore.mm.toString();
    let yyyy=this.valore.yyyy.toString();

    if (this.valore.dd<10){
      dd='0'+dd;
    }
    if (this.valore.mm<10){
      mm='0'+mm;
    }
    let dataestesa:string = dd+'/'+mm+'/'+yyyy;

    let inizio=pa.Datainizio.toString();
    let fine=pa.Datafine.toString();

    let giornoinizio=(Number(inizio.substring(8,10))+1).toString();
    let giornofine=(Number(fine.substring(8,10))+1).toString();

    let datainizio:string = giornoinizio+'/'+inizio.substring(5,7)+'/'+inizio.substring(0,4);
    let datafine:string = giornofine+'/'+fine.substring(5,7)+'/'+fine.substring(0,4);

    console.log("Data estesa:"+dataestesa);
    console.log("Data inizio:"+datainizio);
    console.log("Data fine:"+datafine);

    if ( this.datetonumber(dataestesa) >= this.datetonumber(datainizio) && this.datetonumber(dataestesa) <= this.datetonumber(datafine)){
        return "Low";
    }
    return "";
  }

  datetonumber(data:string):number{
    let n=0;
    let d=data.substring(6,10)+data.substring(3,5)+data.substring(0,2);
    n=Number(d);
    return n;
  }

}
