import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit {
  selectedValueMaster:string;
  IPAddress:string;
  Port:string;
  Protocol:string;
  masters: Master[];
  moduli: Modulo[];
  currentMaster:Master;
  displayedColumns: string[] = [
    'Nome',
    'Descrizione',
    'Abilitato',
    'Modifica',
    'Elimina'];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();


  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource:MatTableDataSource<Modulo>;

  constructor(private httpClient: HttpClient, private router: Router,
    private commonService: CommonService, private dialog: MatDialog) {}

  ngOnInit() {

    this.sortedDirection.set("Name",true);
    this.sortedDirection.set("Descrizione",true);
    this.sortedDirection.set("Abilitato",true);


    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllMasters().subscribe((res) => {
        this.masters = res;
    });

    this.moduli=[];

  }

  ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
  }

  manageChangeMaster(){
    if (this.selectedValueMaster===undefined){
     this.aggiornaLista();
     return;
    }
    this.masters.forEach(element => {
      if (this.selectedValueMaster === element.Nome){
        this.currentMaster=element;
      }
    });

    this.commonService.getModuliByMaster(this.currentMaster).subscribe(response => {
        this.moduli=response;
        this.dataSource = new MatTableDataSource<Modulo>(this.moduli);
        this.dataSource.paginator = this.paginator;
//        this.dataSource.sort = this.sort;
      });
  }

  getNomeByCodicemaster(codicemaster: number):String{
    let nomemaster:String="";
    this.masters.forEach(element => {
      if (element.Codice === codicemaster){
        nomemaster=element.Nome;
    
      }
    });
    return nomemaster;
  }

  toggle(modulo: Modulo){
    const moduloData = new FormData();
    moduloData.append('codice', modulo.Codice.toString());
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/modulo/toggle", moduloData, { observe: 'response' })
    .subscribe((response) => {
        this.aggiornaLista();
    });
  }

  aggiornaLista(){
    if (this.selectedValueMaster!=undefined){
      this.commonService.getModuliByMaster(this.currentMaster).subscribe(response => {
          this.moduli=response;
          this.dataSource = new MatTableDataSource<Modulo>(this.moduli);
        });
      }else{
        this.commonService.getModuli().subscribe(response => {
            this.moduli=response;
            this.dataSource = new MatTableDataSource<Modulo>(this.moduli);
          });
        }
      }

   deleteModule(modulo: Modulo){
     const dialogRef = this.dialog.open(ConfirmComponent, {
       width: '250px',
       data: {title:'Attenzione',description:'Confermi la cancellazione?'}
     });

     dialogRef.afterClosed().subscribe(result => {
       if (result){
         this.commonService.deleteModule(modulo.Codice).subscribe(data => {
           if (data===1){
             alert("Non si può eseguire la cancellazione in quanto sono presenti delle lezioni collegate");
           }else{
             this.moduli = this.moduli.filter(u => u !== modulo);
             this.dataSource = new MatTableDataSource<Modulo>(this.moduli);
           }
         });
       }
     });
   }

   newModule(){
     this.router.navigate(['addmodule']);
   }

   editModule(modulo: Modulo){
     modulo.Master=this.currentMaster;
     this.commonService.selectedModulo=modulo;
     this.router.navigate(['addmodule']);
   }

   cancel(){
     this.selectedValueMaster=undefined;
     this.moduli=[];
     this.dataSource = new MatTableDataSource<Modulo>(this.moduli);
   }

   applyFilter(filterValue: string) {
       this.dataSource.filter = filterValue.trim().toLowerCase();

       if (this.dataSource.paginator) {
         this.dataSource.paginator.firstPage();
       }
     }
  getDataSourceLength(){
    return this.moduli.length;
  }

  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Modulo[];
    tempList=this.moduli;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Modulo>(this.moduli);

   }

}
