import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Tipopermesso } from '../../models/tipopermesso';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addtipopermesso',
  templateUrl: './addtipopermesso.component.html',
  styleUrls: ['./addtipopermesso.component.css']
})
export class AddtipopermessoComponent implements OnInit {
  tipopermesso: Tipopermesso=new Tipopermesso();
  hide = true;
  rc:number;
  sedetoedit: boolean;
  IPAddress:string;
  Port:string;
  Protocol:string;
  registerForm: FormGroup;
  submitted = false;

  public message =  sessionStorage.getItem('token');

  constructor(private httpClient: HttpClient, private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }

    }

  ngOnInit(): void {
    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
            Nome: ['', Validators.required],
            Descrizione: [''],
            Codice: ['0']
        });
    }


        if (this.commonService.selectedTipopermesso!=undefined){
          this.tipopermesso=this.commonService.selectedTipopermesso;
          this.commonService.selectedTipopermesso=undefined;


        }else{
          this.tipopermesso=new Tipopermesso();
          /*
          this.sede.Nome='';
          this.sede.Numerodipendenti=0;
          this.sede.Codice=0;

          */
        }
        //this.tipopermesso.Codice=this.commonService.selectedTipopermesso.Codice;
        this.registerForm.setValue({
          Nome: this.tipopermesso.Nome,
          Descrizione: this.tipopermesso.Descrizione,
          Codice: this.tipopermesso.Codice
        });

    }

get f() { return this.registerForm.controls; }

save() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            alert("Errore: verifica i campi indicati con *");
            return;
        }else{
            this.commonService.saveTipopermesso(this.registerForm.value).subscribe(data => {
              this.rc=Number(data); //ritorna l'id dell'utente se è stato creato con successo
              //console.log("--->SaveUser"+this.rc.toString());
              if (this.rc===0){
                alert("Tipo permesso già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione tipo permesso");
              }else{
                alert("Tipo permesso salvato con successo");
                this.router.navigate(['tipipermesso']);
              }
            });

        }
    //    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
    }

  cancel(){
    this.commonService.selectedTipopermesso=null;
    this.router.navigate(['tipipermesso']);
  }

}
