import { Component, OnInit, ViewChild } from '@angular/core';
import {CommonService} from "../../service/common.service";
import {Router} from '@angular/router';
import {EventEmitterService} from '../../service/event-emitter.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Master} from '../../models/master';
import {MenuItem} from '../../models/menuitem';
import {Istanza} from '../../models/istanza';


@Component({
  selector: 'app-fbservice',
  templateUrl: './fbservice.component.html',
  styleUrls: ['./fbservice.component.css']
})
export class FbserviceComponent implements OnInit {

  constructor(private commonService: CommonService,
    private eventEmitterService: EventEmitterService,
    private router: Router) {

    }

//  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('drawer') drawer;
  items: MenuItem[] = [];
  masterList: Master[] = [];
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  isOpen$: Observable<boolean>;
  isSidenavOpened: boolean=false;

  ngOnInit(): void {
  /*
    console.log("INIT CONTENT");
    if (this.eventEmitterService.subsVar2==undefined) {
      this.eventEmitterService.subsVar2 = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((name:string) => {
        console.log("SUBSCRIBE CONTENT");
        this.firstFunction();
      });
    }
*/
    console.log("Set istanza fbservice");
    this.commonService.currentIstanza=new Istanza();
    this.commonService.currentIstanza.Codice=0;
    this.commonService.getAllIstanze().subscribe((res) => {
        res.forEach(element => {
          if (element.Codice===4){
            this.commonService.currentIstanza=element;

          }
        });
        this.router.navigate(['']);
    });

  }

  retrieveData(): void {
    console.log("Retrieve data");
//    this.commonService.getMenu(sessionStorage.getItem("username")).subscribe((res) => {
    this.commonService.getMenu().subscribe((res) => {
        this.items = res;
      }
  );
  this.commonService.getMaster(sessionStorage.getItem("username")).subscribe((res) => {
    this.masterList=res;
    //  sessionStorage.setItem("masterList", this.masterList);
  });
}
  firstFunction() {
    this.retrieveData();
    this.drawer.toggle();
    // alert('Toggle drawer');
    }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }
  mouseleave() {
   if (!this.isExpanded) {
     this.isShowing = false;
   }
  }

  goto(contesto:string) {
   this.router.navigate([contesto]);
  }


}
