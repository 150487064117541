<mat-card>
    <mat-card-header>
        <mat-card-title>Dettaglio lul</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <mat-error *ngIf="numberFormControl.hasError('pattern')"> <!-- && !numberFormControl.hasError('required')"> -->
        Inserisci un numero valido
      </mat-error>


      <form [formGroup]="registerForm" (ngSubmit)="save()">
        <mat-form-field class="example-full-width">
          <mat-label class="text-sm">Nome</mat-label>
          <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label class="text-sm">Descrizione</mat-label>
          <input matInput placeholder="Descrizione" formControlName="Descrizione" name="Descrizione" required>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label class="text-sm">Inizio</mat-label>
          <input matInput placeholder="Paginainizio" formControlName="Paginainizio" name="Paginainizio" required>

        <!--
             <input matInput [formControl]="numberFormControl" [errorStateMatcher]="matcher" name="Pagineinizio"
                    type="text" formControlName="Paginainizio"/>
             <button *ngIf="Paginainizio" matSuffix mat-icon-button aria-label="Clear" (click)="Paginainizio=null">
               <mat-icon>close</mat-icon>
             </button>
        -->
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label class="text-sm">Fine</mat-label>
          <input matInput placeholder="Paginafine" formControlName="Paginafine" name="Paginafine" required>


    <!--
             <input matInput [formControl]="numberFormControl" [errorStateMatcher]="matcher" name="Pagine fine"
                   placeholder="{{Paginafine}}"  type="text" formControlName="Paginafine"/>
            <button *ngIf="Paginafine" matSuffix mat-icon-button aria-label="Clear" (click)="Paginafine=null">
              <mat-icon>close</mat-icon>
            </button>
    -->
        </mat-form-field>
        <mat-form-field appearance="fill" style="padding-right: 10px">
          <mat-label class="text-sm">Template</mat-label>

                       <!--
                       <mat-select [(ngModel)]="selectedValueTemplate" name="template" (selectionChange)="manageChangeTemplate()" class="text-sm">
                       -->
          <mat-select formControlName="Nometemplate" name="template" (selectionChange)="manageChangeTemplate()" class="text-sm">
                         <mat-option *ngFor="let template of templates" [value]="template.Nome">
                           {{template.Nome}}
                         </mat-option>
                       </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">Azienda</mat-label>
            <mat-select formControlName="Nomeazienda" name="azienda" (selectionChange)="manageChangeAzienda()" class="text-sm">
                    <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
                          {{azienda.Nome}}
                    </mat-option>
                  </mat-select>
               </mat-form-field>
         <table>
          <tr>
            <td>
              <button mat-raised-button color="primary">Salva</button>
            </td>
            <td>
              <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
            </td>
         </tr>
        </table>
      </form>
    </mat-card-content>
</mat-card>
