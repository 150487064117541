import { Master } from '../models/master';
import { Modulo } from '../models/modulo';
export class Lezione {

  id: number;
  Nome: string;
  Codice: number;
  Datainizio: Date;
  Datafine: Date;
  Descrizione: string;
  Url: string;
  Codicemaster: number;
  Codicemodulo: number;
  Abilitato: string;
  Orainizio: string;
  Orafine: string;
  Codiceroom: number;
  Sede: string;
  Aula: string;
  DocenteListEstesa: string;
  Modulo: Modulo;
  Master: Master;
  Picbyte: any;

  constructor(){
    this.Nome='';
    this.Codice=0;
    this.Datainizio=null;
    this.Datafine=null;
    this.Descrizione='';
    this.Url='';
    this.Abilitato='NO';
    this.Codicemaster=0;
    this.Codicemodulo=0;
    this.Abilitato='';
    this.Orainizio=null;
    this.Orafine=null;
    this.Codiceroom=0;
    this.Sede='';
    this.Aula='';
    this.DocenteListEstesa='';
    this.Modulo=null;
    this.Master=null;
    this.Picbyte=null;
  }

}
