import { Modulo } from '../models/modulo';
export class Master {
  Nome: string;
  Codice: number;
  Codicecorso: string;
  Descrizione: string;
  Abilitato: string;
  ModuloList: Modulo[];
  Picbyte: any;

  constructor(){
    this.Codicecorso='';
    this.Nome='';
    this.Codice=0;
    this.Descrizione='';
    this.Abilitato='';
    this.ModuloList=[];
    this.Picbyte=null;
  }
}
