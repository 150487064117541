import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Azienda } from '../../models/azienda';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addazienda',
  templateUrl: './addazienda.component.html',
  styleUrls: ['./addazienda.component.css']
})
export class AddaziendaComponent implements OnInit {
  azienda: Azienda=new Azienda();
  hide = true;
  rc:number;
  aziendatoedit: boolean;
  IPAddress:string;
  Port:string;
  Protocol:string;
  registerForm: FormGroup;
  submitted = false;

  public message =  sessionStorage.getItem('token');

  constructor(private httpClient: HttpClient, private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }

    }

  ngOnInit(): void {
    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
            Nome: ['', Validators.required],
            Partitaiva: [''],// [Validators.required, Validators.pattern('^[a-z0-9]')]],
            Missione: [''],
            Codicefiscale: [''],//['', [Validators.minLength(16), Validators.maxLength(16)]],
            Ragionesociale: [''],
            Attivita: [''],
            Numerodipendenti: ['0'], //, [Validators.pattern(/^\d+'/)]],
            Rea: [''],
            Datarea: [''], //,[Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
            Cameracommercio: ['0'],
            Codice: ['0']
        });
    }


        if (this.commonService.selectedAzienda!=undefined){
          this.azienda=this.commonService.selectedAzienda;
          this.commonService.selectedAzienda=undefined;

          this.registerForm.setValue({
            Nome: this.azienda.Nome,
            Partitaiva: this.azienda.Partitaiva,
            Missione: this.azienda.Missione,
            Codicefiscale: this.azienda.Codicefiscale,
            Ragionesociale: this.azienda.Ragionesociale,
            Attivita: this.azienda.Attivita,
            Numerodipendenti: this.azienda.Numerodipendenti,
            Rea: this.azienda.Rea,
            Datarea: this.azienda.Datarea,
            Cameracommercio: this.azienda.Cameracommercio,
            Codice: this.azienda.Codice
          });
        }else{
          this.azienda.Nome='',
          this.azienda.Partitaiva='',
          this.azienda.Missione='',
          this.azienda.Codicefiscale='',
          this.azienda.Ragionesociale='',
          this.azienda.Attivita='',
          this.azienda.Numerodipendenti=0,
          this.azienda.Rea='',
          this.azienda.Datarea=null,
          this.azienda.Cameracommercio='',
          this.azienda.Codice=0
        }
    }

get f() { return this.registerForm.controls; }

save() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            alert("Errore: verifica i campi indicati con *");
            return;
        }else{
            this.commonService.saveAzienda(this.registerForm.value).subscribe(data => {
              this.rc=Number(data); //ritorna l'id dell'utente se è stato creato con successo
              //console.log("--->SaveUser"+this.rc.toString());
              if (this.rc===0){
                alert("Azienda già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione azienda");
              }else{
                alert("Azienda salvata con successo");
                this.router.navigate(['aziende']);
              }
            });

        }
    //    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
    }

  cancel(){
    this.commonService.selectedAzienda=null;
    this.router.navigate(['aziende']);
  }

  myFilter(d: Date): boolean {
    if(d!=undefined){
      const selectinablemonth = d.getMonth();
      const selectinableyear = d.getFullYear();
      const selectinableday = d.getDate();
      const todaysDateObject = new Date();
      const actualday = todaysDateObject.getDate();
      const actualmonth = todaysDateObject.getMonth();
      const actualyear = todaysDateObject.getFullYear();
      return true;
//      if (actualyear - 11> selectinableyear){
//        return true;
//      }else{
//        return false;
//      }
    }
    return false;
  }

}
