import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Materiale } from '../../models/materiale';
import { CommonService } from '../../service/common.service';
import { Ruolo } from '../../models/ruolo';
import { DomSanitizer } from '@angular/platform-browser';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { Lezione } from '../../models/lezione';
import { Router } from '@angular/router';
import { SelectionModel} from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigazioneComponent} from './navigazione/navigazione.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-materiale',
  templateUrl: './materiale.component.html',
  styleUrls: ['./materiale.component.css']
})



export class MaterialeComponent implements OnInit, AfterViewInit {

  currentMateriale: Materiale;
  materiali:Materiale[]=[];
  cachedmateriali:Materiale[]=[];
  //materiali = new MatTableDataSource<Materiale>(ELEMENT_DATA);
  dataSource = new MatTableDataSource<Materiale>(this.materiali);
  role: Ruolo;
  rolename:string;
  name = 'Angular 5';
  lezioni:Lezione[];
  selectedValueMaster:string;
  selectedValueModulo:string;
  selectedValue:string;
  masters: Master[];
  moduli: Modulo[];
  currentMaster:Master;
  currentModulo:Modulo;
  displayedColumns: string[] = [
    ' ',
    'Name',
    'Datapubblicazione',
    'Filename',
    'Nomelezione',
    'Mimetype',
    'Scarica',
    'Elimina',
    'select'];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();


  IPAddress: string;
  Port: string;
  Protocol: string;
  fileUrl;



  selection:SelectionModel<Materiale>;

  @ViewChild('nav') navigazione:NavigazioneComponent
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService,
    private dialog: MatDialog, private sanitizer: DomSanitizer) {



//    this.dataSource = new MatTableDataSource<Materiale>(this.materiali);
    this.selection = new SelectionModel<Materiale>(true, []);


  this.sortedDirection.set("Name",true);
  this.sortedDirection.set("Datapubblicazione",true);
  this.sortedDirection.set("Filename",true);
  this.sortedDirection.set("Nomelezione",true);
  this.sortedDirection.set("Mimetype",true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
      this.dataSource.paginator = this.paginator;


  }



  ngOnInit(): void {

    this.rolename=sessionStorage.getItem("rolename");
    const lezione:Lezione=this.commonService.currentLezione;
    this.currentMateriale=this.commonService.currentMateriale;
    console.log("Get Lezioni per:"+this.rolename);

    this.commonService.getAllMasters().subscribe((res) => {
        this.masters = res;
        this.lezioni=[];

        //console.log("--->>>"+this.sort);
    });


  /*
    this.commonService.getAllLezioniByUserid(sessionStorage.getItem("username")).subscribe((res) => {
      this.lezioni=res;
      console.log("Num lezioni trovate:"+this.lezioni.length);
      this.navigazione.goToRoot();
    })
    this.aggiornaLista();
    */
  }

  ngAfterInit(): void {
//    this.navigazione.goToRoot();
//    this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
//    this.tabSort.disableClear = true;

  }
  ngAfterViewInit(): void {
  //console.log("Sort value= "+this.sort);

  }

  manageChangeMaster(){
//    if (this.selectedValueMaster===undefined || this.selectedValueMaster===''){

     if (this.selectedValueMaster===undefined){
      return;
     }
     this.moduli=[];
     this.lezioni=[];
     this.materiali=[];
     this.dataSource = new MatTableDataSource<Materiale>(this.materiali);
     this.selectedValueModulo="";
//     return;
//    }
    this.masters.forEach(element => {
      if (this.selectedValueMaster === element.Nome){
        this.currentMaster=element;
      }
    });
    this.commonService
      .getModuliByMaster(this.currentMaster)
      .subscribe(response => {
        this.moduli=response;
      });
  }

  manageChangeModulo(){
    if (this.selectedValueModulo===undefined){
     return;
    }
    this.lezioni=[];
    this.moduli.forEach(element => {
      if (this.selectedValueModulo === element.Nome){
        this.currentModulo=element;
      }
    });
    this.commonService
      .getLezioniByModulo(this.currentModulo)
      .subscribe(response => {
        this.lezioni=response;
        this.navigazione.goToRoot();
      });
}


  isEnabledToLoad(): boolean{
     return (this.rolename=="Amministratore" || this.rolename=="Docente");
  }

  downloadFile(materiale:Materiale): any {
    //console.log("Lezione:"+materiale.Nomelezione);
    const data = 'some text';
    const blob = new Blob([data], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
		return this.commonService.downloadFile(materiale);
  }
  cambiaCartella(materiale:Materiale): any {
    // console.log("Cambia cartella:"+materiale.Materialeid);
    this.commonService.currentMateriale=materiale;
    this.currentMateriale=materiale;
    this.navigazione.addFolder(materiale);
    //this.commonService.currentMateriale.Codice=materiale.Codice;
    const lezione:Lezione=this.commonService.currentLezione;
    this.aggiornaLista();
  }
  cartellaRoot(): any {
    this.commonService.currentMateriale.Codice=1;
    this.selectedValue = '';
    this.aggiornaLista();
  }
  creaCartella(): any {
    this.cachedmateriali=[];
    if (this.selectedValue !== undefined){
      //this.commonService.currentLezioneCodice = this.getCodiceLezioneByNome(this.selectedValue);
      this.commonService.currentLezioneCodice=Number(this.selectedValue);
      this.router.navigate(['newfolder']);
    }
  }
  caricaAllegato(): any {
    this.cachedmateriali=[];
    if (this.selectedValue !== undefined){
      //this.commonService.currentLezioneCodice=this.getCodiceLezioneByNome(this.selectedValue);
      this.commonService.currentLezioneCodice=Number(this.selectedValue);
      this.router.navigate(['newattachment']);
    }
  }
  eliminaMateriale(materiale: Materiale){

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione',description:'Confermi la cancellazione?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.cachedmateriali=[];
        const materialeData = new FormData();
        materialeData.append('codice', materiale.Codice.toString());
  //      this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/materiale/toggle/", materialeData, { observe: 'response' })
        this.httpClient.delete(this.Protocol+"://"+this.IPAddress+":"+this.Port+'/api/materiale/'+ materiale.Codice)

        .subscribe((response) => {
          if (response===0){
            this.aggiornaLista();
          }else if (response===-1){
            alert("Errore cancellazione materiale");
          }else if (response===-2){
            alert("Materiale in uso ad altro processo (es. LUL)");
          }
        });
      }
    });

  }
  toggle(materiale: Materiale){
    //selection.toggle(row);
    const materialeData = new FormData();
    materialeData.append('codice', materiale.Codice.toString());
//    this.httpClient.put("http://"+this.IPAddress+":"+this.Port+"/api/materiale/view/"+materiale.Codice, materialeData, { observe: 'response' })

    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/materiale/toggle", materialeData, { observe: 'response' })
    .subscribe((response) => {
        this.cachedmateriali=[];
        this.aggiornaLista();
    });
  }
  aggiornaLista(){

    //console.log("Aggiorna lezione:"+this.selectedValue);
    //console.log("Aggiorna Codice materiale:"+this.commonService.currentMateriale.Codice.toString());
    // Si cercano tutti i materiali che hanno materiale padre pari a currentMateriale.Codice

    if (this.cachedmateriali.length===0){
      this.commonService.getAllMateriali(this.commonService.currentMateriale.Codice).subscribe((res) => {
        this.cachedmateriali=res;
        this.refreshLista();
        });
      }else{
        this.refreshLista();
      }
  }

  applyFilter(event: Event) {
    const element = event.currentTarget as HTMLInputElement
    let filterValue = element.value
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  refreshLista(){
    //  var tempmateriali = res;
      this.materiali = [];
      let codiceLezione=Number(this.selectedValue);
      this.cachedmateriali.forEach(element1 => {
        if (codiceLezione === element1.Codicelezione){
             this.materiali.push(element1);
           }
    });
    this.dataSource = new MatTableDataSource<Materiale>(this.materiali);

    if (this.dataSource===undefined){
        console.log("dataSource undefined");
    }
    if (this.sort!=undefined){
       this.dataSource.sort = this.sort;
     }else{
        console.log("Sort undefined");
    }
  //  this.dataSource.paginator = this.paginator;
  }
  getCodiceLezioneByNome(nome: string){
    var rc:number;
    this.lezioni.forEach(element => {
      if (nome === element.Nome){
        rc=element.Codice;
      }
    });
    return rc;
  }

  isAllSelected() {
    const dataSource = new MatTableDataSource<Materiale>(this.materiali);
    const numSelected = this.selection.selected.length;
    const numRows = dataSource.data.length;
    return numSelected === numRows;
  }

/** Selects all rows if they are not all selected; otherwise clear selection. */
 masterToggle() {
   this.isAllSelected() ?
         this.selection.clear() :
         this.dataSource.data.forEach(row => this.selection.select(row));
  }

  onNavigazioneEvent(materiale:Materiale){
    // console.log("On navigazione event:"+materiale.Name);
    if (materiale.Codice===1){
      this.selectedValue="";
      this.commonService.currentMateriale=new Materiale();
    }else{
      this.commonService.currentMateriale=materiale;
    }
    this.aggiornaLista();
  }

  getProperyValue(property: string):string{
    return this.commonService.getProperyValue(property);
  /*
    let json={
      1: {labelLezioni: "Corso", creaCartella: true},
      2: {labelLezioni: "Stato", creaCartella: false},
      3: {labelLezioni: "Argomento", creaCartella: false},
      4: {labelLezioni: "Argomento", creaCartella: false},
    }
    return json[this.commonService.currentIstanza.Codice][property];
    /*
    switch(this.commonService.currentIstanza.Codice) {
       case 1: {
          return json.istanza1[property];
          break;
       }
       case 2: {
          return json.istanza2[property];
          break;
       }
       case 3: {
          return json.istanza3[property];
          break;
       }
       case 4: {
          return json.istanza4[property];
          break;
       }
       default: {
          return "";
          break;
       }
    }
    */
  }

  cancel(){
    this.selectedValueMaster="";
    this.selectedValueModulo="";
    this.selectedValue="";
    this.moduli=[];
    this.lezioni=[];
    this.materiali=[];
    this.dataSource = new MatTableDataSource<Materiale>(this.materiali);
    this.aggiornaLista();
  }

  manageClick(attributo: string){

    //alert(ev.target)
/*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Materiale[];
    tempList=this.materiali;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Materiale>(this.materiali);

   }
}
