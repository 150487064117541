import { Component, OnInit, ViewChild } from '@angular/core';
import { PipeTransform, Pipe } from "@angular/core";
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Lezione } from '../../models/lezione';
import { MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Materiale } from '../../models/materiale';
//import { SanitizeUrlPipe } from '../../sanitize-url.pipe';
import { ElementRef } from '@angular/core';
import { delay, of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';



declare const PDFObject: any;

@Component({
  selector: 'app-courseviewer',
  templateUrl: './courseviewer.component.html',
  styleUrls: ['./courseviewer.component.css']
})
export class CourseviewerComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  urlSafe: SafeResourceUrl;
  url: string;
  currentMateriale: Materiale;
  selectedValue: string;
  lezioni: Lezione[];
  materiali: Materiale[];
  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  pdfData:any;
  isLoading = false;
  src = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  //srcvideo; //="assets/videos/video1.m4v";
  //srcvideo="assets/videos/MOLL3428.m4v";
  //srcvideo="assets/videos/eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ3aGl0ZWJvYXJkIiwiZXhwIjoxNzEyODM4MDc4LCJpYXQiOjE3MTI4MjAwNzh9.DbA1bHBhc-I8GoJjRyvyjllJ9ieZl1VHP6lD-8dAN7316uyh8R_9Bu6WeSi7PqNKfxUoeHrStz6HIH8Z-KutOg/22571.m4v";
  //srcvideo="http://127.0.0.1:20080/api/attachment/get/22571"
  basepath="assets/videos/";
  srcvideo="";
  pageVariable = 1;
  zoom = 1
  angle = 0

  // Call backs Monitor
  errorTriggeredCb = 0;
  afterpageLoadedCb = 0;
  pageRenderCb = 0;
  textLayerRenderedCb = 0;

  filepath="hello";
  mimetype='';
  codiceMateriale:number;

@ViewChild('download') download: ElementRef;


@ViewChild('videoPlayer') videoPlayer: any;
public startedPlay:boolean = false;
public show:boolean = false;
pauseVideo(videoplayer)
{
  videoplayer.nativeElement.play();
  // this.startedPlay = true;
  // if(this.startedPlay == true)
  // {
     setTimeout(() =>
     {
      videoplayer.nativeElement.pause();
       if(videoplayer.nativeElement.paused)
      {
        this.show = !this.show;
      }
     }, 5000);
  // }
}



//  pdfSrc ='https://github.com/itext/i7js-highlevel/blob/develop/cmpfiles/chapter02/cmp_canvas_example.pdf';
//  pdfSrc ='http://127.0.0.1:20080//api/attachment/get/22411';

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService,
  private dialog: MatDialog, public sanitizer: DomSanitizer, private elementRef: ElementRef<HTMLElement>) {

    this.mimetype=this.commonService.selectedMateriale.Mimetype
    this.srcvideo=this.commonService.srcvideo;
    console.log("-->"+this.mimetype);

    console.log("-->"+this.srcvideo);
  }



@ViewChild('videoplayer') private videoplayer: ElementRef;
  toggleVideo(event: any) { this.videoplayer.nativeElement.play(); // this.videoplayer.nativeElement.pause();
}

  ngOnInit() {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
    /*
    this.commonService.getAllMaterialiByLezioneid(this.commonService.selectedLezione.Codice).subscribe(response => {
      let tempMateriali:Materiale[]=response;
      this.materiali=[];
      tempMateriali.forEach(element => {
        if (element.Mimetype!=null && (element.Mimetype.includes("pdf") || element.Mimetype.includes("video"))){
          this.materiali.push(element);
        }
      });
    });
    */
    let username=sessionStorage.getItem("username");
    this.basepath=this.basepath+username+"/";
    this.manageChangeMateriale();
    //this.basepath=this.basepath+token.substring(7,token.length)+"/";
    //this.basepath=this.basepath+token.substring(7,token.length)+"/";
  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
    if (this.mimetype.includes("video")){
      this.httpClient.get(this.Protocol + '://' + this.IPAddress + ':' + this.Port + '/api/attachment/removevideo/' + this.codiceMateriale).subscribe(
        data => {
          console.log("Remove video:"+data);
        });
    }
  }

  toggle(lezione: Lezione){
    const lezioneData = new FormData();
    lezioneData.append('codice', lezione.Codice.toString());
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/lezioni/toggle", lezioneData, { observe: 'response' })
    .subscribe((response) => {
  //      this.aggiornaLista();
    });
  }

//  manageChangeMateriale(ev:Event){
// this.codiceMateriale=Number(ev);
  manageChangeMateriale(){
    this.codiceMateriale=this.commonService.selectedMateriale.Codice;

//    let codiceMateriale=22322;
/*
    this.moduli.forEach(element => {
      if (this.selectedValueModulo === element.Nome){
        this.currentModulo=element;
      }
    });
*/
      //this.url = 'https://view.officeapps.live.com/op/embed.aspx?src=http://www.dickinson.edu/download/downloads/id/1076/sample_powerpoint_slides.pptx';
      //this.url = 'https://view.officeapps.live.com/op/embed.aspx?src=http://iodipendente.it:20081/hello/Presentation1.pptx';

      //this.url = 'http://iodipendente.it:20081/hello/Presentation1.pptx';


//this.url = 'https://view.officeapps.live.com/op/embed.aspx?src='+this.commonService.downloadFileByCodice(codiceMateriale)
//      this.url = 'https://view.officeapps.live.com/op/embed.aspx?src=  http://127.0.0.1:20080/api/attachment/get/22411';
//this.url = 'https://view.officeapps.live.com/op/embed.aspx?src=  http://127.0.0.1:20081/hello';

  //    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlppt);

  //    this.url=this.Protocol+'://' + this.IPAddress + ':' + this.Port + '/api/attachment/get/' + codiceMateriale;
//      this.url='http://127.0.0.1:4200/assets/temp/Presentation1.pptx';

     //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
     //this.url='https://view.officeapps.live.com/op/embed.aspx?src= ';
     //console.log(this.urlSafe);

/*
      this.isLoading = true;
      this.commonService.getPdf().subscribe({
        next: (res) => {
          this.pdfData = res;
          this.isLoading = false;
          if (this.pdfData) {
            this.handleRenderPdf(this.pdfData);
          }
        }
      });
*/
      this.isLoading = true;

      let ext='';
      /*
      this.materiali.forEach(element => {
        if (element.Codice===this.codiceMateriale){
          this.mimetype=element.Mimetype;
          let nomeAllegato=element.Filename;
          ext=nomeAllegato.substring(nomeAllegato.length-4, nomeAllegato.length);
        }
      });
*/
      this.mimetype=this.commonService.selectedMateriale.Mimetype;
      let nomeAllegato=this.commonService.selectedMateriale.Filename;
      ext=nomeAllegato.substring(nomeAllegato.length-4, nomeAllegato.length);


      if (this.mimetype.includes("pdf")){
    //  let data=this.commonService.downloadFileBase64ByCodice(codiceMateriale);
        this.httpClient.get(this.Protocol + '://' + this.IPAddress + ':' + this.Port + '/api/attachment/getbase64/' + this.codiceMateriale, { responseType: 'text' }).subscribe(
          data => {
      //      alert("Contenuto pronto alla fruizione");
      //      let rc = of(data).pipe(delay(5000)); // Serve avere un ritardo altrimenti non si vede il file
      //      console.log("Get pdf:"+rc);
            this.pdfData=data;
            this.isLoading = false;
        });
      }else if (this.mimetype.includes("video")){
          this.isLoading = false;
    //    this.httpClient.get(this.Protocol + '://' + this.IPAddress + ':' + this.Port + '/api/attachment/getvideo/' + this.codiceMateriale).subscribe(
    //      data => {
    //        this.isLoading = false;
    //        alert(this.srcvideo)
    //       alert("Contenuto pronto alla fruizione");
    //        this.srcvideo=this.basepath+this.codiceMateriale+ext;
    //    });

      }
      /*.subscribe({
        next: (res) => {
          console.log(res);
          console.log("-------");
          this.pdfData = of(res).pipe(delay(3000));
          this.isLoading = false;
        },
        error: err => {
          console.log(err);
        }
      });
      */
  }

   valueChanged(value: string) {
//      this.filepath = value;
//      console.log(this.filepath);
    }

  loadVideo(){
    //this.filepath="assets/videos/eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJnYWJyaWVsZSIsImV4cCI6MTcxMjg0NDQ4MSwiaWF0IjoxNzEyODI2NDgxfQ.xqxuvKwPnOPpUU_MjhwufA_PmVrxY7rXD1C0FRRrSseaZmRnsuaKdM-zRpwrIUUovgsIe35OvHsA1182bXyUGw/22571.m4v";
//    this.srcvideo=this.filepath;
//    alert(this.filepath);
  }


  handleRenderPdf(data) {
      const pdfObject = PDFObject.embed(data, '#pdfContainer');
  }
  contentLoaded() {
      console.log('File loaded');
    }
  getProperyValue(property: string):string{
    return this.commonService.getProperyValue(property);
  }

  plusZoom() {
      this.zoom++;
    }

    minusZoom() {
      if(this.zoom > 1 ) {
      this.zoom--;
      }
    }

    rotatedx() {
        if (this.angle === 0) {
          this.angle = 90;
        } else if (this.angle === 90) {
          this.angle = 180;
        } else if (this.angle === 180) {
          this.angle = 270;
        } else if (this.angle === 270) {
          this.angle = 0;
        }
      }

      rotatesx() {
          if (this.angle === 0) {
            this.angle = 270;
          } else if (this.angle === 270) {
            this.angle = 180;
          } else if (this.angle === 180) {
            this.angle = 90;
          } else if (this.angle === 90) {
            this.angle = 0;
          }
        }

      nextPage() {
        this.pageVariable++;
      }

      previousPage() {

        if(this.pageVariable > 1) {
              this.pageVariable--;
        }
      }

      afterLoadComplete(pdf: any) {
        this.afterpageLoadedCb++;
        console.log('after-load-complete');
      }

      pageRendered(e: CustomEvent) {
        this.pageRenderCb++;
        console.log('(page-rendered)');
      }

      textLayerRendered(e: CustomEvent) {
        // Callback Monitor variable
        this.textLayerRenderedCb++;

        // Finds anchors and sets hrefs void
        this.disableAnchorLinks();
        console.log('(text-layer-rendered)');

      }

      disableAnchorLinks() {

          let externalLinks: HTMLCollectionOf<HTMLAnchorElement>;
          if (externalLinks!=undefined){
            const pdfReport = document.getElementById('pdf-report-id');

            if (pdfReport) {
              externalLinks = pdfReport.getElementsByTagName('a');
            }
            for (let i = 0; i < externalLinks.length; i++) {
              externalLinks[i].href = "JavaScript:void(0);"
            }
          }
        }

        errorTest(event) {
          this.errorTriggeredCb++;
          console.log(event, 'errorCalback')
        }
}
