import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Materiale } from '../../../models/materiale';

@Component({
  selector: 'navigazione',
  templateUrl: './navigazione.component.html',
  styleUrls: ['./navigazione.component.css']
})

export class NavigazioneComponent implements OnInit{
  @Input() folderList:Array<Materiale> = [];
  @Output() onNavigazioneEvent = new EventEmitter<Materiale>();

  constructor() { }
  ngOnInit(): void {

 }
 public addFolder(materiale:Materiale): void{
    this.folderList.push(materiale);
 }
 public goToRoot(): void{
    this.folderList=[];
    const materiale:Materiale=new Materiale();
    this.folderList.push(materiale);
    console.log("Navigazione emitter:"+materiale.Name);
    this.onNavigazioneEvent.emit(materiale);
 }
 onClick(materiale:Materiale): void{
    if (materiale.Codice===1){
      this.goToRoot();
      return;
    }
    let temFolderList:Array<Materiale>=[];
  //  this.folderList.forEach(element => {
    for(let element of this.folderList) {
      temFolderList.push(element);
      if (element.Codice===materiale.Codice){
        break;
      }
    }
    this.folderList=temFolderList;
    this.onNavigazioneEvent.emit(materiale);
 }
}

export interface INavigazioneComponent {

}
