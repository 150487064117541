import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-masters',
  templateUrl: './masters.component.html',
  styleUrls: ['./masters.component.css']
})
export class MastersComponent implements OnInit {
  selectedValueMaster:string;
  IPAddress:string;
  Port:string;
  Protocol:string;
  masters: Master[];
  moduli: Modulo[];
  currentMaster:Master;
  displayedColumns: string[] = [
    'Codicecorso',
    'Nome',
    'Descrizione',
    'Abilitato',
    'Modifica',
    'Elimina'];



    sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource:MatTableDataSource<Master>;

  constructor(private httpClient: HttpClient, private router: Router,
    private commonService: CommonService, private dialog: MatDialog) {}

  ngOnInit() {
    this.sortedDirection.set('Codicecorso',true);
    this.sortedDirection.set('Nome',true);
    this.sortedDirection.set('Descrizione',true);
    this.sortedDirection.set('Abilitato',true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllMasters().subscribe((res) => {
        this.masters = res;
        this.dataSource = new MatTableDataSource<Master>(this.masters);
        this.dataSource.paginator = this.paginator;
    });


  }

  ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
  }


  deleteMaster(master: Master): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione',description:'Confermi la cancellazione?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.commonService.deleteMaster(master.Codice).subscribe(data => {
          if (data===1){
            alert("Non si può eseguire la cancellazione in quanto sono presenti dei moduli collegati");
          }else{
            this.masters = this.masters.filter(u => u !== master);
            this.dataSource = new MatTableDataSource<Master>(this.masters);
          }
        });
      }
    });
  }


  aggiornaLista(){
    this.commonService.getAllMasters().subscribe(response => {
        this.masters=response;
        this.dataSource = new MatTableDataSource<Master>(this.masters);
    });
  }


   toggle(master: Master){
        const masterData = new FormData();
         masterData.append('codice', master.Codice.toString());
         this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/master/toggle/", masterData, { observe: 'response' })
         .subscribe((response) => {
             this.aggiornaLista();
         });
    }

   newMaster(){
     this.router.navigate(['addmaster']);
   }
   editMaster(master: Master){
     //console.log("-------"+modulo);
     this.commonService.selectedMaster=master;
     this.router.navigate(['addmaster']);
   }

   cancel(){
     this.masters=[];
   }

   applyFilter(filterValue: string) {
       this.dataSource.filter = filterValue.trim().toLowerCase();
       if (this.dataSource.paginator) {
         this.dataSource.paginator.firstPage();
       }
     }
  getDataSourceLength(){
    return this.masters.length;
  }

  manageClick(attributo: string){
    //alert(ev.target)
/*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Master[];
    tempList=this.masters;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Master>(this.masters);

   }

}
