<div class="master-container">
    <div class="titolo"> Materiali disponibili </div>
    <div class="container">

          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">{{getProperyValue("labelMaster")}}</mat-label>
            <mat-select [(ngModel)]="selectedValueMaster" name="master" (selectionChange)="manageChangeMaster()" class="text-sm">
              <mat-option *ngFor="let master of masters" [value]="master.Nome">
                {{master.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">{{getProperyValue("labelModulo")}}</mat-label>
            <mat-select [(ngModel)]="selectedValueModulo" name="modulo" (selectionChange)="manageChangeModulo()" class="text-sm">
              <mat-option *ngFor="let modulo of moduli" [value]="modulo.Nome">
                {{modulo.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">{{getProperyValue("labelLezioni")}}</mat-label>
            <mat-select [(ngModel)]="selectedValue" (ngModelChange)="aggiornaLista($event)" name="lezione" class="text-sm">
              <mat-option *ngFor="let lezione of lezioni" [value]="lezione.Codice">
                {{lezione.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <ng-container matColumnDef=" ">
              <th mat-header-cell *matHeaderCellDef mat-sort-header ></th>
              <td mat-cell *matCellDef="let materiale">
                <mat-icon color="primary">{{geticona(materiale)}}</mat-icon>
               </td>
            </ng-container>

            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Materiale"> Materiale
                 <img src="./assets/images/sort.png" (click)="manageClick('Name')"/>
              </th>
              <td mat-cell *matCellDef="let materiale" >
                  {{materiale.Name}}
               </td>
            </ng-container>

            <ng-container matColumnDef="Datapubblicazione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data
                <img src="./assets/images/sort.png" (click)="manageClick('Datapubblicazione')"/>
              </th>
              <td mat-cell *matCellDef="let materiale">
                  {{materiale.Datapubblicazione | date:'dd/MM/yyy'}}
               </td>
            </ng-container>

            <ng-container matColumnDef="Filename">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome file
                <img src="./assets/images/sort.png" (click)="manageClick('Filename')"/>
              </th>
              <td mat-cell *matCellDef="let materiale">
                  {{materiale.Filename}}
               </td>
            </ng-container>

            <ng-container matColumnDef="Visualizza">
                <th mat-header-cell *matHeaderCellDef> Visualizza </th>
                <td mat-cell *matCellDef="let materiale">
                  <mat-icon color="primary" (click)="visualizza(materiale)">play_circle_filled</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="Mimetype">
              <th mat-header-cell *matHeaderCellDef mat-sort-header hidden=true> Tipo
                <img src="./assets/images/sort.png" (click)="manageClick('Mimetype')"/>
              </th>
              <td mat-cell *matCellDef="let materiale" hidden=true>
                  {{materiale.Mimetype}}
               </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table>
          </div>
          <div>
            <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
          </div>

    <!--    <div fxLayout="row wrap" fxLayoutAlign="space-around center">
          <mat-card class="example-card" *ngFor = "let lezione of lezioni">
            <mat-card-header>
              <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                  <mat-card-title>{{lezione.Nome}}</mat-card-title>
              </div>
            </mat-card-header>
              <mat-card-content class="card-content">
                <img class="courseimage" src="{{lezione.Picbyte}}" >
                <p>
                  {{lezione.Descrizione}}
                </p>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="visualizzaCorso(lezione)">Visualizza</button>
            </mat-card-actions>
          </mat-card>
        </div>-->
        <div>
          <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
        </div>
      </div>
</div>
