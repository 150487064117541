import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CommonService } from "../../service/common.service";
import { NotifyService } from '../../service/notify.service';
import { Item } from '../../models/item';
import { Istanza } from '../../models/istanza';
import { MatDialog } from '@angular/material/dialog';
import { DialogSampleComponent } from '../../common/dialog-sample/dialog-sample.component';

import { startWith, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';


/*
import {
    GanttBarClickEvent,
    GanttViewType,
    GanttDragEvent,
    GanttLoadOnScrollEvent,
    GanttLineClickEvent,
    GanttLinkDragEvent,
    GanttItem,
    GanttViewOptions,
    GanttDate,
    GanttPrintService
} from '@worktile/gantt';
*/
@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.css']
})
export class GuestComponent implements OnInit {



constructor(private commonService: CommonService, private dialog: MatDialog, private notify: NotifyService) { }

ngOnInit(): void {
  this.commonService.currentIstanza=new Istanza();

}

/*
inizio:number=1514736000;
fine:number=1609430400;

viewType: GanttViewType = GanttViewType.month;
options = {
    viewType: GanttViewType.month,
    draggable: true,
    async: true,
    childrenResolve: this.getChildren.bind(this)
};

barClick(event: GanttBarClickEvent) {
     console.log(event);
 }

 dragEnded(event: GanttDragEvent) {
     this.items = [...this.items];
 }

 loadOnScroll(event: GanttLoadOnScrollEvent) {}

 getChildren(item: GanttItem) {
     return of([
         {
             id: new Date().getTime(),
             title: new Date().getTime(),
             start: Math.floor(new Date().getTime() / 1000),
             draggable: true,
             linkable: false
         }
     ]).pipe(delay(1000));
 }


viewOptions: GanttViewOptions = {
    start: new GanttDate(new Date('2024-01-01')),
    end: new GanttDate(new Date('2024-09-30'))
};

//  items: Item[] = [];
items: GanttItem[] = [
    { id: '000000', title: 'Task 0', start: 1706741999, end: 1709161199, expandable: true }];
    /*,
    { id: '000001', title: 'Task 1', start: 1617361997, end: 1625483597, links: ['000003', '000004', '000000'], expandable: true },
    { id: '000002', title: 'Task 2', start: 1610536397, end: 1610622797 },
    { id: '000003', title: 'Task 3', start: 1628507597, end: 1633345997, expandable: true },
    { id: '000004', title: 'Task 4', start: 1624705997, expandable: true },
    { id: '000005', title: 'Task 5', start: 1628075597, end: 1629544397, color: '#aaaaaa' },
    { id: '000006', title: 'Task 6', start: 1641121997, end: 1645528397 },
    { id: '000007', title: 'Task 7', start: 1639393997, end: 1640862797 },
    { id: '000008', title: 'Task 8', end: 1628783999, color: '#709dc1' },
    { id: '000009', title: 'Task 9', start: 1639307597, end: 1640344397 },
    { id: '0000010', title: 'Task 10', start: 1609067597, end: 1617275597 },
    { id: '0000011', title: 'Task 11', start: 1611918797, end: 1611918797 },
    { id: '0000012', title: 'Task 12', start: 1627816397, end: 1631358797 },
    { id: '0000013', title: 'Task 13', start: 1625051597, end: 1630667597, links: ['0000012'] },
    { id: '0000014', title: 'Task 14', start: 1627920000, end: 1629129599 },
    { id: '0000015', title: 'Task 15', start: 1633259597, end: 1639480397 },
    { id: '0000016', title: 'Task 16', start: 1624965197, end: 1627211597 },
    { id: '0000017', title: 'Task 17', start: 1641035597, end: 1649157197 },
    { id: '0000018', title: 'Task 18', start: 1637061197, end: 1642677197 },
    { id: '0000019', title: 'Task 19', start: 1637925197, end: 1646305997 }
];
*/






/*
  openDetails(id: number): void {
    this.commonService.get(id)
      .subscribe((itemDetails) => {
        const dialogRef = this.dialog.open(DialogSampleComponent, {
          width: '250px',
          data: itemDetails
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      }, (error => this.notify.openSnackBar(error.message)));
  }
*/
}
