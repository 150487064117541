import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import { UsernameData } from '../models/usernamedata';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  Username:string = ''


  @Input() error: string | null;

  constructor(private router: Router, private commonService: CommonService) { }

  ngOnInit() {

  }

  sendRecupero() {
    if (this.Username===undefined || this.Username===''){
      alert("E' necessario inserire lo Username");
      return;
    }
    //let usernamedata:UsernameData;
    //usernamedata.username=this.Username;
    this.commonService.newpassword(this.Username).subscribe(data => {
      if (data===0){
        alert("La nuova password è stata mandata alla email legata allo Username indicato");
      }else{
        alert("Errore reset password");
      }
    });
  }
 cancel(){
   this.router.navigate(['']);
 }

}
