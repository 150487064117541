import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Lezione } from '../../models/lezione';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { ConfirmData } from '../../models/confirmdata';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-courseshome',
  templateUrl: './courseshome.component.html',
  styleUrls: ['./courseshome.component.css']
})
export class CourseshomeComponent implements OnInit {
  selectedValueMaster:string;
  selectedValueModulo:string;
  IPAddress:string;
  Port:string;
  Protocol: string;
  masters: Master[];
  orderedmasters: Master[];
  currentMaster:Master;
  currentModulo:Modulo;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;


  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService,
  private dialog: MatDialog, public sanitizer: DomSanitizer) {}


  ngOnInit() {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
    this.masters=[];
    this.orderedmasters=[];

    this.commonService.getMasterOrdinati().subscribe(response => {
        this.orderedmasters=response;
        for (let i=0; i<this.orderedmasters.length; i++){
          this.httpClient.get(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/master/picture/' + this.orderedmasters[i].Codice)
            .subscribe(
              res => {
                this.retrieveResonse = res;
                if (this.retrieveResonse!=null && this.retrieveResonse.Picbyte!=null){
                  this.base64Data = this.retrieveResonse.Picbyte;
                  this.orderedmasters[i].Picbyte='data:image/jpeg;base64,' + this.base64Data;
                }else{
                  this.orderedmasters[i].Picbyte='assets/images/coursedefault.jpg';
                }
              }
            )};

            this.commonService.getAllMasters().subscribe((response) => {
                let tempmasters = response;
                tempmasters.forEach(element => {
                  if (element.Abilitato === 'YES'){
                    let trovato=false;
                    for (let m=0; m<this.orderedmasters.length; m++){
                      if (this.orderedmasters[m].Codicecorso===element.Codicecorso){
                        trovato=true;
                      }
                    }
                    if (!trovato){
                      this.masters.push(element);
                    }
                  }
                });




                for (let j=0; j<this.masters.length; j++){
                  this.httpClient.get(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/master/picture/' + this.masters[j].Codice)
                    .subscribe(
                      (res) => {
                        this.retrieveResonse = res;
                        if (this.retrieveResonse!=null && this.retrieveResonse.Picbyte!=null){
                          this.base64Data = this.retrieveResonse.Picbyte;
                          this.masters[j].Picbyte='data:image/jpeg;base64,' + this.base64Data;
                        }else{
                          this.masters[j].Picbyte='assets/images/coursedefault.jpg';
                        }
                      }
                    )};

              });

      });


  }
  ngAfterViewInit() {

  }

  getNomeByCodicemaster(codicemaster: number):String{
    let nomemaster:String="";
    this.masters.forEach(element => {
      if (element.Codice === codicemaster){
        nomemaster=element.Nome;

      }
    });
    return nomemaster;
  }

  visualizza(master:Master){
      this.commonService.selectedMaster=master;
      this.router.navigate(['courses']);

    //  this.commonService.selectedLezione=corso;
    //  this.router.navigate(['courseviewer']);
  }
  cancel(){

  }
}
