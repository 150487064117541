
export class Attivita {

  Codice: number;
	Nome: string;
	Descrizione: string;


  constructor(){
    this.Nome='';
    this.Codice=0;
    this.Descrizione='';

  }
}
