import { Component, OnInit, Input, Output } from '@angular/core';
import { CommonService } from "../../../service/common.service";
import { Progettoattivita } from '../../../models/progettoattivita';

import { startWith, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'mese',
  templateUrl: './mese.component.html',
  styleUrls: ['./mese.component.css']
})
export class MeseComponent implements OnInit {

attivitas:Progettoattivita[]=[];

constructor(private commonService: CommonService) { }


@Input() valore:any;

@Output() public refresh(){
  this.refreshHeader();
}

giorni;
valorigiorni;

numattivita:number=3;

numerogiorninelmese=[31,28,31,30,31,30,31,31,30,31,30,31];
numerogiorni=0;
nome='';

  ngOnInit(): void {
    if (this.isBisestile(this.valore.yyyy)){
      this.numerogiorninelmese[0]=29;
    }
    this.refresh();
  }


  public refreshHeader(){
    if (this.valore.mm>0) {
      this.numerogiorni=this.numerogiorninelmese[this.valore.mm-1];
      //console.log("Numero giorni:"+this.numerogiorni);
      this.giorni=new Array(this.numerogiorni);
      for(var i = 0;i<this.numerogiorni;i++) {
    //    this.giorni[i]={yyyy: this.valore.yyyy, mm: this.valore.mm, dd:i+1, attivitas:[{id:1, Nome:'Installazione', 'Datainizio':'03/02/2024', 'Datafine':'10/02/2024'}, {id:2, Nome:'pulizia', 'Datainizio':'11/02/2024', 'Datafine':'12/02/2024'}]};
        this.giorni[i]={yyyy: this.valore.yyyy, mm: this.valore.mm, dd:i+1};
      }
    }
  }

 isBisestile(anno:number){
   let rc:boolean=false;
      if (anno > 1584 && (
        (anno % 400 == 0) ||
        (anno %4 == 0 && anno % 100 != 0)
      )){
        rc=true;
      }
    return rc;
 }

 @Output() getNome():string{
   return this.nome;
 }
}
