import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { Permesso } from '../../models/permesso';


@Component({
  selector: 'app-addcommento',
  templateUrl: './addcommento.component.html',
  styleUrls: ['./addcommento.component.css']
})
export class AddcommentoComponent implements OnInit {
  IPAddress:string;
  Port:string;
  permesso: Permesso;
  constructor(public dialogRef: MatDialogRef<AddcommentoComponent>,
    private commonService: CommonService, private httpClient: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.permesso=this.data.permesso;
      //console.log("Init Permesso:"+this.permesso.Codice);
    }

    ngOnInit(): void {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
      }
  }

  saveCommento(){
    if (this.permesso!=null && this.permesso!=undefined){
      console.log("Permesso:"+this.permesso.Codice);
      console.log("Commento:"+this.permesso.Commento);
      this.commonService.saveCommento(this.permesso).subscribe(data => {
        let rc;
        rc=JSON.stringify(data);
        console.log("Salva commento:"+rc);
        if (rc==="0"){
          alert("Commento salvato con successo");
        }else{
          alert("Errore salvataggio commento");
        }
      });
    }
  }

}
