import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Documento } from '../../models/documento';
import { Categoria } from '../../models/categoria';
import { Persona } from '../../models/persona';
import { Azienda } from '../../models/azienda';
import { Gruppo } from '../../models/gruppo';
import { Stato } from '../../models/stato';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { configurazione } from '../../../configurazioni/configurazione';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-documenti',
  templateUrl: './documenti.component.html',
  styleUrls: ['./documenti.component.css']
})
export class DocumentiComponent implements OnInit {

  IPAddress:string;
  Port:string;
  Protocol: string;

  selectedValueAzienda:string;
  currentAzienda:Azienda;
  currentCategoria:Categoria;
  currentPersona:Persona;
  currentGruppo:Gruppo;
  currentStato:Stato;
  fileUrl;
  rolename:string;
  displayedColumns: string[] = [
  "Nome",
  "Descrizione",
  "Filename",
  "Nota",
  "Datapubblicazione",
  "Stato",
  "Elimina",
  "Scarica",
  "Invia",
  "Tipodestinatario"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();


  documenti: Documento[]=[];
  cachedocumenti: Documento[]=[];
  dataSource = new MatTableDataSource<Documento>(this.documenti);
  categorie: Categoria[];
  selectedValueCategoria: string='';
  persone:Persona[];
  aziende: Azienda[];
  gruppi:Gruppo[];
  selectedValuePersona: string='';
  selectedValueGruppo: string='';
  stati:Stato[];
  selectedValueStato: string='';

  @ViewChild('paginator') paginator: MatPaginator;

  constructor(private httpClient: HttpClient, private router: Router, private dialog: MatDialog, private commonService: CommonService, private sanitizer: DomSanitizer) {}

  ngOnInit() {

    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);
    this.sortedDirection.set("Filename",true);
    this.sortedDirection.set("Datapubblicazione",true);
    this.sortedDirection.set("Nota",true);
    this.sortedDirection.set("Stato",true);
    this.sortedDirection.set("Elimina",true);
    this.sortedDirection.set("Tipodestinatario",true);
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.dataSource.paginator = this.paginator;

    //    if (this.commonService.currentTipocategoria===undefined || this.commonService.currentTipocategoria===null || this.commonService.currentTipocategoria===''){

    if (this.commonService.selectedPersona!=undefined){
      this.commonService.tipoDestinatario="Persona";
      this.currentPersona=this.commonService.selectedPersona;
      this.persone=[];
      this.persone.push(this.commonService.selectedPersona);
      this.persone.forEach(element => {
        if (element.Codice === this.commonService.selectedPersona.Codice){
          this.selectedValuePersona=element.Cognome;
        }
      });
      this.commonService.currentGruppoCodice=undefined;
    }else if (this.commonService.selectedGruppo!=undefined){
      this.commonService.tipoDestinatario="Gruppo";
      this.currentGruppo=this.commonService.selectedGruppo;
      this.commonService.currentGruppoCodice=this.commonService.selectedGruppo.Codice;
      this.gruppi=[];
      this.gruppi.push(this.commonService.selectedGruppo);
      this.gruppi.forEach(element => {
        if (element.Codice === this.commonService.selectedGruppo.Codice){
          this.selectedValueGruppo=element.Nome;
        }
      });
      this.commonService.currentPersonaCodice=undefined;
    }else{
      this.commonService.currentPersonaCodice=undefined;
      this.commonService.currentGruppoCodice=undefined;
      this.commonService.tipoDestinatario="Pubblico";
    }

//    if (this.selectedValueAzienda===undefined){
      this.commonService.getAllAziende().subscribe((res) => {
          this.aziende = res;
          if (this.aziende.length>0){
            this.currentAzienda=this.aziende[0];
            this.selectedValueAzienda=this.aziende[0].Nome;
            this.commonService.currentAziendaCodice=this.aziende[0].Codice;
          }
          const postData = new FormData();
          postData.append('codiceazienda', this.commonService.currentAziendaCodice.toString());
          postData.append('tipocategoria', this.commonService.tipoDestinatario);
          this.commonService.getAllCategorieByAziendaTipo(postData).subscribe((res) => {
            this.categorie = res;
            if (this.commonService.currentCategoria!=undefined){
              this.categorie.forEach(element => {
                if (element.Codice === this.commonService.currentCategoria.Codice){
                  this.selectedValueCategoria=element.Nome;
                  this.currentCategoria=element;
                }
              });
            }
            this.commonService.getAllStati().subscribe((res) => {
                this.stati = res;
                if (this.commonService.currentStato!=undefined){
                  this.stati.forEach(element => {
                    if (element.Codice === this.commonService.currentStato.Codice){
                      this.selectedValueStato=element.Nome;
                      this.currentStato=element;
                    }
                  });
                }
                this.aggiornaLista();
            });
          });
      });

//    }
    this.rolename=sessionStorage.getItem("rolename");
    this.aggiornaLista();



  }

  ngAfterInit(): void {
//    this.navigazione.goToRoot();
    this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
  }

  manageChangeAzienda(){


    if (this.selectedValueAzienda===undefined){
     return;
    }
    this.aziende.forEach(element => {
      if (this.selectedValueAzienda === element.Nome){
        this.currentAzienda=element;
        this.selectedValueAzienda=this.currentAzienda.Nome;
      }
    });
    const postData = new FormData();

    postData.append('codiceazienda', this.currentAzienda.Codice.toString());
    postData.append('tipocategoria', this.commonService.tipoDestinatario);
    this.commonService.getAllCategorieByAziendaTipo(postData).subscribe((res) => {
      this.categorie = res;
    });
    this.documenti=[];
    this.selectedValueCategoria=undefined;
  }

  manageChangeCategoria(){

    if (this.selectedValueCategoria===undefined){
     return;
    }
    this.categorie.forEach(element => {
      if (this.selectedValueCategoria === element.Nome){
        this.currentCategoria=element;
        this.commonService.currentCategoria=element;
      }
    });
    this.aggiornaLista();
  }

  manageChangeStato(){
    if (this.selectedValueStato===undefined){
     return;
    }
    this.stati.forEach(element => {
      if (this.selectedValueStato === element.Nome){
        this.currentStato=element;
        this.commonService.currentStato=element;
      }
    });
    this.refreshLista();
  }



/*
  getNomeByCodicecategoria(codicecategoria: number):String{
    let nomecategoria:String="";
    this.categorie.forEach(element => {
      if (element.Codice === codicecategoria){
        nomecategoria=element.Nome;
        return element.Nome;
      }
    });
    return nomecategoria;
  }
*/
  getCodiceCategoriaByNome(nome: string){
    var rc:number;
    this.categorie.forEach(element => {
      if (nome === element.Nome){
        rc=element.Codice;
      }
    });
    return rc;
  }

  aggiornaLista(){
    if (this.currentCategoria!=undefined){
      this.commonService.currentCategoriaCodice=this.currentCategoria.Codice;
  //    console.log("Categoria="+this.commonService.currentCategoriaCodice);

      if (this.currentPersona!=undefined){
        this.commonService.currentPersonaCodice=this.currentPersona.Codice;
  //      console.log("Persona="+this.commonService.currentPersonaCodice);
        const postData = new FormData();
        postData.append('codicecategoria', this.commonService.currentCategoriaCodice.toString());
        postData.append('codicepersona', this.commonService.currentPersonaCodice.toString());
        this.commonService.getAllDocumentiByPersonaCategoria(postData).subscribe((res) => {
  //        this.documenti = res;
          this.cachedocumenti = res;
          this.refreshLista();
  //        this.dataSource = new MatTableDataSource<Documento>(this.documenti);
  //        this.dataSource.paginator = this.paginator;
        });
      } else if (this.currentGruppo!=undefined){
        this.commonService.currentGruppoCodice=this.currentGruppo.Codice;
        const postData = new FormData();
        postData.append('codicecategoria', this.commonService.currentCategoriaCodice.toString());
        postData.append('codicegruppo', this.commonService.currentGruppoCodice.toString());
        this.commonService.getAllDocumentiByGruppoCategoria(postData).subscribe((res) => {
  //        this.documenti = res;
          this.cachedocumenti = res;
          this.refreshLista();
  //        this.dataSource = new MatTableDataSource<Documento>(this.documenti);
  //        this.dataSource.paginator = this.paginator;
        });
      }  else{
        //alert(this.currentCategoria.Codice);
        var codicecategoria=this.currentCategoria.Codice;
        this.commonService.getAllDocumentiByCategoriaid(codicecategoria).subscribe((res) => {
  //        this.documenti = res;
          this.cachedocumenti = res;
          this.refreshLista();
  //        this.dataSource = new MatTableDataSource<Documento>(this.documenti);
  //        this.dataSource.paginator = this.paginator;
        });
      }
    }

  }

  refreshLista(){
     //console.log(this.currentStato.Nome);
     this.documenti=[];
     this.cachedocumenti.forEach(element => {
       //console.log(element.Nomestato);
       if (this.currentStato.Nome === element.Nomestato){
         this.documenti.push(element);
       }
     });
     this.dataSource = new MatTableDataSource<Documento>(this.documenti);
     this.dataSource.paginator = this.paginator;
   }
/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/
  isEnabledToLoad(): boolean{
    //console.log("Ruolo="+this.rolename)
    return (this.rolename==="Docente" || this.rolename==="HR");
  }
  newDocumento(): any {

    this.commonService.selectedAzienda=this.currentAzienda;
    this.commonService.currentAziendaCodice=this.currentAzienda.Codice;
  //  this.commonService.tipoDestinatario="Pubblico";
    if (this.selectedValueCategoria != undefined){
      this.commonService.currentCategoriaCodice=this.getCodiceCategoriaByNome(this.selectedValueCategoria);

      this.router.navigate(['newdocumento']);
    }
  }

  downloadDocumento(documento:Documento): any {
    const data = 'some text';
    const blob = new Blob([data], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    return this.commonService.downloadDocumento(documento);
  }

  sendDocumento(documento:Documento): any {
    const postData = new FormData();
    postData.append('codicedocumento', documento.Codice.toString());
    postData.append('nomestato', this.getConfigurazione().stato.stato_inviato);

    this.httpClient.post(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/documenti/updatestato", postData, { observe: 'response' })
      .subscribe((response) => {
    //    console.log(response)
        if (response.status === 200) {
          alert('Documento inviato correttamente');
          this.aggiornaLista();
        } else {
          alert('Errore invio documento');
        }
//        this.router.navigate(['documenti']);
      });
  }


  eliminaDocumento(documento: Documento){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione',description:'Confermi la cancellazione?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        const documentoData = new FormData();
        documentoData.append('codice', documento.Codice.toString());
  //      this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/materiale/toggle/", materialeData, { observe: 'response' })
        this.httpClient.delete(this.Protocol+"://"+this.IPAddress+":"+this.Port+'/api/documenti/'+ documento.Codice)
        .subscribe((response) => {
            this.aggiornaLista();
        });
      }
    });

  }

  isSelectedPersona(){
   return this.commonService.tipoDestinatario==="Persona";
  }
  isSelectedGruppo(){
   return this.commonService.tipoDestinatario==="Gruppo";
  }
  isSelectedPubblico(){
   return this.commonService.tipoDestinatario==="Pubblico";
  }

  cancel(){
    this.selectedValueCategoria=undefined;
    this.currentCategoria=undefined;
    this.selectedValueStato=undefined;
    this.documenti = [];
    this.cachedocumenti = [];
    this.refreshLista();
  }

  getConfigurazione(){
    return configurazione.persona.fb;
  }

  ngDoCheck(){
  //  alert("ngDoCheck");
  }
  ngAfterContentInit(){
  //  alert("ngAfterContentInit");
  }

  ngAfterContentChecked(){
//    alert("ngAfterContentChecked");
  }
  ngAfterViewInit(){
//    alert("ngAfterViewInit");
  }
  ngAfterViewChecked(){
//    alert("ngAfterViewChecked");
  }

  ngOnDestroy(){

  }

  manageClick(attributo: string){
    //alert(ev.target)
/*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Documento[];
    tempList=this.documenti;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Documento>(this.documenti);

   }

}
