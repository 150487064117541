import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Lul } from '../../models/lul';
import { Template } from '../../models/template';
import { Azienda } from '../../models/azienda';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Inject, LOCALE_ID } from '@angular/core';
import { formatDate} from '@angular/common';
import {FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
@Component({
  selector: 'app-addlul',
  templateUrl: './addlul.component.html',
  styleUrls: ['./addlul.component.css']
})
export class AddlulComponent implements OnInit {
  registerForm: FormGroup;
  lul: Lul;
  hide = true;
//  selectedValueTemplate:string='';
//  selectedValueAzienda:string='';
  currentTemplate: Template;
  currentAzienda: Azienda;
  templates: Template[];
  aziende: Azienda[];
  rc:String;
  lultoedit: boolean;

//  Nome:String="";
//  Descrizione:String="";
//  Azienda:String="";
//  Paginainizio:number=0;
//  Paginafine:number=0;

//  Orainizio: string;
//  Orafine: string;
//  Data: Date= new Date();;

  numberFormControl = new FormControl('', [Validators.pattern('[0-9]*')]); //Validators.required, Validators.number
  matcher = new MyErrorStateMatcher();


  constructor(private commonService: CommonService, private router: Router, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
      //  console.log("+++++"+this.commonService.selectedLul);

      if (this.registerForm===undefined){
         this.registerForm = this.formBuilder.group({
              Nometemplate: [''],
              Nomeazienda: [''],
              Nome: ['', Validators.required],
              Descrizione:['', Validators.required],
              Codice: ['0'],
              Codicetemplate: ['0'],
              Codiceazienda: ['0'],
              Paginainizio: ['0'],
              Paginafine: ['0'],
              Materialeid: ['0'],
              Materialename: [''],
              Materialefilename: ['']
          });
        }

        this.commonService.getAllTemplates().subscribe((res) => {
          this.templates = res;
          this.templates.forEach(element => {
            console.log("--->"+element.Codice);
          });
          this.commonService.getAllAziende().subscribe((resaziende) => {
            this.aziende=resaziende;

            if (this.commonService.selectedLul!=undefined){
              this.lul=this.commonService.selectedLul;
              this.commonService.selectedLul=undefined;
              this.templates.forEach(element => {
                    if (element.Codice === this.lul.Codicetemplate){
                        this.lul.Nometemplate=element.Nome;
                        this.currentTemplate=element
                    }

                });
                this.aziende.forEach(elementazienda => {
                  if (elementazienda.Codice === this.lul.Codiceazienda){
                    this.lul.Nomeazienda=elementazienda.Nome;
                    this.currentAzienda=elementazienda;
                  }
                });
                this.registerForm.setValue({
                          Nometemplate:this.lul.Nometemplate,
                          Nomeazienda:this.lul.Nomeazienda,
                          Nome: this.lul.Nome,
                          Codice: this.lul.Codice,
                          Codicetemplate: this.lul.Codicetemplate,
                          Descrizione: this.lul.Descrizione,
                          Codiceazienda: this.lul.Codiceazienda,
                          Paginainizio: this.lul.Paginainizio,
                          Paginafine: this.lul.Paginafine,
                          Materialeid: this.lul.Materialeid,
                          Materialename: this.lul.Materialename,
                          Materialefilename: this.lul.Materialefilename
                });
            }else{
                this.lul=new Lul();
            }
            });
        });
  }
  ngAfterViewInit(){
    //console.log("---->"+this.lezione.Datainizio.toString());
    //this.Data=new Date();
    //console.log("---->"+this.Data.toString());
  }


  getNomeByCodiceazienda(codiceazienda: number):String{
    let nomeazienda:String="";
    this.aziende.forEach(element => {
      if (element.Codice === codiceazienda){
        nomeazienda=element.Nome;
        return;
      }
    });
    return nomeazienda;
  }


  manageChangeTemplate(){

//   if (this.registerForm.value.Nometemplate===undefined){
//     this.registerForm.value.Codicetemplate=0;
//     this.registerForm.value.Nometemplate='';
//   }else{
      this.templates.forEach(element => {
        if (this.registerForm.value.Nometemplate === element.Nome){
          this.currentTemplate=element;
    //      this.lul.Codicetemplate=element.Codice;
    //      this.lul.Nometemplate=element.Nome;
    //      this.registerForm.value.Codicetemplate=element.Codice;
        }
      });

//      alert(this.registerForm.value.Codicetemplate)
//    }
  }

  manageChangeAzienda(){
//   if (this.registerForm.value.Nomeazienda===undefined){
//     this.registerForm.value.Codiceazienda=0;
//     this.registerForm.value.Nomeazienda='';
//   }else{
      this.aziende.forEach(element => {
        if (this.registerForm.value.Nomeazienda === element.Nome){
          this.currentAzienda=element;
    //      this.lul.Codiceazienda=element.Codice;
    //      this.lul.Nomeazienda=element.Nome;
    //      this.registerForm.value.Codiceazienda=element.Codice;
        }
      });
//      alert(this.registerForm.value.Codiceazienda)
//    }
  }




  save() {

   if (this.registerForm.invalid) {
        alert("Errore: verifica i campi indicati con *");
        return;
   }else{
      if (this.currentTemplate!=undefined){
        this.registerForm.value.Codicetemplate=this.currentTemplate.Codice;
      }
      if (this.currentAzienda!=undefined){
        this.registerForm.value.Codiceazienda=this.currentAzienda.Codice;
      }
      this.commonService.saveLul(this.registerForm.value).subscribe(data => {

        this.rc=JSON.stringify(data)
        if (this.rc==="0"){
        //  alert("Lul creato con successo");

          this.router.navigate(['lul']);
        }else if (this.rc==="2"){
          alert("Lul già esistente");
        }else{
          alert("Errore creazione lul");
        }
      });
    }
  }

  cancel(){
    this.commonService.selectedLul=null;
    this.router.navigate(['lul']);
  }


}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
