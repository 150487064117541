<div class="master-container">
  <div class="titolo"> Ordini effettuati </div>
  <div class="container">
      <div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="Numeroordine">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero ordine
                  <img src="./assets/images/sort.png" (click)="manageClick('Numeroordine')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Numeroordine}} </td>
            </ng-container>

            <ng-container matColumnDef="Dataordine">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data ordine
                  <img src="./assets/images/sort.png" (click)="manageClick('Dataordine')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Dataordine | date:'dd/MM/yyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="Nomecorso">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nomecorso
                  <img src="./assets/images/sort.png" (click)="manageClick('Nomecorso')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Nomecorso}} </td>
            </ng-container>

            <ng-container matColumnDef="Codicecorso">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice
                  <img src="./assets/images/sort.png" (click)="manageClick('Codicecorso')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Codicecorso}} </td>
            </ng-container>

            <ng-container matColumnDef="Statoordine">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Statoordine
                  <img src="./assets/images/sort.png" (click)="manageClick('Statoordine')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Statoordine}} </td>
            </ng-container>

            <ng-container matColumnDef="Telefono">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono
                  <img src="./assets/images/sort.png" (click)="manageClick('Telefono')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Telefono}} </td>
            </ng-container>

            <ng-container matColumnDef="Provincia">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Provincia
                  <img src="./assets/images/sort.png" (click)="manageClick('Provincia')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Provincia}} </td>
            </ng-container>

            <ng-container matColumnDef="Comune">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Comune
                  <img src="./assets/images/sort.png" (click)="manageClick('Comune')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Comune}} </td>
            </ng-container>

            <ng-container matColumnDef="Cap">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CAP
                  <img src="./assets/images/sort.png" (click)="manageClick('Cap')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Cap}} </td>
            </ng-container>

            <ng-container matColumnDef="Indirizzo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Indirizzo
                  <img src="./assets/images/sort.png" (click)="manageClick('Indirizzo')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Indirizzo}} </td>
            </ng-container>

            <ng-container matColumnDef="Cognome">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome
                  <img src="./assets/images/sort.png" (click)="manageClick('Cognome')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Cognome}} </td>
            </ng-container>

            <ng-container matColumnDef="Nome">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                  <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Nome}} </td>
            </ng-container>

            <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email
                  <img src="./assets/images/sort.png" (click)="manageClick('Email')"/>
                </th>
                <td mat-cell *matCellDef="let ordineantur"> {{ordineantur.Email}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div>
        <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
      </div>
      <!--
      <div>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </div>
      -->
</div>


<div>
