import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { CommonService } from "../../service/common.service";
import { EventEmitterService } from '../../service/event-emitter.service';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import {Istanza} from '../../models/istanza';

@Component({
  selector: 'app-dthink',
  templateUrl: './dthink.component.html',
  styleUrls: ['./dthink.component.css']
})
export class DthinkComponent implements OnInit {

  constructor(private commonService: CommonService,
    private eventEmitterService: EventEmitterService,
    private router: Router) { }

  ngOnInit() {
    console.log("Set istanza dthink");
    this.commonService.currentIstanza=new Istanza();
    this.commonService.currentIstanza.Codice=0;
    this.commonService.getAllIstanze().subscribe((res) => {
        res.forEach(element => {
          if (element.Codice===7){
            this.commonService.currentIstanza=element;

          }
        });
        this.router.navigate(['']);
      });
    }
}
