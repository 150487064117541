import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Ruolo } from '../../models/ruolo';
import { Persona } from '../../models/persona';
import { Gruppo } from '../../models/gruppo';
import { Gruppopersona } from '../../models/gruppopersona';
import { MatSelectionList } from '@angular/material/list';
import { MatSelectionListChange } from '@angular/material/list';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-addtogruppi',
  templateUrl: './addtogruppi.component.html',
  styleUrls: ['./addtogruppi.component.css']
})
export class AddtogruppiComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol:string;
  selectedValueGruppo:string;
  gruppi: Gruppo[];
  persone:Array<Persona>=[];
  currentGruppo:Gruppo;
  currentPersona:Persona;

  selectedPersone:Array<Persona>=[];
//  @ViewChild(MatSelectionList, {static: true})
  private selectionList:  MatSelectionList;
  private associatedSelectionList:  MatSelectionList;
  associatedpersone:Array<Persona>=[];

  @ViewChildren(MatSelectionList) selections: QueryList<MatSelectionList>;

  constructor(private commonService: CommonService, private httpClient: HttpClient) {

  }

  ngOnInit(): void {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllGruppi().subscribe((res1) => {
        this.gruppi = res1;
        this.manageChangeGruppo();

        this.commonService.getPersone(this.commonService.currentIstanza.Codice).subscribe((res2) => {
                  this.persone = res2;
        });
  //      this.commonService.getAllPersoneByGruppoid(this.currentGruppo.Codice).subscribe((res2) => {
  //          this.persone = res2;
  //          this.commonService.getAllLezioni().subscribe((res3) => {
  //              this.lezioni = res3;
  //          });
  //      });
    });

  }
  ngAfterViewChecked() {
      if (this.selections.length===2){
        this.selectionList=this.selections.toArray()[0] as MatSelectionList;
        this.associatedSelectionList=this.selections.toArray()[1] as MatSelectionList;
      }

  }

  //manageChangeGruppo(){
  //  if (this.selectedValueRuolo===undefined || this.selectedValueRuolo===""){
  //   return;
  //  }
  //  this.gruppi.forEach(element => {
  //    if (this.selectedValueGruppo === element.Nome){
  //      this.currentGruppo=element;
  //    }
  //  });
  //  if (this.currentRuolo.Nome==='Docente' || this.currentRuolo.Nome==='Tutor'){
  //    this.commonService.getAllUsersByRuoloid(this.currentRuolo.Codice).subscribe((res) => {
  //        this.users = res;
  //    });
  //  }else{
  //    this.users=[];
  //    alert("Il ruolo selezionato non prevede associazioni a lezioni");
  //  }
  //}
  manageChangeGruppo(){
    if (this.selectedValueGruppo===undefined || this.selectedValueGruppo===""){
     return;
    }



    this.associatedpersone=[];
    this.gruppi.forEach(element => {
      if (this.selectedValueGruppo === element.Nome){
        this.currentGruppo=element;
        this.commonService.getAllPersoneByGruppoid(this.currentGruppo.Codice).subscribe((res) => {
          const r:Persona[]=res;
          r.forEach(element => {
            //console.log(element);
            this.associatedpersone.push(element as Persona);
          });
        });
      }
    });


  }

  save() {
    if (this.selectedValueGruppo===undefined || this.selectedValueGruppo===""){
     alert("E' necessario selezionare un gruppo");
     return;
    }
    let codicegruppo:number=0;
    let gruppo:Gruppo;
    this.gruppi.forEach(element => {
      if (this.selectedValueGruppo === element.Nome){
            codicegruppo=element.Codice;
            gruppo=element;
      }
    });

    const formData = new FormData();
    formData.append('gruppoid', codicegruppo.toString());
    this.httpClient.put(
      this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/gruppi/persone", formData,  { observe: 'response' })
      .subscribe((response) => {
        let rc;
        rc=JSON.stringify(response)
        if (rc!="0"){
          console.log("Cancellazione persone:"+rc);
          //alert("Errore aggiornamento docenti");
          //return;
          let gruppipersona:Array<Gruppopersona>=[];
          this.associatedpersone.forEach(element => {
            let gp:Gruppopersona=new Gruppopersona();
            gp.Personaid=element.Codice;
            gp.Gruppoid=codicegruppo;
            gruppipersona.push(gp);
          });
          //console.log("Lista docenti selezionati"+docenti);

          this.commonService.savePersone(gruppipersona).subscribe(data => {
            let rc;
            rc=JSON.stringify(data)
            if (rc==="0"){
              alert("Associazione effettuata con successo");
              this.cancel();
            }else{
              alert("Errore associazione");
            }
          });

        }
      });



  }
  cancel(){
    //this.selectedValueRuolo="";
    this.selectedValueGruppo="";
    //this.users=[];
    this.associatedpersone=[];
  }

  add(){
    //alert("Add");
    const checkedItems = this.selectionList.selectedOptions.selected;
    //const associatedCheckedItems = this.associatedSelectionList.selectedOptions.selected;
    //alert(checkedItems.length+" "+associatedCheckedItems.length);

    this.selectedPersone=checkedItems.map(i => {
    //  console.log("+++ "+i);
      //console.log("+++ "+i.selected);
      i.selected=false;
      return i.value});
    this.selectedPersone.forEach(element => {
    //  console.log(element);
      const index = this.associatedpersone.findIndex(persona => (persona.Nome === element.Nome && persona.Cognome === element.Cognome));
     //console.log("---- "+index);

      if (index != undefined && index<0){

        this.associatedpersone.push(element as Persona);
      }
    });

  }
  remove(){
    const checkedItems = this.associatedSelectionList.selectedOptions.selected;
  //  alert(checkedItems.length)
    const tempPersone=checkedItems.map(i => i.value);
  //  console.log(tempUsers.length);
    tempPersone.forEach(element1 => {
  //    console.log(element1.Userid);
      const index = this.associatedpersone.findIndex(persona => persona.Nome === element1.Nome);
      this.associatedpersone.splice(index, 1);
    });
  }

  manageSelectUser(e, v){
   console.error(e.option.value);
  }
  manageAssociatedSelectionChange(e: MatSelectionListChange) {
//    const checkedItems = this.associatedSelectionList.selectedOptions.selected;
//    console.log(checkedItems.map(i => i.value));
  }
  manageSelectionChange(e: MatSelectionListChange) {
//    const checkedItems = this.selectionList.selectedOptions.selected;
//    console.log(checkedItems.map(i => i.value));
  }
}
