<mat-card class="matcardcontent">
    <mat-card-header>
        <mat-card-title>Lista moduli</mat-card-title>
    </mat-card-header>
    <mat-card-content>

           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Master</mat-label>
             <mat-select [(ngModel)]="selectedValueMaster" name="master" (selectionChange)="manageChangeMaster()" class="text-sm">
               <mat-option *ngFor="let master of masters" [value]="master.Nome">
                 {{master.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>

      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

          <ng-container matColumnDef="Nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
              </th>
              <td mat-cell *matCellDef="let modulo"> {{modulo.Nome}} </td>
          </ng-container>

          <ng-container matColumnDef="Descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione
                <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
              </th>
              <td mat-cell *matCellDef="let modulo"> {{modulo.Descrizione}} </td>
          </ng-container>

          <ng-container matColumnDef="Abilitato">
            <th mat-header-cell *matHeaderCellDef> Abilitato
              <img src="./assets/images/sort.png" (click)="manageClick('Abilitato')"/>
            </th>
            <td mat-cell *matCellDef="let modulo">
              <mat-icon *ngIf="modulo.Abilitato==='YES'"  color="primary" (click)="toggle(modulo)">check_circle</mat-icon>
              <mat-icon *ngIf="modulo.Abilitato==='NO'"  color="primary" (click)="toggle(modulo)">check_circle_outline</mat-icon>
           </td>
          </ng-container>

          <ng-container matColumnDef="Modifica" mat-sort-header>
            <th mat-header-cell *matHeaderCellDef> Modifica </th>
            <td mat-cell *matCellDef="let modulo">
              <mat-icon color="primary" (click)="editModule(modulo)">create</mat-icon>
           </td>
          </ng-container>

          <ng-container matColumnDef="Elimina">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Elimina </th>
            <td mat-cell *matCellDef="let modulo">
              <mat-icon color="primary" (click)="deleteModule(modulo)">delete</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>

    </mat-card-content>

    <mat-card-actions>
  <!--
      <table>
        <tr>
          <td>
          -->
            <button mat-raised-button  color="primary" (click)="newModule()">Nuovo modulo</button>

            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
<!--
          </td>
        </tr>
      </table>
-->

      </mat-card-actions>


</mat-card>
