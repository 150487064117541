import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Ruolo } from '../../models/ruolo';
import { User } from '../../models/user';
import { Lezione } from '../../models/lezione';
import { Docente } from '../../models/docente';
import { MatSelectionList } from '@angular/material/list';
import { MatSelectionListChange } from '@angular/material/list';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-addtolezioni',
  templateUrl: './addtolezioni.component.html',
  styleUrls: ['./addtolezioni.component.css']
})
export class AddtolezioniComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol:string;
  selectedValueRuolo:string;
  selectedValueLezione:string;
  ruoli: Ruolo[];
  users:Array<User>=[];
  lezioni: Lezione[];
  currentRuolo:Ruolo;
  currentLezione:Lezione;

  selectedUsers:Array<User>=[];
//  @ViewChild(MatSelectionList, {static: true})
  private selectionList:  MatSelectionList;
  private associatedSelectionList:  MatSelectionList;
  associatedUsers:Array<User>=[];
  @ViewChildren(MatSelectionList) selections: QueryList<MatSelectionList>;

  constructor(private commonService: CommonService, private httpClient: HttpClient) {

  }

  ngOnInit(): void {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllRuoli().subscribe((res1) => {
        this.ruoli = res1;
  /*
        this.selectedValueRuolo='Docente';
        this.manageChangeRuolo();
        this.commonService.getAllUsersByRuoloid(this.currentRuolo.Codice).subscribe((res2) => {
            this.users = res2;
            this.commonService.getAllLezioni().subscribe((res3) => {
                this.lezioni = res3;
            });
        });
        */
    });




  }
  ngAfterViewChecked() {
    //  console.log(this.selections);
      if (this.selections.length===2){
        this.selectionList=this.selections.toArray()[0] as MatSelectionList;
  //      console.log(this.selectionList);
        this.associatedSelectionList=this.selections.toArray()[1] as MatSelectionList;
      }

  }

  manageChangeRuolo(){
    if (this.selectedValueRuolo===undefined ||  this.selectedValueRuolo===''){
     return;
    }
    this.ruoli.forEach(element => {
      if (this.selectedValueRuolo === element.Nome){
        this.currentRuolo=element;
      }
    });
    this.commonService.getAllUsersByRuoloid(this.currentRuolo.Codice).subscribe((res) => {
          this.users = res;
    });

  }
  manageChangeLezione(){
    if (this.selectedValueLezione==undefined){
     return;
    }
    this.associatedUsers=[];
    this.lezioni.forEach(element => {
      if (this.selectedValueLezione === element.Nome){
        this.currentLezione=element;

        this.commonService.getAllUsersByLezioneid(this.currentLezione.Codice).subscribe((res) => {
          const r:User[]=res;
          r.forEach(element => {
            //console.log(element);
            this.associatedUsers.push(element as User);
          });
        });
      }
    });

  }

  save() {
    if (this.selectedValueLezione===undefined || this.selectedValueLezione===""){
     alert("E' necessario selezionare una lezione");
     return;
    }
    let codicelezione:number=0;
    let lezione:Lezione;
    this.lezioni.forEach(element => {
      if (this.selectedValueLezione === element.Nome){
            codicelezione=element.Codice;
            lezione=element;
      }
    });

    const formData = new FormData();
    formData.append('lezioneid', codicelezione.toString());
    this.httpClient.put(
      this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/docente/lezione/", formData,  { observe: 'response' })
      .subscribe((response) => {
        let rc;
        rc=JSON.stringify(response)
        if (rc!="0"){
          console.log("Cancellazione docenti:"+rc);
          //alert("Errore aggiornamento docenti");
          //return;
          let docenti:Array<Docente>=[];
          this.associatedUsers.forEach(element => {
            let d:Docente=new Docente();
            d.lezioneid=codicelezione;
            d.Username=element.Username;
            d.Titolare="SI";
            d.Assistente="NO";
            //d.user=element;
            //d.lezione=lezione;
            docenti.push(d);
          });
          //console.log("Lista docenti selezionati"+docenti);

          this.commonService.saveDocenti(docenti).subscribe(data => {
            let rc;
            rc=JSON.stringify(data)
            if (rc==="0"){
              alert("Associazione effettuata con successo");
              this.cancel();
            }else{
              alert("Errore associazione");
            }
          });

        }
      });






//    this.ruoli.forEach(element => {
//      if (this.selectedValueRuolo === element.Nome){
//        this.user.Codiceruolo=element.Codice;
//      }
//    });
/*
    console.log("Ruolo:"+this.user.Codiceruolo);

    this.commonService.saveUser(this.user).subscribe(data => {

      this.rc=JSON.stringify(data)
      if (this.rc==="0"){
        alert("Utente creato con successo");
      }else if (this.rc==="2"){
        alert("Utente già esistente");
      }else{
        alert("Errore creazione utente");
      }
    });
*/
  }
  cancel(){
    //this.selectedValueRuolo="";
    this.selectedValueLezione="";
    //this.users=[];
    this.associatedUsers=[];
  }

  add(){
    //alert("Add");
    const checkedItems = this.selectionList.selectedOptions.selected;
    //const associatedCheckedItems = this.associatedSelectionList.selectedOptions.selected;
    //alert(checkedItems.length+" "+associatedCheckedItems.length);

    this.selectedUsers=checkedItems.map(i => i.value);
    this.selectedUsers.forEach(element => {
    //  console.log(element);
      const index = this.associatedUsers.findIndex(user => user.Username === element.Username);
     console.log("---- "+index);
      if (index != undefined && index<0){

        this.associatedUsers.push(element as User);
      }
    });

  }
  remove(){
    const checkedItems = this.associatedSelectionList.selectedOptions.selected;
  //  alert(checkedItems.length)
    const tempUsers=checkedItems.map(i => i.value);
  //  console.log(tempUsers.length);
    tempUsers.forEach(element1 => {
  //    console.log(element1.Userid);
      const index = this.associatedUsers.findIndex(user => user.Username === element1.Username);
      this.associatedUsers.splice(index, 1);
    });
  }

  manageSelectUser(e, v){
   console.error(e.option.value);
  }
  manageAssociatedSelectionChange(e: MatSelectionListChange) {
//    const checkedItems = this.associatedSelectionList.selectedOptions.selected;
//    console.log(checkedItems.map(i => i.value));
  }
  manageSelectionChange(e: MatSelectionListChange) {
//    const checkedItems = this.selectionList.selectedOptions.selected;
//    console.log(checkedItems.map(i => i.value));
  }
}
