import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Tipopermesso } from '../../models/tipopermesso';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-tipipermesso',
  templateUrl: './tipipermesso.component.html',
  styleUrls: ['./tipipermesso.component.css']
})
export class TipipermessoComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  tipipermesso: Tipopermesso[]=[];
  displayedColumns: string[] = [
  "Nome",
  "Descrizione",
  "edit"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  dataSource = new MatTableDataSource<Tipopermesso>(this.tipipermesso);
  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService) {}

  ngOnInit() {
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.aggiornaLista();
  }

  cancel(){
    this.aggiornaLista();
  }

  deleteTipopermesso(tipopermesso: Tipopermesso): void {
    this.commonService.deleteTipopermesso(tipopermesso).subscribe(data => {
      this.tipipermesso = this.tipipermesso.filter(g => g !== tipopermesso);
    });
  }



  aggiornaLista(){
//    let nomeruolo:String="";
//    if (this.selectedValueRuolo!=undefined){
//     nomeruolo=this.selectedValueRuolo;
//    }
    this.commonService
      .getAllTipipermesso()
      .subscribe(response => {

        let temptipipermesso:Tipopermesso[]=response;
  //      let templistausers:User[]=[];
  //      tempusers.forEach(element => {
  //        const user:User=element;
  //        user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
          //console.log("Nome ruolo="+user.Nomeruolo);
  //        if (nomeruolo==="" || nomeruolo===user.Nomeruolo){
  //          templistausers.push(user);
  //        }
  //      });
        this.refreshLista(temptipipermesso);
      });
   }
   refreshLista(lista:Tipopermesso[]){
     this.tipipermesso=lista;
     this.dataSource = new MatTableDataSource<Tipopermesso>(this.tipipermesso);
   }
/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/
  newTipopermesso(){
    this.router.navigate(['addtipopermesso']);
  }
  editTipopermesso(tipopermesso: Tipopermesso): void {
      this.commonService.selectedTipopermesso=tipopermesso;
      this.router.navigate(['addtipopermesso']);
  }


  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Tipopermesso[];
    tempList=this.tipipermesso;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Tipopermesso>(this.tipipermesso);

   }
}
