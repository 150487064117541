import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Attivita } from '../../models/attivita';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addattivita',
  templateUrl: './addattivita.component.html',
  styleUrls: ['./addattivita.component.css']
})
export class AddattivitaComponent implements OnInit {
  attivita: Attivita=new Attivita();
  hide = true;
  rc:number;
  sedetoedit: boolean;
  IPAddress:string;
  Port:string; 
  Protocol:string;
  registerForm: FormGroup;
  submitted = false;

  public message =  sessionStorage.getItem('token');



  constructor(private httpClient: HttpClient, private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }

    }

  ngOnInit(): void {
    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
            Nome: ['', Validators.required],
            Descrizione: [''],
            Codice: ['0']
        });
    }


        if (this.commonService.selectedAttivita!=undefined){
          this.attivita=this.commonService.selectedAttivita;
          this.commonService.selectedAttivita=undefined;


        }else{
          this.attivita=new Attivita();
          /*
          this.sede.Nome='';
          this.sede.Numerodipendenti=0;
          this.sede.Codice=0;

          */
        }

        this.registerForm.setValue({
          Nome: this.attivita.Nome,
          Descrizione: this.attivita.Descrizione,
          Codice: this.attivita.Codice
        });
    }


get f() { return this.registerForm.controls; }

save() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            alert("Errore: verifica i campi evidenziati in rosso");
            return;
        }else{
            this.commonService.saveAttivita(this.registerForm.value).subscribe(data => {
              this.rc=Number(data); //ritorna l'id dell'utente se è stato creato con successo
              if (this.rc===0){
                alert("Attivita già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione attivita");
              }else{
                alert("Attivita salvato con successo");
                this.router.navigate(['attivita']);
              }
            });

        }
    //    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
    }

  cancel(){
    this.commonService.selectedProgetto=null;
    this.router.navigate(['attivita']);
  }

}
