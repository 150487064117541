import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Lezione } from '../../models/lezione';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { Site } from '../../models/site';
import { Sede } from '../../models/sede';
import { Room } from '../../models/room';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Inject, LOCALE_ID } from '@angular/core';
import { formatDate} from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-addlesson',
  templateUrl: './addlesson.component.html',
  styleUrls: ['./addlesson.component.css']
})
export class AddlessonComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol:string;
  lezione: Lezione;
  modulo: Modulo;
  //site: Site;
  sede: Sede;
  room: Room;
  hide = true;
  selectedValueMaster:string;
  selectedValueModulo:string;
  selectedValueSede:string;
  selectedValueRoom:string;
  moduli: Modulo[];
  masters: Master[];
  //sites: Site[];
  sedi: Sede[];
  rooms: Room[];
  rc:number;
  lezionetoedit: boolean;
  currentMaster: Master;
  currentModulo: Modulo;
  currentSede: Sede;
  currentRoom: Room;
  registerForm: FormGroup;
  submitted = false;
//  Orainizio: string;
//  Orafine: string;
//  Data: Date= new Date();;
  selectedFile: File;
  retrievedImage: any;
  imagehaschanged=false;
  base64Data: any;
  retrieveResonse: any;

  event = {
      eampm: "AM"
    }

  constructor(private commonService: CommonService, private httpClient: HttpClient,
    private router: Router, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
          selectedValueMaster:['', Validators.required],
          selectedValueModulo:['', Validators.required],
          Nome: ['', Validators.required],
          Descrizione: [''],
          Url: [''],
          Abilitato: [''],
          Codice: [0],
          Codicemaster: [0],
          Codicemodulo: [0],
          Datainizio: [''],
          Orainizio: [''],
          Orafine: ['']
  //        selectedValueSede: [''],
  //        selectedValueRoom: ['']
      });
    }

    this.commonService.getAllMasters().subscribe((res) => {
      this.masters = res;
//      this.commonService.currentMasters=res;

      this.commonService.getAllSedi().subscribe((res1) => {
        this.sedi = res1;
//        this.commonService.currentSites=res1;

          if (this.commonService.selectedLezione!=undefined){
            this.lezione=this.commonService.selectedLezione;
            this.getImage(this.lezione.Codice);
            this.commonService.selectedLezione=undefined;

            //this.moduli=this.commonService.currentModuli;
            //this.masters=this.commonService.currentMasters;
            //this.Data=this.lezione.Datainizio;
            //this.Orainizio=this.lezione.Orainizio;
            //this.Orafine=this.lezione.Orafine;
            //this.setModulo(this.lezione.Codicemodulo);
            //console.log("---Codiceroom---"+this.lezione.Codiceroom);
            if (this.lezione.Codiceroom!=null){
              this.setRoom(this.lezione.Codiceroom);
            }
            const data:Date=new Date(this.lezione.Datainizio);
            this.lezione.Datainizio=data;
            this.lezione.Datafine=data;

        //    console.log("-->"+this.lezione.Master.Nome);
        //    console.log("-->"+this.lezione.Modulo.Nome);
            this.commonService.getModuliByMaster(this.lezione.Master).subscribe(response => {
              this.moduli=response;
              this.registerForm.setValue({
                        selectedValueMaster:this.lezione.Master.Nome,
                        selectedValueModulo:this.lezione.Modulo.Nome,
                        Nome:this.lezione.Nome,
                        Descrizione:this.lezione.Descrizione,
                        Url:this.lezione.Url,
                        Abilitato:this.lezione.Abilitato,
                        Codice:this.lezione.Codice,
                        Codicemaster:this.lezione.Master.Codice,
                        Codicemodulo:this.lezione.Modulo.Codice,
                        Datainizio:this.lezione.Datainizio,
                        Orainizio:this.lezione.Orainizio,
                        Orafine:this.lezione.Orafine
    //                    selectedValueSede:this.lezione.Sede,
    //                    selectedValueRoom:this.lezione.Aula
              });
            });
          }else{
            this.lezione= new Lezione();
          }
        });
    });

  }
  ngAfterViewInit(){
    //console.log("---->"+this.lezione.Datainizio.toString());
    //this.Data=new Date();
    //console.log("---->"+this.Data.toString());
  }


  public onFileChanged(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (files[0].size>200000){
      alert("Immagine troppo grande (max 200KB)");
    }else if (mimeType.match(/image\/*/) == null) {
        alert("Sono supportate solo immagini.");
    }else{
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
          this.retrievedImage = reader.result;
      }
      this.selectedFile = files[0];
      this.imagehaschanged=true;
      console.log("Image changed");
    }
  }

  removeImg(){
    //this.selectedFile=new File(null, "empty", { type: 'image/jpeg' });

    this.selectedFile=null;
    this.retrievedImage = null;
  }


  manageChangeMaster(){
    this.masters.forEach(element => {
      if (this.registerForm.value.selectedValueMaster === element.Nome){
        this.lezione.Master=element;
        this.commonService.getModuliByMaster(this.lezione.Master).subscribe(response => {
            console.log("---<"+response.length);
            this.moduli=response;
            this.lezione.Modulo=new Modulo();
            this.registerForm.setValue({
                      selectedValueMaster:this.lezione.Master.Nome,
                      selectedValueModulo:this.lezione.Modulo.Nome,
                      Nome:this.lezione.Nome,
                      Descrizione:this.lezione.Descrizione,
                      Url:this.lezione.Url,
                      Abilitato:this.lezione.Abilitato,
                      Codice:this.lezione.Codice,
                      Codicemaster:this.lezione.Master.Codice,
                      Codicemodulo:this.lezione.Modulo.Codice,
                      Datainizio:this.lezione.Datainizio,
                      Orainizio:this.lezione.Orainizio,
                      Orafine:this.lezione.Orafine
          //            selectedValueSede:this.lezione.Sede,
          //            selectedValueRoom:this.lezione.Aula
            });
          });
      }
    });

    /*
    this.selectedValueModulo=undefined;
    if (this.selectedValueMaster==undefined){
     this.selectedValueModulo="";
     return;
     manageChangeMaster(){
   //    if (this.selectedValueMaster===undefined || this.selectedValueMaster===''){
        this.lezioni=[];
        this.dataSource = new MatTableDataSource<Lezione>(this.lezioni);
        this.selectedValueModulo="";
   //     return;
   //    }
       this.masters.forEach(element => {
         if (this.selectedValueMaster === element.Nome){
           this.currentMaster=element;
         }
       });
       console.log("--->"+this.currentMaster.Nome);
       this.commonService
         .getModuliByMaster(this.currentMaster)
         .subscribe(response => {
           console.log("---<"+response.length);
           this.moduli=response;
         });
     }
    }
*/
    console.log("--->"+this.lezione.Master.Nome);
    this.commonService
      .getModuliByMaster(this.lezione.Master)
      .subscribe(response => {
        console.log("---<"+response.length);
        this.moduli=response;
      });
  }

  manageChangeModulo(){

    this.moduli.forEach(element => {
      if (this.registerForm.value.selectedValueModulo === element.Nome){
        this.lezione.Modulo=element;
        this.registerForm.setValue({
                  selectedValueMaster:this.lezione.Master.Nome,
                  selectedValueModulo:this.lezione.Modulo.Nome,
                  Nome:this.lezione.Nome,
                  Descrizione:this.lezione.Descrizione,
                  Url:this.lezione.Url,
                  Abilitato:this.lezione.Abilitato,
                  Codice:this.lezione.Codice,
                  Codicemaster:this.lezione.Master.Codice,
                  Codicemodulo:this.lezione.Modulo.Codice,
                  Datainizio:this.lezione.Datainizio,
                  Orainizio:this.lezione.Orainizio,
                  Orafine:this.lezione.Orafine
      //            selectedValueSede:this.lezione.Sede,
      //            selectedValueRoom:this.lezione.Aula
        });
      }
    });
    /*
    if (this.selectedValueModulo==undefined){
     return;
    }
    this.moduli.forEach(element => {
      //console.log("Nome:"+element.Nome);
      //console.log("Codice:"+element.Codice);
      if (this.selectedValueModulo === element.Nome){
        this.currentModulo=element;
      }
    });
    */
  }

  manageChangeSede(){
    this.selectedValueRoom="";
    if (this.selectedValueSede==undefined){
     this.selectedValueRoom="";
     return;
    }
    this.sedi.forEach(element => {
      if (this.selectedValueSede === element.Nome){
        this.currentSede=element;
      }
    });

    this.commonService
      .getAuleBySede(this.currentSede)
      .subscribe(response => {
        this.rooms=response;
      });
  }

  manageChangeRoom(){
    if (this.selectedValueRoom===undefined){
     return;
    }
    this.rooms.forEach(element => {
      //console.log("Nome:"+element.Nome);
      //console.log("Codice:"+element.Codice);
      if (this.selectedValueRoom === element.Name){
        this.currentRoom=element;
      }
    });
  }

  setModulo(codiceModulo:number){
    this.commonService.getModuloById(codiceModulo).subscribe((res) => {
      this.commonService.currentModulo = res;
      this.commonService.getMasterById(this.commonService.currentModulo.Master.Codice).subscribe((res1) => {
        this.commonService.currentMaster=res1;
        this.selectedValueMaster=this.commonService.currentMaster.Nome;
        this.commonService
          .getModuliByMaster(this.commonService.currentMaster)
          .subscribe(response => {
            this.moduli=response;
            this.selectedValueModulo=this.commonService.currentModulo.Nome;
          });
      });
    });
    /*
    for (var modulo of this.moduli) {
      if (modulo.Codice===codiceModulo){
        this.selectedValueModulo=modulo.Nome;
        this.commonService.currentModulo=modulo;
        this.setMaster(modulo.Codicemaster);
        //console.log("----"+modulo.Codicemaster);
        break;
      }Codicecorso

    }
    */
  }
  /*
  setMaster(codiceMaster:number){
    for (var master of this.masters) {
      if (master.Codice===codiceMaster){
        this.selectedValueMaster=master.Nome;
        this.commonService.currentMaster=master;
        break;
      }
    }
  }
*/

setRoom(codiceRoom:number){
  this.commonService.getRoomById(codiceRoom).subscribe((res) => {
    this.commonService.currentRoom = res;

    console.log("Codicesede="+this.commonService.currentRoom.Codicesite);
    this.commonService.getSiteById(this.commonService.currentRoom.Codicesite).subscribe((res1) => {
      this.commonService.currentSite=res1;
      this.selectedValueSede=this.currentSede.Nome;
      this.commonService
        .getAuleBySede(this.currentSede)
        .subscribe(response => {
          this.rooms=response;
          this.selectedValueRoom=this.commonService.currentRoom.Name;
        });
    });
  });
}
  save() {


    //console.log("Modulo:"+this.lezione.Codicemodulo);
    //console.log("Dataestesa:"+this.lezione.Dataestesa);

    //this.lezione.Dataestesa=this.Data+" "+this.Orainizio+" "+this.Orafine;

    //this.lezione.Datainizio=this.Data;
    //this.lezione.Datafine=this.Data;
    //this.lezione.Orainizio=this.Orainizio;
    //this.lezione.Orafine=this.Orafine;

  /*
    if ((this.lezione.Orainizio===undefined)||(this.lezione.Orafine===undefined)){
      alert("E' necessario selezionare l'ora di inizio e fine");
     return;
    }

    if (!this.dataIsValid(this.lezione.Datainizio)){
      alert("La data non è valida");
     return;
    }
    this.lezione.Datafine=this.lezione.Datainizio;

    if (this.checkHourIsValid(this.lezione.Orainizio, this.lezione.Orafine)){
*/
  this.submitted = true;
  //console.log(this.registerForm.value);
  let formisvalid=true;

  if (!formisvalid) {
      alert("Errore: verifica i campi indicati con *");
      return;
  }else{
    this.commonService.saveLezione(this.registerForm.value).subscribe(data => {
      this.rc=Number(data);
      if (this.rc===0){
        alert("Lezione già esistente");
      }else if (this.rc===-1){
        alert("Errore creazione lezione");
      }else{
          console.log("----> addImageToLesson");
          if (this.imagehaschanged){
            this.addImageToLesson(this.rc);
          }else{
            alert("Lezione salvata con successo");
            this.router.navigate(['lessons']);
          }
      }
    });
  }
}

addImageToLesson(uid:number){
  const uploadImageData = new FormData();
  if (this.selectedFile!=undefined){
    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
  }else{
    uploadImageData.append('imageFile', new File([], '', { type: 'image/jpeg' }), '');
  }
  uploadImageData.append('lezioneid', uid.toString());

  //Make a call to the Spring Boot Application to save the image
    this.httpClient.post(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/lezioni/upload', uploadImageData, { observe: 'response' })
      .subscribe((response) => {
        console.log("--->AddImage"+response.status.toString());
        if (response.status === 200) {
          alert("Lezione salvata con successo");
          this.imagehaschanged=false;
          this.router.navigate(['lessons']);
        } else {
          alert('Errore salvataggio immagine');
        }
      }
      );
  }

  getImage(lezioneid:number) {
  this.httpClient.get(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/lezioni/picture/' + lezioneid)
    .subscribe(
      res => {
        this.retrieveResonse = res;
        if (this.retrieveResonse!=null && this.retrieveResonse.Picbyte!=null){
          this.base64Data = this.retrieveResonse.Picbyte;
          this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
        }
      }
    );
  }


  dataIsValid(d:Date):boolean{
    if (d!=undefined){
       const regExp = new RegExp(/^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/);
        //  if (regExp.test(controls.value)) {
        //    return null;
        //  } else {
        //    return { 'validateDate': true }
        //  }
        return regExp.test(d.toString()) ? false : true;
      }
      return false;
  }
  checkHourIsValid(oinizio:string, ofine:string):boolean{
    /*
    const parts = d.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
   */
    const inizioparts = oinizio.split(':');
    const orainizio = parseInt(inizioparts[0]);
    const minutiinizio = parseInt(inizioparts[1]);
    const fineparts = ofine.split(':');
    const orafine = parseInt(fineparts[0]);
    const minutifine = parseInt(fineparts[1]);

    if (isNaN(orainizio)||isNaN(minutiinizio)||isNaN(orafine)||isNaN(minutifine)){
      alert('Gli orari devono essere numerici');
      return false;
     }else if (orafine<orainizio){
      alert('Ora di fine deve essere maggiore di quella di inizio');
      return false;
     }else if ((orafine===orainizio)&&(minutifine<minutiinizio)){
      alert('Minuti di fine devono essere maggiori di quelli di inizio');
      return false;
    }
    //console.log("------"+orainizio+" "+orafine);
    return true;
  }

  cancel(){
    this.commonService.selectedLezione=null;
    this.router.navigate(['lessons']);
  }

  myFilter(d: Date): boolean {
  //		const selectinableday = d.getDay();
  //console.log("---->"+d);
    if(d!=undefined){
      const selectinablemonth = d.getMonth();
      const selectinableyear = d.getFullYear();
  		const selectinableday = d.getDate();
  		const todaysDateObject = new Date();
  		const actualday = todaysDateObject.getDate();
      const actualmonth = todaysDateObject.getMonth();
      const actualyear = todaysDateObject.getFullYear();
    //  console.log(todays_date)

      	/** Prevent actual system date from being selected.*/
//      if (month === actualMonth && todays_date === today) {
//        return false;
//      } else if (day !== 0 && day !== 6) {
//        return true;
//      } else if (day !== 0 && day !== 6) {
      if (actualyear < selectinableyear){
        return true;
      }else if (actualyear > selectinableyear){
        return false;
      }else if (actualyear === selectinableyear){
        if (actualmonth < selectinablemonth){
          return true;
        }else if (actualmonth > selectinablemonth){
          return false;
        }else{
          if (actualday<selectinableday) {
            return true;
          } else {
          return false;
          }
        }
      }
  	}
    return false;
  }
  OnDateChange(event: MatDatepickerInputEvent<Date>){

    //console.log("++++"+event.value);
    //this.lezione.Datafine=event.value;
    //this.lezione.Datainizio=event.value;

    /*
    const d:Date=event.value;
    let day:string='';
    let month:string='';
    let fullday:string='';
    if (d.getDate()<10){
      day='0'+d.getDate().toString();
    }else{
      day=d.getDate().toString();
     }
    if ((d.getMonth()+1)<10){
        month='0'+(d.getMonth()+1).toString();
      }else{
        month=(d.getMonth()+1).toString();
      }
    this.Data=day+"/"+month+"/"+d.getFullYear().toString()
*/


  }

  OnStartHourChange(event: any){
  //  this.Orainizio=event.target.value;
//    console.log(this.Orainizio);
  }
  OnEndHourChange(event: any){
  //  this.Orafine=event.target.value;
    //console.log(event.target.value);
  }
}
