import { Component, OnInit, ViewChild } from '@angular/core';
import {CommonService} from "../service/common.service";
import {Router} from '@angular/router';
import {EventEmitterService} from '../service/event-emitter.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Master} from '../models/master';
import {MenuItem} from '../models/menuitem';


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  items: MenuItem[] = [];
  masterList: Master[] = [];


  constructor(private commonService: CommonService,
    private eventEmitterService: EventEmitterService,
    private router: Router) { }

//  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('drawer') drawer;

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

    isOpen$: Observable<boolean>;
    isSidenavOpened: boolean=false;

  ngOnInit(): void {
    console.log("INIT CONTENT");
    if (this.eventEmitterService.subsVar2===undefined) {
      this.eventEmitterService.subsVar2 = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((name:string) => {
        console.log("SUBSCRIBE Content");
        this.firstFunction();
      });
    }

    if (this.eventEmitterService.subsVar3===undefined) {
      this.eventEmitterService.subsVar3 = this.eventEmitterService.
      invokeOnLogout.subscribe((name:string) => {
        console.log("SUBSCRIBE OnLogout");
        this.logoutFunction();
      });
    }


    if (this.commonService.currentIstanza===undefined){
      console.log("Istanza non definita");
    }else{
      console.log("Istanza:"+this.commonService.currentIstanza.Codice);
    }


//    alert('Init Content');
//    this.router.navigate(['']);
  }

  retrieveData(): void {
    console.log("Retrieve data");
//    this.commonService.getMenu(sessionStorage.getItem("username")).subscribe((res) => {
    this.commonService.getMenu().subscribe((res) => {
        this.items = res;
      }
  );
  this.commonService.getMaster(sessionStorage.getItem("username")).subscribe((res) => {
    this.masterList=res;
    //  sessionStorage.setItem("masterList", this.masterList);
  });
}
  firstFunction() {
    this.retrieveData();
    this.drawer.toggle();
    // alert('Toggle drawer');
    }

  logoutFunction() {
      this.drawer.close();
      // alert('Toggle drawer');
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }
  mouseleave() {
   if (!this.isExpanded) {
     this.isShowing = false;
   }
  }

  goto(contesto:string) {
   this.router.navigate([contesto]);
  }


}
