import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Struttura } from '../../models/struttura';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addstruttura',
  templateUrl: './addstruttura.component.html',
  styleUrls: ['./addstruttura.component.css']
})
export class AddstrutturaComponent implements OnInit {
  struttura: Struttura=new Struttura();
  hide = true;
  rc:number;
  sedetoedit: boolean;
  IPAddress:string;
  Port:string;
  Protocol:string;
  registerForm: FormGroup;
  submitted = false;

  public message =  sessionStorage.getItem('token');

  constructor(private httpClient: HttpClient, private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }

    }

  ngOnInit(): void {
    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
            Nome: ['', Validators.required],
            Descrizione: [''],
            Codice: ['0'],
            Codiceazienda : ['0']
        });
    }
        if (this.commonService.selectedStruttura!=undefined){
          this.struttura=this.commonService.selectedStruttura;
          this.commonService.selectedStruttura=undefined;
/*
          this.registerForm.setValue({
            Nome: this.struttura.Nome,
            Descrizione: this.struttura.Descrizione,
            Codice: this.struttura.Codice,
            Codiceazienda : this.struttura.Codiceazienda
          });
*/
        }else{
          this.struttura=new Struttura();
/*
          this.struttura.Nome='';
          this.struttura.Descrizione='';
          this.struttura.Codice=0;


  */
        }
        this.struttura.Codiceazienda=this.commonService.selectedAzienda.Codice;
        this.registerForm.setValue({
          Nome: this.struttura.Nome,
          Descrizione: this.struttura.Descrizione,
          Codice: this.struttura.Codice,
          Codiceazienda : this.struttura.Codiceazienda
        });
    }

get f() { return this.registerForm.controls; }

save() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            alert("Errore: verifica i campi indicati con *");
            return;
        }else{
          console.log("--- "+this.commonService.selectedAzienda.Codice);
          console.log("--- "+this.registerForm.value.Nome);
          console.log("--- "+this.registerForm.value.Codiceazienda);

            this.commonService.saveStruttura(this.registerForm.value).subscribe(data => {
              this.rc=Number(data); //ritorna l'id dell'utente se è stato creato con successo
              //console.log("--->SaveUser"+this.rc.toString());
              if (this.rc===0){
                alert("Struttura già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione struttura");
              }else{
                alert("Struttura salvata con successo");
                this.router.navigate(['strutture']);
              }
            });

        }
    //    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
    }

  cancel(){
    this.commonService.selectedStruttura=null;
    this.router.navigate(['strutture']);
  }

}
