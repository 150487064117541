import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Lulemployee } from '../../models/lulemployee';
import { Azienda } from '../../models/azienda';
import { Anno } from '../../models/anno';
import { LulemployeeData } from '../../models/lulemployeedata';
import { MatDialog } from '@angular/material/dialog';
import { AddMaterialeToLulComponent } from '../../dialog/addMaterialeToLul/addMaterialeToLul.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-lulemployee',
  templateUrl: './lulemployee.component.html',
  styleUrls: ['./lulemployee.component.css']
})
export class LulemployeeComponent implements OnInit {
  anni = [
    {
      "Codice": 1,
      "Nome": "2022"
    },
    {
      "Codice": 2,
      "Nome": "2023"
    },
    {
      "Codice": 3,
      "Nome": "2024"
    },
    {
      "Codice": 4,
      "Nome": "2025"
    }
  ];


  IPAddress:string;
  Port:string;
  Protocol: string;
  selectedValueAzienda:string;
  selectedValueAnno:string;
  lulemployees: Lulemployee[]=[];
  aziende: Azienda[];
  currentAzienda:Azienda;
  currentAnno:Anno;
//  exporting: boolean=false;
//  grouping: boolean=false;
  displayedColumns: string[] =
  ["Codicefiscale",
  "Dipendente",
  "Nomeazienda",
  "Periodo",
  "Valore",
  "Data"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  spinnervalue = 100;
//  analizing = false;
  busy = false;

  dataSource = new MatTableDataSource<Lulemployee>(this.lulemployees);
  constructor(private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog) {}

  ngOnInit() {

    this.sortedDirection.set("Codicefiscale",true);
    this.sortedDirection.set("Dipendente",true);
    this.sortedDirection.set("Nomeazienda",true);
    this.sortedDirection.set("Periodo",true);
    this.sortedDirection.set("Valore",true);
    this.sortedDirection.set("Data",true);


    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    //  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

    this.commonService.getAllAziende().subscribe((res) => {
      this.aziende = res;
      this.aggiornaLista();
    });


  this.aggiornaLista();

  }

  getNomeByCodiceazienda(codiceazienda: number):String{
    let nomeazienda:String="";
    this.aziende.forEach(element => {
      if (element.Codice === codiceazienda){
        nomeazienda=element.Nome;
        return;
      }
    });
    return nomeazienda;
  }

  manageChangeAzienda(){
    if (this.selectedValueAzienda==undefined){
     return;
    }
    this.aziende.forEach(element => {
      if (this.selectedValueAzienda === element.Nome){
        this.currentAzienda=element;
      }
    });
    this.aggiornaLista();
  }

  manageChangeAnno(){
    if (this.selectedValueAnno==undefined){
     return;
    }
    var anno=this.selectedValueAnno.substr(2,3);
  //  console.log(anno);
    this.anni.forEach(element => {
      if (this.selectedValueAnno === element.Nome){
        this.currentAnno=new Anno();
        this.currentAnno.Nome=element.Nome;
        this.currentAnno.Codice=element.Codice;
      }
    });
    this.aggiornaLista();
  }

  cancel(){
    this.selectedValueAzienda=undefined;
    this.selectedValueAnno=undefined;
    this.aggiornaLista();
  }

  group(){
    if (this.busy){
      console.log("Grouping già partita");
      return;
    }

    this.busy = true;

    let lulemployeedata:LulemployeeData= new LulemployeeData();
    lulemployeedata.anno=this.currentAnno;
    lulemployeedata.azienda=this.currentAzienda;
    this.commonService
      .groupLulemployeesByAzienda(lulemployeedata)
      .subscribe(response => {
        this.lulemployees=response;
        this.busy = false;
    });
  }

  export(){
    if (this.busy){
      console.log("Export già partito");
      return;
    }
    this.busy = true;
    this.commonService
      .exportLulemployees()
      .subscribe(response => {
        this.busy = false;
        if (response===0){
          alert("Export termitato...controlla nei report il risultato");
        }else{
          alert("Export andato in errore");
        }
    });
  }

  truncate(){
    if (this.busy){
      console.log("Eliminazione già partita");
      return;
    }
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione!!! Operazione irreversibile',description:'Confermi la cancellazione?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.busy = true;
        let lulemployeedata:LulemployeeData= new LulemployeeData();
        lulemployeedata.anno=this.currentAnno;
        lulemployeedata.azienda=this.currentAzienda;
        this.commonService
          .truncateLulemployeesByAzienda(lulemployeedata)
          .subscribe(response => {
            this.lulemployees=response;
            this.busy = false;
        });
      }
    });
   }

  aggiornaLista(){
    //console.log("Aggiorna lista");
    let nomeazienda:String="";
    let anno="";
    if (this.selectedValueAnno!=undefined){
     anno=this.selectedValueAnno.substr(2,3);
    }
    if (this.selectedValueAzienda!=undefined){
     nomeazienda=this.selectedValueAzienda;
    }
    this.commonService
      .getLulemployeesByAzienda(this.currentAzienda.Codice)
      .subscribe(response => {
        let templulemployees:Lulemployee[]=response;
        let templistalulemployees:Lulemployee[]=[];

        templulemployees.forEach(element => {

          const lulemployee:Lulemployee=element;
          console.log("Lulemployee="+element.Dipendente);
          console.log("Codiceazienda"+lulemployee.Codiceazienda);
          console.log("Nomeazienda"+lulemployee.Nomeazienda);
      //    lulemployee.Nomeazienda=this.getNomeByCodiceazienda(element.Codiceazienda);
      //    if ((nomeazienda==="" || nomeazienda===lulemployee.Nomeazienda)&&(lulemployee.Periodo.indexOf(anno)>-1)){
          if (lulemployee.Periodo.indexOf(anno)>-1){

            templistalulemployees.push(lulemployee);
          }
        });

        this.refreshLista(templistalulemployees);
      });
   }
   refreshLista(listalulemployees:Lulemployee[]){
     this.lulemployees=listalulemployees;
     this.dataSource = new MatTableDataSource<Lulemployee>(this.lulemployees);
   }
   manageClick(attributo: string){
     let tempList:Lulemployee[];
     tempList=this.lulemployees;
     let direction=this.sortedDirection.get(attributo);

     tempList.sort((a, b) => {
        var typeA = "";
        var typeB = "";
        if (a[attributo]!=null){
          if (isNaN(b[attributo]))
             typeA = a[attributo].toLowerCase();
          else
             typeA = a[attributo].toString();
        }
        if (b[attributo]!=null){
          if (isNaN(b[attributo]))
             typeB = b[attributo].toLowerCase();
           else
             typeB = b[attributo].toString();
        }
          if (direction){
            if (typeA > typeB){
              return -1;
            }else if (typeA < typeB){
               return 1;
            }else{
              return 0;
            }
          }else{
            if (typeA < typeB){
              return -1;
            }else if (typeA > typeB){
               return 1;
            }else{
              return 0;
            }
          }
      });
      direction=!direction;
      this.sortedDirection.set(attributo,direction);

      this.dataSource = new MatTableDataSource<Lulemployee>(this.lulemployees);

    }
}
