<mat-card>
    <mat-card-header>
        <mat-card-title>Dettaglio Menu Item</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form [formGroup]="registerForm" (ngSubmit)="save()">

           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Ruolo</mat-label>
              <mat-select formControlName="selectedValue" name="ruolo" (selectionChange)="manageChangeRuolo()" class="text-sm">
               <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.Nome">
                 {{ruolo.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Nome</mat-label>
             <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Contesto</mat-label>
             <input matInput placeholder="Contesto" formControlName="Contesto" name="Contesto">
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Sezione</mat-label>
             <input matInput placeholder="Sezione" formControlName="Sezione" name="Sezione">
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Ordine</mat-label>
             <input matInput placeholder="Ordine" formControlName="Ordine" name="Ordine">
           </mat-form-field>

           <mat-form-field class="example-full-width" [hidden]="true">
             <input matInput formControlName="Istanzaid">
           </mat-form-field>

           <mat-form-field class="example-full-width" [hidden]="true">
             <input matInput formControlName="Codice">
           </mat-form-field>

           <mat-form-field class="example-full-width" [hidden]="true">
             <input matInput formControlName="Codiceruolo">
           </mat-form-field>

      </form>

    </mat-card-content>
    <mat-card-actions>
      <table>
         <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="save()">Salva</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

</mat-card>
