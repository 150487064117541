import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Azienda } from '../../models/azienda';
import { Tipopersona } from '../../models/tipopersona';
import { Tipocontratto } from '../../models/tipocontratto';
import { Struttura } from '../../models/struttura';
import { Sede } from '../../models/sede';
import { Persona } from '../../models/persona';
import { Ruolo } from '../../models/ruolo';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { configurazione } from '../../../configurazioni/configurazione';

@Component({
  selector: 'app-addpersona',
  templateUrl: './addpersona.component.html',
  styleUrls: ['./addpersona.component.css']
})
export class AddpersonaComponent implements OnInit {
  persona: Persona=new Persona();
  hide = true;
  //selectedValueAzienda:string='';
  selectedTipopersona:string='';
  selectedTipocontratto:string='';
  //selectedStruttura:string='';
  //selectedSede:string='';
  aziende: Azienda[];
  azienda: Azienda;
  tipipersona: Tipopersona[];
  tipicontratto: Tipocontratto[];
  strutture: Struttura[];
  struttura: Struttura;
  sedi: Sede[];
  sede: Sede;

  rc:number;

  IPAddress:string;
  Port:string;
  Protocol:string;

  registerForm: FormGroup;
  submitted = false;
  public message =  sessionStorage.getItem('token');

  constructor(private httpClient: HttpClient, private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }
    }

  ngOnInit(): void {

    this.azienda=new Azienda();
    this.struttura= new Struttura();
    this.sede=new Sede();

    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
        selectedValueAzienda:['', Validators.required],
        Nome:['', Validators.required],
        Cognome:['', Validators.required],
        Telefono:[''],
        Codicefiscale:['', Validators.required],
        Matricola:[''],
        Datanascita:[''],
        Dataassunzione:[''],
        Datalicenziamento:[''],
        Sesso:[''],
        Cittanascita:[''],
        Provincianascita:[''],
        Citta:[''],
        Provincia:[''],
        Residenza:[''],
        Cap:[''],
        selectedValueTipopersona:[''],
        selectedValueTipocontratto:[''],
        selectedValueStruttura:[''],
        selectedValueSede:[''],
        Nazione:[''],
        Pec:[''],
        Codice:[0]
        });
    }

    this.commonService.getAllAziende().subscribe((res1) => {
      console.log("getAllAziende");
      this.aziende = res1;
      if (this.aziende===undefined || this.aziende.length===0){
        alert("Non è possibile aggiungere persone in quanto non ci sono aziende configurate")
      }else{
        /*
        let codiceAzienda=0;
        this.aziende.forEach(element => {
          if (this.registerForm.value.selectedValueAzienda === element.Nome){
            codiceAzienda=element.Codice;
          }
        });
        */

        this.commonService.getAllTipipersona().subscribe((res2) => {
          console.log("getAllTipipersona");
          this.tipipersona = res2;

          this.commonService.getAllTipicontratto().subscribe((res3) => {
            console.log("getAllTipicontratto");
            this.tipicontratto = res3;
/*
            this.commonService.getAllStruttureByAziendaid(codiceAzienda).subscribe((res4) => {
              console.log("getAllStrutture");
              this.strutture = res4;

              this.commonService.getAllSediByAziendaid(codiceAzienda).subscribe((res5) => {
                console.log("getAllSedi");
                this.sedi = res5;
                this.sedi.forEach(element => {
                  if (element.Codice === this.commonService.selectedPersona.Codicesede){
                    this.selectedValueSede=element.Nome;
                    this.sede=element;
                }

                console.log("selectedPersona "+this.commonService.selectedPersona);

                this.aziende.forEach(element => {
                  if (element.Codice === this.commonService.selectedPersona.Codiceazienda){
                    this.selectedValueAzienda=element.Nome;
                    this.azienda=element;
                    this.aggiornaForm();
                }

                });
                */
//                }); // getAllSedi
//              }); // getAllStrutture
              this.aggiornaForm()
            }); // getAllTipicontratto
          }); // getAllTipipersona
        }
      }); // end subscribe getAllAziende
  }

aggiornaForm(){

    if (this.commonService.selectedPersona!=undefined){
      this.persona=this.commonService.selectedPersona;
      this.commonService.selectedPersona=undefined;
    }else{
      this.persona=new Persona();
    }
    this.aziende.forEach(element => {
      if (this.commonService.selectedAzienda.Codice === element.Codice){
        this.azienda=element;
        this.persona.Nomeazienda=this.azienda.Nome;
        this.persona.Codiceazienda=this.azienda.Codice;


      }
    });

    this.commonService.getAllStruttureByAziendaid(this.persona.Codiceazienda).subscribe((res4) => {
      this.strutture = res4;
      this.strutture.forEach(element4 => {
        if (element4.Codice === this.persona.Codicestruttura){
                this.struttura=element4;
        }
      });
      console.log("Struttura="+ this.struttura.Nome);
      this.commonService.getAllSediByAziendaid(this.persona.Codiceazienda).subscribe((res5) => {
        this.sedi = res5;
        this.sedi.forEach(element5 => {
          if (element5.Codice === this.persona.Codicesede){
            this.sede=element5;
          }
        });

        this.registerForm.value.selectedValueSede=this.sede.Nome;
        console.log("Sede ++:"+this.registerForm.value.selectedValueSede);
        if (this.tipipersona!=undefined){
          this.tipipersona.forEach(element => {
            if (element.Codice === this.persona.Codicetipopersona){
              this.selectedTipopersona=element.Nome;
            }
          });
        }
        if (this.tipicontratto!=undefined){
          this.tipicontratto.forEach(element => {
            if (element.Codice === this.persona.Codicetipocontratto){
              this.selectedTipocontratto=element.Nome;
            }
          });
        }

        this.registerForm.setValue({
            selectedValueAzienda:this.azienda.Nome,
            Nome:this.persona.Nome,
            Cognome:this.persona.Cognome,
            Telefono:this.persona.Telefono,
            Codicefiscale:this.persona.Codicefiscale,
            Matricola:this.persona.Matricola,
      //            Mansione:this.persona.Mansione,
            Datanascita:this.persona.Datanascita,
            Dataassunzione:this.persona.Dataassunzione,
            Datalicenziamento:this.persona.Datalicenziamento,
            Sesso:this.persona.Sesso,
            Cittanascita:this.persona.Cittanascita,
            Provincianascita:this.persona.Provincianascita,
            Citta:this.persona.Citta,
            Provincia:this.persona.Provincia,
            Residenza:this.persona.Residenza,
            Cap:this.persona.Cap,
            selectedValueTipopersona:this.selectedTipopersona,
            selectedValueTipocontratto:this.selectedTipocontratto,
            selectedValueStruttura:this.struttura.Nome,
            selectedValueSede:this.sede.Nome,
            Nazione:this.persona.Nazione,
            Pec:this.persona.Pec,
            Codice:this.persona.Codice
          });

      });  // Sedi
    });     // Strutture


}

get f() { return this.registerForm.controls; }

save() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            alert("Errore: verifica i campi indicati con *");
            return;
        }else{

            this.aziende.forEach(element => {
              if (this.registerForm.value.selectedValueAzienda === element.Nome){
                this.registerForm.value.Codiceazienda=element.Codice;
              }
            });
            this.tipipersona.forEach(element => {
              if (this.registerForm.value.selectedValueTipopersona === element.Nome){
                this.registerForm.value.Codicetipopersona=element.Codice;
              }
            });
            this.tipicontratto.forEach(element => {
              if (this.registerForm.value.selectedValueTipocontratto === element.Nome){
                this.registerForm.value.Codicetipocontratto=element.Codice;
              }
            });
            this.registerForm.value.Codicestruttura=0;
            this.strutture.forEach(element => {
              if (this.registerForm.value.selectedValueStruttura === element.Nome){
                this.registerForm.value.Codicestruttura=element.Codice;
              }
            });
            this.registerForm.value.Codicesede=0;
            this.sedi.forEach(element => {
              if (this.registerForm.value.selectedValueSede === element.Nome){
                this.registerForm.value.Codicesede=element.Codice;
              }
            });
            console.log("Save user");
            this.commonService.savePersona(this.registerForm.value).subscribe(data => {
              this.rc=Number(data); //ritorna l'id dell'utente se è stato creato con successo
              //console.log("--->SaveUser"+this.rc.toString());
              if (this.rc===0){
                alert("Persona già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione persona");
              }else {
                alert("Persona salvata con successo");
                this.router.navigate(['persone']);
              }
            });

        }
    //    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
    }

  cancel(){
    this.commonService.selectedPersona=null;
    this.router.navigate(['persone']);
  }

  manageChangeAzienda(){

    this.aziende.forEach(element => {
      if (element.Nome === this.registerForm.value.selectedValueAzienda){
        this.azienda=element;
      }
    });

    this.commonService.getAllStruttureByAziendaid(this.azienda.Codice).subscribe((res4) => {
      // console.log("getAllStrutture");

      this.strutture = res4;

      if (this.strutture!=undefined){
        this.strutture.forEach(element => {
          console.log("Struttura:"+element.Codice);
          if (element.Codice === this.persona.Codicestruttura){
            this.struttura=element;
            this.registerForm.value.selectedValueStruttura=element.Nome;
          }
        });
      }

      this.commonService.getAllSediByAziendaid(this.azienda.Codice).subscribe((res5) => {
        // console.log("getAllSedi");
        this.sedi = res5;

        if (this.sedi!=undefined){
          this.sedi.forEach(element => {
            if (element.Codice === this.persona.Codicesede){
              this.sede=element;
              this.registerForm.value.selectedValueSede=element.Nome;
            }
          });
        }

      }); // getAllSedi
     }); // getAllStrutture
  }






  myFilter(d: Date): boolean {
  //		const selectinableday = d.getDay();
  //console.log("---->"+d);
    if(d!=undefined){
      const selectinablemonth = d.getMonth();
      const selectinableyear = d.getFullYear();
      const selectinableday = d.getDate();
      const todaysDateObject = new Date();
      const actualday = todaysDateObject.getDate();
      const actualmonth = todaysDateObject.getMonth();
      const actualyear = todaysDateObject.getFullYear();
      if (actualyear - 11> selectinableyear){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }
  getConfigurazione(){
    return configurazione.persona.fb;
  }
}
