import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppComponent } from './app.component';
import { VisualizzatoreComponent } from './visualizzatore/visualizzatore.component';
import { AppMaterialModule } from './app-material/app-material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import { BasicAuthHtppInterceptorService } from './service/basic-auth-interceptor.service';
import { HeaderComponent } from './homepages/header/header.component';
import { FooterComponent } from './homepages/footer/footer.component';
import { GuestComponent } from './homepages/guest/guest.component';
import { FbserviceComponent } from './homepages/fbservice/fbservice.component';
import { WhiteboardComponent } from './homepages/whiteboard/whiteboard.component';
import { DthinkComponent } from './homepages/dthink/dthink.component';
import { DialogSampleComponent } from './common/dialog-sample/dialog-sample.component';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { LogoutComponent } from './logout/logout.component';
import { CambiapasswordComponent } from './dialog/cambiapassword/cambiapassword.component';
import { FormsModule } from '@angular/forms';
import { ContentComponent } from './content/content.component';
import { AddlessonComponent } from './content/addlesson/addlesson.component';
import { AddmasterComponent } from './content/addmaster/addmaster.component';
import { AddmoduleComponent } from './content/addmodule/addmodule.component';
import { AddtolezioniComponent } from './content/addtolezioni/addtolezioni.component';
import { AdduserComponent } from './content/adduser/adduser.component';
import { CoursesComponent } from './content/courses/courses.component';
import { CourseshomeComponent } from './content/courseshome/courseshome.component';
import { CourseviewerComponent } from './content/courseviewer/courseviewer.component';
import { LessonsComponent } from './content/lessons/lessons.component';
//import { MasterplanComponent } from './content/masterplan/masterplan.component';
import { MastersComponent } from './content/masters/masters.component';
import { MaterialeComponent } from './content/materiale/materiale.component';
import { ModulesComponent } from './content/modules/modules.component';
import { ModuliComponent } from './content/moduli/moduli.component';
import { NewattachmentComponent } from './content/newattachment/newattachment.component';
import { NewfolderComponent } from './content/newfolder/newfolder.component';
import { UsersComponent } from './content/users/users.component';
import { OrdinianturComponent } from './content/ordiniantur/ordiniantur.component';
import { VideoappComponent } from './content/videoapp/videoapp.component';
import { NavigazioneComponent } from './content/materiale/navigazione/navigazione.component';

import { AddanchorComponent } from './antur/addanchor/addanchor.component';
import { AddlulComponent } from './antur/addlul/addlul.component';
import { AddtemplateComponent } from './antur/addtemplate/addtemplate.component';
import { AnchorComponent } from './antur/anchor/anchor.component';
import { LulComponent } from './antur/lul/lul.component';
import { LulemployeeComponent } from './antur/lulemployee/lulemployee.component';
import { TemplateComponent } from './antur/template/template.component';
import { AddMaterialeToLulComponent } from './dialog/addMaterialeToLul/addMaterialeToLul.component';
import { ConfirmComponent } from './dialog/confirm/confirm.component';
import { EndanalisilulComponent } from './dialog/endanalisilul/endanalisilul.component';

import { AddaziendaComponent } from './enterprise/addazienda/addazienda.component';
import { AddgruppoComponent } from './enterprise/addgruppo/addgruppo.component';
import { AddpersonaComponent } from './enterprise/addpersona/addpersona.component';
import { AddsedeComponent } from './enterprise/addsede/addsede.component';
import { AddstrutturaComponent } from './enterprise/addstruttura/addstruttura.component';
import { AddtipopermessoComponent } from './enterprise/addtipopermesso/addtipopermesso.component';
import { AddtogruppiComponent } from './enterprise/addtogruppi/addtogruppi.component';
import { AziendeComponent } from './enterprise/aziende/aziende.component';
import { DocumentiComponent } from './enterprise/documenti/documenti.component';
import { GruppiComponent } from './enterprise/gruppi/gruppi.component';
import { JumpdocumentiComponent } from './enterprise/jumpdocumenti/jumpdocumenti.component';
import { NewdocumentoComponent } from './enterprise/newdocumento/newdocumento.component';
import { NotespeseComponent } from './enterprise/notespese/notespese.component';
import { PermessiComponent } from './enterprise/permessi/permessi.component';
import { PersoneComponent } from './enterprise/persone/persone.component';
import { RicevuteComponent } from './enterprise/ricevute/ricevute.component';
import { SediComponent } from './enterprise/sedi/sedi.component';
import { StruttureComponent } from './enterprise/strutture/strutture.component';
import { TipipermessoComponent } from './enterprise/tipipermesso/tipipermesso.component';

import { GanttitemComponent } from './project/ganttitem/ganttitem.component';

import { ProgettiComponent } from './project/progetti/progetti.component';
import { AttivitaComponent } from './project/attivita/attivita.component';
import { AddtoprogettiComponent } from './project/addtoprogetti/addtoprogetti.component';
import { AddprogettoComponent } from './project/addprogetto/addprogetto.component';
import { AddattivitaComponent } from './project/addattivita/addattivita.component';

//import { GanttComponent } from './project/gantt/gantt.component';
//import { GANTT_GLOBAL_CONFIG, NgxGanttModule } from '@worktile/gantt';


import { AddcommentoComponent } from './dialog/addcommento/addcommento.component';
import { AddPersonaToUserComponent } from './dialog/addpersonatouser/addpersonatouser.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { GiornoComponent } from './project/conoprogramma/giorno/giorno.component';
import { MeseComponent } from './project/conoprogramma/mese/mese.component';
import { AnnoComponent } from './project/conoprogramma/anno/anno.component';
import { CronoprogrammaComponent } from './project/conoprogramma/cronoprogramma.component';

import { MenuitemsComponent } from './admin/menuitems/menuitems.component';
import { AddmenuitemComponent } from './admin/addmenuitem/addmenuitem.component';
import { RolesComponent } from './admin/roles/roles.component';
import { AddroleComponent } from './admin/addrole/addrole.component';

@NgModule({
  declarations: [AppComponent,
    VisualizzatoreComponent,
    HeaderComponent,
    FooterComponent,
    FbserviceComponent,
    WhiteboardComponent,
    DthinkComponent,
    GuestComponent,
    DialogSampleComponent,
    LoginComponent,
    ForgotpasswordComponent,
    LogoutComponent,
    CambiapasswordComponent,
    ContentComponent,
    AddlessonComponent,
    AddmasterComponent,
    AddmoduleComponent,
    AddtolezioniComponent,
    AdduserComponent,
    CoursesComponent,
    CourseshomeComponent,
    CourseviewerComponent,
    LessonsComponent,
//    MasterplanComponent,
    MastersComponent,
    MaterialeComponent,
    ModulesComponent,
    ModuliComponent,
    NewattachmentComponent,
    NewfolderComponent,
    UsersComponent,
    OrdinianturComponent,
    VideoappComponent,
    NavigazioneComponent,
    AddanchorComponent,
    AddlulComponent,
    AddtemplateComponent,
    AnchorComponent,
    LulComponent,
    LulemployeeComponent,
    TemplateComponent,
    AddMaterialeToLulComponent,
    ConfirmComponent,
    EndanalisilulComponent,
    AddaziendaComponent,
    AddgruppoComponent,
    AddpersonaComponent,
    AddsedeComponent,
    AddstrutturaComponent,
    AddtipopermessoComponent,
    AddtogruppiComponent,
    AziendeComponent,
    DocumentiComponent,
    GruppiComponent,
    JumpdocumentiComponent,
    NewdocumentoComponent,
    NotespeseComponent,
    PermessiComponent,
    PersoneComponent,
    RicevuteComponent,
    SediComponent,
    StruttureComponent,
    TipipermessoComponent,
    AddcommentoComponent,
    AddPersonaToUserComponent,

GanttitemComponent,

    ProgettiComponent,
    AttivitaComponent,
    AddtoprogettiComponent,
    AddprogettoComponent,
    AddattivitaComponent,

//    GanttComponent,


    GiornoComponent,
    MeseComponent,
    AnnoComponent,
    CronoprogrammaComponent,

    MenuitemsComponent,
    AddmenuitemComponent,
    RolesComponent,
    AddroleComponent

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PdfViewerModule,
    NgbModule
//    NgxGanttModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: BasicAuthHtppInterceptorService, multi: true },
              { provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
              { provide: LOCALE_ID, useValue: 'en-US'},
  //            { provide: GANTT_GLOBAL_CONFIG, useValue: { dateFormat: {
  //                 yearQuarter: `'Trim.'Q 'del' yyyy`,
  //                 month: 'LLLL',
  //                 yearMonth: `LLLL yyyy'(week' s ')'`}
  //            }  }
    ],
  bootstrap: [AppComponent]
})

export class AppModule { }
