export class Sede {
  Nome: string;
  Codice: number;
  Indirizzo: string;
  Codiceazienda: number;
  Numerodipendenti: number;
  Codicetipologia: number;
  Codiceprovincia: number;
  Codicecomune: number;
  Codiceasl: number;

  constructor(){
    this.Nome='';
    this.Codice=0;
    this.Indirizzo='';
    this.Codiceazienda=0
    this.Numerodipendenti=0;
    this.Codicetipologia=0;
    this.Codiceprovincia=0;
    this.Codicecomune=0;
    this.Codiceasl=0;
  }
}
