import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Categoria } from '../../models/categoria';
import { Persona } from '../../models/persona';
import { Gruppo } from '../../models/gruppo';
import { Azienda } from '../../models/azienda';
import { CommonService } from "../../service/common.service";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { configurazione } from '../../../configurazioni/configurazione';

@Component({
  selector: 'app-newdocumento',
  templateUrl: './newdocumento.component.html',
  styleUrls: ['./newdocumento.component.css']
})
export class NewdocumentoComponent implements OnInit {

  filename = ''
  invalidFolder = false
  categoria:Categoria;
  selectedFile: File;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;
  message: string;
  imageName: any;
  IPAddress:String;
  Port:String;
  Protocol:string;
  categorie:Categoria[];
  persone:Persona[];
  gruppi:Gruppo[];
  aziende: Azienda[];
  selectedValueAzienda:string;
  selectedValuePersona: string='';
  selectedValueGruppo: string='';
  selectedValueCategoria: string='';

  currentPersona:Persona;
  currentGruppo:Gruppo;
  currentAzienda:Azienda;
  currentCategoria:Categoria;
  rolename:string;
  Descrizione: string='';
  Nota: string='';

  @Input() error: string | null;

  constructor(private router: Router, private httpClient: HttpClient, private commonService: CommonService) {
      if (this.commonService.currentCategoria!=null && this.commonService.currentCategoria!=undefined){
        this.categoria=this.commonService.currentCategoria;
        this.selectedValueCategoria=this.categoria.Nome;
      }
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }
      this.rolename=sessionStorage.getItem("rolename");

   }

  ngOnInit() {

    this.commonService.getAllAziende().subscribe((res) => {
        this.aziende = res;
        this.aziende.forEach(element => {
          if (element.Codice === this.commonService.currentAziendaCodice){
            this.selectedValueAzienda=element.Nome;
          }
        });


  //      const postData = new FormData();
  //      postData.append('codiceazienda', this.commonService.currentAziendaCodice.toString());
  //      postData.append('tipocategoria', this.commonService.tipoDestinatario);
  //      this.commonService.getAllCategorieByAziendaTipo(postData).subscribe((res) => {
  //        this.categorie = res;
  //      });
    });

    if (this.commonService.selectedPersona!=undefined){
      this.commonService.tipoDestinatario="Persona";
      this.currentPersona=this.commonService.selectedPersona;
      this.persone=[];
      this.persone.push(this.commonService.selectedPersona);
      this.persone.forEach(element => {
        if (element.Codice === this.commonService.selectedPersona.Codice){
          this.selectedValuePersona=element.Cognome;
        }
      });
    }else if (this.commonService.selectedGruppo!=undefined){
      this.commonService.tipoDestinatario="Gruppo";
      this.currentGruppo=this.commonService.selectedGruppo;
      this.commonService.currentGruppoCodice=this.commonService.selectedGruppo.Codice;
      this.gruppi=[];
      this.gruppi.push(this.commonService.selectedGruppo);
      this.gruppi.forEach(element => {
        if (element.Codice === this.commonService.selectedGruppo.Codice){
          this.selectedValueGruppo=element.Nome;
        }
      });
    }else{
      this.commonService.tipoDestinatario="Pubblico";
    }

    const postData = new FormData();
    postData.append('codiceazienda', this.commonService.currentAziendaCodice.toString());
    postData.append('tipocategoria', this.commonService.tipoDestinatario);
    this.commonService.getAllCategorieByAziendaTipo(postData).subscribe((res) => {
      this.categorie = res;
      if (this.commonService.selectedPersona!=undefined){
        this.currentPersona=this.commonService.selectedPersona;
        this.commonService.currentPersonaCodice=this.commonService.selectedPersona.Codice;
        this.persone=[];
        this.persone.push(this.commonService.selectedPersona);
        this.persone.forEach(element => {
          if (element.Codice === this.commonService.selectedPersona.Codice){
            this.selectedValuePersona=element.Cognome;
          }
        });
      }
      if (this.commonService.selectedGruppo!=undefined){
        this.currentGruppo=this.commonService.selectedGruppo;
        this.commonService.currentGruppoCodice=this.commonService.selectedGruppo.Codice;
        this.gruppi=[];
        this.gruppi.push(this.commonService.selectedGruppo);
        this.gruppi.forEach(element => {
          if (element.Codice === this.commonService.selectedGruppo.Codice){
            this.selectedValueGruppo=element.Nome;
          }
        });
      }
    });


/*
      if (this.commonService.currentUser===undefined || this.commonService.currentUser===null){
        const username=sessionStorage.getItem('username');
        this.commonService.getUser(username).subscribe(data => {
          this.commonService.currentUser=data;
          this.commonService.currentAziendaCodice=this.commonService.currentUser.Codiceazienda;
        });
      }
*/

      /*
      this.commonService.getAllCategorie().subscribe((res) => {
          this.categorie = res;
      });
      */


  }

  getCodicecategoriaByNome(nome: string){
    var rc:number;
    this.categorie.forEach(element => {
      if (nome === element.Nome){
        rc=element.Codice;
      }
    });
    return rc;
  }

  public onFileChanged(event) {
    //Select File
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;
    if (event.target.files[0].size>20000000){
      alert("Immagine troppo grande (max 20MB)");
//    }else if (mimeType.match(/image\/*/) == null) {
//        alert("Sono supportate solo immagini.");
    }else{
      this.selectedFile = event.target.files[0];
    }
  }
  //Gets called when the user clicks on submit to upload the image
  salva() {
  //  if (this.filename===undefined || this.filename===""){
  //    alert("E' necessario specificare il nome del file");
  //  } else {
    //console.log("File:"+this.selectedFile);
      this.commonService.currentCategoriaCodice=this.getCodicecategoriaByNome(this.selectedValueCategoria);
  //    alert(this.selectedValueCategoria+" "+this.commonService.currentCategoriaCodice);
      if (this.commonService.currentCategoriaCodice===undefined){
        this.commonService.currentCategoriaCodice=0;
      }
      if (this.commonService.currentPersonaCodice===undefined){
        this.commonService.currentPersonaCodice=0;
      }
      if (this.commonService.currentGruppoCodice===undefined){
        this.commonService.currentGruppoCodice=0;
      }
      //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
      /*
      if (this.selectedFile===undefined){
        alert("E' necessario selezionare un file");
        return;
      }
      */
      const uploadImageData = new FormData();
      if (this.selectedFile!=undefined){
        uploadImageData.append('documentFile', this.selectedFile, this.selectedFile.name);
      }


      uploadImageData.append('codicecategoria', this.commonService.currentCategoriaCodice.toString());
      uploadImageData.append('codicepersona', this.commonService.currentPersonaCodice.toString());
      uploadImageData.append('codicegruppo', this.commonService.currentGruppoCodice.toString());

//      uploadImageData.append('codiceazienda', this.commonService.currentAziendaCodice.toString());
      uploadImageData.append('nome', this.filename);
      uploadImageData.append('nota', this.Nota);
      uploadImageData.append('tipo', 'DOCUMENTO');
      uploadImageData.append('descrizione', this.Descrizione);
      uploadImageData.append('username', sessionStorage.getItem("username").toString());

      if (this.selectedFile!=undefined){
        uploadImageData.append('isdownloadable', 'YES');
      } else {
        uploadImageData.append('isdownloadable', 'NO');
      }
    // TODO *****************
    //Passare il documentiid della cartella padre

    //uploadImageData.append('documentoid', this.commonService.currentMateriale.Codice.toString());
    uploadImageData.append('documentoid', "1");
    //Make a call to the Spring Boot Application to save the image


    this.httpClient.post(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/documenti/upload", uploadImageData, { observe: 'response' })
      .subscribe((response) => {
        if (response.status === 200) {
          this.message = 'Documento caricato correttamente';
        } else {
          this.message = 'Errore caricamento documento';
        }
        this.router.navigate(['documenti']);
      }
      );
  //  }
  }

  manageChangeCategoria(){
    if (this.selectedValueCategoria===undefined){
     return;
    }
    this.categorie.forEach(element => {
      if (this.selectedValueCategoria === element.Nome){

        this.currentCategoria=element;
      }
    });

  }

  isSelectedPersona(){
   return this.commonService.tipoDestinatario==="Persona";
//   if (this.selectedValuePersona!=undefined && this.selectedValuePersona!=""){
//     return true;
//   }else{
//     return false;
//   }
  }
  isSelectedGruppo(){
   return this.commonService.tipoDestinatario==="Gruppo";
//   if (this.selectedValuePersona!=undefined && this.selectedValuePersona!=""){
//     return true;
//   }else{
//     return false;
//   }
  }
  isSelectedPubblico(){
   return this.commonService.tipoDestinatario==="Pubblico";
//   if (this.selectedValuePersona!=undefined && this.selectedValuePersona!=""){
//     return true;
//   }else{
//     return false;
//   }
  }

    cancella(){

       if (this.commonService.tipoDestinatario==="Persona"){
          this.router.navigate(['persone']);
       }else if (this.commonService.tipoDestinatario==="Gruppo"){
          this.router.navigate(['gruppi']);
       } else if (this.commonService.tipoDestinatario==="Pubblico"){
          this.router.navigate(['documenti']);
       }
    }
    isEnabledToLoad(): boolean{
      return ((this.selectedFile!=undefined && this.filename!='') || this.Descrizione!=undefined);
//      return (this.rolename==="Docente" || this.rolename==="HR");
    }

    getConfigurazione(){
      return configurazione.persona.fb;
    }
    ngOnDestroy(){
      //this.commonService.tipoDestinatario='';
    }
  }
