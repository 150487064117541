import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Istanza } from '../../models/istanza';
import { Ruolo } from '../../models/ruolo';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addrole',
  templateUrl: './addrole.component.html',
  styleUrls: ['./addrole.component.css']
})
export class AddroleComponent implements OnInit {

  ruolo: Ruolo;
  hide = true;
  selectedValue:string;
  ruoli: Ruolo[];
  istanze: Istanza[];
  rc:String;
  registerForm: FormGroup;
  submitted = false;

  constructor(private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {

    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
          selectedValue:['', Validators.required],
          Nome:['', Validators.required],
          Codice: ['0'],
          Codiceistanza: ['0'],
          Livello: ['0']
      });
    }

    if (this.commonService.selectedRuolo!=undefined){
      this.ruolo=this.commonService.selectedRuolo;
      this.commonService.selectedRuolo=undefined;
    }else{
      this.ruolo= new Ruolo();
    }

    this.commonService.getAdminIstanze().subscribe((res) => {
      this.istanze = res;
      this.istanze.forEach(element => {
        if (this.ruolo.Codiceistanza === element.Codice){
          this.ruolo.Nomeistanza=element.Nome;
          this.registerForm = this.formBuilder.group({
              selectedValue:[this.ruolo.Nomeistanza , Validators.required],
              Nome:[this.ruolo.Nome , Validators.required],
              Codice: [this.ruolo.Codice],
              Codiceistanza: [this.ruolo.Codiceistanza],
              Livello: [this.ruolo.Livello]
          });
        }
      });
      alert(this.ruolo.Codice)
    });
  }

  save() {
    this.submitted = true;
    if (this.registerForm.invalid) {
        alert("Errore: verifica i campi evidenziati in rosso");
        return;
    }else{
      this.commonService.saveRuolo(this.registerForm.value).subscribe(data => {

        this.rc=JSON.stringify(data)
        if (this.rc==="0"){
          alert("Ruolo salvato con successo");
          this.cancel();
        }else if (this.rc==="2"){
          alert("Ruolo  già esistente");
        }else{
          alert("Errore creazione Ruolo ");
        }
      });
    }
  }
  cancel(){
    this.selectedValue=undefined;
    this.ruolo=new Ruolo();
    this.router.navigate(['roles']);
  }

  manageChangeIstanza(){
    this.istanze.forEach(element => {
      if (this.registerForm.value.selectedValue === element.Nome){
        this.ruolo.Codiceistanza=element.Codice;
        this.ruolo.Nomeistanza=element.Nome;
        this.registerForm = this.formBuilder.group({
          selectedValue:[this.ruolo.Nomeistanza, Validators.required],
          Nome:[this.ruolo.Nome, Validators.required],
          Codice: [this.ruolo.Codice],
          Codiceistanza: [this.ruolo.Codiceistanza],
          Livello: [this.ruolo.Livello]
        });
      }
    });
  }
}
