import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Sede } from '../../models/sede';
import { Azienda } from '../../models/azienda';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sedi',
  templateUrl: './sedi.component.html',
  styleUrls: ['./sedi.component.css']
})
export class SediComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  aziende: Azienda[];
  sedi: Sede[]=[];
  currentAzienda:Azienda;
  selectedValueAzienda:string;
  displayedColumns: string[] = [
  "Nome",
  "Numerodipendenti",
  "edit",
  "elimina"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  dataSource = new MatTableDataSource<Sede>(this.sedi);

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService, private dialog: MatDialog) {}

  ngOnInit() {
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Numerodipendenti",true);
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllAziende().subscribe((res) => {
        this.aziende = res;
        if (this.aziende.length>0){
          this.selectedValueAzienda=this.aziende[0].Nome;
          this.aziende.forEach(element => {
            if (this.selectedValueAzienda === element.Nome){
              this.currentAzienda=element;
            }
          });
        }
        this.aggiornaLista();
    });



  }

  cancel(){
    this.aggiornaLista();
  }

  manageChangeAzienda(){
    if (this.selectedValueAzienda===undefined){
     return;
    }
    this.aziende.forEach(element => {
      if (this.selectedValueAzienda === element.Nome){
        this.currentAzienda=element;
      }
    });
    this.aggiornaLista();
  }

  aggiornaLista(){
//    let nomeruolo:String="";
//    if (this.selectedValueRuolo!=undefined){
//     nomeruolo=this.selectedValueRuolo;
//    }
    this.commonService
      .getAllSediByAziendaid(this.currentAzienda.Codice)
      .subscribe(response => {
        let tempsedi:Sede[]=response;
  //      let templistausers:User[]=[];
  //      tempusers.forEach(element => {
  //        const user:User=element;
  //        user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
          //console.log("Nome ruolo="+user.Nomeruolo);
  //        if (nomeruolo==="" || nomeruolo===user.Nomeruolo){
  //          templistausers.push(user);
  //        }
  //      });
        this.refreshLista(tempsedi);
      });
   }
   refreshLista(listasedi:Sede[]){
     this.sedi=listasedi;
     this.dataSource = new MatTableDataSource<Sede>(this.sedi);
   }
/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/
  newSede(){
    this.commonService.selectedAzienda=this.currentAzienda;
    this.router.navigate(['addsede']);
  }
  edit(sede: Sede): void {
      this.commonService.selectedAzienda=this.currentAzienda;
      this.commonService.selectedSede=sede;
      this.router.navigate(['addsede']);
  }
  elimina(sede: Sede){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione',description:'Confermi la cancellazione?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        const data = new FormData();
        data.append('codice', sede.Codice.toString());
  //      this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/materiale/toggle/", materialeData, { observe: 'response' })
        this.httpClient.delete(this.Protocol+"://"+this.IPAddress+":"+this.Port+'/api/sedi/'+ sede.Codice)
        .subscribe((response) => {
            this.aggiornaLista();
        });
      }
    });
  }
  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Sede[];
    tempList=this.sedi;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Sede>(this.sedi);

   }
}
