  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista cantieri</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->

    <mat-form-field appearance="fill">
      <mat-label class="text-sm">Azienda</mat-label>
      <mat-select [(ngModel)]="selectedValueAzienda" name="azienda" (selectionChange)="manageChangeAzienda()" class="text-sm">
        <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
          {{azienda.Nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let sede"> {{sede.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Numerodipendenti">
          <th mat-header-cell *matHeaderCellDef> Numero dipendenti
            <img src="./assets/images/sort.png" (click)="manageClick('Numerodipendenti')"/>
          </th>
          <td mat-cell *matCellDef="let sede"> {{sede.Numerodipendenti}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Modifica </th>
          <td mat-cell *matCellDef="let sede">
            <mat-icon color="primary" (click)="edit(sede)">create</mat-icon>
          </td>
      </ng-container>

      <ng-container matColumnDef="elimina">
        <th mat-header-cell *matHeaderCellDef> Elimina </th>
        <td mat-cell *matCellDef="let sede">
          <mat-icon  color="primary" (click)="elimina(sede)">delete</mat-icon>
        </td>
      </ng-container>
<!--
      <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Elimina </th>
          <td mat-cell *matCellDef="let user"><button class="btn btn-danger" (click)="deleteUser(user)">Elimina</button> </td>
      </ng-container>
-->


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


<!-- </form> -->
</mat-card-content>
<mat-card-actions>
  <table>
    <tr>
      <td>
        <button mat-raised-button  color="primary" (click)="newSede()">Nuovo cantiere</button>
      </td>
      <td>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </td>
    </tr>
  </table>
</mat-card-actions>

</mat-card>
