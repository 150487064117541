  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista ricevute</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->



  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Giustificativo">
          <th mat-header-cell *matHeaderCellDef> Giustificativo
              <img src="./assets/images/sort.png" (click)="manageClick('Giustificativo')"/>
          </th>
          <td mat-cell *matCellDef="let ricevuta"> {{ricevuta.Giustificativo}} </td>
      </ng-container>

      <ng-container matColumnDef="Data">
        <th mat-header-cell *matHeaderCellDef> Data
            <img src="./assets/images/sort.png" (click)="manageClick('Data')"/>
        </th>
        <td mat-cell *matCellDef="let ricevuta">{{ricevuta.Data | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="Importo">
        <th mat-header-cell *matHeaderCellDef> Importo
            <img src="./assets/images/sort.png" (click)="manageClick('Importo')"/>
        </th>
        <td mat-cell *matCellDef="let ricevuta">{{ricevuta.Importo}}</td>
      </ng-container>

      <ng-container matColumnDef="Immagine">
        <th mat-header-cell *matHeaderCellDef> Immagine </th>
        <td mat-cell *matCellDef="let ricevuta">
          <mat-icon color="primary" (click)="vediImmagine(ricevuta)">visibility</mat-icon>
       </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="imagecontent" *ngIf=retrievedImage>
      <img src="{{retrievedImage}}" >
  </div>

<!-- </form> -->
</mat-card-content>
<mat-card-actions>
  <table>
    <tr>
      <td>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </td>
    </tr>
  </table>
</mat-card-actions>

</mat-card>
