import { Sede } from '../models/sede';
export class Progetto {

  Codice: number;
	Nome: string;
	Descrizione: string;
  Stato: string;
  Sede: Sede;
  Codicesede: number;
  Nomesede: string;
  constructor(){
    this.Nome='';
    this.Codice=0;
    this.Descrizione='';
    this.Stato='';
    this.Sede=null;
    this.Codicesede=0;
    this.Nomesede='';
  }
}
