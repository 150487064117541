  <mat-card>
      <mat-card-header>
          <mat-card-title>Dettaglio utente</mat-card-title>
      </mat-card-header>
      <mat-card-content class="content">

        <div class="col-md-12">

          <table>
            <tr *ngIf=retrievedImage>
                <img src="{{retrievedImage}}" >
            </tr>
            <tr>
              <td>
                <input #file id="myfile" style="display:none;" type="file" accept='image/*' (change)="onFileChanged(file.files)">

                <button mat-raised-button color="primary" onclick="document.getElementById('myfile').click()">Seleziona immagine (max 50KB)</button>

              </td>
              <td>
                      <button mat-raised-button  color="primary" (click)="removeImg()">Rimuovi immagine</button>
              </td>
            </tr>
          </table>



        </div>

        <form [formGroup]="registerForm" (ngSubmit)="save()">

                 <mat-form-field appearance="fill">
                   <mat-label class="text-sm">Ruolo</mat-label>
                   <mat-select formControlName="selectedValue" name="ruolo" class="text-sm">
                     <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.Nome">
                       {{ruolo.Nome}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>


                           <mat-form-field class="example-full-width">
                             <mat-label class="text-sm">Username</mat-label>
                               <input matInput placeholder="Username" formControlName="Username" name="Username" required [readonly]="user.Codice!=0">
                           </mat-form-field>

                        <!--     <mat-form-field [hidden]="user!=undefined" class="example-full-width"> -->
                         <mat-form-field class="example-full-width"> 
                             <mat-label>Password (8 caratteri)</mat-label>
                             <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="Password" name="Password" required [readonly]="user.Codice!=0"> <!--required [readonly]="this.user.Userid!=''"> -->
                             <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Nascondi password'" [attr.aria-pressed]="hide">
                               <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                            </mat-form-field>

                        <!--     <mat-form-field [hidden]="user!=undefined" class="example-full-width"> -->
                            <mat-form-field class="example-full-width">
                              <mat-label>Conferma Password (8 caratteri)</mat-label>
                              <input matInput [type]="hide ? 'password' : 'text'" placeholder="confermaPwd" formControlName="confermaPwd" name="confermaPwd" required [readonly]="user.Codice!=0"> <!--required [readonly]="this.user.Userid!=''"> -->
                              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Nascondi password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                               </button>
                             </mat-form-field>

                             <mat-form-field class="example-full-width">
                               <mat-label class="text-sm">Email</mat-label>
                                 <input matInput placeholder="Email (abc@xyz.com)" formControlName="Email" name="Email" required>
                             </mat-form-field>
                    <!--         <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                              </div> -->
<!--
                             <mat-form-field class="example-full-width">
                               <mat-label class="text-sm">Attivo</mat-label>
                                 <input matInput placeholder="Attivo" formControlName="Isactive" name="Isactive" required [readonly]=true>
                             </mat-form-field>
                             -->
     <!--
                             <mat-form-field class="example-full-width">
                               <mat-label class="text-sm">Lingua</mat-label>
                                 <input matInput placeholder="Lingua" formControlName="Language" name="Language">
                             </mat-form-field>


                         <td>
                             <mat-form-field class="example-full-width">
                                 <input matInput placeholder="Data di nascita" [(ngModel)]="user.Birthdate" name="Birthdate">
                             </mat-form-field>
                         </td>
                       -->
<!--
                           <mat-form-field appearance="fill">
                             <mat-label class="text-sm">Data di nascita</mat-label>
                              <input matInput [matDatepickerFilter]="myFilter"
                               [matDatepicker]="picker" placeholder="Data di nascita" name="Data" formControlName="Birthdate"/>
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>

                             <mat-form-field class="example-full-width">
                               <mat-label class="text-sm">Città di nascita</mat-label>
                                 <input matInput placeholder="Città di nascita" formControlName="Birthcity" name="Birthcity">
                             </mat-form-field>

                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Nazionalità</mat-label>
                                <input matInput placeholder="Nazionalità" formControlName="Nationality" name="Nationality">
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Provincia di nascita</mat-label>
                                <input matInput placeholder="Provincia di nascita" formControlName="Birthprovince" name="Birthprovince">
                            </mat-form-field>
-->

<!--
                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Città</mat-label>
                                <input matInput placeholder="Città" formControlName="City" name="City">
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Provincia</mat-label>
                                <input matInput placeholder="Provincia" formControlName="Province" name="Province">
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Residenza</mat-label>
                                <input matInput placeholder="Residenza" formControlName="Residence" name="Residence">
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">CAP</mat-label>
                                <input matInput placeholder="CAP" formControlName="Zipcode" name="Zipcode">
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Telefono</mat-label>
                                <input matInput placeholder="Telefono" formControlName="Phone" name="Phone">
                            </mat-form-field>
-->
                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Cellulare</mat-label>
                                <input matInput placeholder="Cellulare" formControlName="Cellphone" name="Cellphone">
                            </mat-form-field>

<!--
                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Nome</mat-label>
                                <input matInput placeholder="Nome" formControlName="Firstname" name="Firstname" readonly>
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Cognome</mat-label>
                                <input matInput placeholder="Cognome" formControlName="Lastname" name="Lastname" readonly>
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                              <mat-label class="text-sm">Codice fiscale</mat-label>
                                <input matInput placeholder="Codice fiscale" formControlName="Fiscalcode" name="Fiscalcode" readonly>
                            </mat-form-field>
-->
<!--
                            <mat-form-field class="example-full-width" [hidden]=true>
                              <mat-label class="text-sm">Codice</mat-label>
                                <input matInput placeholder="Codice" formControlName="Codice" name="Codice" readonly >
                            </mat-form-field>
-->
        </form>
      </mat-card-content>
      <mat-card-actions>
        <table>
          <tr>
            <td>
              <button mat-raised-button  color="primary" (click)="save()">Salva</button>
            </td>
            <td>
              <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
            </td>
          </tr>
        </table>
      </mat-card-actions>

  </mat-card>
