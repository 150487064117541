    <mat-card class="matcardcontent" *ngFor="let item of items">
        <mat-card-header>
            <mat-card-title>Programma: {{item.Nome}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-card class="card" *ngFor="let modulo of item.ModuloList" >
            <mat-card-title class="mat-card-title">
              Modulo: {{modulo.Nome}}
            </mat-card-title>
            <mat-card-content >
              <mat-card class="card" *ngFor="let lezione of modulo.LezioneList" >
                <mat-card-title class="mat-card-title">
                  Lezione: {{lezione.Nome}}
                </mat-card-title>
                <mat-card-content >
                  <p><a mat-button (click)="dettaglio(lezione)" class="nav-link">Materiale didattico</a></p>
                  <p>Data: {{getDatainizio(lezione) | date:'dd/MM/yyyy'}} Ora: {{lezione.Orainizio}}-{{lezione.Orafine}}</p>
                  <p>Docenti: {{lezione.DocenteListEstesa}}</p>
                  <p>Sede: {{lezione.Sede}}</p>
                  <p>Aula: {{lezione.Aula}}</p>
                </mat-card-content>
            </mat-card>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>
