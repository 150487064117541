
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>

            <table>
              <tr>
                <td style="padding: 15px">Vecchia password</td>
                <td><input type="password" name="oldpassword" [(ngModel)]="oldpassword"  /></td>
			  </tr>
			  <tr>
                <td style="padding: 15px">Nuova password</td>
                <td><input type="password" name="newpassword" [(ngModel)]="newpassword" /></td>
			  </tr>
			  <tr>
                <td style="padding: 15px">Conferma password</td>
                <td><input type="password" name="confirmpassword" [(ngModel)]="confirmpassword" /></td>
              </tr>
            </table>

</div>
<div mat-dialog-actions>
  <button mat-raised-button  color="primary" [mat-dialog-close]='true' (click)="savePassword()">Salva</button>
  <button mat-raised-button  color="primary" mat-dialog-close >Cancella</button>


</div>
