import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Anchor } from '../../models/anchor';
import { Template } from '../../models/template';
import { Materiale } from '../../models/materiale';
import { MatDialog } from '@angular/material/dialog';
import { AddMaterialeToLulComponent } from '../../dialog/addMaterialeToLul/addMaterialeToLul.component';
import { DomSanitizer } from '@angular/platform-browser';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-anchor',
  templateUrl: './anchor.component.html',
  styleUrls: ['./anchor.component.css']
})
export class AnchorComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  selectedValueTemplate:string;
  anchors: Anchor[]=[];
  templates: Template[];
  currentTemplate:Template;
  displayedColumns: string[] =
  ["Nometemplate",
  "Tipo",
  "Testo",
  "Duecifre",
  "X1",
  "Y1",
  "X2",
  "Y2",
  "Xoffset",
  "Yoffset",
  "Xdim",
  "Ydim",
  "X1v",
  "Y1v",
  "X2v",
  "Y2v",
  "Modifica"];


  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  numberFormControl = new FormControl('', [Validators.pattern('[0-9]*')]); //Validators.required, Validators.number
  matcher = new MyErrorStateMatcher();

  dataSource = new MatTableDataSource<Anchor>(this.anchors);
  constructor(private sanitizer: DomSanitizer, private httpClient: HttpClient, private router: Router, private commonService: CommonService, private dialog: MatDialog) {}

  ngOnInit() {


    this.sortedDirection.set("Nometemplate",true);
    this.sortedDirection.set("Tipo",true);
    this.sortedDirection.set("Testo",true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    //  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

    this.commonService.getAllTemplates().subscribe((res) => {
      this.templates = res;
      this.aggiornaLista();
    });
  }

  getNomeByCodicetemplate(codicetemplate: number):String{
    let nometemplate:String="";
    this.templates.forEach(element => {
      if (element.Codice === codicetemplate){
        nometemplate=element.Nome;

      }
    });
    return nometemplate;
  }

  manageChangeTemplate(){
    if (this.selectedValueTemplate===undefined  || this.selectedValueTemplate===''){
     return;
    }
    this.templates.forEach(element => {
      if (this.selectedValueTemplate === element.Nome){
        this.currentTemplate=element;
      }
    });
    this.aggiornaLista();
  }

  cancel(){
    this.selectedValueTemplate="";
    this.aggiornaLista();
  }


  /*
  toggleEnable(user: User){
    //selection.toggle(row);
    const userData = new FormData();
    userData.append('userid', user.Userid);
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/users/toggle", userData, { observe: 'response' })
    .subscribe((response) => {
        this.aggiornaLista();
    });
  }
*/

  aggiornaLista(){
    let nometemplate:String="";
    if (this.selectedValueTemplate!=undefined){
     nometemplate=this.selectedValueTemplate;
    } else {
      this.anchors=[];
    }
    this.commonService
      .getAnchors()
      .subscribe(response => {

        let tempanchors:Anchor[]=response;
        let templistaanchors:Anchor[]=[];
        tempanchors.forEach(element => {
          const anchor:Anchor=element;
          anchor.Nometemplate=this.getNomeByCodicetemplate(element.Codicetemplate);
          if (nometemplate==="" || nometemplate===anchor.Nometemplate){
            templistaanchors.push(anchor);
          }
        });
        this.refreshLista(templistaanchors);
      });
   }
   refreshLista(listaanchors:Anchor[]){
     this.anchors=listaanchors;
     this.dataSource = new MatTableDataSource<Anchor>(this.anchors);
   }


  newAnchor(){
    this.router.navigate(['addanchor']);
  }
  editAnchor(anchor: Anchor): void {
    this.commonService.selectedAnchor=anchor;
    this.router.navigate(['addanchor']);
  }

  saveAnchor(anchor: Anchor):void{
  //    console.log("Pagina inizio:"+lul.Paginainizio);
      this.commonService.saveAnchor(anchor);
  }

  manageClick(attributo: string){
    let tempList:Anchor[];
    tempList=this.anchors;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Anchor>(this.anchors);

   }

}


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
