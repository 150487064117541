import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { Ruolo } from '../../models/ruolo';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {
  user: User=new User();
  hide = true;
  selectedRole:string='';
  ruoli: Ruolo[];
  rc:number;
  usertoedit: boolean;
  IPAddress:string;
  Port:string;
  Protocol:string;
  //pwd:string='';
  //confermaPwd:string='';

  selectedFile: File;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;
  imageName: any;
  registerForm: FormGroup;
  submitted = false;
  imagehaschanged=false;

  public message =  sessionStorage.getItem('token');

  constructor(private httpClient: HttpClient, private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }

    }

  ngOnInit(): void {
    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
            selectedValue:['', Validators.required],
            Username: ['', Validators.required],
            Email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            Password: ['', [Validators.required, Validators.minLength(8)]],
            confermaPwd: ['', [Validators.required, Validators.minLength(8)]],
            Isactive: ['false'],
//            Language: [''],
//            Birthdate: [''],
//            Birthcity: [''],
//            Nationality: [''],
//            Birthprovince: [''],
//            Firstname: [''],
//            Lastname: [''],
//            Fiscalcode: [''],
//            City: [''],
//            Province: [''],
//            Residence: [''],
//            Zipcode: [''],
//            Phone: [''],
            Cellphone: [''],
            Codice: ['0'],
            Codiceruolo: ['0'],
            Codiceistanza: ['0']
        });
    }

    this.commonService.getAllRuoli().subscribe((res) => {
        this.ruoli = res;
        //console.log("+++++"+this.commonService.selectedUser);
        if (this.commonService.selectedUser!=undefined){
          this.user=this.commonService.selectedUser;
//          const datanascita:Date=new Date(this.user.Birthdate);
//          this.user.Birthdate=datanascita;
          this.getImage(this.user.Username);
          this.commonService.selectedUser=undefined;

          this.ruoli.forEach(element => {
            if (element.Codice === this.user.Codiceruolo){
              this.selectedRole=element.Nome;
            }
          });
          console.log("SELECTED USER:"+this.user);
          this.registerForm.setValue({
            selectedValue:this.selectedRole,
  //          Firstname: this.user.Firstname,
  //          Lastname: this.user.Lastname,
            Username: this.user.Username,
            Email: this.user.Email,
            Password: this.user.Password,
            confermaPwd: this.user.Password,
            Isactive: this.user.Isactive,
//            Language: this.user.Language,
//            Birthdate: this.user.Birthdate,
//            Birthcity: this.user.Birthcity,
//            Nationality: this.user.Nationality,
//            Birthprovince: this.user.Birthprovince,
//            Fiscalcode: this.user.Fiscalcode,
//            City: this.user.City,
//            Province: this.user.Province,
//            Residence: this.user.Residence,
//            Zipcode: this.user.Zipcode,
//            Phone: this.user.Phone,
            Cellphone:this.user.Cellphone,
            Codice: this.user.Codice,
            Codiceruolo: this.user.Codiceruolo,
            Codiceistanza: this.commonService.currentIstanza.Codice
          });
        }else{
  //          this.user.Firstname='';
  //          this.user.Lastname='';
            this.user.Username='';
            this.user.Email='';
            this.user.Password='',
            this.user.Isactive=false;
            this.user.Language='';
  //          this.user.Birthdate=null;
  //          this.user.Birthcity='';
  //          this.user.Nationality='';
  //          this.user.Birthprovince='';
  //          this.user.Fiscalcode='';
  //          this.user.City='';
  //          this.user.Province='';
  //          this.user.Residence='';
  //          this.user.Zipcode='';
  //          this.user.Phone='';
            this.user.Cellphone='';
            this.user.Codice=0;
            this.user.Codiceruolo=0;
            this.user.Codiceistanza=0;
        }
      });

  }
get f() { return this.registerForm.controls; }

save() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            alert("Errore: verifica i campi indicati con *");
            return;
        }else{

        //    this.user.Password=this.pwd;
        //    const datanascita:Date=new Date(this.user.Birthdate);
        //    this.user.Birthdate=datanascita;
            this.ruoli.forEach(element => {
              if (this.registerForm.value.selectedValue === element.Nome){
                this.registerForm.value.Codiceruolo=element.Codice;
                console.log("Codice ruolo="+this.registerForm.value.Codiceruolo);
              }
            });

            this.commonService.saveUser(this.registerForm.value).subscribe(data => {
              this.rc=Number(data); //ritorna l'id dell'utente se è stato creato con successo
              console.log("--->SaveUser"+this.rc.toString());
              if (this.rc===0){
                alert("Utente già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione utente");
              }else if (this.rc===-2){
                alert("Lo Username è di un utente esistente");
              }else{
                  if (this.imagehaschanged){

                      this.addImageToUser(this.rc);

                  }else{
                    alert("Utente salvato con successo");
                    this.router.navigate(['users']);
                  }
              }
            });

        }
    //    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
    }

  cancel(){
    this.commonService.selectedUser=null;
    this.router.navigate(['users']);
  }

  addImageToUser(uid:number){
    const uploadImageData = new FormData();
    if (this.selectedFile!=undefined){
      uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    }else{
      uploadImageData.append('imageFile', new File([], '', { type: 'image/jpeg' }), '');
    }
    uploadImageData.append('uid', uid.toString());

    //Make a call to the Spring Boot Application to save the image
      this.httpClient.post(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/users/upload', uploadImageData, { observe: 'response' })
        .subscribe((response) => {
          console.log("--->AddImage"+response.status.toString());
          if (response.status === 200) {
            alert("Utente salvato con successo");
            this.imagehaschanged=false;
            this.router.navigate(['users']);
          } else {
            alert('Errore salvataggio immagine');
          }
        }
        );
    }


  public onFileChanged(files) {

    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (files[0].size>50000){
      alert("Immagine troppo grande (max 50KB)");
    }else if (mimeType.match(/image\/*/) == null) {
        alert("Sono supportate solo immagini.");
    }else{
      var reader = new FileReader();
      const imagePath:any = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
          this.retrievedImage = reader.result;
      }
      this.selectedFile = files[0];
      this.imagehaschanged=true;
    }
  }

  onUpload() {
    console.log(this.selectedFile);
    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.

  }

  getImage(Username:string) {
  //Make a call to Sprinf Boot to get the Image Bytes.
  //console.log('http://'+this.IPAddress+':'+this.Port+'/api/users/picture/' + Username);
  this.httpClient.get(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/users/picture/' + Username)
    .subscribe(
      res => {
  //      console.log("---+>"+res);
        this.retrieveResonse = res;
        if (this.retrieveResonse!=null && this.retrieveResonse.Picbyte!=null){
          this.base64Data = this.retrieveResonse.Picbyte;
          this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
        }
  //      console.log("---->"+this.retrievedImage);
      }
    );
  }

  removeImg(){
    //this.selectedFile=new File(null, "empty", { type: 'image/jpeg' });
    this.selectedFile=null;
    this.retrievedImage = null;
    this.imagehaschanged = true;
  }

  myFilter(d: Date): boolean {
  //		const selectinableday = d.getDay();
  //console.log("---->"+d);
    if(d!=undefined){
      const selectinablemonth = d.getMonth();
      const selectinableyear = d.getFullYear();
      const selectinableday = d.getDate();
      const todaysDateObject = new Date();
      const actualday = todaysDateObject.getDate();
      const actualmonth = todaysDateObject.getMonth();
      const actualyear = todaysDateObject.getFullYear();
      if (actualyear - 11> selectinableyear){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }

}
