export class Anchor {
  Codice: number;
  Tipo: String;
  Testo: String;
  Duecifre: String;
  X1: number;
  Y1: number;
  X2: number;
  Y2: number;
  Xoffset: number;
  Yoffset: number;
  Xdim: number;
  Ydim: number;
  X1v: number;
  Y1v: number;
  X2v: number;
  Y2v: number;
  Nometemplate: String;
  Codicetemplate: number;
  constructor(){
    this.Codice=0;
    this.Tipo='';
    this.Testo='';
    this.Duecifre='';
    this.X1=0;
    this.Y1=0;
    this.X2=0;
    this.Y2=0;
    this.Xoffset=0;
    this.Yoffset=0;
    this.Xdim=0;
    this.Ydim=0;
    this.X1v=0;
    this.Y1v=0;
    this.X2v=0;
    this.Y2v=0;
    this.Nometemplate='';
    this.Codicetemplate=0;
  }
}
