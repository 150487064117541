export class Progettoattivita {
  Codice: number;
  Nomeattivita: string;
  Descrizioneattivita: string;
  Datainizio: Date;
  Datafine: Date;
  Attivitaid: number;
  Progettoid: number;
  Stato: string;
  constructor(){
      this.Codice=0;
      this.Attivitaid=0;
      this.Progettoid=0;
      this.Nomeattivita='';
      this.Descrizioneattivita='';
      this.Datainizio=null;
      this.Datafine=null;
      this.Stato='';
    }
}
