<table>
  <tr>
    <td>
      {{progettoattivita.Nomeattivita}}
    </td>
    <td>
      <mat-form-field style="margin-top:20px">
        <mat-label>Inserisci intervallo date</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="pickerdi">
          <input matStartDate formControlName="Datainizio" placeholder="Data inizio">
          <input matEndDate formControlName="Datafine" placeholder="Data fine">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="pickerdi"></mat-datepicker-toggle>
        <mat-date-range-picker #pickerdi></mat-date-range-picker>
<!--
        @if (range.controls.Datainizio.hasError('matStartDateInvalid')) {
          <mat-error>Data inizio non valida</mat-error>
        }
        @if (range.controls.Datafine.hasError('matEndDateInvalid')) {
          <mat-error>Data fine non valida</mat-error>
        }
        -->
      </mat-form-field>
    </td>
    <td>
      {{progettoattivita.Stato}}
    </td>
  </tr>
</table>
