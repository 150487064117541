import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  subsVar2: Subscription;
  subsVar3: Subscription;
  invokeOnLogin = new EventEmitter();
  invokeOnLogout = new EventEmitter();

  constructor() { }

  onFirstComponentButtonClick() {
    this.invokeFirstComponentFunction.emit();
  }

  onLogin() {
    this.invokeOnLogin.emit();
  }

  onLogout() {
    this.invokeOnLogout.emit();
  }

}
