export class Ruolo {
  id: number;
  Nome: string;
  Codice: number;
  Nomeistanza: string;
  Codiceistanza: number;
  Livello: number;
  Nomeazienda: string;
  Codiceazienda: number;
  constructor(){
    this.id=0;
    this.Nome='';
    this.Codice=0;
    this.Nomeistanza='';
    this.Codiceistanza=0;
    this.Livello=0;
    this.Nomeazienda='';
    this.Codiceazienda=0;
  }
}
