export class Istanza {
  Nome: string;
  Codice: number;
  Descrizione: string;
  constructor(){
    this.Nome='';
    this.Descrizione='';
    this.Codice=0;
  }

}
