<mat-card>
    <mat-card-header>
        <mat-card-title>Gestisci lezioni</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form>
         <table>
           <tr>
              <td style="padding:10px">
                 <mat-form-field class="example-full-width">
                   <mat-label class="text-sm">Ruolo</mat-label>
                   <mat-select [(ngModel)]="selectedValueRuolo" name="ruolo" (selectionChange)="manageChangeRuolo()" class="text-sm">
                     <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.Nome">
                       {{ruolo.Nome}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>
              </td>
              <td>
                 <mat-form-field class="example-full-width">
                   <mat-label class="text-sm">Lezione</mat-label>
                   <mat-select [(ngModel)]="selectedValueLezione" name="lezione" (selectionChange)="manageChangeLezione()" class="text-sm">
                     <mat-option *ngFor="let lezione of lezioni" [value]="lezione.Nome">
                       {{lezione.Nome}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>
              </td>
           </tr>
           <tr>
              <td style="padding:10px">
                <mat-selection-list #selectionList>
                  <mat-list-option [value]="user" *ngFor="let user of users"  >
                    {{user.Firstname}} {{user.Lastname}}
                  </mat-list-option>
                </mat-selection-list>
             </td>

             <td style="padding:10px">
               <mat-selection-list #associatedSelectionList>
                 <mat-list-option [value]="associateduser" *ngFor="let associateduser of associatedUsers"  >
                   {{associateduser.Firstname}} {{associateduser.Lastname}}
                 </mat-list-option>
               </mat-selection-list>
            </td>
          </tr>
          <tr>
             <td align="right" style="padding:20px">
               <button mat-raised-button  color="primary" (click)="add()">Aggiungi</button>
             </td>

             <td>
               <button mat-raised-button  color="primary" (click)="remove()">Togli</button>
             </td>
         </tr>

        </table>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <table>
         <tr>
          <td>
             <button mat-raised-button  color="primary" (click)="save()">Salva</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

</mat-card>
