<mat-card>
    <mat-card-header>
        <mat-card-title>Lista Ruolo</mat-card-title>
    </mat-card-header>

    <mat-card-content class="content">
          <form>
           <mat-form-field appearance="fill" style="padding-right: 10px">
              <mat-label class="text-sm">Istanza</mat-label>
                <mat-select [(ngModel)]="selectedValueIstanza" name="Istanza" (selectionChange)="manageChangeIstanza()" class="text-sm">
                  <mat-option *ngFor="let istanza of istanze" [value]="istanza.Nome">
                    {{istanza.Nome}}
                  </mat-option>
              </mat-select>
           </mat-form-field>

         <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

         <ng-container matColumnDef="Istanza">
               <th mat-header-cell *matHeaderCellDef> Istanza
                 <img src="./assets/images/sort.png" (click)="manageClick('Istanza')"/>
               </th>
               <td mat-cell *matCellDef="let ruolo"> {{ruolo.Nomeistanza}} </td>
         </ng-container>

         <ng-container matColumnDef="Nome">
             <th mat-header-cell *matHeaderCellDef> Nome
               <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
             </th>
             <td mat-cell *matCellDef="let ruolo"> {{ruolo.Nome}} </td>
         </ng-container>

         <ng-container matColumnDef="Livello">
             <th mat-header-cell *matHeaderCellDef> Livello
               <img src="./assets/images/sort.png" (click)="manageClick('Livello')"/>
             </th>
             <td mat-cell *matCellDef="let ruolo"> {{ruolo.Livello}} </td>
         </ng-container>

         <ng-container matColumnDef="Modifica" mat-sort-header>
           <th mat-header-cell *matHeaderCellDef> Modifica </th>
           <td mat-cell *matCellDef="let ruolo">
             <mat-icon color="primary" (click)="edit(ruolo)">create</mat-icon>
          </td>
         </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <table>
        <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="new()">Nuovo ruolo</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
        </table>

    </mat-card-actions>



</mat-card>
