import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Ruolo } from '../../models/ruolo';
import { Attivita } from '../../models/attivita';
import { Progetto } from '../../models/progetto';
import { Progettoattivita } from '../../models/progettoattivita';
import { MatSelectionList } from '@angular/material/list';
import { MatSelectionListChange } from '@angular/material/list';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GanttitemComponent } from "../ganttitem/ganttitem.component";
import { environment } from '../../../environments/environment';

import {FormGroup, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {JsonPipe} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NativeDateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-addtoprogetti',
  templateUrl: './addtoprogetti.component.html',
  styleUrls: ['./addtoprogetti.component.css']
})
export class AddtoprogettiComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol:string;
  selectedValueProgetto:string;
  progetti: Progetto[];
  attivita:Array<Attivita>=[];
  currentProgetto:Progetto;
  currentAttivita:Attivita;

  selectedAttivita:Array<Attivita>=[];
//  @ViewChild(MatSelectionList, {static: true})
  private selectionList:  MatSelectionList;
  private associatedSelectionList:  MatSelectionList;
  associatedattivita:Array<Progettoattivita>=[];
/*
  range = new FormGroup({
    Datainizio: new FormControl<Date | null>(null),
    Datafine: new FormControl<Date | null>(null),
  });
*/
  @ViewChildren(MatSelectionList) selections: QueryList<MatSelectionList>;
  @ViewChildren('gitem') ganttitems: QueryList<GanttitemComponent>;

  constructor(private commonService: CommonService, private httpClient: HttpClient) {

  }

  ngOnInit(): void {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllProgetti().subscribe((res1) => {
        this.progetti = res1;
        this.manageChangeProgetto();

        this.commonService.getAllAttivita().subscribe((res2) => {
                  this.attivita = res2;
        });
  //      this.commonService.getAllPersoneByGruppoid(this.currentGruppo.Codice).subscribe((res2) => {
  //          this.persone = res2;
  //          this.commonService.getAllLezioni().subscribe((res3) => {
  //              this.lezioni = res3;
  //          });
  //      });
    });

  }
  ngAfterViewChecked() {
      if (this.selections.length===2){
        this.selectionList=this.selections.toArray()[0] as MatSelectionList;
        this.associatedSelectionList=this.selections.toArray()[1] as MatSelectionList;
      }

  }

  //manageChangeGruppo(){
  //  if (this.selectedValueRuolo===undefined || this.selectedValueRuolo===""){
  //   return;
  //  }
  //  this.gruppi.forEach(element => {
  //    if (this.selectedValueGruppo === element.Nome){
  //      this.currentGruppo=element;
  //    }
  //  });
  //  if (this.currentRuolo.Nome==='Docente' || this.currentRuolo.Nome==='Tutor'){
  //    this.commonService.getAllUsersByRuoloid(this.currentRuolo.Codice).subscribe((res) => {
  //        this.users = res;
  //    });
  //  }else{
  //    this.users=[];
  //    alert("Il ruolo selezionato non prevede associazioni a lezioni");
  //  }
  //}
  manageChangeProgetto(){
    if (this.selectedValueProgetto===undefined || this.selectedValueProgetto===""){
     return;
    }
    this.associatedattivita=[];
    this.progetti.forEach(element => {
      if (this.selectedValueProgetto === element.Nome){
        this.currentProgetto=element;
        this.commonService.getAllAttivitaByProgettoid(this.currentProgetto.Codice).subscribe((res) => {
          const r:Progettoattivita[]=res;
          r.forEach(element => {
            //console.log(element);
            this.associatedattivita.push(element as Progettoattivita);
          });
        });
      }
    });
  }

  save() {
    if (this.selectedValueProgetto===undefined || this.selectedValueProgetto===""){
     alert("E' necessario selezionare un progetto");
     return;
    }
    let codiceprogetto:number=0;
    let progetto:Progetto;
    this.progetti.forEach(element => {
      if (this.selectedValueProgetto === element.Nome){
            codiceprogetto=element.Codice;
            progetto=element;
      }
    });

    const formData = new FormData();
    formData.append('progettoid', codiceprogetto.toString());
    this.httpClient.put(
      this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/progetti/attivita", formData,  { observe: 'response' })
      .subscribe((response) => {
        let rc;
        rc=JSON.stringify(response)
        if (rc!="0"){
          console.log("Cancellazione attivita:"+rc);
          //alert("Errore aggiornamento docenti");
          //return;
          let progettiattivita:Array<Progettoattivita>=[];
          var i:number;
          for(i = 0; i <this.associatedattivita.length; i ++) {
            let element=this.associatedattivita[i];
            let pa:Progettoattivita=new Progettoattivita();
            //console.log("----:"+this.ganttitems.get(i));
            pa.Datainizio=this.ganttitems.get(i).range.value.Datainizio;
            pa.Datafine=this.ganttitems.get(i).range.value.Datafine;
            pa.Attivitaid=element.Attivitaid;
            pa.Progettoid=codiceprogetto;
            pa.Stato=this.ganttitems.get(i).currentStato;
            progettiattivita.push(pa);
          };
          //console.log("Lista docenti selezionati"+docenti);

          this.commonService.saveProgettoAttivita(progettiattivita).subscribe(data => {
            let rc;
            rc=JSON.stringify(data)
            if (rc==="0"){
              alert("Associazione effettuata con successo");
              this.cancel();
            }else{
              alert("Errore associazione");
            }
          });
        }
      });



  }
  cancel(){
    //this.selectedValueRuolo="";
    this.selectedValueProgetto="";
    //this.users=[];
    this.associatedattivita=[];
  }

  add(){
    //alert("Add");
    const checkedItems = this.selectionList.selectedOptions.selected;
    //const associatedCheckedItems = this.associatedSelectionList.selectedOptions.selected;
    //alert(checkedItems.length+" "+associatedCheckedItems.length);

    this.selectedAttivita=checkedItems.map(i => {
    //  console.log("+++ "+i);
      //console.log("+++ "+i.selected);
      i.selected=false;
      return i.value});
    this.selectedAttivita.forEach(element => {
     console.log("--->"+element.Codice);
      const index = this.associatedattivita.findIndex(attivita => (attivita.Attivitaid === element.Codice));

     console.log("---- "+index);

      if (index != undefined && index<0){
        let progettoattivita:Progettoattivita = new Progettoattivita();
        progettoattivita.Attivitaid=element.Codice;
        progettoattivita.Nomeattivita=element.Nome;
        progettoattivita.Descrizioneattivita=element.Descrizione;
        this.associatedattivita.push(progettoattivita);
      }
    });

  }
  remove(){
    const checkedItems = this.associatedSelectionList.selectedOptions.selected;
  //  alert(checkedItems.length)
    const tempPersone=checkedItems.map(i => i.value);
  //  console.log(tempUsers.length);
    tempPersone.forEach(element1 => {
  //    console.log(element1.Userid);
      const index = this.associatedattivita.findIndex(attivita => attivita.Nomeattivita === element1.Nomeattivita);
      this.associatedattivita.splice(index, 1);
    });
  }

  manageSelectUser(e, v){
   console.error(e.option.value);
  }
  manageAssociatedSelectionChange(e: MatSelectionListChange) {
//    const checkedItems = this.associatedSelectionList.selectedOptions.selected;
//    console.log(checkedItems.map(i => i.value));
  }
  manageSelectionChange(e: MatSelectionListChange) {
//    const checkedItems = this.selectionList.selectedOptions.selected;
//    console.log(checkedItems.map(i => i.value));
  }

  myFilter(d: Date): boolean {
  //		const selectinableday = d.getDay();
  //console.log("---->"+d);
    if(d!=undefined){
      const selectinablemonth = d.getMonth();
      const selectinableyear = d.getFullYear();
      const selectinableday = d.getDate();
      const todaysDateObject = new Date();
      const actualday = todaysDateObject.getDate();
      const actualmonth = todaysDateObject.getMonth();
      const actualyear = todaysDateObject.getFullYear();
    //  console.log(todays_date)

        /** Prevent actual system date from being selected.*/
  //      if (month === actualMonth && todays_date === today) {
  //        return false;
  //      } else if (day !== 0 && day !== 6) {
  //        return true;
  //      } else if (day !== 0 && day !== 6) {
      if (actualyear < selectinableyear){
        return true;
      }else if (actualyear > selectinableyear){
        return false;
      }else if (actualyear === selectinableyear){
        if (actualmonth < selectinablemonth){
          return true;
        }else if (actualmonth > selectinablemonth){
          return false;
        }else{
          if (actualday<selectinableday) {
            return true;
          } else {
          return false;
          }
        }
      }
    }
    return false;
  }



}
