import { Component } from '@angular/core';

@Component({
  selector: 'app-visualizzatore',
  templateUrl: './visualizzatore.component.html',
  styleUrls: ['./visualizzatore.component.css']
})
export class VisualizzatoreComponent {
  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

}
