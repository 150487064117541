  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista gruppi</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->

<!--
           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Ruolo</mat-label>
             <mat-select [(ngModel)]="selectedValueRuolo" name="ruolo" (selectionChange)="manageChangeRuolo()" class="text-sm">
               <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.Nome">
                 {{ruolo.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>
-->

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let gruppo"> {{gruppo.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Descrizione">
          <th mat-header-cell *matHeaderCellDef> Descrizione
            <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
          </th>
          <td mat-cell *matCellDef="let gruppo"> {{gruppo.Descrizione}} </td>
      </ng-container>

      <ng-container matColumnDef="documents">
          <th mat-header-cell *matHeaderCellDef> Documenti </th>
          <td mat-cell *matCellDef="let gruppo">
            <mat-icon color="primary" (click)="viewDocumentiGruppo(gruppo)">folder_shared</mat-icon>
          </td>
      </ng-container>

      <ng-container matColumnDef="newdocument">
          <th mat-header-cell *matHeaderCellDef> Allega </th>
          <td mat-cell *matCellDef="let gruppo">
            <mat-icon color="primary" (click)="newDocumentoGruppo(gruppo)">attachment</mat-icon>
          </td>
      </ng-container>

      <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Modifica </th>
          <td mat-cell *matCellDef="let gruppo">
            <mat-icon color="primary" (click)="editGruppo(gruppo)">create</mat-icon>
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


<!-- </form> -->
</mat-card-content>
<mat-card-actions>
  <table>
    <tr>
      <td>
        <button mat-raised-button  color="primary" (click)="newGruppo()">Nuovo gruppo</button>
      </td>
      <td>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </td>
    </tr>
  </table>
</mat-card-actions>

</mat-card>
