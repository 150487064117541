import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { User } from '../../models/user';
import { Persona } from '../../models/persona';
import { Ruolo } from '../../models/ruolo';
import { Istanza } from '../../models/istanza';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddPersonaToUserComponent } from '../../dialog/addpersonatouser/addpersonatouser.component';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  selectedValueRuolo:number;
  selectedValueIstanza:string;
  IPAddress:string;
  Port:string;
  Protocol: string;
  users: User[];
  ruoli: Ruolo[];
  istanze: Istanza[];
  currentRuolo:Ruolo;
  currentIstanza:Istanza;
  displayedColumns: string[] = [
  "Isactive",
  "Username",
//  "Password",
  "Email",

//  "Language",
//  "Birthdate",
//  "Birthcity",
//  "Nationality",
//  "Birthprovince",

//  "City",
//  "Province",
//  "Residence",
//  "Zipcode",
//  "Phone",
  "Cellphone",
  "Nomeruolo",
  "Nomeistanza",
  "edit",
//  "delete",
//  "Codiceruolo",
  "addPersona",
  "Firstname",
  "Lastname",
  "Fiscalcode",];

  show=sessionStorage.getItem('rolename')==='Super';
  ultimoattributoordinato: string='';
  sortedDirection:Map<string, boolean>=new Map<string, boolean>();


  @ViewChild('paginator') paginator: MatPaginator;
  dataSource:MatTableDataSource<User>;

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService, private dialog: MatDialog) {}

  ngOnInit() {

    this.sortedDirection.set("Firstname",true);
    this.sortedDirection.set("Lastname",true);
    this.sortedDirection.set("Username",true);
    this.sortedDirection.set("Email",true);
    this.sortedDirection.set("Isactive",true);
    this.sortedDirection.set("Fiscalcode",true);
    this.sortedDirection.set("Cellphone",true);
    this.sortedDirection.set("Nomeruolo",true);
    this.sortedDirection.set("Nomeistanza",true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

/*
    this.commonService.getAllIstanze().subscribe((res1) => {
        this.istanze = res1;
        this.commonService.getAllRuoli().subscribe((res2) => {
            this.ruoli = res2;
        });
    });
*/
    this.commonService.getAllRuoli().subscribe((res2) => {
        this.ruoli = res2;
    });
    this.aggiornaLista();

  }
  ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
  }
  manageChangeIstanza(){
    if (this.selectedValueIstanza===undefined){
     return;
    }
    this.istanze.forEach(element => {
      if (this.selectedValueIstanza === element.Nome){
        this.currentIstanza=element;
      }
    });
    this.aggiornaLista();
  }

  manageChangeRuolo(){
    if (this.selectedValueRuolo===undefined){
     return;
    }
    this.ruoli.forEach(element => {
      if (this.selectedValueRuolo === element.Codice){
        this.currentRuolo=element;
      }
    });
    this.aggiornaLista();
  }

  cancel(){
    this.selectedValueRuolo=0;
    this.aggiornaLista();
  }

  getCodiceistanzaByNome(nomeistanza: String):number{
    let codiceistanza:number=0;
    this.istanze.forEach(element => {
      if (element.Nome === nomeistanza){
        codiceistanza=element.Codice;

      }
    });
    return codiceistanza;
  }

  getNomeByCodiceruolo(codiceruolo: number):String{
    let nomeruolo:String="";
    this.ruoli.forEach(element => {
      if (element.Codice === codiceruolo){
        nomeruolo=element.Nome;

      }
    });
    return nomeruolo;
  }

  deleteUser(user: User): void {
    this.commonService.deleteUser(user).subscribe(data => {
      this.users = this.users.filter(u => u !== user);
    });
  }

  toggleEnable(user: User){
    //selection.toggle(row);
    const userData = new FormData();
    userData.append('username', user.Username);
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/users/toggle", userData, { observe: 'response' })
    .subscribe((response) => {
  //    console.log("---->"+response);
        this.aggiornaLista();
    });
  }

  aggiornaLista(){
    this.users=[];
    /*
    let nomeruolo:String="";
    if (this.selectedValueRuolo!=undefined){
     nomeruolo=this.selectedValueRuolo;
    }

    let codiceistanza=100;
    if (this.selectedValueIstanza!=undefined){
     codiceistanza=this.getCodiceistanzaByNome(this.selectedValueIstanza);
    }

    this.commonService.getUsers(codiceistanza).subscribe(response => {
    */
//alert(this.commonService.currentIstanza.Codice)
    this.commonService.getUsers(this.commonService.currentIstanza.Codice).subscribe(response => {
        let tempusers:User[]=response;
        let templistausers:User[]=[];

        tempusers.forEach(element => {
          const user:User=element;
          //user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);

          if (this.selectedValueRuolo===undefined || this.selectedValueRuolo===0){
            templistausers.push(user);
          }else if (this.selectedValueRuolo===user.Codiceruolo){
            templistausers.push(user);
          }
        });
        this.refreshLista(templistausers);
      });
   }
   refreshLista(listausers:User[]){
     this.users=listausers;
     if (this.ultimoattributoordinato!=''){
      let direction=this.sortedDirection.get(this.ultimoattributoordinato);
      direction=!direction;
      this.sortedDirection.set(this.ultimoattributoordinato,direction);
      this.manageClick(this.ultimoattributoordinato);
    }else{
      this.dataSource = new MatTableDataSource<User>(this.users);
      this.dataSource.paginator = this.paginator;
    }
   }
/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/
  newUser(){
    this.router.navigate(['adduser']);
  }
  editUser(user: User): void {
      this.commonService.selectedUser=user;
      this.router.navigate(['adduser']);
  }
  addPersona(user: User): void {
      var personaList:Persona[]=[];
      this.commonService.getPersone(user.Codiceistanza).subscribe((res) => {
        personaList = res;
        const dialogRef = this.dialog.open(AddPersonaToUserComponent, {
           width: '450px',
           data: {title:'Aggiungi una persona',personaList:personaList, user: user}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result){
             this.aggiornaLista();
           }
         });
      });
  }
  removePersona(user: User): void {
    user.Codicepersona=0;
    user.Firstname=null;
    user.Lastname=null;
    user.Fiscalcode=null;
      this.commonService.addPersonaToUser(user).subscribe(data => {
        let rc;
        rc=JSON.stringify(data)
        console.log("-->"+rc);
        if (rc!=-1){
    //      alert("Materiale salvato con successo");
        }else{
          alert("Errore salvataggio persona");
        }
      });
  }

  isTheSameUser(user:User){
    return user.Username===sessionStorage.getItem('username')
  }
  manageClick(attributo: string){
    this.ultimoattributoordinato=attributo;
        //alert(ev.target)
    /*
        switch (_case) {
             case 'number':
               this.elements2.sort((a, b) => {
                 return a.position - b.position;
               });
               break;
             case 'text':
      */
        let tempList:User[];
        tempList=this.users;
        let direction=this.sortedDirection.get(attributo);

        tempList.sort((a, b) => {
           var typeA = "";
           var typeB = "";
           if (a[attributo]!=null){
             if (isNaN(b[attributo]))
                typeA = a[attributo].toLowerCase();
             else
                typeA = a[attributo].toString();
           }
           if (b[attributo]!=null){
             if (isNaN(b[attributo]))
                typeB = b[attributo].toLowerCase();
              else
                typeB = b[attributo].toString();
           }
             if (direction){
               if (typeA > typeB){
                 return -1;
               }else if (typeA < typeB){
                  return 1;
               }else{
                 return 0;
               }
             }else{
               if (typeA < typeB){
                 return -1;
               }else if (typeA > typeB){
                  return 1;
               }else{
                 return 0;
               }
             }
         });
         direction=!direction;
         this.sortedDirection.set(attributo,direction);
         this.dataSource = new MatTableDataSource<User>(this.users);
         this.dataSource.paginator = this.paginator;
       }
}
