<mat-card>
    <mat-card-header>
        <mat-card-title>Gestisci progetti</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form>
         <table>
           <tr>
              <td>
                   <mat-label class="text-sm">Attività</mat-label>
              </td>
<!--                 <mat-label *ngIf="selectedValueGruppo===undefined || selectedValueGruppo===''" class="text-sm" style="font-weight:normal; color:red">Selezionare un gruppo </mat-label>
-->             <td>
                  <mat-form-field>
                   <mat-label class="text-sm">Progetto</mat-label>
                   <mat-select [(ngModel)]="selectedValueProgetto" name="progetto" (selectionChange)="manageChangeProgetto()" class="text-sm">
                     <mat-option *ngFor="let progetto of progetti" [value]="progetto.Nome">
                       {{progetto.Nome}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>
              </td>
           </tr>
           <tr>
              <td>
                  <mat-selection-list #selectionList>
                    <mat-list-option [value]="att" *ngFor="let att of attivita">
                      {{att.Nome}}
                      </mat-list-option>
                    </mat-selection-list>
             </td>
             <td>
                   <mat-selection-list #associatedSelectionList>
                     <mat-list-option style="height:60px" [value]="associatedatt" *ngFor="let associatedatt of associatedattivita">
                       <ganttitem #gitem [progettoattivita]=associatedatt></ganttitem>
                      </mat-list-option>
                   </mat-selection-list>
            </td>
          </tr>
          <tr>
             <td align="right" style="padding:20px">
               <button mat-raised-button  color="primary" (click)="add()" [disabled]="this.selectedValueProgetto===undefined || this.selectedValueProgetto===''">Aggiungi</button>
             </td>

             <td>
               <button mat-raised-button  color="primary" (click)="remove()">Togli</button>
             </td>
         </tr>

        </table>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button  color="primary" (click)="save()">Salva</button>
      <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
    </mat-card-actions>

</mat-card>
