import { Master } from '../models/master';
import { Lezione } from '../models/lezione';
export class Modulo {
  Nome: string;
  Codice: number;
  Descrizione: string;
  Abilitato: string;
  Master: Master;
  Codicemaster: number;
  LezioneList: Lezione[];

  constructor(){
    this.Nome='';
    this.Codice=0;
    this.Descrizione='';
    this.Abilitato='';
    this.Master=null;
    this.Codicemaster=0
    this.LezioneList=[];
  }

}
