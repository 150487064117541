<mat-card>
    <mat-card-header>
        <mat-card-title>Lista Template</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form>

<!--
           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Template</mat-label>
             <mat-select [(ngModel)]="selectedValueTemplate" name="template" (selectionChange)="manageChangeTemplate()" class="text-sm">
               <mat-option *ngFor="let template of templates" [value]="template.Nome">
                 {{template.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>

-->
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let template"> {{template.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Descrizione">
          <th mat-header-cell *matHeaderCellDef> Descrizione
            <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
          </th>
          <td mat-cell *matCellDef="let template"> {{template.Descrizione}} </td>
      </ng-container>
<!--
      <ng-container matColumnDef="Codice">
          <th mat-header-cell *matHeaderCellDef> Codice </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Codice}} </td>
      </ng-container>
-->
      <ng-container matColumnDef="Soglia1">
          <th mat-header-cell *matHeaderCellDef> Soglia 1 </th>
          <td mat-cell *matCellDef="let template"> {{template.Soglia1}} </td>
      </ng-container>

      <ng-container matColumnDef="Orainiziof1">
          <th mat-header-cell *matHeaderCellDef> Ora inizio F1 </th>
          <td mat-cell *matCellDef="let template"> {{template.Orainiziof1}} </td>
      </ng-container>

      <ng-container matColumnDef="Orafinef1">
          <th mat-header-cell *matHeaderCellDef> Ora fine F1 </th>
          <td mat-cell *matCellDef="let template"> {{template.Orafinef1}} </td>
      </ng-container>

      <ng-container matColumnDef="Soglia2">
          <th mat-header-cell *matHeaderCellDef> Soglia 2 </th>
          <td mat-cell *matCellDef="let template"> {{template.Soglia2}} </td>
      </ng-container>

      <ng-container matColumnDef="Orainiziof2">
          <th mat-header-cell *matHeaderCellDef> Ora inizio F2 </th>
          <td mat-cell *matCellDef="let template"> {{template.Orainiziof2}} </td>
      </ng-container>

      <ng-container matColumnDef="Orafinef2">
          <th mat-header-cell *matHeaderCellDef> Ora fine F2 </th>
          <td mat-cell *matCellDef="let template"> {{template.Orafinef2}} </td>
      </ng-container>

      <ng-container matColumnDef="Modifica">
        <th mat-header-cell *matHeaderCellDef> Modifica </th>
        <td mat-cell *matCellDef="let template">
          <mat-icon color="primary" (click)="editTemplate(template)">create</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="Duplica">
        <th mat-header-cell *matHeaderCellDef> Duplica </th>
        <td mat-cell *matCellDef="let template">
          <mat-icon color="primary" (click)="copyTemplate(template)">file_copy<</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</form>
</mat-card-content>
<mat-card-actions>
<!--  <table>
    <tr>
      <td>-->
        <button mat-raised-button  color="primary" (click)="newTemplate()">Nuovo Template</button>
<!--      </td>
      <td>-->
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
<!--      </td>
    </tr>
  </table>-->
</mat-card-actions>

</mat-card>
