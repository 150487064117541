import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { MaterialeData } from '../../models/materialedata';
import { Materiale } from '../../models/materiale';
import { Lul } from '../../models/lul';

@Component({
  selector: 'app-addMaterialeToLul',
  templateUrl: './addMaterialeToLul.component.html',
  styleUrls: ['./addMaterialeToLul.component.css']
})
export class AddMaterialeToLulComponent implements OnInit {
  IPAddress:string;
  Port:string;
  materialeList: Array<Materiale>;
  currentLul: Lul;
  selectedMaterial: Materiale;
  constructor(public dialogRef: MatDialogRef<AddMaterialeToLulComponent>,
    private commonService: CommonService, private httpClient: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: MaterialeData) {
      this.materialeList=this.data.materialeList;
      this.currentLul=this.data.lul;
    }

    ngOnInit(): void {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
      }
  }
  removeMaterialeFromLul(){
    this.currentLul.Materialefilename="";
    this.currentLul.Materialeid=null;
    this.currentLul.Materialename="";
    this.updateLul();
  }
  addMaterialeToLul(){
    const formData = new FormData();
      this.currentLul.Materialefilename=this.selectedMaterial.Filename;
      this.currentLul.Materialeid=this.selectedMaterial.Codice;
      this.currentLul.Materialename=this.selectedMaterial.Name;
      this.updateLul();
  }

  updateLul(){
    this.commonService.addMaterialeToLul(this.currentLul).subscribe(data => {
      let rc;
      rc=JSON.stringify(data)
      if (rc!=-1){
  //      alert("Materiale salvato con successo");
      }else{
        alert("Errore salvataggio materiale");
      }
    });
  }
}
