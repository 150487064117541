<mat-card>
    <mat-card-header>
        <mat-card-title>Gestisci gruppi</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form>
         <table>
           <tr>
              <td>
                   <mat-label class="text-sm">Persone</mat-label>
              </td>
<!--                 <mat-label *ngIf="selectedValueGruppo===undefined || selectedValueGruppo===''" class="text-sm" style="font-weight:normal; color:red">Selezionare un gruppo </mat-label>
-->             <td>
                  <mat-form-field>
                   <mat-label class="text-sm">Gruppo</mat-label>
                   <mat-select [(ngModel)]="selectedValueGruppo" name="gruppo" (selectionChange)="manageChangeGruppo()" class="text-sm">
                     <mat-option *ngFor="let gruppo of gruppi" [value]="gruppo.Nome">
                       {{gruppo.Nome}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>
              </td>
           </tr>
           <tr>
              <td>
                  <mat-selection-list #selectionList>
                    <mat-list-option [value]="persona" *ngFor="let persona of persone"  >
                      {{persona.Nome}} {{persona.Cognome}}
                      </mat-list-option>
                    </mat-selection-list>

             </td>
             <td>
                   <mat-selection-list #associatedSelectionList>
                     <mat-list-option [value]="associatedpersona" *ngFor="let associatedpersona of associatedpersone"  >
                       {{associatedpersona.Nome}} {{associatedpersona.Cognome}}
                     </mat-list-option>
                   </mat-selection-list>
            </td>
          </tr>
          <tr>
             <td align="right" style="padding:20px">
               <button mat-raised-button  color="primary" (click)="add()" [disabled]="this.selectedValueGruppo===undefined || this.selectedValueGruppo===''">Aggiungi</button>
             </td>

             <td>
               <button mat-raised-button  color="primary" (click)="remove()">Togli</button>
             </td>
         </tr>

        </table>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button  color="primary" (click)="save()">Salva</button>
      <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
    </mat-card-actions>

</mat-card>
