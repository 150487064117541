<mat-card>
    <mat-card-header>
        <mat-card-title>Lista Menu Items</mat-card-title>
    </mat-card-header>

    <mat-card-content class="content">
          <form>
           <mat-form-field appearance="fill" style="padding-right: 10px">
              <mat-label class="text-sm">Istanza</mat-label>
                <mat-select [(ngModel)]="selectedValueIstanza" name="Istanza" (selectionChange)="manageChangeIstanza()" class="text-sm">
                  <mat-option *ngFor="let istanza of istanze" [value]="istanza.Nome">
                    {{istanza.Nome}}
                  </mat-option>
              </mat-select>
           </mat-form-field>
           <mat-form-field appearance="fill" style="padding-right: 10px">
              <mat-label class="text-sm">Ruolo</mat-label>
                <mat-select [(ngModel)]="selectedValueRuolo" name="ruolo" (selectionChange)="manageChangeRuolo()" class="text-sm">
                  <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.Nome">
                    {{ruolo.Nome}}
                  </mat-option>
              </mat-select>
           </mat-form-field>

         <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

         <ng-container matColumnDef="Nome">
             <th mat-header-cell *matHeaderCellDef> Nome
               <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
             </th>
             <td mat-cell *matCellDef="let menuitem"> {{menuitem.Nome}} </td>
         </ng-container>

         <ng-container matColumnDef="Contesto">
             <th mat-header-cell *matHeaderCellDef> Contesto
               <img src="./assets/images/sort.png" (click)="manageClick('Contesto')"/>
             </th>
             <td mat-cell *matCellDef="let menuitem"> {{menuitem.Contesto}} </td>
         </ng-container>

         <ng-container matColumnDef="Ruolo">
             <th mat-header-cell *matHeaderCellDef> Ruolo
               <img src="./assets/images/sort.png" (click)="manageClick('Role')"/>
             </th>
             <td mat-cell *matCellDef="let menuitem"> {{menuitem.Nomeruolo}} </td>
         </ng-container>

          <ng-container matColumnDef="Ordine">
              <th mat-header-cell *matHeaderCellDef> Ordine
                <img src="./assets/images/sort.png" (click)="manageClick('Ordine')"/>
              </th>
              <td mat-cell *matCellDef="let menuitem"> {{menuitem.Ordine}} </td>
          </ng-container>

          <ng-container matColumnDef="Sezione">
              <th mat-header-cell *matHeaderCellDef> Sezione
                <img src="./assets/images/sort.png" (click)="manageClick('Sezione')"/>
              </th>
              <td mat-cell *matCellDef="let menuitem"> {{menuitem.Sezione}} </td>
          </ng-container>

          <ng-container matColumnDef="Modifica" mat-sort-header>
            <th mat-header-cell *matHeaderCellDef> Modifica </th>
            <td mat-cell *matCellDef="let menuitem">
              <mat-icon color="primary" (click)="edit(menuitem)">create</mat-icon>
           </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <table>
        <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="new()" [disabled]="selectedValueIstanza===undefined || selectedValueIstanza===''">Nuovo item</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>
</mat-card>
