import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {Lezione} from '../../models/lezione';
import {CommonService} from "../../service/common.service";
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-newfolder',
  templateUrl: './newfolder.component.html',
  styleUrls: ['./newfolder.component.css']
})
export class NewfolderComponent implements OnInit {

  foldername = ''
  invalidFolder = false
  IPAddress:String;
  Port:String;
  Protocol:string;
  lezione:Lezione;
  message: string;

  @Input() error: string | null;

  constructor(private router: Router, private httpClient: HttpClient, private commonService: CommonService) {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
  }

  ngOnInit() {
  }

  salva() {
    if (this.foldername===undefined || this.foldername===""){
      alert("E' necessario specificato il nome della cartella");
    } else {
    const folderData = new FormData();
    folderData.append('materialeid', this.commonService.currentMateriale.Codice.toString());
    folderData.append('codice', this.commonService.currentLezioneCodice.toString());
    folderData.append('nome', this.foldername);
    folderData.append('tipo', 'CARTELLA');
    //Make a call to the Spring Boot Application to save the image


    this.httpClient.post(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/materiale/createfolder", folderData, { observe: 'response' })
      .subscribe((response) => {
        if (response.status === 200) {
          this.message = 'Cartella creata correttamente';
        } else {
          this.message = 'Errore creazione cartella';
        }
        this.router.navigate(['materiale']);
      }
      );
    }
  }
  cancella(){
   this.router.navigate(['materiale']);
  }

  }
