<h1 mat-dialog-title>Inserici un commento</h1>
<div mat-dialog-content>
  <mat-form-field class="content">
    <textarea matInput [(ngModel)]="permesso.Commento"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button  color="primary" [mat-dialog-close]='true' (click)="saveCommento()">Salva</button>
  <button mat-raised-button  color="primary" mat-dialog-close >Cancella</button>
</div>
