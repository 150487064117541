<mat-card class="matcardcontent">
    <mat-card-header>
        <mat-card-title>Lista master</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <table mat-table style="width=100%" [dataSource]="dataSource" matSort class="mat-elevation-z8">

          <ng-container matColumnDef="Codicecorso">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice corso
                <img src="./assets/images/sort.png" (click)="manageClick('Codicecorso')"/>
              </th>
              <td mat-cell *matCellDef="let master"> {{master.Codicecorso}} </td>
          </ng-container>

          <ng-container matColumnDef="Nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
              </th>
              <td mat-cell *matCellDef="let master"> {{master.Nome}} </td>
          </ng-container>

          <ng-container matColumnDef="Descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione
                <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
              </th>
              <td mat-cell *matCellDef="let master"> {{master.Descrizione}} </td>
          </ng-container>

          <ng-container matColumnDef="Abilitato">
            <th mat-header-cell *matHeaderCellDef> Abilitato
              <img src="./assets/images/sort.png" (click)="manageClick('Abilitato')"/>
            </th>
            <td mat-cell *matCellDef="let master">
              <mat-icon *ngIf="master.Abilitato==='YES'"  color="primary" (click)="toggle(master)">check_circle</mat-icon>
              <mat-icon *ngIf="master.Abilitato==='NO'"  color="primary" (click)="toggle(master)">check_circle_outline</mat-icon>
           </td>
          </ng-container>

          <ng-container matColumnDef="Modifica" mat-sort-header>
            <th mat-header-cell *matHeaderCellDef> Modifica </th>
            <td mat-cell *matCellDef="let master">
              <mat-icon color="primary" (click)="editMaster(master)">create</mat-icon>
           </td>
          </ng-container>

          <ng-container matColumnDef="Elimina">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Elimina </th>
            <td mat-cell *matCellDef="let master">
              <mat-icon color="primary" (click)="deleteMaster(master)">delete</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>

    </mat-card-content>

    <mat-card-actions>
            <button mat-raised-button  color="primary" (click)="newMaster()">Nuovo master</button>
  <!--          <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button> -->
    </mat-card-actions>


</mat-card>
