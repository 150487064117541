import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from "../../service/common.service";
import { EventEmitterService } from '../../service/event-emitter.service';
import { Istanza } from "../../models/istanza";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CambiapasswordComponent } from '../../dialog/cambiapassword/cambiapassword.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public loginService:AuthenticationService,
    private commonService: CommonService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private dialog: MatDialog){ }


      private rolename:string;
      private drawerIsVisible:boolean;
      searchtext:string;
      username:string;
      ngOnInit() {

        this.drawerIsVisible=false;
        console.log("INIT HEADER");
        if (this.eventEmitterService.subsVar===undefined) {
          console.log("UNDEFINED EMITTER SERVICE");
          this.eventEmitterService.subsVar = this.eventEmitterService.invokeOnLogin.subscribe((name:string) => {
            console.log("OK LOGIN RECEIVED");
            this.username=sessionStorage.getItem("username");
            this.getRole();
          });
        }

      }
      getRole(){
        console.log("GET ROLE");
        this.commonService.getRuolo(this.username).subscribe(
          response => this.handleSuccessfulResponse(response)
        );
      }
      getUsername():string{
        console.log("USERNAME:"+this.username);
        return sessionStorage.getItem('username');
      }


      handleSuccessfulResponse(response) {
        this.commonService.currentRuolo=response;
        //console.log(this.role);
        this.rolename=this.commonService.currentRuolo.Nome;
        sessionStorage.setItem("rolename", this.rolename);
        console.log("USER:"+this.username);
        console.log("ROLE:"+this.rolename);
      }

      onDrawerClick(){
        this.drawerIsVisible=!this.drawerIsVisible;
        console.log("EMIT DRAWER CLICK");
        this.eventEmitterService.onFirstComponentButtonClick();

      }


       cambiaPassword(){
        const dialogRef = this.dialog.open(CambiapasswordComponent, {
          width: '450px',
          data: {title:'Cambia password',username:sessionStorage.getItem("username")}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result){

          }
        });
      }

      cerca(){

     }
    entra(){
       this.router.navigate(['login']);
    }

    showvideoapp(){
       this.router.navigate(['videoapp']);
    }

     esci(){
       this.drawerIsVisible=false;
       this.eventEmitterService.onLogout();

    //   if (this.drawerIsVisible){
    //      this.onDrawerClick();
    //      this.drawerIsVisible=false;
    //   }
       this.router.navigate(['logout']);
     }

     getCurrentIstanza(){
       if (this.commonService.currentIstanza!=undefined){
         return this.commonService.currentIstanza.Codice;
       }else{
         return -1;
       }
     }
  }
