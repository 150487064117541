import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Template } from '../../models/template';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Inject, LOCALE_ID } from '@angular/core';
import { formatDate} from '@angular/common';

@Component({
  selector: 'app-addtemplate',
  templateUrl: './addtemplate.component.html',
  styleUrls: ['./addtemplate.component.css']
})
export class AddtemplateComponent implements OnInit {
  template: Template;
  hide = true;
  rc:String;

//  Orainizio: string;
//  Orafine: string;
//  Data: Date= new Date();;

  constructor(private commonService: CommonService, private router: Router) {
  }

  ngOnInit(): void {


        //console.log("+++++"+this.commonService.selectedUser);

          if (this.commonService.selectedTemplate!=undefined){
            this.template=this.commonService.selectedTemplate;
            this.commonService.selectedTemplate=undefined;

            }else{
              this.template= new Template();
              this.template.Nome='';
              this.template.Codice=0;
              this.template.Descrizione='';
              this.template.Soglia1=0;
              this.template.Orainiziof1='';
              this.template.Orafinef1='';
              this.template.Soglia2=0;
              this.template.Orainiziof2='';
              this.template.Orafinef2='';
            }

  }
  ngAfterViewInit(){
    //console.log("---->"+this.lezione.Datainizio.toString());
    //this.Data=new Date();
    //console.log("---->"+this.Data.toString());
  }


  save() {

    if ((this.template.Nome=='')||(this.template.Descrizione=='')){
      alert("E' necessario inserire nome e descrizione");
   }else{

      if (this.checkHourIsValid(this.template.Orainiziof1, this.template.Orafinef1)
        && this.checkHourIsValid(this.template.Orainiziof2, this.template.Orafinef2)){

        this.commonService.saveTemplate(this.template).subscribe(data => {

          this.rc=JSON.stringify(data)
          if (this.rc==="0"){

            this.router.navigate(['template']);
          }else{
            alert("Errore creazione template");
          }
        });
      }
    }

  }

  checkHourIsValid(oinizio:string, ofine:string):boolean{
    /*
    const parts = d.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
   */
    const inizioparts = oinizio.split(':');
    const orainizio = parseInt(inizioparts[0]);
    const minutiinizio = parseInt(inizioparts[1]);
    const fineparts = ofine.split(':');
    const orafine = parseInt(fineparts[0]);
    const minutifine = parseInt(fineparts[1]);

    if (isNaN(orainizio)||isNaN(minutiinizio)||isNaN(orafine)||isNaN(minutifine)){
      alert('Gli orari devono essere numerici');
      return false;
     }else if (orafine<orainizio){
      alert('Ora di fine deve essere maggiore di quella di inizio');
      return false;
     }else if ((orafine===orainizio)&&(minutifine<minutiinizio)){
      alert('Minuti di fine devono essere maggiori di quelli di inizio');
      return false;
    }
    //console.log("------"+orainizio+" "+orafine);
    return true;
  }

  cancel(){
    this.commonService.selectedTemplate=null;
    this.router.navigate(['template']);
  }


}
