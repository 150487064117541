  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista documenti</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->
          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">Azienda</mat-label>
            <mat-select [(ngModel)]="selectedValueAzienda" name="azienda" (selectionChange)="manageChangeAzienda()" class="text-sm">
              <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
                {{azienda.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="isSelectedPersona()" appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">Persona</mat-label>
            <mat-select [(ngModel)]="selectedValuePersona" name="persona" class="text-sm">
              <mat-option *ngFor="let persona of persone" [value]="persona.Cognome">
                {{persona.Cognome}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="isSelectedGruppo()" appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">Gruppo</mat-label>
            <mat-select [(ngModel)]="selectedValueGruppo" name="gruppo" class="text-sm">
              <mat-option *ngFor="let gruppo of gruppi" [value]="gruppo.Nome">
                {{gruppo.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

           <mat-form-field appearance="fill" style="padding-right: 10px">
             <mat-label class="text-sm">Categoria</mat-label>
             <mat-select [(ngModel)]="selectedValueCategoria" name="categoria" (selectionChange)="manageChangeCategoria()" class="text-sm">
               <mat-option *ngFor="let categoria of categorie" [value]="categoria.Nome">
                 {{categoria.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>

           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Stato</mat-label>
             <mat-select [(ngModel)]="selectedValueStato" name="stato" (selectionChange)="manageChangeStato()" class="text-sm">
               <mat-option *ngFor="let stato of stati" [value]="stato.Nome">
                 {{stato.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let documento"> {{documento.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Descrizione">
          <th mat-header-cell *matHeaderCellDef> Descrizione
            <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
          </th>
          <td mat-cell *matCellDef="let documento"> {{documento.Descrizione}} </td>
      </ng-container>

      <ng-container matColumnDef="Filename">
        <th mat-header-cell *matHeaderCellDef> Nome file
          <img src="./assets/images/sort.png" (click)="manageClick('Filename')"/>
        </th>
        <td mat-cell *matCellDef="let documento">{{documento.Filename}}</td>
      </ng-container>

      <ng-container matColumnDef="Datapubblicazione">
        <th mat-header-cell *matHeaderCellDef> Data  pubblicazione
          <img src="./assets/images/sort.png" (click)="manageClick('Datapubblicazione')"/>
        </th>
        <td mat-cell *matCellDef="let documento">{{documento.Datapubblicazione | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="Nota">
        <th mat-header-cell *matHeaderCellDef hidden="isSelectedPersona()"> Nota
          <img src="./assets/images/sort.png" (click)="manageClick('Nota')"/>
        </th>
        <td mat-cell *matCellDef="let documento" hidden="isSelectedPersona()">{{documento.Nota}}</td>
      </ng-container>

      <ng-container matColumnDef="Stato">
        <th mat-header-cell *matHeaderCellDef> Stato
          <img src="./assets/images/sort.png" (click)="manageClick('Stato')"/>
        </th>
        <td mat-cell *matCellDef="let documento">{{documento.Nomestato}}</td>
      </ng-container>

      <ng-container matColumnDef="Elimina">
        <th mat-header-cell *matHeaderCellDef> Elimina </th>
        <td mat-cell *matCellDef="let documento">
          <mat-icon  color="primary" (click)="eliminaDocumento(documento)">delete</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="Scarica">
        <th mat-header-cell *matHeaderCellDef> Scarica </th>
        <td mat-cell *matCellDef="let documento">
          <mat-icon color="primary" (click)="downloadDocumento(documento)">save_alt</mat-icon>
       </td>
      </ng-container>

      <ng-container matColumnDef="Invia">
        <th mat-header-cell *matHeaderCellDef> Invia </th>
        <td mat-cell *matCellDef="let documento">
          <mat-icon color="primary" (click)="sendDocumento(documento)" *ngIf="documento.Nomestato==='Bozza'">send</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="Tipodestinatario">
          <th mat-header-cell *matHeaderCellDef> Destinatario </th>
          <td mat-cell *matCellDef="let documento"> {{documento.Tipodestinatario}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>

<!-- </form> -->
</mat-card-content>
<mat-card-actions>
  <table>
    <tr>
      <td>
        <button mat-raised-button  color="primary" [disabled]="!isEnabledToLoad() || this.selectedValueCategoria===undefined || this.selectedValueCategoria===''" (click)="newDocumento()" class="nav-link">Allega file</button>
      </td>
      <td>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </td>
    </tr>
  </table>
</mat-card-actions>

</mat-card>
