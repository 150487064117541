<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-around">
	<!--<div fxFlex="100%">-->
	  <mat-drawer-container hasBackdrop=false  class="drawer-container">
	    <mat-drawer #drawer mode="side" class="drawer">
	        <mat-list class="sidebar">
	          <a class="active">Amministrazione</a>
	          <mat-list-item class="listitem" *ngFor="let item of items">
	            <button mat-menu-item (click)="goto(item.Contesto); drawer.toggle()">{{item.Nome}}</button>
	          </mat-list-item>
		<!--           <a class="active">Link utili</a>
	          <mat-list-item class="listitem">
               <a matLine href="https://www.dthink.it/" target="_blank" >dThink</a>
	          </mat-list-item>-->
	        </mat-list>
	  	</mat-drawer>
	    <mat-drawer-content class="contents">
	  		  <router-outlet></router-outlet>
	    </mat-drawer-content>
	  </mat-drawer-container>
</div>
