import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Materiale } from '../../models/materiale';
import { Lezione } from '../../models/lezione';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { ConfirmData } from '../../models/confirmdata';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  selectedValueMaster:string;
  selectedValueModulo:string;
  selectedValue:string;
  IPAddress:string;
  Port:string;
  Protocol: string;
  masters: Master[];
  moduli: Modulo[];
  lezioni: Lezione[];
  currentMaster:Master;
  currentModulo:Modulo;
  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;
  materiali:Materiale[]=[];
  basepath="assets/videos/";
  //materiali = new MatTableDataSource<Materiale>(ELEMENT_DATA);
  dataSource = new MatTableDataSource<Materiale>(this.materiali);
  displayedColumns: string[] = [
    ' ',
    'Name',
    'Datapubblicazione',
    'Filename',
    'Visualizza',
    'Mimetype'];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService,
  private dialog: MatDialog, public sanitizer: DomSanitizer) {}


  ngOnInit() {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllMasters().subscribe((res) => {
        this.masters = res;
        if (this.commonService.selectedMaster!=undefined){
          this.currentMaster=this.commonService.selectedMaster;
          this.selectedValueMaster=this.currentMaster.Nome;
          this.commonService.selectedMaster=undefined;
          this.commonService.getModuliByMaster(this.currentMaster).subscribe(response => {
              this.moduli=response;
            });
        }
    });

    this.lezioni=[];
    this.moduli=[];
    this.materiali=[];

    this.sortedDirection.set("Name",true);
    this.sortedDirection.set("Datapubblicazione",true);
    this.sortedDirection.set("Filename",true);
    this.sortedDirection.set("Nomelezione",true);
    this.sortedDirection.set("Mimetype",true);

  }
  ngAfterViewInit() {

  }

  manageChangeMaster(){
//    if (this.selectedValueMaster===undefined || this.selectedValueMaster===''){
     this.lezioni=[];
     this.selectedValueModulo="";
//     return;
//    }
    this.masters.forEach(element => {
      if (this.selectedValueMaster === element.Nome){
        this.currentMaster=element;
      }
    });
    this.selectedValueModulo=undefined;
    this.selectedValue=undefined;
    this.moduli=[];
    this.lezioni=[];
    this.materiali=[];
    this.dataSource = new MatTableDataSource<Materiale>(this.materiali);
    this.commonService
      .getModuliByMaster(this.currentMaster)
      .subscribe(response => {
        this.moduli=response;
      });
  }

  manageChangeModulo(){
    if (this.selectedValueModulo===undefined){

     return;
    }
    this.moduli.forEach(element => {
      if (this.selectedValueModulo === element.Nome){
        this.currentModulo=element;
      }
    });
    this.selectedValue=undefined;
    this.lezioni=[];
    this.materiali=[];
    this.dataSource = new MatTableDataSource<Materiale>(this.materiali);

    this.commonService.getLezioniByModulo(this.currentModulo).subscribe(response => {
        this.lezioni=response;
        for (let i=0; i<this.lezioni.length; i++){
          this.httpClient.get(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/lezioni/picture/' + this.lezioni[i].Codice)
            .subscribe(
              res => {
                this.retrieveResonse = res;
                if (this.retrieveResonse!=null && this.retrieveResonse.Picbyte!=null){
                  this.base64Data = this.retrieveResonse.Picbyte;
                  this.lezioni[i].Picbyte='data:image/jpeg;base64,' + this.base64Data;
                }else{
                  this.lezioni[i].Picbyte='assets/images/coursedefault.jpg';
                }
              }
            )};
      });
  }


  getNomeByCodicemaster(codicemaster: number):String{
    let nomemaster:String="";
    this.masters.forEach(element => {
      if (element.Codice === codicemaster){
        nomemaster=element.Nome;

      }
    });
    return nomemaster;
  }

  getNomeByCodicemodulo(codicemodulo: number):String{
    let nomemodulo:string="";
    this.moduli.forEach(element => {
      if (element.Codice === codicemodulo){
        nomemodulo=element.Nome;
      }
    });
    return nomemodulo;
  }


  aggiornaLista(ev:Event){
      this.commonService.getAllMaterialiByLezioneid(Number(ev)).subscribe((res) => {
        this.materiali=res;
        this.dataSource = new MatTableDataSource<Materiale>(this.materiali);
      });
   }

  deleteLesson(lezione: Lezione){

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione',description:'Confermi la cancellazione?'}
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result){
        this.commonService.deleteLesson(lezione.Codice).subscribe(data => {
          if (data===1){
            alert("Non si può eseguire la cancellazione in quanto sono presenti dei materiali collegati");
          }else{
            this.lezioni = this.lezioni.filter(u => u !== lezione);

          }
        });
      }
    });
  }


    visualizzaCorso(corso:Lezione){
      this.commonService.selectedLezione=corso;
      this.router.navigate(['courseviewer']);
    }

    cancel(){
      this.selectedValueMaster=undefined;
      this.selectedValueModulo=undefined;
      this.materiali=[];
      this.moduli=[];
      this.lezioni=[];
      this.dataSource = new MatTableDataSource<Materiale>(this.materiali);
    }

    getProperyValue(property: string):string{
      return this.commonService.getProperyValue(property);
    }



    manageClick(attributo: string){

      //alert(ev.target)
    /*
      switch (_case) {
           case 'number':
             this.elements2.sort((a, b) => {
               return a.position - b.position;
             });
             break;
           case 'text':
    */
      let tempList:Materiale[];
      tempList=this.materiali;
      let direction=this.sortedDirection.get(attributo);

      tempList.sort((a, b) => {
         var typeA = "";
         var typeB = "";
         if (a[attributo]!=null){
           if (isNaN(b[attributo]))
              typeA = a[attributo].toLowerCase();
           else
              typeA = a[attributo].toString();
         }
         if (b[attributo]!=null){
           if (isNaN(b[attributo]))
              typeB = b[attributo].toLowerCase();
            else
              typeB = b[attributo].toString();
         }
           if (direction){
             if (typeA > typeB){
               return -1;
             }else if (typeA < typeB){
                return 1;
             }else{
               return 0;
             }
           }else{
             if (typeA < typeB){
               return -1;
             }else if (typeA > typeB){
                return 1;
             }else{
               return 0;
             }
           }
       });
       direction=!direction;
       this.sortedDirection.set(attributo,direction);

       this.dataSource = new MatTableDataSource<Materiale>(this.materiali);

     }
  geticona(materiale:Materiale):string{
       if (materiale.Mimetype.includes("pdf")) {
         return "picture_as_pdf";
       }else if(materiale.Mimetype.includes("video")){
            return "video_call";
       }else{
        return "insert_drive_file";
      }
  }

  visualizza(materiale:Materiale){
    this.commonService.selectedMateriale=materiale;
    this.httpClient.get(this.Protocol + '://' + this.IPAddress + ':' + this.Port + '/api/attachment/getvideo/' + materiale.Codice).subscribe(
      data => {
        let mimetype=this.commonService.selectedMateriale.Mimetype;
        let nomeAllegato=this.commonService.selectedMateriale.Filename;
        let ext=nomeAllegato.substring(nomeAllegato.length-4, nomeAllegato.length);
        let username=sessionStorage.getItem('username');
        this.commonService.srcvideo=this.basepath+username+'/'+materiale.Codice+ext;
        console.log("-->"+this.basepath);
        console.log("-->"+materiale.Codice);
        console.log("-->"+ext);
        console.log("-->"+username);
        this.router.navigate(['courseviewer']);
    });

  }
}
