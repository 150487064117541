export class Azienda {
  Nome: string;
  Partitaiva: string;
  Missione: string;
  Codicefiscale: string;
  Ragionesociale: string;
  Attivita: string;
  Numerodipendenti: number;
  Rea: string;
  Datarea: Date;
  Cameracommercio: string;
  Isactive: boolean;
  Codice: number;
  Codiceistanza: number;

  constructor(){
    this.Nome='';
    this.Partitaiva='';
    this.Missione='';
    this.Codicefiscale='';
    this.Ragionesociale='';
    this.Attivita='';
    this.Numerodipendenti=0;
    this.Rea='';
    this.Datarea=null;
    this.Cameracommercio='';
    this.Isactive=true;
    this.Codice=0;
    this.Codiceistanza=0;
  }
}
