  <mat-card>
      <mat-card-header>
          <mat-card-title>Dettaglio azienda</mat-card-title>
      </mat-card-header>
      <mat-card-content class="content">

        <form [formGroup]="registerForm" (ngSubmit)="save()">

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Nome</mat-label>
            <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Partita IVA</mat-label>
            <input matInput placeholder="Partitaiva" formControlName="Partitaiva" name="Partitaiva">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Codice fiscale</mat-label>
            <input matInput placeholder="Codicefiscale" formControlName="Codicefiscale" name="Codicefiscale">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Missione</mat-label>
            <input matInput placeholder="Missione" formControlName="Missione" name="Missione">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Ragione sociale</mat-label>
            <input matInput placeholder="Ragione sociale" formControlName="Ragionesociale" name="Ragionesociale">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Attivita</mat-label>
            <input matInput placeholder="Attivita" formControlName="Attivita" name="Attivita">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Numero dipendenti</mat-label>
            <input matInput placeholder="Numero dipendenti" formControlName="Numerodipendenti" name="Numerodipendenti">
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Rea</mat-label>
            <input matInput placeholder="Rea" formControlName="Rea" name="Rea">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label class="text-sm">Data Rea</mat-label>
             <input matInput
              [matDatepicker]="picker" placeholder="Datarea" name="Datarea" formControlName="Datarea"/>
             <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
             <mat-datepicker #picker></mat-datepicker>
         </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Camera commercio</mat-label>
            <input matInput placeholder="Cameracommercio" formControlName="Cameracommercio" name="Cameracommercio">
          </mat-form-field>

          <mat-form-field class="example-full-width" [hidden]=true>
            <mat-label class="text-sm">Codice</mat-label>
            <input matInput placeholder="Codice" formControlName="Codice" name="Codice">
          </mat-form-field>



        </form>
      </mat-card-content>
      <mat-card-actions>
        <table>
          <tr>
            <td>
              <button mat-raised-button  color="primary" (click)="save()">Salva</button>
            </td>
            <td>
              <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
            </td>
          </tr>
        </table>
      </mat-card-actions>

  </mat-card>
