<!-- <div class="container">
    <div>
        User Name : <input type="text" name="username" [(ngModel)]="username"> Password : <input type="password" name="password" [(ngModel)]="password">
    </div>
    <button (click)=checkLogin() class="btn btn-success">
    Login
  </button>
</div> -->

<mat-card class="matcardcontent">
    <mat-card-header>
        <mat-card-title>Login</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <table class="example-full-width" cellspacing="0">

    <!--
                <tr *ngIf="getCurrentIstanza().Codice===0">
                  <td>
                    <mat-form-field appearance="fill" style="padding-right: 10px">
                      <mat-label class="text-sm">Istanza</mat-label>
                      <mat-select [(ngModel)]="selectedValue" name="istanza" (selectionChange)="manageChange()" class="text-sm">
                        <mat-option *ngFor="let istanza of istanze" [value]="istanza.Nome">
                          {{istanza.Nome}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
-->


                <!--
                <tr *ngIf="getCurrentIstanza().Codice>0" style="height: 40px; color: #0000FF">
                  <td>
                      <mat-label class="example-full-width">
                        {{getCurrentIstanza().Nome}}
                      </mat-label>
                  </td>
                </tr>
                -->
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Username" [(ngModel)]="username" (keyup.enter)="checkLogin()" name="username" required>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Password" [(ngModel)]="password" (keyup.enter)="checkLogin()" type="password" name="password" required>
                        </mat-form-field>
                    </td>
                </tr>

                <p *ngIf="error" class="error">
                    {{ error }}
                </p>

            </table>
        </form>



    </mat-card-content>
    <mat-card-actions>
        <table>
          <tr>
            <td>
              <button mat-raised-button (click)="checkLogin()" color="primary">Login</button>
            </td>
            <td>
              <button mat-raised-button (click)="cancelLogin()" color="primary">Cancel</button>
            </td>
          </tr>
          <tr style="height: 40px;">
            <td>
            </td>
            <td>
              <a style="font-size: 12px;" matLine [routerLink]="['/forgotpassword']" >Password dimenticata</a>
            </td>
          </tr>
        </table>



    </mat-card-actions>

<!--
<div>
  <p>
   <strong>Nota bene</strong> - In caso di problemi di autenticazione, </p>
  <p>
   probabilmente non hai abilitato il certificato emesso
  </p>
  <p>  dalla piattaforma.
  </p>
  <p>
   Verifica facendo click <a href="https://vm.dthink.it:20080/authenticate">qui</a>
  </p>
</div>
-->

</mat-card>
