export class Lul {
  Nome: String;
  Codice: number;
  Descrizione: String;
  Codiceazienda: number;
  Nomeazienda: String;
  Codicetemplate: number;
  Nometemplate: String;
  Nomefile: String;
  Materialeid: number
  Materialename: String;
  Materialefilename: String;
  Paginainizio:number;
  Paginafine:number;
}
