<mat-card>
    <mat-card-header>
        <mat-card-title>Dettaglio lezione</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form [formGroup]="registerForm" (ngSubmit)="save()">
                <mat-form-field appearance="fill" style="padding-right: 10px">
                  <mat-label class="text-sm">Master</mat-label>
                  <mat-select formControlName="selectedValueMaster" name="master" (selectionChange)="manageChangeMaster()" class="text-sm">
                    <mat-option *ngFor="let master of masters" [value]="master.Nome">
                      {{master.Nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label class="text-sm">Modulo</mat-label>
                  <mat-select formControlName="selectedValueModulo" name="modulo" (selectionChange)="manageChangeModulo()" class="text-sm">
                    <mat-option *ngFor="let modulo of moduli" [value]="modulo.Nome">
                      {{modulo.Nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="example-full-width">
                    <div *ngIf=retrievedImage>
                        <img class="courseimage" src="{{retrievedImage}}" >
                    </div>
                    <div class="container row">
                      <div class="col-md-6">
                          <input style="width:75px; height=75px;" #file type="file" accept='image/*' (change)="onFileChanged(file.files)">
                          <button mat-raised-button  color="primary" (click)="removeImg()">Rimuovi immagine</button>
                      </div>
                    </div>
                </div>

  

                <mat-form-field class="example-full-width">
                    <mat-label>Nome</mat-label>
                  <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <mat-label>Descrizione</mat-label>
                  <input matInput placeholder="Descrizione" formControlName="Descrizione" name="Descrizione">
                </mat-form-field>
<!--
                <mat-form-field class="example-full-width">
                  <mat-label class="text-sm">Sede</mat-label>
                  <mat-select formControlName="selectedValueSede" name="sede" (selectionChange)="manageChangeSede()" class="text-sm">
                    <mat-option *ngFor="let sede of sedi" [value]="sede.Nome">
                      {{sede.Nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
-->
                <mat-form-field class="example-full-width">
                  <mat-label>Url</mat-label>
                  <input matInput placeholder="Url" formControlName="Url" name="Url">
                </mat-form-field>
<!--
                <mat-form-field class="example-full-width">
                  <mat-label class="text-sm">Aula</mat-label>
                  <mat-select formControlName="selectedValueRoom" name="room" (selectionChange)="manageChangeRoom()" class="text-sm">
                    <mat-option *ngFor="let room of rooms" [value]="room.Name">
                      {{room.Name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
-->
                   <mat-form-field appearance="fill" style="padding-right: 10px">
                  <mat-label>Data</mat-label>
                         <!--
<!--<!>>
                         <input
      matInput
                              [matDatepicker]="picker"
                              [(ngModel)]="selectedDate"
                              [ngModelOptions]="{standalone: true}"
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker [ngModelOptions]="{standalone: true}">
                        -->
                      <input matInput [matDatepickerFilter]="myFilter"
                           [matDatepicker]="picker" placeholder="Data" name="Data" formControlName="Datainizio" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>

                  </mat-form-field>

            <!--       <mat-form-field appearance="outline"> -->

                   <mat-form-field appearance="fill" style="padding-right: 10px">
                       <mat-label>Ora inizio</mat-label>
                       <input matInput name="Orainizio" formControlName="Orainizio" type="time" placeholder="HH:MM" id="start_time_hour" >
              <!--           <mat-select name="ampm" class="ampm" [(ngModel)]="event.eampm">
                         <mat-option value="AM">AM</mat-option>
                         <mat-option value="PM">PM</mat-option>
                     </mat-select> -->
                  </mat-form-field>

                   <mat-form-field appearance="fill" style="padding-right: 10px">
                       <mat-label>Ora fine</mat-label>
                       <!-- (change)="OnEndHourChange($event)" -->
                           <input matInput  type="time" name="Orafine" formControlName="Orafine" placeholder="HH:MM" id="end_time_hour" >
                <!--       <mat-select name="ampm" class="ampm" [(ngModel)]="event.eampm">
                         <mat-option value="AM">AM</mat-option>
                         <mat-option value="PM">PM</mat-option>
                       </mat-select>   -->
                  </mat-form-field>



      </form>
    </mat-card-content>
    <mat-card-actions>
      <table>
         <tr>
          <td>
             <button mat-raised-button  color="primary" (click)="save()">Salva</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

</mat-card>
