<mat-card>
    <mat-card-header>
        <mat-card-title>Dettaglio modulo</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form [formGroup]="registerForm" (ngSubmit)="save()">

           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Master</mat-label>
              <mat-select formControlName="selectedValue" name="master" (selectionChange)="manageChangeMaster()" class="text-sm">
               <mat-option *ngFor="let master of masters" [value]="master.Nome">
                 {{master.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Nome</mat-label>
             <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Descrizione</mat-label>
             <input matInput placeholder="Descrizione" formControlName="Descrizione" name="Descrizione">
           </mat-form-field>
<!--
           <mat-form-field class="example-full-width">
             <input matInput formControlName="Codice">
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <input matInput formControlName="Codicemaster">
           </mat-form-field>
-->
      </form>

    </mat-card-content>
    <mat-card-actions>
      <table>
         <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="save()">Salva</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

</mat-card>
