import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from '../../environments/environment';


export class User {
  constructor(public status: string) {}
}

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  IPAddress:String;
  Port:String;
  Protocol:string;
  constructor(private httpClient: HttpClient) {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
    // console.log("Environment local:"+environment.address.isLocal);
  }
// Provide username and password for authentication, and once authentication is successful,
//store JWT token in session
  authenticate(username: string, password: string, codiceistanza: number) {
    //console.log("Protocol:"+this.Protocol);
    //console.log("IPAddress:"+this.IPAddress);
    //console.log("Port:"+this.Port);
    //console.log("Username:"+username);
    //console.log("Codiceistanza:"+codiceistanza);
    return this.httpClient
      .post<any>(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/authenticate", { username, password, codiceistanza })
      .pipe(
        map(userData => {
          sessionStorage.setItem("username", username);
          //console.log("Token:"+userData.Token);
          let tokenStr = "Bearer " + userData.Token;
          //let tokenStr = userData.Token;
          //console.log("Token:"+tokenStr);
          sessionStorage.setItem("token", tokenStr);

          return userData;
        })
      );
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem("username");
    //console.log('User null: '+(user === null));
    return !(user === null);
  }

  logOut() {
    sessionStorage.removeItem("username");
  }
}
