import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Persona } from '../../models/persona';
import { Azienda } from '../../models/azienda';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { configurazione } from '../../../configurazioni/configurazione';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-persone',
  templateUrl: './persone.component.html',
  styleUrls: ['./persone.component.css']
})
export class PersoneComponent implements OnInit {
  selectedValueAzienda:string;
  IPAddress:string;
  Port:string;
  Protocol: string;
  persone: Persona[]=[];
  aziende: Azienda[];
//  currentAzienda:Azienda;
  displayedColumns: string[] = ["Nome",
  "Cognome",
  "Matricola",
  "Datanascita",
  "Dataassunzione",
  "Datalicenziamento",
  "Sesso",
  "Cittanascita",
  "Provincianascita",
  "Codicefiscale",
  "Citta",
  "Provincia",
  "Residenza",
  "Cap",
  "Telefono",
  "Tipopersona",
  "Tipocontratto",
  "Nazione",
  "Pec",
  "Nomesede",
  "Mansione",
  "Nomestruttura",
//  "Codicemansione",
//  "Codiceazienda",
//  "Codicetipopersona",
//  "Codicetipocontratto",
//  "Codicestruttura",
//  "Codicesede",
  "newdocument",
  "documents",
  "edit"];

  azienda:Azienda;

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  dataSource = new MatTableDataSource<Persona>(this.persone);
  @ViewChild('paginator') paginator: MatPaginator;
  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService) {}

  ngOnInit() {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.commonService.getAllAziende().subscribe((res) => {
        this.aziende = res;
    //    if (this.aziende.length>0){
    //      this.azienda=this.aziende[0];
    //      this.selectedValueAzienda=this.azienda.Nome;
    //    }

        this.aziende.forEach(element => {
          if (element.Codice === this.commonService.currentAziendaCodice){
            this.azienda=element;
            this.selectedValueAzienda=this.azienda.Nome;
          }
        });

  //      this.manageChangeAzienda();
        this.aggiornaLista();
    });

    this.dataSource.paginator = this.paginator;

    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Cognome",true);
    this.sortedDirection.set("Matricola",true);
    this.sortedDirection.set("Datanascita",true);
    this.sortedDirection.set("Dataassunzione",true);
    this.sortedDirection.set("Datalicenziamento",true);
    this.sortedDirection.set("Sesso",true);
    this.sortedDirection.set("Cittanascita",true);
    this.sortedDirection.set("Provincianascita",true);
    this.sortedDirection.set("Codicefiscale",true);
    this.sortedDirection.set("Citta",true);
    this.sortedDirection.set("Provincia",true);
    this.sortedDirection.set("Residenza",true);
    this.sortedDirection.set("Cap",true);
    this.sortedDirection.set("Telefono",true);
    this.sortedDirection.set("Tipopersona",true);
    this.sortedDirection.set("Tipocontratto",true);
    this.sortedDirection.set("Nazione",true);
    this.sortedDirection.set("Pec",true);
    this.sortedDirection.set("Nomesede",true);
    this.sortedDirection.set("Mansione",true);
    this.sortedDirection.set("Nomestruttura",true);
  //  "Codicemansione",
  //  "Codiceazienda",
  //  "Codicetipopersona",
  //  "Codicetipocontratto",
  //  "Codicestruttura",
  //  "Codicesede",
    this.sortedDirection.set("newdocument",true);
    this.sortedDirection.set("documents",true);
    this.sortedDirection.set("edit",true);


  }

  manageChangeAzienda(){
    if (this.selectedValueAzienda===undefined){
     return;
    }
    this.aziende.forEach(element => {
      if (this.selectedValueAzienda === element.Nome){
        this.azienda=element;
      }
    });
    this.aggiornaLista();
  }

  cancel(){
    this.selectedValueAzienda="";
    this.aggiornaLista();
  }

  getNomeByCodiceazienda(codiceazienda: number):String{
    let nomeazienda:String="";
    this.aziende.forEach(element => {
      if (element.Codice === codiceazienda){
        nomeazienda=element.Nome;

      }
    });
    return nomeazienda;
  }

  deletePersona(persona: Persona): void {
    this.commonService.deletePersona(persona).subscribe(data => {
      this.persone = this.persone.filter(p => p !== persona);
    });
  }


  toggleEnable(persona: Persona){
    //selection.toggle(row);
    const personaData = new FormData();
//    personaData.append('userid', user.Userid);
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/persone/toggle", personaData, { observe: 'response' })
    .subscribe((response) => {
        this.aggiornaLista();
    });
  }

  aggiornaLista(){
    let nomeazienda:String="";
    let templistapersone:Persona[]=[];
    if (this.selectedValueAzienda!=undefined && this.selectedValueAzienda!=""){
      nomeazienda=this.selectedValueAzienda;
      this.commonService.getPersone(this.commonService.currentIstanza.Codice).subscribe(response => {
          let temppersone:Persona[]=response;
          temppersone.forEach(element => {
            const persona:Persona=element;
            persona.Nomeazienda=this.getNomeByCodiceazienda(element.Codiceazienda);
            persona.Codiceazienda=element.Codiceazienda;
            //console.log("Nome ruolo="+user.Nomeruolo);
          //  if (nomeazienda==="" || nomeazienda===persona.Nomeazienda){
            if (this.azienda.Codice===persona.Codiceazienda){
              templistapersone.push(persona);
            }
          });
          this.refreshLista(templistapersone);
        });
    }else{
      this.refreshLista(templistapersone);
    }
   }
   refreshLista(listapersone:Persona[]){
     this.persone=listapersone;
     this.dataSource = new MatTableDataSource<Persona>(this.persone);
     this.dataSource.paginator = this.paginator;
   }
/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/
  newPersona(){
    this.commonService.selectedAzienda=this.azienda;
    this.router.navigate(['addpersona']);
  }
  editPersona(persona: Persona): void {
      this.commonService.selectedAzienda=this.azienda;
      this.commonService.selectedPersona=persona;
      this.router.navigate(['addpersona']);
  }
  viewDocumentiPersona(persona: Persona): void {

      this.commonService.tipoDestinatario="Persona";
      this.commonService.selectedPersona=persona;
      if (persona!=undefined){
        this.commonService.currentAziendaCodice=persona.Codiceazienda;
        this.commonService.currentTipocategoria="Persona";
        this.router.navigate(['documenti']);
      }else{
        alert("E' necessario selezionare una persona")
      }
  }
  newDocumentoPersona(persona: Persona): void {
      this.commonService.tipoDestinatario="Persona";
      this.commonService.selectedPersona=persona;
      if (persona!=undefined){
        this.commonService.currentAziendaCodice=persona.Codiceazienda;
        this.commonService.currentTipocategoria="Persona";
        this.router.navigate(['newdocumento']);
      }else{
        alert("E' necessario selezionare una persona")
      }
  }

  getConfigurazione(){
    return configurazione.persona.fb;
  }

  manageClick(attributo: string){
    let tempList:Persona[];
    tempList=this.persone;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Persona>(this.persone);

   }

}
