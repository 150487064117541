import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';

import { CommonService } from "../../../service/common.service";
import { NotifyService } from '../../../service/notify.service';
import { MeseComponent } from '../mese/mese.component';




/*
import {
    GanttBarClickEvent,
    GanttViewType,
    GanttDragEvent,
    GanttLoadOnScrollEvent,
    GanttLineClickEvent,
    GanttLinkDragEvent,
    GanttItem,
    GanttViewOptions,
    GanttDate,
    GanttPrintService
} from '@worktile/gantt';
*/
@Component({
  selector: 'anno',
  templateUrl: './anno.component.html',
  styleUrls: ['./anno.component.css']
})
export class AnnoComponent implements OnInit {
@ViewChild('mese') mese: MeseComponent;
@Input() valore:any;
@Output() public refresh(){

 this.ngOnInit();
}

projectPeriod:any;

mesi=[];

selectedPeriod: any;

nomeMese:string;
nomidelmese=['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];


selectedValueProgetto: string;
hide = true;
rc:number;

IPAddress:string;
Port:string;
Protocol:string;

submitted = false;


  constructor(private commonService: CommonService) { }

  ngOnInit(): void {

    //this.projectPeriod=this.valore;

    let now:Date=new Date();

    const yyyyStart=now.getFullYear()-1;
    const yyyyEnd=now.getFullYear()+1;
    const mmStart=now.getMonth();
    const mmEnd=now.getMonth();


    for (let m = mmStart+1; m < 12; m++){
      this.mesi.push({ yyyy:yyyyStart, mm:m });
    }
    for (let m = 0; m < 12; m++){
      this.mesi.push({ yyyy:now.getFullYear(), mm:m });
    }
    for (let m = 0; m < mmEnd; m++){
      this.mesi.push({ yyyy:yyyyEnd, mm:m });
    }

    this.projectPeriod={ yyyyStart:yyyyStart, mmStart:mmStart, yyyyEnd:yyyyEnd, mmEnd:mmEnd };
    this.selectedPeriod = {yyyy: now.getFullYear(), mm:now.getMonth()+1};
    this.nomeMese=this.nomidelmese[mmStart];

   if (this.mese!=undefined){
       this.mese.refresh();
    }
    this.retrieveData();

  }

  ngAfterInit(): void {

  }

  retrieveData(): void {
  //  this.commonService.getAll().subscribe((res) => this.items = res);
  }


  previousMonth(){
    let mesedacontrollare=1;
    if (this.projectPeriod.yyyyStart===this.selectedPeriod.yyyy){
       mesedacontrollare=this.projectPeriod.mmStart;
    }

    if (this.selectedPeriod.mm>mesedacontrollare){
      this.selectedPeriod.mm--;

    }else if ((this.selectedPeriod.mm==mesedacontrollare) && (this.projectPeriod.yyyyStart<this.selectedPeriod.yyyy)){
      this.selectedPeriod.yyyy=this.selectedPeriod.yyyy-1;
      this.selectedPeriod.mm=12;
    }

    this.mese.refresh();
    this.nomeMese=this.nomidelmese[this.selectedPeriod.mm-1];
   }
 nextMonth(){
   let mesedacontrollare=12;
   if (this.projectPeriod.yyyyEnd===this.selectedPeriod.yyyy){
      mesedacontrollare=this.projectPeriod.mmEnd;
   }

   if (this.selectedPeriod.mm<mesedacontrollare){
     this.selectedPeriod.mm++;

   }else if ((this.selectedPeriod.mm==mesedacontrollare) && (this.projectPeriod.yyyyEnd>this.selectedPeriod.yyyy)){
     this.selectedPeriod.yyyy=this.selectedPeriod.yyyy+1;
     this.selectedPeriod.mm=1;
   }
   this.mese.refresh();
   this.nomeMese=this.nomidelmese[this.selectedPeriod.mm-1];
}

/*
  openDetails(id: number): void {
    this.commonService.get(id)
      .subscribe((itemDetails) => {
        const dialogRef = this.dialog.open(DialogSampleComponent, {
          width: '250px',
          data: itemDetails
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      }, (error => this.notify.openSnackBar(error.message)));
  }
*/
}
