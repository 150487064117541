import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContentComponent } from './content/content.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthGaurdService } from './service/auth-gaurd.service';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';

//import { CalendarioComponent } from './content/calendario/calendario.component';
//import { CalendarComponent } from './content/calendar/calendar.component';
//import { ProfilebookComponent } from './content/profilebook/profilebook.component';
//import { LezioniComponent } from './content/lezioni/lezioni.component';
//import { ImageuploadComponent } from './common/imageupload/imageupload.component';

import { ModuliComponent } from './content/moduli/moduli.component';
import { NewfolderComponent } from './content/newfolder/newfolder.component';
import { NewattachmentComponent } from './content/newattachment/newattachment.component';
import { MaterialeComponent } from './content/materiale/materiale.component';
import { UsersComponent } from './content/users/users.component';
import { AziendeComponent } from './enterprise/aziende/aziende.component';
import { AddsedeComponent } from './enterprise/addsede/addsede.component';
import { AddstrutturaComponent } from './enterprise/addstruttura/addstruttura.component';
import { SediComponent } from './enterprise/sedi/sedi.component';
import { StruttureComponent } from './enterprise/strutture/strutture.component';
import { PersoneComponent } from './enterprise/persone/persone.component';
import { GruppiComponent } from './enterprise/gruppi/gruppi.component';
import { AddpersonaComponent } from './enterprise/addpersona/addpersona.component';
import { AddgruppoComponent } from './enterprise/addgruppo/addgruppo.component';
import { AddtogruppiComponent } from './enterprise/addtogruppi/addtogruppi.component';
import { AdduserComponent } from './content/adduser/adduser.component';
import { AddaziendaComponent } from './enterprise/addazienda/addazienda.component';
import { DocumentiComponent } from './enterprise/documenti/documenti.component';
import { JumpdocumentiComponent } from './enterprise/jumpdocumenti/jumpdocumenti.component';
import { NewdocumentoComponent } from './enterprise/newdocumento/newdocumento.component';
import { AddtolezioniComponent } from './content/addtolezioni/addtolezioni.component';
import { ModulesComponent } from './content/modules/modules.component';
import { MastersComponent } from './content/masters/masters.component';
import { AddmoduleComponent } from './content/addmodule/addmodule.component';
import { AddmasterComponent } from './content/addmaster/addmaster.component';
import { LessonsComponent } from './content/lessons/lessons.component';
import { AddlessonComponent } from './content/addlesson/addlesson.component';
import { CoursesComponent } from './content/courses/courses.component';
import { CourseshomeComponent } from './content/courseshome/courseshome.component';
import { NavigazioneComponent } from './content/materiale/navigazione/navigazione.component';
import { OrdinianturComponent } from './content/ordiniantur/ordiniantur.component';
//import { MasterplanComponent } from './content/masterplan/masterplan.component';
//import { BachecaComponent } from './content/bacheca/bacheca.component';
//import { PostsComponent } from './content/posts/posts.component';
//import { AddpostComponent } from './content/addpost/addpost.component';
//import { CroGuestComponent } from './content/cro/cro-guest/cro-guest.component';
//import { CroLoggedComponent } from './content/cro/cro-logged/cro-logged.component';
import { LulComponent } from './antur/lul/lul.component';
import { AnchorComponent } from './antur/anchor/anchor.component';
import { AddanchorComponent } from './antur/addanchor/addanchor.component';
import { TemplateComponent } from './antur/template/template.component';
import { AddlulComponent } from './antur/addlul/addlul.component';
import { AddtemplateComponent } from './antur/addtemplate/addtemplate.component';
import { LulemployeeComponent } from './antur/lulemployee/lulemployee.component';
import { VideoappComponent } from './content/videoapp/videoapp.component';
import { NotespeseComponent } from './enterprise/notespese/notespese.component';
import { RicevuteComponent } from './enterprise/ricevute/ricevute.component';
import { PermessiComponent } from './enterprise/permessi/permessi.component';
import { TipipermessoComponent } from './enterprise/tipipermesso/tipipermesso.component';
import { AddtipopermessoComponent } from './enterprise/addtipopermesso/addtipopermesso.component';
import { CourseviewerComponent } from './content/courseviewer/courseviewer.component';

import { ProgettiComponent } from './project/progetti/progetti.component';
import { AttivitaComponent } from './project/attivita/attivita.component';
import { AddtoprogettiComponent } from './project/addtoprogetti/addtoprogetti.component';
import { AddprogettoComponent } from './project/addprogetto/addprogetto.component';
import { AddattivitaComponent } from './project/addattivita/addattivita.component';

//import { GanttComponent } from './project/gantt/gantt.component';

import { HeaderComponent } from './homepages/header/header.component';
import { GuestComponent } from './homepages/guest/guest.component';
import { FbserviceComponent } from './homepages/fbservice/fbservice.component';
import { WhiteboardComponent } from './homepages/whiteboard/whiteboard.component';
import { DthinkComponent } from './homepages/dthink/dthink.component';
import { CronoprogrammaComponent } from './project/conoprogramma/cronoprogramma.component';

import { MenuitemsComponent } from './admin/menuitems/menuitems.component';
import { AddmenuitemComponent } from './admin/addmenuitem/addmenuitem.component';
import { RolesComponent } from './admin/roles/roles.component';
import { AddroleComponent } from './admin/addrole/addrole.component';

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'menuitems', component: MenuitemsComponent, canActivate:[AuthGaurdService]},
  { path: 'addmenuitem', component: AddmenuitemComponent, canActivate:[AuthGaurdService]},
  { path: 'roles', component: RolesComponent, canActivate:[AuthGaurdService]},
  { path: 'addrole', component: AddroleComponent, canActivate:[AuthGaurdService]},
//  { path: 'cro', component: CroGuestComponent},
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent,canActivate:[AuthGaurdService] },
  { path: 'fbservice', component: FbserviceComponent },
  { path: 'whiteboard', component: WhiteboardComponent},
  { path: 'dthink', component: DthinkComponent },
  { path: 'courses', component: CoursesComponent ,canActivate:[AuthGaurdService] },
  { path: 'courseshome', component: CourseshomeComponent ,canActivate:[AuthGaurdService] },
  { path: 'courseviewer', component: CourseviewerComponent ,canActivate:[AuthGaurdService] },
  { path: 'content', component: ContentComponent,canActivate:[AuthGaurdService] },
//  { path: 'calendario', component: CalendarioComponent,canActivate:[AuthGaurdService] },
//  { path: 'profilebook', component: ProfilebookComponent,canActivate:[AuthGaurdService] },
  { path: 'moduli', component: ModuliComponent,canActivate:[AuthGaurdService] },
//  { path: 'lezioni', component: LezioniComponent,canActivate:[AuthGaurdService] },
  { path: 'newfolder', component: NewfolderComponent,canActivate:[AuthGaurdService] },
  { path: 'newattachment', component: NewattachmentComponent,canActivate:[AuthGaurdService] },
//  { path: 'imageupload', component: ImageuploadComponent,canActivate:[AuthGaurdService] },

  { path: 'materiale', component: MaterialeComponent,canActivate:[AuthGaurdService] },
  { path: 'users', component: UsersComponent,canActivate:[AuthGaurdService] },
  { path: 'aziende', component: AziendeComponent,canActivate:[AuthGaurdService] },
  { path: 'sedi', component: SediComponent,canActivate:[AuthGaurdService] },
  { path: 'strutture', component: StruttureComponent,canActivate:[AuthGaurdService] },
  { path: 'addazienda', component: AddaziendaComponent,canActivate:[AuthGaurdService] },
  { path: 'addsede', component: AddsedeComponent,canActivate:[AuthGaurdService] },
  { path: 'addstruttura', component: AddstrutturaComponent,canActivate:[AuthGaurdService] },
  { path: 'gruppi', component: GruppiComponent,canActivate:[AuthGaurdService] },
  { path: 'addpersona', component: AddpersonaComponent,canActivate:[AuthGaurdService] },
  { path: 'addgruppo', component: AddgruppoComponent,canActivate:[AuthGaurdService] },
  { path: 'addtogruppi', component: AddtogruppiComponent,canActivate:[AuthGaurdService] },
  { path: 'adduser', component: AdduserComponent,canActivate:[AuthGaurdService] },
  { path: 'addtolezioni', component: AddtolezioniComponent,canActivate:[AuthGaurdService] },
  { path: 'modules', component: ModulesComponent,canActivate:[AuthGaurdService] },
  { path: 'masters', component: MastersComponent,canActivate:[AuthGaurdService] },
  { path: 'addmodule', component: AddmoduleComponent,canActivate:[AuthGaurdService] },
  { path: 'addmaster', component: AddmasterComponent,canActivate:[AuthGaurdService] },
  { path: 'lessons', component: LessonsComponent,canActivate:[AuthGaurdService] },
  { path: 'addlesson', component: AddlessonComponent,canActivate:[AuthGaurdService] },
  { path: 'ordiniantur', component: OrdinianturComponent,canActivate:[AuthGaurdService] },
//  { path: 'masterplan', component: MasterplanComponent,canActivate:[AuthGaurdService] },
  { path: 'lul', component: LulComponent,canActivate:[AuthGaurdService] },
  { path: 'anchors', component: AnchorComponent,canActivate:[AuthGaurdService] },
  { path: 'addanchor', component: AddanchorComponent,canActivate:[AuthGaurdService] },
  { path: 'template', component: TemplateComponent,canActivate:[AuthGaurdService] },
  { path: 'addlul', component: AddlulComponent,canActivate:[AuthGaurdService] },
  { path: 'addtemplate', component: AddtemplateComponent,canActivate:[AuthGaurdService] },
  { path: 'lulemployee', component: LulemployeeComponent,canActivate:[AuthGaurdService] },
  { path: 'videoapp', component: VideoappComponent ,canActivate:[AuthGaurdService] },
  { path: 'documenti', component: DocumentiComponent ,canActivate:[AuthGaurdService] },
  { path: 'jumpdocumenti', component: JumpdocumentiComponent ,canActivate:[AuthGaurdService] },
  { path: 'newdocumento', component: NewdocumentoComponent ,canActivate:[AuthGaurdService] },
  { path: 'notespese', component: NotespeseComponent ,canActivate:[AuthGaurdService] },
  { path: 'ricevute', component: RicevuteComponent ,canActivate:[AuthGaurdService] },
  { path: 'permessi', component: PermessiComponent ,canActivate:[AuthGaurdService] },
  { path: 'persone', component: PersoneComponent ,canActivate:[AuthGaurdService] },
  { path: 'tipipermesso', component: TipipermessoComponent ,canActivate:[AuthGaurdService] },
  { path: 'addtipopermesso', component: AddtipopermessoComponent ,canActivate:[AuthGaurdService] },

  { path: 'progetti', component: ProgettiComponent ,canActivate:[AuthGaurdService] },
  { path: 'attivita', component: AttivitaComponent ,canActivate:[AuthGaurdService] },
  { path: 'addtoprogetti', component: AddtoprogettiComponent ,canActivate:[AuthGaurdService] },
  { path: 'addprogetto', component: AddprogettoComponent ,canActivate:[AuthGaurdService] },
  { path: 'addattivita', component: AddattivitaComponent ,canActivate:[AuthGaurdService] },
  { path: 'cronoprogramma', component: CronoprogrammaComponent ,canActivate:[AuthGaurdService] }

//  { path: 'gantt', component: GanttComponent ,canActivate:[AuthGaurdService] }

//  { path: 'bacheca', component: BachecaComponent,canActivate:[AuthGaurdService] },
//  { path: 'posts', component: PostsComponent,canActivate:[AuthGaurdService] },
//  { path: 'addpost', component: AddpostComponent,canActivate:[AuthGaurdService] },
//  { path: 'crologged', component: CroLoggedComponent,canActivate:[AuthGaurdService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
