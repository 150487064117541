<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  
  <mat-radio-group class="radio-group" [(ngModel)]="selectedMaterial">
    <mat-radio-button  *ngFor="let materiale of materialeList" [value]="materiale">{{materiale.Name}}</mat-radio-button>
  </mat-radio-group>
<!--
      <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let materiale of materialeList" >
            <table>
              <tr>
                <td style="padding-right: 10px">{{materiale.Name}}</td>
              </tr>
            </table>
          </mat-list-item>
      </mat-list>
      -->
</div>
<div mat-dialog-actions>
  <button mat-raised-button  color="primary" [mat-dialog-close]='data.lul' (click)="addMaterialeToLul()">Salva</button>
  <button mat-raised-button  color="primary" [mat-dialog-close]='data.lul' (click)="removeMaterialeFromLul()">Dissocia</button>
  <button mat-raised-button  color="primary" mat-dialog-close >Cancella</button>
</div>
