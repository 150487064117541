  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista permessi</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->

          <mat-form-field appearance="fill">
            <mat-label class="text-sm">Azienda</mat-label>
            <mat-select [(ngModel)]="selectedValueAzienda" name="azienda" (selectionChange)="manageChangeAzienda()" class="text-sm">
              <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
                {{azienda.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">Stato</mat-label>
            <mat-select [(ngModel)]="selectedValueStatopermesso" name="statons" (selectionChange)="manageChangeStatopermesso()" class="text-sm">
              <mat-option *ngFor="let statopermesso of statipermesso" [value]="statopermesso.Nome">
                {{statopermesso.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="Nomepersona">
        <th mat-header-cell *matHeaderCellDef> Nome
          <img src="./assets/images/sort.png" (click)="manageClick('Nomepersona')"/>
        </th>
        <td mat-cell *matCellDef="let permesso"> {{permesso.Nomepersona}} </td>
    </ng-container>

    <ng-container matColumnDef="Cognomepersona">
        <th mat-header-cell *matHeaderCellDef> Cognome
          <img src="./assets/images/sort.png" (click)="manageClick('Cognomepersona')"/>
        </th>
        <td mat-cell *matCellDef="let permesso"> {{permesso.Cognomepersona}} </td>
    </ng-container>

      <ng-container matColumnDef="Commento">
          <th mat-header-cell *matHeaderCellDef> Commento
            <img src="./assets/images/sort.png" (click)="manageClick('Commento')"/>
          </th>
          <td mat-cell *matCellDef="let permesso"> {{permesso.Commento}} </td>
      </ng-container>

      <ng-container matColumnDef="Notaazienda">
          <th mat-header-cell *matHeaderCellDef> Nota azienda
            <img src="./assets/images/sort.png" (click)="manageClick('Notaazienda')"/>
          </th>
          <td mat-cell *matCellDef="let permesso"> {{permesso.Notaazienda}} </td>
      </ng-container>

      <ng-container matColumnDef="Datainizio">
          <th mat-header-cell *matHeaderCellDef> Data inizio
            <img src="./assets/images/sort.png" (click)="manageClick('Datainizio')"/>
          </th>
          <td mat-cell *matCellDef="let permesso"> {{permesso.Datainizio | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="Datafine">
          <th mat-header-cell *matHeaderCellDef> Data fine
            <img src="./assets/images/sort.png" (click)="manageClick('Datafine')"/>
          </th>
          <td mat-cell *matCellDef="let permesso"> {{permesso.Datafine | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="Orainizio">
        <th mat-header-cell *matHeaderCellDef> Ora inizio
          <img src="./assets/images/sort.png" (click)="manageClick('Orainizio')"/>
        </th>
        <td mat-cell *matCellDef="let permesso">{{permesso.Orainizio}}</td>
      </ng-container>

      <ng-container matColumnDef="Orafine">
        <th mat-header-cell *matHeaderCellDef> Ora fine
          <img src="./assets/images/sort.png" (click)="manageClick('Orafine')"/>
        </th>
        <td mat-cell *matCellDef="let permesso">{{permesso.Orafine}}</td>
      </ng-container>

      <ng-container matColumnDef="Commenta">
        <th mat-header-cell *matHeaderCellDef> Commenta </th>
        <td mat-cell *matCellDef="let permesso">
          <mat-icon  color="primary" (click)="commentaPermesso(permesso)">comment</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="Stato">
        <th mat-header-cell *matHeaderCellDef> Stato
          <img src="./assets/images/sort.png" (click)="manageClick('Stato')"/>
        </th>
        <td mat-cell *matCellDef="let permesso">{{permesso.Nomestatopermesso}}</td>
      </ng-container>

      <ng-container matColumnDef="Approva">
        <th mat-header-cell *matHeaderCellDef> Approva </th>
        <td mat-cell *matCellDef="let permesso">
          <mat-icon color="primary" (click)="approvaPermesso(permesso, 'YES')" *ngIf="permesso.Nomestatopermesso==='Ricevuto'">check</mat-icon>
       </td>
      </ng-container>

      <ng-container matColumnDef="Rigetta">
        <th mat-header-cell *matHeaderCellDef> Rigetta </th>
        <td mat-cell *matCellDef="let permesso">
          <mat-icon color="primary" (click)="approvaPermesso(permesso, 'NO')" *ngIf="permesso.Nomestatopermesso==='Ricevuto'">block</mat-icon>
       </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>

<!-- </form> -->
</mat-card-content>
<mat-card-actions>
       <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
</mat-card-actions>

</mat-card>
