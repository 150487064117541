
  <div class="container" fxLayout="row" fxLayoutAlign="center">
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center">

      <div fxLayout="row" fxFlex="100" fxLayoutAlign="center">
                <button mat-mini-fab (click)="previousMonth()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <div class="example-spacer"></div>
                <div style="font-size: 16px;"> {{nomeMese}} - {{selectedPeriod.yyyy}} </div>

                <div class="example-spacer"></div>
                <button mat-mini-fab (click)="nextMonth()">
                    <mat-icon>chevron_right</mat-icon>
                </button>
        </div>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center">
          <mese #mese [valore]="selectedPeriod"></mese>
        </div>




    </div>
  </div>
