  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista aziende</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->

<!--
           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Ruolo</mat-label>
             <mat-select [(ngModel)]="selectedValueRuolo" name="ruolo" (selectionChange)="manageChangeRuolo()" class="text-sm">
               <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.Nome">
                 {{ruolo.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>
-->

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Partitaiva">
          <th mat-header-cell *matHeaderCellDef> Partita IVA
            <img src="./assets/images/sort.png" (click)="manageClick('Partitaiva')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Partitaiva}} </td>
      </ng-container>

      <ng-container matColumnDef="Missione">
          <th mat-header-cell *matHeaderCellDef> Missione
            <img src="./assets/images/sort.png" (click)="manageClick('Missione')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Missione}} </td>
      </ng-container>

      <ng-container matColumnDef="Codicefiscale">
          <th mat-header-cell *matHeaderCellDef> Codice fiscale
            <img src="./assets/images/sort.png" (click)="manageClick('Codicefiscale')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Codicefiscale}} </td>
      </ng-container>

      <ng-container matColumnDef="Ragionesociale">
          <th mat-header-cell *matHeaderCellDef> Ragione sociale
            <img src="./assets/images/sort.png" (click)="manageClick('Ragionesociale')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Ragionesociale}} </td>
      </ng-container>

      <ng-container matColumnDef="Attivita">
          <th mat-header-cell *matHeaderCellDef> Attività
            <img src="./assets/images/sort.png" (click)="manageClick('Attivita')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Attivita}} </td>
      </ng-container>

      <ng-container matColumnDef="Numerodipendenti">
          <th mat-header-cell *matHeaderCellDef> Numero dipendenti
            <img src="./assets/images/sort.png" (click)="manageClick('Numerodipendenti')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Numerodipendenti}} </td>
      </ng-container>

      <ng-container matColumnDef="Rea">
          <th mat-header-cell *matHeaderCellDef> Rea
            <img src="./assets/images/sort.png" (click)="manageClick('Rea')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Rea}} </td>
      </ng-container>

      <ng-container matColumnDef="Datarea">
          <th mat-header-cell *matHeaderCellDef> Data Rea
            <img src="./assets/images/sort.png" (click)="manageClick('Datarea')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Datarea | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="Cameracommercio">
          <th mat-header-cell *matHeaderCellDef> Camera commercio
            <img src="./assets/images/sort.png" (click)="manageClick('Cameracommercio')"/>
          </th>
          <td mat-cell *matCellDef="let azienda"> {{azienda.Cameracommercio}} </td>
      </ng-container>

      <ng-container matColumnDef="Isactive">
        <th mat-header-cell *matHeaderCellDef> Attivo
          <img src="./assets/images/sort.png" (click)="manageClick('Isactive')"/>
        </th>
        <td mat-cell *matCellDef="let azienda">
          <mat-icon *ngIf="azienda.Isactive==true"  color="primary" (click)="toggleEnable(azienda)">check_circle</mat-icon>
          <mat-icon *ngIf="azienda.Isactive==false"  color="primary" (click)="toggleEnable(azienda)">check_circle_outline</mat-icon>
       </td>
      </ng-container>

      <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Modifica </th>
          <td mat-cell *matCellDef="let azienda">
            <mat-icon color="primary" (click)="editAzienda(azienda)">create</mat-icon>
          </td>
      </ng-container>
<!--
      <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Elimina </th>
          <td mat-cell *matCellDef="let user"><button class="btn btn-danger" (click)="deleteUser(user)">Elimina</button> </td>
      </ng-container>
-->


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


<!-- </form> -->
</mat-card-content>
<mat-card-actions>
  <table>
    <tr>
      <td>
        <button mat-raised-button  color="primary" (click)="newAzienda()">Nuova azienda</button>
      </td>
      <td>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </td>
    </tr>
  </table>
</mat-card-actions>

</mat-card>
