import {Lezione} from '../models/lezione';
import {User} from '../models/user';

export class Docente {
  lezioneid: number;
  Username: String;
  Titolare: String;
  Assistente: String;
  Compenso: number;
  Regalo: String;
  Nome: String;
  Cognome: String;
  //user: User;
  //lezione: Lezione;
}
