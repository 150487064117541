<div class="master-container">
  <div class="titolo"> Corsi in evidenza </div>
  <div class="container">
    <div class="example-card" *ngFor = "let master of masters">
        <div>
          <p style="font-weight:bold; font-size:18px">{{master.Nome}}</p>
        </div>
        <div styles="text-align: center;">
          <img class="courseimage" src="{{master.Picbyte}}">
        </div>
        <div>
            <p style="font-size:14px">{{master.Descrizione}}</p>
        </div>
        <div class="actions">
           <a matLine href="https://www.antur-academy.com/formazione/" target="_blank">Acquista</a>
        </div>
    </div>
  </div>
  <div class="titolo"> I miei corsi </div>
  <div class="container">
    <div class="example-card" *ngFor = "let orderedmaster of orderedmasters">
        <div>
          <p style="font-weight:bold; font-size:18px">{{orderedmaster.Nome}}</p>
        </div>
        <img class="courseimage" src="{{orderedmaster.Picbyte}}">
        <div>
            <p style="font-size:14px">{{orderedmaster.Descrizione}}</p>
        </div>
        <div class="actions">
          <button mat-raised-button color="primary" (click)="visualizza(orderedmaster)">Visualizza</button>
        </div>
    </div>
  </div>
</div>
