<mat-card>
    <mat-card-header>
        <mat-card-title>Allega documento</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
          <table class="example-full-width" cellspacing="0">
            <tr>
              <td>
                <mat-form-field appearance="fill" style="padding-right: 10px">
                  <mat-label class="text-sm">Azienda</mat-label>
                  <mat-select [(ngModel)]="selectedValueAzienda" name="azienda" class="text-sm"  [disabled]="true">
                    <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
                      {{azienda.Nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field *ngIf="isSelectedPersona()" appearance="fill">
                  <mat-label class="text-sm">Persona</mat-label>
                  <mat-select [(ngModel)]="selectedValuePersona" name="persona" class="text-sm">
                    <mat-option *ngFor="let persona of persone" [value]="persona.Cognome">
                      {{persona.Cognome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field *ngIf="isSelectedGruppo()" appearance="fill">
                  <mat-label class="text-sm">Gruppo</mat-label>
                  <mat-select [(ngModel)]="selectedValueGruppo" name="gruppo" class="text-sm">
                    <mat-option *ngFor="let gruppo of gruppi" [value]="gruppo.Nome">
                      {{gruppo.Nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field appearance="fill">
                  <mat-label class="text-sm">Categoria</mat-label>
                  <mat-select [(ngModel)]="selectedValueCategoria" name="categoria" (selectionChange)="manageChangeCategoria()" class="text-sm">
                    <mat-option *ngFor="let categoria of categorie" [value]="categoria.Nome">
                      {{categoria.Nome}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>

            </tr>
            <tr>
              <td>
                <mat-form-field class="example-full-width">
                  <mat-label class="text-sm">Nome file</mat-label>
                  <input matInput placeholder="Nome file" [(ngModel)]="filename" name="filename">
                  <input type="file" (change)="onFileChanged($event)">
                  <p *ngIf="error" class="error">
                    {{ error }}
                </p>
                </mat-form-field>
              </td>
            </tr>
              <tr>
                <td>
                  <mat-form-field class="example-full-width">
                    <mat-label class="text-sm">Descrizione</mat-label>
                    <input matInput placeholder="Descrizione" [(ngModel)]="Descrizione" name="Descrizione">
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-form-field class="example-full-width">
                    <mat-label class="text-sm">Nota</mat-label>
                    <input matInput placeholder="Nota" [(ngModel)]="Nota" name="Nota">
                  </mat-form-field>
                </td>
              </tr>
            </table>
        </form>
    </mat-card-content>
    <mat-card-actions>
      <table>
        <tr>
          <td>
            <button mat-raised-button (click)="salva()" color="primary" [disabled]="!isEnabledToLoad() || this.selectedValueCategoria===undefined || this.selectedValueCategoria===''">Salva</button>
          </td>
          <td>
            <button mat-raised-button (click)="cancella()" color="primary">Cancella</button>
          </td>
      </table>
    </mat-card-actions>
</mat-card>
