import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MenuItem } from '../../models/menuitem';
import { Istanza } from '../../models/istanza';
import { Ruolo } from '../../models/ruolo';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-menuitems',
  templateUrl: './menuitems.component.html',
  styleUrls: ['./menuitems.component.css']
})
export class MenuitemsComponent implements OnInit {


  IPAddress:string;
  Port:string;
  Protocol: string;
  selectedValueIstanza:string;
  selectedValueRuolo:string;
  menuitems: MenuItem[]=[];
  istanze: Istanza[];
  ruoli: Ruolo[];
  currentIstanza:Istanza;
  currentRuolo:Ruolo;
  displayedColumns: string[] =
  ["Nome",
  "Contesto",
  "Ordine",
  "Sezione",
  "Ruolo",
  "Modifica"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  spinnervalue = 100;
//  analizing = false;
  busy = false;

  dataSource = new MatTableDataSource<MenuItem>(this.menuitems);
  constructor(private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog) {}

  ngOnInit() {

    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Contesto",true);
    this.sortedDirection.set("Ordine",true);
    this.sortedDirection.set("Sezione",true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    //  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

    this.commonService.getAdminIstanze().subscribe((res) => {
      this.istanze = res;
          this.aggiornaLista();
    });
  }

  getNomeByCodiceaistanza(codiceistanza: number):String{
    let nomeistanza:String="";
    this.menuitems.forEach(element => {
      if (element.Codice === codiceistanza){
        nomeistanza=element.Nome;
        return;
      }
    });
    return nomeistanza;
  }

  manageChangeIstanza(){
    if (this.selectedValueIstanza==undefined){
     return;
    }
    this.istanze.forEach(element => {
      if (this.selectedValueIstanza === element.Nome){
        this.currentIstanza=element;

        this.commonService.getRuoliByIstanza(this.currentIstanza.Codice).subscribe((res2) => {
            this.ruoli = res2;
            this.commonService.getAdminMenuitems(this.currentIstanza.Codice).subscribe((res3) => {
              this.menuitems = res3;
              this.aggiornaLista();
            });
        });
      }
    });
  }

  manageChangeRuolo(){
    if (this.selectedValueRuolo==undefined){
     return;
    }

    this.commonService.getRuoliByIstanza(this.currentIstanza.Codice).subscribe((res2) => {
        this.ruoli = res2;
        this.commonService.getAdminMenuitems(this.currentIstanza.Codice).subscribe((res3) => {
          this.menuitems = res3;
          this.ruoli.forEach(element => {
            if (this.selectedValueRuolo === element.Nome){
              this.currentRuolo=element;

            }
          });
          let tempmenuitems:MenuItem[]=[];
          this.menuitems.forEach(element => {
            if (this.currentRuolo.Nome === element.Nomeruolo){
              tempmenuitems.push(element);
            }
          });
          this.menuitems=tempmenuitems;
          this.aggiornaLista();
        });
    });


  }

  cancel(){
    this.selectedValueIstanza=undefined;
    this.selectedValueRuolo=undefined;
    this.ruoli=[];
    this.menuitems=[];
    this.aggiornaLista();
  }
  aggiornaLista(){
       this.refreshLista(this.menuitems);
  }

   refreshLista(listamenuitems:MenuItem[]){
     this.menuitems=listamenuitems;
     this.dataSource = new MatTableDataSource<MenuItem>(this.menuitems);
   }
   manageClick(attributo: string){
     let tempList:MenuItem[];
     tempList=this.menuitems;
     let direction=this.sortedDirection.get(attributo);

     tempList.sort((a, b) => {
        var typeA = "";
        var typeB = "";
        if (a[attributo]!=null){
          if (isNaN(b[attributo]))
             typeA = a[attributo].toLowerCase();
          else
             typeA = a[attributo].toString();
        }
        if (b[attributo]!=null){
          if (isNaN(b[attributo]))
             typeB = b[attributo].toLowerCase();
           else
             typeB = b[attributo].toString();
        }
          if (direction){
            if (typeA > typeB){
              return -1;
            }else if (typeA < typeB){
               return 1;
            }else{
              return 0;
            }
          }else{
            if (typeA < typeB){
              return -1;
            }else if (typeA > typeB){
               return 1;
            }else{
              return 0;
            }
          }
      });
      direction=!direction;
      this.sortedDirection.set(attributo,direction);

      this.dataSource = new MatTableDataSource<MenuItem>(this.menuitems);

    }
    new():void{
      this.commonService.currentIstanza=this.currentIstanza;
      this.router.navigate(['addmenuitem']);
    }

    edit(menuitem: MenuItem){
      this.commonService.currentIstanza=this.currentIstanza;
      this.commonService.selectedMenuitem=menuitem;
      this.router.navigate(['addmenuitem']);
    }
}
