import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { Router } from '@angular/router';
import {CommonService} from "../service/common.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private router: Router) {

  }

  ngOnInit() {
    this.authenticationService.logOut();
    console.log("LOGOUT");
    console.log('Esci');
    if (this.commonService.currentIstanza.Codice === 1){
      this.router.navigate(['dthink']);
    }else if (this.commonService.currentIstanza.Codice === 2){
      this.router.navigate(['dthink']);
    }else if (this.commonService.currentIstanza.Codice === 3){
      this.router.navigate(['dthink']);
    }else if (this.commonService.currentIstanza.Codice === 4){
      this.router.navigate(['fbservice']);
    }else{
      this.router.navigate(['dthink']);
    }
  }

}
