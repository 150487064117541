import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Lul } from '../../models/lul';
import { Template } from '../../models/template';
import { Materiale } from '../../models/materiale';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  selectedValueTemplate:string;
  //luls: Lul[];
  templates: Template[]=[];
  currentTemplate:Template;
  displayedColumns: string[] =
  ["Nome",
  "Descrizione",
//  "Codice",
  "Soglia1",
  "Orainiziof1",
  "Orafinef1",
  "Soglia2",
  "Orainiziof2",
  "Orafinef2",
  "Modifica",
  "Duplica"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();
  dataSource = new MatTableDataSource<Template>(this.templates);
  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService, private dialog: MatDialog) {}

  ngOnInit() {

    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
/*
    this.commonService.getAllTemplates().subscribe((res) => {
      this.templates = res;
    });
*/
    this.aggiornaLista();

  }

  getNomeByCodicetemplate(codicetemplate: number):String{
    let nometemplate:String="";
    this.templates.forEach(element => {
      if (element.Codice === codicetemplate){
        nometemplate=element.Nome;

      }
    });
    return nometemplate;
  }
/*
  manageChangeTemplate(){
    if (this.selectedValueTemplate==undefined){
     return;
    }
    this.templates.forEach(element => {
      if (this.selectedValueTemplate === element.Nome){
        this.currentTemplate=element;
      }
    });
    this.aggiornaLista();
  }



*/

  /*
  toggleEnable(user: User){
    //selection.toggle(row);
    const userData = new FormData();
    userData.append('userid', user.Userid);
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/users/toggle", userData, { observe: 'response' })
    .subscribe((response) => {
        this.aggiornaLista();
    });
  }
*/

  aggiornaLista(){
    let nometemplate:String="";
    if (this.selectedValueTemplate!=undefined){
     nometemplate=this.selectedValueTemplate;
    }
    this.commonService
      .getAllTemplates()
      .subscribe(response => {

        let temptemplates:Template[]=response;
        let templistatemplates:Template[]=[];
  /*
        templuls.forEach(element => {
          const lul:Lul=element;
          lul.Nometemplate=this.getNomeByCodicetemplate(element.Codicetemplate);
          //console.log("Nome ruolo="+user.Nomeruolo);
          if (nometemplate==="" || nometemplate===lul.Nometemplate){
            templistaluls.push(lul);
          }
        });

        */
          templistatemplates=temptemplates;  //non serve a niente
        this.refreshLista(templistatemplates); //serve solo a mantenere la struttura


      });
   }
   refreshLista(listatemplates:Template[]){
     this.templates=listatemplates;
     this.dataSource = new MatTableDataSource<Template>(this.templates);
   }

/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/

  newTemplate(){
    this.router.navigate(['addtemplate']);
  }
  editTemplate(template: Template): void {
      this.commonService.selectedTemplate=template;
      this.router.navigate(['addtemplate']);
  }
  copyTemplate(template: Template): void {
    this.commonService.copyTemplate(template.Codice).subscribe(data => {
      let rc:string=JSON.stringify(data)
      if (rc==="0"){
        this.selectedValueTemplate="";
        this.aggiornaLista();
      }else{
        alert("Errore copia template");
      }
    });
  }
  cancel(){
    this.selectedValueTemplate="";
    this.aggiornaLista();
  }

  manageClick(attributo: string){
    let tempList:Template[];
    tempList=this.templates;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Template>(this.templates);

   }
}
