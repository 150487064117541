import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Lul } from '../../models/lul';
import { Caratteripage } from '../../models/caratteripage';
import { Carattere } from '../../models/carattere';
import { Template } from '../../models/template';
import { Materiale } from '../../models/materiale';
import { MatDialog } from '@angular/material/dialog';
import { AddMaterialeToLulComponent } from '../../dialog/addMaterialeToLul/addMaterialeToLul.component';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { ErrorStateMatcher} from '@angular/material/core';
import { EndanalisilulComponent } from '../../dialog/endanalisilul/endanalisilul.component';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-lul',
  templateUrl: './lul.component.html',
  styleUrls: ['./lul.component.css']
})
export class LulComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  selectedValueTemplate:string;
  luls: Lul[]=[];
  templates: Template[];
  currentTemplate:Template;
  analyzing: boolean=false;
  displayedColumns: string[] =
  ["Nome",
  "Descrizione",
  "Nomeazienda",
  "Paginainizio",
  "Paginafine",
//  "Codice",
  "Nometemplate",
  "Materialename",
  "Materialefilename",
  "AddMateriale",
  "Analizza",
  "Modifica",
  "Indaga",
  "Elimina"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();
  spinnervalue = 100;
  analizing = false;
  direction=false;
  numberFormControl = new FormControl('', [Validators.pattern('[0-9]*')]); //Validators.required, Validators.number
  matcher = new MyErrorStateMatcher();
  dataSource = new MatTableDataSource<Lul>(this.luls);
  constructor(private httpClient: HttpClient, private router: Router,
    private commonService: CommonService, private dialog: MatDialog) {}

  ngOnInit() {

    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);
    this.sortedDirection.set("Nomeazienda",true);
    this.sortedDirection.set("Nometemplate",true);
    this.sortedDirection.set("Materialename",true);
    this.sortedDirection.set("Materialefilename",true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    //  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

    this.commonService.getAllTemplates().subscribe((res) => {
      this.templates = res;
      this.aggiornaLista();
    });
  }

  getNomeByCodicetemplate(codicetemplate: number):String{
    let nometemplate:String="";
    this.templates.forEach(element => {
      if (element.Codice === codicetemplate){
        nometemplate=element.Nome;

      }
    });
    return nometemplate;
  }

  manageChangeTemplate(){
    if (this.selectedValueTemplate==undefined){
     return;
    }
    this.templates.forEach(element => {
      if (this.selectedValueTemplate === element.Nome){
        this.currentTemplate=element;
      }
    });
    this.aggiornaLista();
  }

  cancel(){
    this.selectedValueTemplate="";
    this.aggiornaLista();
  }


  /*
  toggleEnable(user: User){
    //selection.toggle(row);
    const userData = new FormData();
    userData.append('userid', user.Userid);
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/users/toggle", userData, { observe: 'response' })
    .subscribe((response) => {
        this.aggiornaLista();
    });
  }
*/

  aggiornaLista(){
    let nometemplate:String="";
    if (this.selectedValueTemplate!=undefined){
     nometemplate=this.selectedValueTemplate;
    }
    this.commonService
      .getLuls()
      .subscribe(response => {
        let templuls:Lul[]=response;
        let templistaluls:Lul[]=[];
        templuls.forEach(element => {
          const lul:Lul=element;
          lul.Nometemplate=this.getNomeByCodicetemplate(element.Codicetemplate);
          //console.log("Nome ruolo="+user.Nomeruolo);
          if (nometemplate==="" || nometemplate===lul.Nometemplate){
            templistaluls.push(lul);
          }
        });
        this.refreshLista(templistaluls);
      });
   }
   refreshLista(listaluls:Lul[]){
     this.luls=listaluls;
     this.dataSource = new MatTableDataSource<Lul>(listaluls);
   }

/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/
  applyFilter(event: Event) {
    const element = event.currentTarget as HTMLInputElement
    let filterValue = element.value
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  addMateriale(lul: Lul){
      const materialeList:Materiale[]=[];
      this.commonService.getAllMateriali(1).subscribe((res) => {
          var tempmateriali = res;
          tempmateriali.sort((a, b) => {
             var typeA = a['Name'].toLowerCase();;
             var typeB = b['Name'].toLowerCase();

               if (this.direction){
                 if (typeA > typeB){
                   return -1;
                 }else if (typeA < typeB){
                    return 1;
                 }else{
                   return 0;
                 }
               }else{
                 if (typeA < typeB){
                   return -1;
                 }else if (typeA > typeB){
                    return 1;
                 }else{
                   return 0;
                 }
               }
           });

        tempmateriali.forEach(element1 => {
            var nomeLezione = element1.Nomelezione;
              if (nomeLezione === 'Da elaborare'){
                 materialeList.push(element1);
              }
          });
          }
      );

      const dialogRef = this.dialog.open(AddMaterialeToLulComponent, {
        width: '450px',
        data: {title:'Aggiungi un materiale',materialeList:materialeList, lul: lul}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result){
          const l:Lul=result;
          console.log("Lul:"+l.Materialefilename);
          this.aggiornaLista();
        }
      });

  }

  onlyparse(lul: Lul):void{
    if (this.analizing){
      console.log("Parsing già partito");
      return;
    }
    // Forzatura del parsing a 2 pagine
    lul.Paginainizio=1;
    lul.Paginafine=3;
    //---------------------------------

    this.analizing = true;
    this.commonService.onlyparse(lul).subscribe(data => {
      let caratteripages:Caratteripage[]=data;
      console.log("Numero pagine create="+caratteripages.length);
      caratteripages.forEach(element => {
        let cp:Caratteripage=element;
        let caratteri=cp.Caratteri;
        console.log("# caratteri pagina ="+caratteri.length);
        console.log("caratteri pagina ="+caratteri);

        var testo:string='';
        for (let i = 0; i < caratteri.length; i++) {
            let carattere:Carattere=caratteri[i];
            testo=testo+carattere.UniCode;
        }
        console.log(testo);
      });

      this.analizing = false;
      /*
      const rc=Number(data);
      if (rc===0){
        const dialogRef = this.dialog.open(EndanalisilulComponent, {
          width: '250px',
          data: {title:'Informazione',description:'Parsing terminato con successo'}
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result){
          }
        });
      }else{
        alert("Errore parsing");
      }
      */
    });
  }

  analize(lul: Lul):void{
    //console.log("Pagina inizio "+lul.Paginainizio);
    //console.log("Pagina fine "+lul.Paginafine);
    //console.log("Codice template "+lul.Codicetemplate);

    if (this.analizing){
      console.log("Analisi già partita");
      return;
    }

    this.analizing = true;

    this.commonService.analize(lul).subscribe(data => {
      const rc=Number(data);
      this.analizing = false;
      if (rc===0){

        const dialogRef = this.dialog.open(EndanalisilulComponent, {
          width: '250px',
          data: {title:'Informazione',description:'Analisi terminata con successo'}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result){

          }
        });
    //    alert("Analisi terminata con successo");
      }else{
        alert("Errore analisi");
      }
    });
    /*
    this.analyzing=true;
    const data = 'some text';
    const blob = new Blob([data], { type: 'application/zip' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    return this.commonService.analize(lul);

    this.analyzing=false;
    */
  }
  newLul(){
    this.router.navigate(['addlul']);
  }
  editLul(lul: Lul): void {
      this.commonService.selectedLul=lul;
      this.router.navigate(['addlul']);
  }

  saveLul(lul: Lul):void{
  //    console.log("Pagina inizio:"+lul.Paginainizio);
      this.commonService.saveLul(lul);
  }

  paginaInizioChanged(l, e){
  //    console.log("Nuova Pagina inizio:"+e);
      l.Paginainizio=e;
  }

  paginaFineChanged(l, e){
      l.Paginafine=e;
  }

  isAnalyzing(){
    return this.analizing;
  }



eliminaLul(lul: Lul){
  const dialogRef = this.dialog.open(ConfirmComponent, {
    width: '250px',
    data: {title:'Attenzione',description:'Confermi la cancellazione?'}
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result){
      const lulData = new FormData();
      lulData.append('codice', lul.Codice.toString());
//      this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/materiale/toggle/", materialeData, { observe: 'response' })
      this.httpClient.delete(this.Protocol+"://"+this.IPAddress+":"+this.Port+'/api/luls/'+ lul.Codice).subscribe((response) => {
        if (response===0){
          this.aggiornaLista();
        }else if (response===1){
          alert("Errore per presenza dei dipendenti");
        }else if (response===-1){
          alert("Errore cancellazione lul");
        }
      });
    }
  });
 }

 manageClick(attributo: string){
   let tempList:Lul[];
   tempList=this.luls;
   let direction=this.sortedDirection.get(attributo);

   tempList.sort((a, b) => {
      var typeA = "";
      var typeB = "";
      if (a[attributo]!=null){
        if (isNaN(b[attributo]))
           typeA = a[attributo].toLowerCase();
        else
           typeA = a[attributo].toString();
      }
      if (b[attributo]!=null){
        if (isNaN(b[attributo]))
           typeB = b[attributo].toLowerCase();
         else
           typeB = b[attributo].toString();
      }
        if (direction){
          if (typeA > typeB){
            return -1;
          }else if (typeA < typeB){
             return 1;
          }else{
            return 0;
          }
        }else{
          if (typeA < typeB){
            return -1;
          }else if (typeA > typeB){
             return 1;
          }else{
            return 0;
          }
        }
    });
    direction=!direction;
    this.sortedDirection.set(attributo,direction);

    this.dataSource = new MatTableDataSource<Lul>(this.luls);

  }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
