import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Sede } from '../../models/sede';
import { Progetto } from '../../models/progetto';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-progetti',
  templateUrl: './progetti.component.html',
  styleUrls: ['./progetti.component.css']
})
export class ProgettiComponent implements OnInit {
  //IPAddress:string;
  //Port:string;
  //Protocol: string;
  progetti: Progetto[]=[];
  sedi: Sede[]=[];
  selectedValueStato:string;
  displayedColumns: string[] = [
  "Nome",
  "Descrizione",
  "Stato",
  "Sede",
  "edit"];

  currentStato={};
  stati=[
    { Nome:'Da iniziare' },
    { Nome:'In corso' } ,
    { Nome:'Completato' },
    { Nome:'Sospeso' }
  ];


  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  dataSource = new MatTableDataSource<Progetto>(this.progetti);

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService) {}

  ngOnInit() {
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Descrizione",true);
    this.sortedDirection.set("Stato",true);
    this.sortedDirection.set("Sede",true);
    /*
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
*/

    this.aggiornaLista();

  }

  cancel(){
    this.selectedValueStato=undefined;
    this.aggiornaLista();
  }

  manageChangeStato(){
    if (this.selectedValueStato===undefined){
     return;
    }
    this.progetti.forEach(element => {
      if (this.selectedValueStato === element.Nome){
        this.currentStato=element;
      }
    });
    this.aggiornaLista();
  }

  aggiornaLista(){
    this.commonService.getAllProgetti().subscribe((res) => {
        if (this.selectedValueStato===undefined || this.selectedValueStato===''){
          this.progetti = res;
          this.refreshLista();
        }else{
          this.progetti = [];
          let tempprogetti = res;
          tempprogetti.forEach(element => {
              if (this.selectedValueStato === element.Stato){
                this.progetti.push(element);
              }
          });
          this.refreshLista();
        }
   });
 }
refreshLista(){
  this.dataSource = new MatTableDataSource<Progetto>(this.progetti);
}

  newProgetto(){
    this.router.navigate(['addprogetto']);
  }
  editProgetto(progetto: Progetto): void {
      this.commonService.selectedProgetto=progetto;
      if(progetto.Sede!=undefined){
        this.commonService.selectedSede=progetto.Sede;
      }
      this.router.navigate(['addprogetto']);
  }
  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Progetto[];
    tempList=this.progetti;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Progetto>(this.progetti);

   }
}
