<mat-card>
    <mat-card-header>
        <mat-card-title>Dettaglio ancora</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <mat-error *ngIf="numberFormControl.hasError('pattern')"> <!-- && !numberFormControl.hasError('required')"> -->
        Inserisci un numero valido
      </mat-error>
      <form [formGroup]="registerForm" (ngSubmit)="save()">

        <mat-form-field appearance="fill" required>
          <mat-label class="text-sm">Template</mat-label>
          <mat-select formControlName="Nometemplate" name="template" (selectionChange)="manageChangeTemplate()" class="text-sm">
            <mat-option *ngFor="let template of templates" [value]="template.Nome">
              {{template.Nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label class="text-sm">Tipo</mat-label>
          <mat-select formControlName="Tipo" name="tipo" (selectionChange)="manageChangeTipo()" class="text-sm">
            <mat-option *ngFor="let tipo of tipi" [value]="tipo.Nome">
              {{tipo.Nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Testo</mat-label>
            <input matInput placeholder="Testo" formControlName="Testo" name="Testo">
          </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Due cifre</mat-label>
             <input matInput placeholder="Duecifre" formControlName="Duecifre" name="Duecifre">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">X1</mat-label>
             <input matInput placeholder="X1" formControlName="X1" name="X1">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Y1</mat-label>
             <input matInput placeholder="Y1" formControlName="Y1" name="Y1">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">X2</mat-label>
             <input matInput placeholder="X2" formControlName="X2" name="X2">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Y2</mat-label>
             <input matInput placeholder="Y2" formControlName="Y2" name="Y2">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Xoffset</mat-label>
               <input matInput placeholder="Xoffset" formControlName="Xoffset" name="Xoffset">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Yoffset</mat-label>
             <input matInput placeholder="Yoffset" formControlName="Yoffset" name="Yoffset">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Xdim</mat-label>
             <input matInput placeholder="Xdim" formControlName="Xdim" name="Xdim">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Ydim</mat-label>
             <input matInput placeholder="Ydim" formControlName="Ydim" name="Ydim">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">X1v</mat-label>
             <input matInput placeholder="X1v" formControlName="X1v" name="X1v">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Y1v</mat-label>
             <input matInput placeholder="Y1v" formControlName="Y1v" name="Y1v">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">X2v</mat-label>
             <input matInput placeholder="X2v" formControlName="X2v" name="X2v">
           </mat-form-field>
           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Y2v</mat-label>
             <input matInput placeholder="Y2v" formControlName="Y2v" name="Y2v">
           </mat-form-field>
          </form>
    </mat-card-content>
    <mat-card-actions>
      <table>
        <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="save()">Salva</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

</mat-card>
