export class MenuItem {
  Codice: number;
  Nome: string;
  Contesto: string;
  Sezione: string;
  Istanzaid: number;
  Codiceruolo: number;
  Nomeruolo: string;
  Ordine: number;
  Icona: string;
  constructor(){
    this.Codice=0;
    this.Nome='';
    this.Contesto='';
    this.Sezione='';
    this.Istanzaid=0;
    this.Codiceruolo=0;
    this.Nomeruolo='';
    this.Ordine=0;
    this.Icona='';
  }
}
