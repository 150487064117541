import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Template } from '../../models/template';
import { Anchor } from '../../models/anchor';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Inject, LOCALE_ID } from '@angular/core';
import { formatDate} from '@angular/common';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

@Component({
  selector: 'app-addanchor',
  templateUrl: './addanchor.component.html',
  styleUrls: ['./addanchor.component.css']
})
export class AddanchorComponent implements OnInit {
  template: Template;
  hide = true;
  rc:String;
  numberFormControl = new FormControl('', [Validators.pattern('[0-9]*')]); //Validators.required, Validators.number
  matcher = new MyErrorStateMatcher();
  registerForm: FormGroup;
  anchor: Anchor;
  currentTemplate:Template;
//  selectedValueTemplate:string='';
  templates: Template[];
//  selectedValueTipo:string='';
  tipi= [
    {
      "Codice": 1,
      "Nome": "PERIODO"
    },
    {
      "Codice": 2,
      "Nome": "DIPENDENTE"
    },
    {
      "Codice": 3,
      "Nome": "GIORNO"
    },
    {
      "Codice": 4,
      "Nome": "CODICEFISCALE"
    }
  ];
//  Orainizio: string;
//  Orafine: string;
//  Data: Date= new Date();;

  constructor(private commonService: CommonService, private router: Router, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
      //  console.log("+++++"+this.commonService.selectedLul);

      if (this.registerForm===undefined){
         this.registerForm = this.formBuilder.group({
              Codice:['0'],
              Codicetemplate:['0'],
              Nometemplate: [''],
              Tipo: [''],
              Testo: [''],
              Duecifre: [''],
              X1:['0'],
              Y1: ['0'],
              X2: ['0'],
              Y2: ['0'],
              Xoffset: ['0'],
              Yoffset: ['0'],
              Xdim: ['0'],
              Ydim: ['0'],
              X1v: ['0'],
              Y1v: ['0'],
              X2v: ['0'],
              Y2v: ['0']
          });
        }
        this.commonService.getAllTemplates().subscribe((res) => {
          this.templates = res;
          if (this.commonService.selectedAnchor!=undefined){
            this.anchor=this.commonService.selectedAnchor;
            this.commonService.selectedAnchor=undefined;
            this.templates.forEach(element => {
                  if (element.Nome === this.anchor.Nometemplate){
                //    this.anchor.Nometemplate=element.Nome;
                    this.anchor.Codicetemplate=element.Codice;
                  }
                });

            this.registerForm.setValue({
                        Codice: this.anchor.Codice,
                        Codicetemplate: this.anchor.Codicetemplate,
                        Nometemplate:this.anchor.Nometemplate,
                        Tipo:this.anchor.Tipo,
                        Testo:this.anchor.Testo,
                        Duecifre: this.anchor.Duecifre,
                        X1: this.anchor.X1,
                        Y1: this.anchor.Y1,
                        X2: this.anchor.X2,
                        Y2: this.anchor.Y2,
                        Xoffset: this.anchor.Xoffset,
                        Yoffset: this.anchor.Yoffset,
                        Xdim: this.anchor.Xdim,
                        Ydim: this.anchor.Ydim,
                        X1v: this.anchor.X1v,
                        Y1v: this.anchor.Y1v,
                        X2v: this.anchor.X2v,
                        Y2v: this.anchor.Y2v
              });

            }else{
                this.anchor=new Anchor();
            }
          });
  }
  ngAfterViewInit(){
    //console.log("---->"+this.lezione.Datainizio.toString());
    //this.Data=new Date();
    //console.log("---->"+this.Data.toString());
  }


  save() {
    if ((this.registerForm.value.Nometemplate==='')||(this.registerForm.value.Nometemplate===undefined)){
      alert("E' necessario selezionare un template");
   }else{

      this.commonService.saveAnchor(this.registerForm.value).subscribe(data => {
        this.rc=JSON.stringify(data)
        if (this.rc==="0"){
          this.router.navigate(['anchors']);
        }else{
          alert("Errore creazione ancora");
        }
      });
    }
  }
  manageChangeTemplate(){
   if (this.registerForm.value.Nometemplate===undefined){
     this.registerForm.value.Nometemplate='';
   }else{
      this.templates.forEach(element => {
        if (this.registerForm.value.Nometemplate === element.Nome){
          this.currentTemplate=element;
          this.anchor.Codicetemplate=element.Codice;
          this.anchor.Nometemplate=element.Nome;
          this.registerForm.value.Codicetemplate=element.Codice;
        }
      });
    }
  }
  manageChangeTipo(){
   if (this.registerForm.value.Tipo===undefined){
     this.registerForm.value.Tipo='';
   }else{
      this.tipi.forEach(element => {
        if (this.registerForm.value.Tipo === element.Nome){
          this.anchor.Tipo=element.Nome;
        }
      });
    }
  }
  cancel(){
    this.commonService.selectedTemplate=null;
    this.router.navigate(['anchors']);
  }


}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
