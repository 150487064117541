import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Sede } from '../../models/sede';
import { Progetto } from '../../models/progetto';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addprogetto',
  templateUrl: './addprogetto.component.html',
  styleUrls: ['./addprogetto.component.css']
})
export class AddprogettoComponent implements OnInit {
  progetto: Progetto=new Progetto();
  sedi: Sede[]=[];
  currentSede: Sede;
  selectedValueSede: string;
  hide = true;
  rc:number;
  sedetoedit: boolean;
  IPAddress:string;
  Port:string;
  Protocol:string;
  registerForm: FormGroup;
  submitted = false;

  currentStato={};
  stati=[
    { Nome:'In corso' } ,
    { Nome:'Completato' }
  ];

  public message =  sessionStorage.getItem('token');

  constructor(private httpClient: HttpClient, private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }

    }

  ngOnInit(): void {
    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
            Nome: ['', Validators.required],
            Descrizione: [''],
            Codice: ['0'],
            Codicesede : ['0'],
            Nomesede : [''],
            Stato : ['']
        });
    }

        this.commonService.getAllSedi().subscribe(data => {
          this.sedi=data;
          if (this.commonService.selectedProgetto!=undefined){
            this.progetto=this.commonService.selectedProgetto;
            this.commonService.selectedProgetto=undefined;
            this.progetto.Sede=new Sede();
            if (this.progetto.Codicesede!=undefined && this.progetto.Codicesede>0){

                this.sedi.forEach(s =>{
                  if (s.Codice===this.progetto.Codicesede){
                      this.progetto.Sede=s;
            //          this.progetto.Nomesede=s.Nome;
            //          this.progetto.Codicesede=s.Codice;
            //          this.selectedValueSede=s.Nome;
                  }
                });
              }
          }else{
            this.progetto=new Progetto();
          }

          this.registerForm.setValue({
            Nome: this.progetto.Nome,
            Descrizione: this.progetto.Descrizione,
            Codice: this.progetto.Codice,
    //        Codicesede : this.progetto.Codicesede,
    //        Nomesede : this.progetto.Nomesede,
            Codicesede : this.progetto.Sede.Codice,
            Nomesede : this.progetto.Sede.Nome,
            Stato : this.progetto.Stato
          });
        });


    }

    manageChangeSede(s:number){
      if (s!=undefined){
        this.sedi.forEach(element => {
          if (s === element.Codice){
            this.progetto.Sede=element;
          }
        });
      }else{
        this.progetto.Sede=new Sede();
      }
      this.registerForm.setValue({
        Nome: this.progetto.Nome,
        Descrizione: this.progetto.Descrizione,
        Codice: this.progetto.Codice,
        Codicesede : this.progetto.Sede.Codice,
        Nomesede : this.progetto.Sede.Nome,
        Stato : this.progetto.Stato
      });
    }


get f() { return this.registerForm.controls; }

save() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            alert("Errore: verifica i campi evidenziati in rosso");
            return;
        }else{
            this.commonService.saveProgetto(this.registerForm.value).subscribe(data => {
              this.rc=Number(data); //ritorna l'id dell'utente se è stato creato con successo
              if (this.rc===0){
                alert("Progetto già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione progetto");
              }else{
                alert("Progetto salvato con successo");
                this.router.navigate(['progetti']);
              }
            });

        }
    //    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
    }

  cancel(){
    this.commonService.selectedProgetto=null;
    this.router.navigate(['progetti']);
  }

}
