import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Sede } from '../../models/sede';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addsede',
  templateUrl: './addsede.component.html',
  styleUrls: ['./addsede.component.css']
})
export class AddsedeComponent implements OnInit {
  sede: Sede=new Sede();
  hide = true;
  rc:number;
  sedetoedit: boolean;
  IPAddress:string;
  Port:string;
  Protocol:string;
  registerForm: FormGroup;
  submitted = false;

  public message =  sessionStorage.getItem('token');

  constructor(private httpClient: HttpClient, private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
        this.Protocol=environment.address.localProtocol;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
        this.Protocol=environment.address.remoteProtocol;
      }

    }

  ngOnInit(): void {
    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
            Nome: ['', Validators.required],
            Numerodipendenti: ['0', [Validators.pattern(/[^0-9]*/)]],
            Codice: ['0'],
            Codiceazienda : ['0']
        });
    }


        if (this.commonService.selectedSede!=undefined){
          this.sede=this.commonService.selectedSede;
          this.commonService.selectedSede=undefined;


        }else{
          this.sede=new Sede();
          /*
          this.sede.Nome='';
          this.sede.Numerodipendenti=0;
          this.sede.Codice=0;

          */
        }
        this.sede.Codiceazienda=this.commonService.selectedAzienda.Codice;
        this.registerForm.setValue({
          Nome: this.sede.Nome,
          Numerodipendenti: this.sede.Numerodipendenti,
          Codice: this.sede.Codice,
          Codiceazienda : this.sede.Codiceazienda
        });
    }

get f() { return this.registerForm.controls; }

save() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            alert("Errore: verifica i campi indicati con *");
            return;
        }else{
            this.commonService.saveSede(this.registerForm.value).subscribe(data => {
              this.rc=Number(data); //ritorna l'id dell'utente se è stato creato con successo
              //console.log("--->SaveUser"+this.rc.toString());
              if (this.rc===0){
                alert("Sede già esistente");
              }else if (this.rc===-1){
                alert("Errore creazione sede");
              }else{
                alert("Sede salvata con successo");
                this.router.navigate(['sedi']);
              }
            });

        }
    //    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
    }

  cancel(){
    this.commonService.selectedSede=null;
    this.router.navigate(['sedi']);
  }

}
