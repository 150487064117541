// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const configurazione = {
  persona: {
    fb: {
      Nome: '',
      Cognome: '',
      Matricola: 'none',
      Datanascita: 'none',
      Dataassunzione: 'none',
      Datalicenziamento: 'none',
      Sesso: 'none',
      Cittanascita: 'none',
      Provincianascita: 'none',
      Codicefiscale: '',
      Citta: 'none',
      Provincia: 'none',
      Residenza: 'none',
      Cap: 'none',
      Telefono: '',
      Tipopersona: 'none',
      Tipocontratto: 'none',
      Nazione: 'none',
      Pec: '',
      Nomesede: '',
      Mansione: 'none',
      Nomestruttura: '',
      Codice: 'none',
      stato: {
        stato_bozza: 'Bozza',
        stato_inviato: 'Inviato',
        stato_letto: 'Letto',
        stato_approvato: 'Approvato'
      }
    }
  },
  ruoliEnabledToload: {
    Amministratore: 'NO',
    Tutor: 'NP',
    Docente: 'YES',
    Allievo: 'NO',
    HR: 'YES',
    Dipendente: 'NO'
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
