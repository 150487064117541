export class Tipopermesso {
  Nome: string;
  Codice: number;
  Descrizione: string;

  constructor(){
    this.Nome='';
    this.Codice=0;
    this.Descrizione='';
  }
}
