export class Template {
  Nome: string;
  Codice: number;
  Descrizione: string;
  Soglia1: number;
  Orainiziof1: string;
  Orafinef1: string;
  Soglia2: number;
  Orainiziof2;
  Orafinef2;
}
