import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Documento } from '../../models/documento';
import { Categoria } from '../../models/categoria';
import { Persona } from '../../models/persona';
import { Gruppo } from '../../models/gruppo';
import { Stato } from '../../models/stato';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { configurazione } from '../../../configurazioni/configurazione';

@Component({
  selector: 'app-jumpdocumenti',
  templateUrl: './jumpdocumenti.component.html',
  styleUrls: ['./jumpdocumenti.component.css']
})
export class JumpdocumentiComponent implements OnInit {

  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.commonService.selectedPersona=undefined;
    this.commonService.selectedGruppo=undefined;
    this.commonService.tipoDestinatario='Pubblico';
    this.router.navigate(['documenti']);
  }
  ngDoCheck(){
  //  alert("ngDoCheck");
  }
  ngAfterContentInit(){
  //  alert("ngAfterContentInit");
  }

  ngAfterContentChecked(){
//    alert("ngAfterContentChecked");
  }
  ngAfterViewInit(){
//    alert("ngAfterViewInit");
  }
  ngAfterViewChecked(){
//    alert("ngAfterViewChecked");
  }

  ngOnDestroy(){
//    alert("ngOnDestroy");
  }
}
