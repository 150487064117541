import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { MenuItem } from '../../models/menuitem';
import { Ruolo } from '../../models/ruolo';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addmenuitem',
  templateUrl: './addmenuitem.component.html',
  styleUrls: ['./addmenuitem.component.css']
})
export class AddmenuitemComponent implements OnInit {

  menuitem: MenuItem;
  hide = true;
  selectedValue:string;
  ruoli: Ruolo[];
  rc:String;
  registerForm: FormGroup;
  submitted = false;

  constructor(private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {

    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
          selectedValue:['', Validators.required],
          Nome:['', Validators.required],
          Contesto: [''],
          Sezione: [''],
          Istanzaid: [0],
          Codice: [0],
          Codiceruolo: [0],
          Ordine: [0]
      });
    }

    this.commonService.getRuoliByIstanza(this.commonService.currentIstanza.Codice).subscribe((res) => {
        this.ruoli = res;
        if (this.commonService.selectedMenuitem!=undefined){
          this.menuitem=this.commonService.selectedMenuitem;
          this.commonService.selectedMenuitem=undefined;
/*
          this.registerForm.setValue({
            selectedValue:this.menuitem.Nomeruolo,
            Nome:this.menuitem.Nome,
            Contesto:this.menuitem.Contesto,
            Sezione:this.menuitem.Sezione,
            Istanzaid:this.commonService.currentIstanza.Codice,
            Codiceruolo:this.menuitem.Codiceruolo,
            Ordine:this.menuitem.Ordine
          });
  */
        }else{
          this.menuitem= new MenuItem();
        }
/*
        if (this.registerForm===undefined){
          this.registerForm = this.formBuilder.group({
                selectedValue:['', Validators.required],
                Nome:['', Validators.required],
                Contesto: [''],
                Sezione: [''],
                Istanzaid: [0],
                Codiceruolo: [0],
                Ordine: [0]
            });
        }else{
          */
          this.registerForm = this.formBuilder.group({
            selectedValue:[this.menuitem.Nomeruolo],
            Nome:[this.menuitem.Nome],
            Contesto: [this.menuitem.Contesto],
            Sezione: [this.menuitem.Sezione],
            Istanzaid: [this.commonService.currentIstanza.Codice],
            Codice: [this.menuitem.Codice],
            Codiceruolo: [this.menuitem.Codiceruolo],
            Ordine: [this.menuitem.Ordine]
          });
//        }
    });

  }

  save() {
    this.submitted = true;
    if (this.registerForm.invalid) {
        alert("Errore: verifica i campi evidenziati in rosso");
        return;
    }else{
      this.commonService.saveMenuitem(this.registerForm.value).subscribe(data => {

        this.rc=JSON.stringify(data)
        if (this.rc==="0"){
          alert("Menu item salvato con successo");
          this.cancel();
        }else if (this.rc==="2"){
          alert("Menu item  già esistente");
        }else{
          alert("Errore creazione Menu item ");
        }
      });
    }
  }
  cancel(){
    this.selectedValue=undefined;
    this.menuitem=new MenuItem();
    this.router.navigate(['menuitems']);
  }

  manageChangeRuolo(){
    this.ruoli.forEach(element => {
      if (this.registerForm.value.selectedValue === element.Nome){
        this.menuitem.Codiceruolo=element.Codice;
        this.menuitem.Nomeruolo=element.Nome;
        this.registerForm.setValue({
                  selectedValue:this.menuitem.Nomeruolo,
                  Nome:this.menuitem.Nome,
                  Contesto:this.menuitem.Contesto,
                  Sezione:this.menuitem.Sezione,
                  Istanzaid:this.commonService.currentIstanza.Codice,
                  Codice:this.menuitem.Codice,
                  Codiceruolo:this.menuitem.Codiceruolo,
                  Ordine:this.menuitem.Ordine
        });
      }
    });
  }
}
