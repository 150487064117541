<mat-card>
    <mat-card-header>
        <mat-card-title>Dettaglio template</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form>

           <mat-form-field class="example-full-width">
               <input matInput placeholder="Nome" [(ngModel)]="template.Nome" name="Nome" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
               <input matInput placeholder="Descrizione" [(ngModel)]="template.Descrizione" name="Descrizione" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
               <input matInput placeholder="Soglia1" [(ngModel)]="template.Soglia1" name="Soglia 1" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
               <input matInput placeholder="Orainiziof1" [(ngModel)]="template.Orainiziof1" name="Ora inizio F1" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
               <input matInput placeholder="Orafinef1" [(ngModel)]="template.Orafinef1" name="Ora fine F1" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
               <input matInput placeholder="Soglia2" [(ngModel)]="template.Soglia2" name="Soglia 2" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
               <input matInput placeholder="Orainiziof2" [(ngModel)]="template.Orainiziof2" name="Ora inizio F2" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
               <input matInput placeholder="Orafinef2" [(ngModel)]="template.Orafinef2" name="Ora fine F2" required>
           </mat-form-field>

      </form>
    </mat-card-content>
    <mat-card-actions>
      <table>
        <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="save()">Salva</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

</mat-card>
