
<mat-card>
    <mat-card-header>
        <mat-card-title>Carica video</mat-card-title>
    </mat-card-header>
    <mat-card-content>


       <form [formGroup]="registerForm" (ngSubmit)="save()">


         <mat-form-field class="example-full-width">
           <button mat-icon-button matPrefix (click)="f_input.click()">
             <mat-icon>attach_file</mat-icon>
           </button>
           <input type="text" readonly matInput [formControl]="display" />

           <input
              type="file" name="nomefile" required
              multiple
              hidden
              #f_input
              (change)="handleFileInputChange(f_input.files)"
            />
      			<mat-error>this field is required</mat-error>
         </mat-form-field>
    <!--
         <mat-form-field class="example-full-width">
           <input matInput name="file" type="file" (change)="onFileChanged($event)" accept="application/m4v">
         </mat-form-field>
-->



       </form>

                    <div id="video-player">
                        <header>
                            <h3 id="now-playing"></h3>
                        </header>
                        <video id="video-screen" width="720px" height="480px" src="{{srcvideo}}" controls></video>
                    </div>
     </mat-card-content>
     <mat-card-actions>

        <table>

         <tr>
           <td>
             <button mat-raised-button  color="primary" (click)="save()">Salva</button>
           </td>
           <td>
             <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
           </td>
         </tr>
       </table>
     </mat-card-actions>

 </mat-card>
