
<mat-card class="matcardcontent">
        <mat-card-header>
            <mat-card-title>Lista materiali</mat-card-title>
        </mat-card-header>
        <mat-card-content>

        <navigazione #nav (onNavigazioneEvent)="onNavigazioneEvent($event)"></navigazione>

        <mat-form-field appearance="fill" style="padding-right: 10px">
          <mat-label class="text-sm">{{getProperyValue("labelMaster")}}</mat-label>
          <mat-select [(ngModel)]="selectedValueMaster" name="master" (selectionChange)="manageChangeMaster()" class="text-sm">
            <mat-option *ngFor="let master of masters" [value]="master.Nome">
              {{master.Nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="padding-right: 10px">
          <mat-label class="text-sm">{{getProperyValue("labelModulo")}}</mat-label>
          <mat-select [(ngModel)]="selectedValueModulo" name="modulo" (selectionChange)="manageChangeModulo()" class="text-sm">
            <mat-option *ngFor="let modulo of moduli" [value]="modulo.Nome">
              {{modulo.Nome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

         <mat-form-field appearance="fill" style="padding-right: 10px">
           <mat-label class="text-sm">{{getProperyValue("labelLezioni")}}</mat-label>
           <mat-select [(ngModel)]="selectedValue" (ngModelChange)="aggiornaLista()" name="lezione" class="text-sm">
             <mat-option *ngFor="let lezione of lezioni" [value]="lezione.Codice">
               {{lezione.Nome}}
             </mat-option>
           </mat-select>
         </mat-form-field>

         <mat-form-field style="padding-right: 10px">
           <mat-label class="text-sm">Filtra</mat-label>
           <input matInput (keyup)="applyFilter($event)" placeholder="...">
         </mat-form-field>

<!--   <div>
      <a mat-button *ngIf="isDocente() && this.selectedValue!=undefined" (click)="creaCartella()" class="nav-link">Crea cartella</a>
      <a mat-button *ngIf="isDocente() && this.selectedValue!=undefined" (click)="caricaAllegato()" class="nav-link">Allega file</a>
   </div>
-->
     <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

     <ng-container matColumnDef=" ">
       <th mat-header-cell *matHeaderCellDef mat-sort-header ></th>
       <td mat-cell *matCellDef="let materiale">
         <mat-icon color="primary" *ngIf="materiale.Type==='CARTELLA'" (click)="cambiaCartella(materiale)">folder_open</mat-icon>
         <mat-icon color="primary" *ngIf="materiale.Type==='DOCUMENTO'">insert_drive_file</mat-icon>
        </td>
     </ng-container>

     <ng-container matColumnDef="Name">
       <th mat-header-cell *matHeaderCellDef mat-sort-header="Materiale"> Materiale
          <img src="./assets/images/sort.png" (click)="manageClick('Name')"/>
       </th>
       <td mat-cell *matCellDef="let materiale" >
           {{materiale.Name}}
        </td>
     </ng-container>

     <ng-container matColumnDef="Datapubblicazione">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Data
         <img src="./assets/images/sort.png" (click)="manageClick('Datapubblicazione')"/>
       </th>
       <td mat-cell *matCellDef="let materiale">
           {{materiale.Datapubblicazione | date:'dd/MM/yyy'}}
        </td>
     </ng-container>

     <ng-container matColumnDef="Filename">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome file
         <img src="./assets/images/sort.png" (click)="manageClick('Filename')"/>
       </th>
       <td mat-cell *matCellDef="let materiale">
           {{materiale.Filename}}
        </td>
     </ng-container>

     <ng-container matColumnDef="Nomelezione">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Argomento
         <img src="./assets/images/sort.png" (click)="manageClick('Nomelezione')"/>
       </th>
       <td mat-cell *matCellDef="let materiale">
           {{materiale.Nomelezione}}
        </td>
     </ng-container>

     <ng-container matColumnDef="Mimetype">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo
         <img src="./assets/images/sort.png" (click)="manageClick('Mimetype')"/>
       </th>
       <td mat-cell *matCellDef="let materiale">
           {{materiale.Mimetype}}
        </td>
     </ng-container>

     <ng-container matColumnDef="Scarica">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Scarica </th>
       <td mat-cell *matCellDef="let materiale">
         <mat-icon *ngIf="materiale.Type==='DOCUMENTO'"  color="primary" (click)="downloadFile(materiale)">save_alt</mat-icon>
      </td>
     </ng-container>

       <ng-container matColumnDef="select">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> Abilita
           <img src="./assets/images/sort.png" (click)="manageClick('select')"/>
         </th>
         <td mat-cell *matCellDef="let materiale">
           <mat-icon *ngIf="materiale.Abilitato=='YES'"  color="primary" (click)="toggle(materiale)">check_circle</mat-icon>
           <mat-icon *ngIf="materiale.Abilitato=='NO'"  color="primary" (click)="toggle(materiale)">check_circle_outline</mat-icon>
        </td>
       </ng-container>

     <ng-container matColumnDef="Elimina">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Elimina </th>
       <td mat-cell *matCellDef="let materiale">
         <mat-icon  color="primary" (click)="eliminaMateriale(materiale)">delete</mat-icon>
      </td>
     </ng-container>

     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
       </tr>
   </table>


   <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>


    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button  color="primary" *ngIf="isEnabledToLoad() && getProperyValue('creaCartella')" (click)="creaCartella()" [disabled]="this.selectedValue===undefined || this.selectedValue===''">Nuova cartella</button>
      <button mat-raised-button  color="primary" [disabled]="this.selectedValue===undefined || this.selectedValue===''"  (click)="caricaAllegato()" class="nav-link">Allega file</button>
      <button mat-raised-button  color="primary" (click)="cancel()" class="nav-link">Cancella</button>
    </mat-card-actions>

</mat-card>
