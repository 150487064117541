import { Component, OnInit } from '@angular/core';
import { Master } from '../../models/master';
import { Lezione } from '../../models/lezione';
import { CommonService}  from "../../service/common.service";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { EventEmitterService } from '../../service/event-emitter.service';

@Component({
  selector: 'app-moduli',
  templateUrl: './moduli.component.html',
  styleUrls: ['./moduli.component.css']
})
export class ModuliComponent implements OnInit {

  items: Master[] = [];

  constructor(private router: Router, private commonService: CommonService,
    private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {

  //  this.items=this.commonService.getStoredMaster();
    this.commonService.getMaster(sessionStorage.getItem("username")).subscribe((res) => {
      this.items=res;
    })


//    this.masterList=sessionStorage.getItem("masterList");
  }

  dettaglio(lezione: Lezione): void {

    this.commonService.currentLezione=lezione;
    console.log("Moduli-Lezione code:"+lezione.Codice);
    //this.eventEmitterService.onDettalioLezioneClick(lezioneid);
    this.router.navigate(['lezioni']);
  }

  getDatainizio(lezione:Lezione):Date{
    if (lezione.Datainizio!=undefined){
      return(lezione.Datainizio);
    }else{
      return null;
    }
  }
}
