export class User {
  Firstname: string;
  Lastname: string;
  Fiscalcode: string;
  Username: string;
  Password: string;
  Email: string;
  Isactive: boolean;
  Language: string;
  Cellphone: string;
  Codiceruolo: number;
  Codiceistanza: number;
  Nomeistanza: string;
  Codicepersona: number;
  Codiceazienda: number;
  Nomeruolo: String;
  Picbyte: any;
  Codice: number;
}
export interface Credentials {
  userId: string;
  password: string;
}
export class AuthResponse {
  user: User;
  token: string;
}

/**
 active: true
 circuit: {id: 300, name: "OPERATIONAL_PLAN"}
 createdBy: null
 createdOn: "2020-12-01T16:23:37"
 currencyId: 1
 deactivatedOn: null
 email: "andrea.tosi@hpe.com"
 firstname: "Andrea"
 id: 85
 lastname: "Tosi"
 modifiedBy: {id: 29, currencyId: 1, kind: "ADMIN", username: "Frank Steffen"}
 modifiedOn: "2020-12-01T16:23:37"
 notifications: {mails: true, messages: true, events: {…}, tracking: {…}}
 notificationsActive: null
 password: null
 permissionList: (14) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
 role: {id: 60, name: "TEAM", description: "Vertical Team"}
 status: "ACTIVE"
 username: "atosi_v2"
 version: 0
 */
