import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../service/common.service";
import {Istanza} from '../../models/istanza';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
 istanza:Istanza;
  constructor(private commonService: CommonService,
    private router: Router) { }

  ngOnInit() {
    /*
    this.commonService.currentIstanza=new Istanza();
    this.commonService.getAllIstanze().subscribe((res) => {
        res.forEach(element => {
          if (element.Codice===4){
            this.commonService.currentIstanza=element;
          }
        });
    });
*/
  }

  public getFooterText(): String{
    if (this.commonService.currentIstanza!=undefined){
      if (this.commonService.currentIstanza.Codice===1){
        return "Antur Academy";
      }else if (this.commonService.currentIstanza.Codice===4){
        return "FBService";
      }else{
       return "";
      }
    }else{
      return "dThink S.r.l.: Via Dardanelli, 15 – 00195 Roma - C.F. E P.IVA 13326111005";
    }
  }

}
