<mat-card>
    <mat-card-header>
        <mat-card-title>Lista Dipendenti</mat-card-title>
    </mat-card-header>
    <mat-card-actions>
      <table>
        <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="export()">Esporta</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="group()" [disabled]="selectedValueAzienda===undefined || selectedValueAnno===undefined">Raggruppa</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="truncate()" [disabled]="selectedValueAzienda===undefined || selectedValueAnno===undefined">Elimina</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>
    <mat-card-content class="content">
          <div *ngIf="busy" class="spinner__loading">
              <mat-progress-spinner  diameter="60" [value]="spinnervalue" mode="indeterminate">
              </mat-progress-spinner>
          </div>
           <mat-form-field appearance="fill" style="padding-right: 10px">
              <mat-label class="text-sm">Azienda</mat-label>
                <mat-select [(ngModel)]="selectedValueAzienda" name="Nomeazienda" (selectionChange)="manageChangeAzienda()" class="text-sm">
                  <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
                    {{azienda.Nome}}
                  </mat-option>
              </mat-select>
           </mat-form-field>
           <mat-form-field appearance="fill" style="padding-right: 10px">
              <mat-label class="text-sm">Anno</mat-label>
                <mat-select [(ngModel)]="selectedValueAnno" name="anno" (selectionChange)="manageChangeAnno()" class="text-sm">
                  <mat-option *ngFor="let anno of anni" [value]="anno.Nome">
                    {{anno.Nome}}
                  </mat-option>
              </mat-select>
           </mat-form-field>

         <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

         <ng-container matColumnDef="Nomeazienda">
             <th mat-header-cell *matHeaderCellDef> Azienda
               <img src="./assets/images/sort.png" (click)="manageClick('Nomeazienda')"/>
             </th>
             <td mat-cell *matCellDef="let lulemployee"> {{lulemployee.Nomeazienda}} </td>
         </ng-container>

         <ng-container matColumnDef="Codicefiscale">
             <th mat-header-cell *matHeaderCellDef> Codice fiscale
               <img src="./assets/images/sort.png" (click)="manageClick('Codicefiscale')"/>
             </th>
             <td mat-cell *matCellDef="let lulemployee"> {{lulemployee.Codicefiscale}} </td>
         </ng-container>

          <ng-container matColumnDef="Dipendente">
              <th mat-header-cell *matHeaderCellDef> Nome e cognome
                <img src="./assets/images/sort.png" (click)="manageClick('Dipendente')"/>
              </th>
              <td mat-cell *matCellDef="let lulemployee"> {{lulemployee.Dipendente}} </td>
          </ng-container>

          <ng-container matColumnDef="Periodo">
              <th mat-header-cell *matHeaderCellDef> Periodo
                <img src="./assets/images/sort.png" (click)="manageClick('Periodo')"/>
              </th>
              <td mat-cell *matCellDef="let lulemployee"> {{lulemployee.Periodo}} </td>
          </ng-container>

          <ng-container matColumnDef="Valore">
              <th mat-header-cell *matHeaderCellDef> Totale ore
                <img src="./assets/images/sort.png" (click)="manageClick('Valore')"/>
              </th>
              <td mat-cell *matCellDef="let lulemployee"> {{lulemployee.Valore}} </td>
          </ng-container>

          <ng-container matColumnDef="Data">
              <th mat-header-cell *matHeaderCellDef> Data caricamento
                <img src="./assets/images/sort.png" (click)="manageClick('Data')"/>
              </th>
              <td mat-cell *matCellDef="let lulemployee"> {{lulemployee.Dataupdate | date: 'dd/MM/yyyy' }} </td>
            </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </mat-card-content>




</mat-card>
