<mat-card class="matcardcontent">
    <mat-card-header>
        <mat-card-title>Lista lezioni</mat-card-title>
    </mat-card-header>
    <mat-card-content>
           <mat-form-field appearance="fill" style="padding-right: 10px">
             <mat-label class="text-sm">Master</mat-label>
             <mat-select [(ngModel)]="selectedValueMaster" name="master" (selectionChange)="manageChangeMaster()" class="text-sm">
               <mat-option *ngFor="let master of masters" [value]="master.Nome">
                 {{master.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>
           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Modulo</mat-label>
             <mat-select [(ngModel)]="selectedValueModulo" name="modulo" (selectionChange)="manageChangeModulo()" class="text-sm">
               <mat-option *ngFor="let modulo of moduli" [value]="modulo.Nome">
                 {{modulo.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
<!--
          <ng-container matColumnDef="Codicecorso">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice
                <img src="./assets/images/sort.png" (click)="manageClick('Codicecorso')"/>
              </th>
              <td mat-cell *matCellDef="let lezione"> {{lezione.Codicecorso}} </td>
          </ng-container>
-->
          <ng-container matColumnDef="Nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome
                <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
              </th>
              <td mat-cell *matCellDef="let lezione"> {{lezione.Nome}} </td>
          </ng-container>

          <ng-container matColumnDef="Descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione
                <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
              </th>
              <td mat-cell *matCellDef="let lezione"> {{lezione.Descrizione}} </td>
          </ng-container>

          <ng-container matColumnDef="Url">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Url
                <img src="./assets/images/sort.png" (click)="manageClick('Url')"/>
              </th>
              <td mat-cell *matCellDef="let lezione"> {{lezione.Url}} </td>
          </ng-container>

          <ng-container matColumnDef="Abilitato">
            <th mat-header-cell *matHeaderCellDef> Abilitato
              <img src="./assets/images/sort.png" (click)="manageClick('Abilitato')"/>
            </th>
            <td mat-cell *matCellDef="let lezione">
              <mat-icon *ngIf="lezione.Abilitato==='YES'"  color="primary" (click)="toggle(lezione)">check_circle</mat-icon>
              <mat-icon *ngIf="lezione.Abilitato==='NO'"  color="primary" (click)="toggle(lezione)">check_circle_outline</mat-icon>
           </td>
          </ng-container>

          <ng-container matColumnDef="Datainizio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data
                <img src="./assets/images/sort.png" (click)="manageClick('Datainizio')"/>
              </th>
              <td mat-cell *matCellDef="let lezione"> {{lezione.Datainizio | date: 'dd/MM/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="Orainizio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ora inizio
                <img src="./assets/images/sort.png" (click)="manageClick('Orainizio')"/>
              </th>
              <td mat-cell *matCellDef="let lezione"> {{lezione.Orainizio}} </td>
          </ng-container>

          <ng-container matColumnDef="Orafine">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ora fine
                <img src="./assets/images/sort.png" (click)="manageClick('Orafine')"/>
              </th>
              <td mat-cell *matCellDef="let lezione"> {{lezione.Orafine}} </td>
          </ng-container>

          <ng-container matColumnDef="Modifica">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Modifica </th>
            <td mat-cell *matCellDef="let lezione">
              <mat-icon color="primary" (click)="editLesson(lezione)">create</mat-icon>
           </td>
          </ng-container>

          <ng-container matColumnDef="Elimina">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Elimina </th>
            <td mat-cell *matCellDef="let lezione">
              <mat-icon color="primary" (click)="deleteLesson(lezione)">delete</mat-icon>
           </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>


</mat-card-content>
<mat-card-actions>
  <button mat-raised-button  color="primary" (click)="newLesson()">Nuova lezione</button>
  <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
</mat-card-actions>

</mat-card>
