import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {CommonService} from "../../service/common.service";
import { HttpClient, HttpEventType } from '@angular/common/http';
import {FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';


@Component({
  selector: 'app-videoapp',
  templateUrl: './videoapp.component.html',
  styleUrls: ['./videoapp.component.scss']
})
export class VideoappComponent implements OnInit {

  IPAddress:String;
  Port:String;
  Protocol:string;
  srcvideo="assets/videos/video1.m4v";
  selectedFile: File;
  file_store: FileList;
  registerForm: FormGroup;
  nomefile:string;
  message: string;
  display: FormControl = new FormControl("", Validators.required);

  @Input() error: string | null;

  constructor(private router: Router, private httpClient: HttpClient, private commonService: CommonService, private formBuilder: FormBuilder) {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }
  }

  ngOnInit() {
/*
    if (this.registerForm===undefined){
       this.registerForm = this.formBuilder.group({
            Nome: [''],
            display: [''],
            Codice: ['0']
        });
      }
*/
  }

  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
  }

    save() {
//      if (this.nomefile===undefined || this.nomefile===""){
//        alert("E' necessario specificato il nome del file");
//      } else {
        const uploadData = new FormData();
        uploadData.append('nome', this.display.getRawValue());
        uploadData.append('file', this.selectedFile, this.selectedFile.name);

        this.httpClient.post(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/videos/save", uploadData, { observe: 'response' })
          .subscribe((response) => {
            if (response.status === 200) {
              this.message = 'File salvato con successo';
            } else {
              this.message = 'File non salvato';
            }
          });
  //    }
    }

    handleFileInputChange(l: FileList): void {
      this.file_store = l;
      if (l.length) {
        const f = l[0];
        this.selectedFile=f;
        this.display.patchValue(`${f.name}`);
    //
    //    const count = l.length > 1 ? `(+${l.length - 1} files)` : "";
    //    this.display.patchValue(`${f.name}${count}`);
      } else {
        this.display.patchValue("");
      }
    }

    cancel(){
//      this.commonService.selectedGruppo=null;
//      this.router.navigate(['gruppi']);
    }

  }
