<mat-card>
    <mat-card-header>
        <mat-card-title>Dettaglio master</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form [formGroup]="registerForm" (ngSubmit)="save()">

          <div class="example-full-width">
              <div *ngIf=retrievedImage>
                  <img class="courseimage" src="{{retrievedImage}}" >
              </div>
              <div class="container row">
                <div class="col-md-6">
                    <input style="width:75px; height=75px;" #file type="file" accept='image/*' (change)="onFileChanged(file.files)">
                    <button mat-raised-button  color="primary" (click)="removeImg()">Rimuovi immagine</button>
                </div>
              </div>
          </div>

          <mat-form-field class="example-full-width">
              <mat-label>Codice</mat-label>
            <input matInput placeholder="Codice" formControlName="Codicecorso" name="Codicecorso" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Nome</mat-label>
            <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Descrizione</mat-label>
            <input matInput placeholder="Descrizione" formControlName="Descrizione" name="Descrizione">
          </mat-form-field>

      </form>
    </mat-card-content>
    <mat-card-actions>
      <table>
         <tr>
          <td>
             <button mat-raised-button  color="primary" (click)="save()">Salva</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

</mat-card>
