  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista progetti</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->

    <mat-form-field appearance="fill">
      <mat-label class="text-sm">Stato</mat-label>
      <mat-select [(ngModel)]="selectedValueStato" name="stato" (selectionChange)="manageChangeStato()" class="text-sm">
        <mat-option *ngFor="let stato of stati" [value]="stato.Nome">
          {{stato.Nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let progetto"> {{progetto.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Descrizione">
          <th mat-header-cell *matHeaderCellDef> Descrizione
            <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
          </th>
          <td mat-cell *matCellDef="let progetto"> {{progetto.Descrizione}} </td>
      </ng-container>

      <ng-container matColumnDef="Sede">
          <th mat-header-cell *matHeaderCellDef> Sede
            <img src="./assets/images/sort.png" (click)="manageClick('Sede')"/>
          </th>
          <td mat-cell *matCellDef="let progetto"> {{progetto.Nomesede}} </td>
      </ng-container>

      <ng-container matColumnDef="Stato">
          <th mat-header-cell *matHeaderCellDef> Stato
            <img src="./assets/images/sort.png" (click)="manageClick('Stato')"/>
          </th>
          <td mat-cell *matCellDef="let progetto"> {{progetto.Stato}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Modifica </th>
          <td mat-cell *matCellDef="let progetto">
            <mat-icon color="primary" (click)="editProgetto(progetto)">create</mat-icon>
          </td>
      </ng-container>
<!--
      <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Elimina </th>
          <td mat-cell *matCellDef="let user"><button class="btn btn-danger" (click)="deleteUser(user)">Elimina</button> </td>
      </ng-container>
-->


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


<!-- </form> -->
</mat-card-content>
<mat-card-actions>
  <table>
    <tr>
      <td>
        <button mat-raised-button  color="primary" (click)="newProgetto()">Nuovo progetto</button>
      </td>
      <td>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </td>
    </tr>
  </table>
</mat-card-actions>

</mat-card>
