
<mat-card>
    <mat-card-header>
        <mat-card-title>Lista LUL</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
     <div *ngIf="isAnalyzing()" class="spinner__loading">
          <mat-progress-spinner  diameter="60" [value]="spinnervalue" mode="indeterminate">
          </mat-progress-spinner>
  <!--     <div *ngIf="isAnalyzing()">
          <mat-label class="text-sm">Elaborazione in corso...</mat-label>-->
      </div>
      <form>
        <div>


           <mat-form-field appearance="fill" style="padding-right: 10px">
             <mat-label class="text-sm">Template</mat-label>
             <mat-select [(ngModel)]="selectedValueTemplate" name="template" (selectionChange)="manageChangeTemplate()" class="text-sm">
               <mat-option *ngFor="let template of templates" [value]="template.Nome">
                 {{template.Nome}}
               </mat-option>
             </mat-select>
            </mat-form-field>

            <mat-form-field style="padding-right: 10px" >
              <mat-label class="text-sm">Filtra</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="...">
            </mat-form-field>
        </div>

  <mat-error *ngIf="numberFormControl.hasError('pattern')"> <!-- && !numberFormControl.hasError('required')"> -->
    Inserisci un numero valido
  </mat-error>

   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Descrizione">
          <th mat-header-cell *matHeaderCellDef> Descrizione
            <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
          </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Descrizione}} </td>
      </ng-container>

      <ng-container matColumnDef="Nomeazienda">
          <th mat-header-cell *matHeaderCellDef> Azienda
            <img src="./assets/images/sort.png" (click)="manageClick('Nomeazienda')"/>
          </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Nomeazienda}} </td>
      </ng-container>

      <ng-container matColumnDef="Paginainizio">
          <th mat-header-cell *matHeaderCellDef> Inizio </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Paginainizio}} </td>
          <!--
          <td mat-cell *matCellDef="let lul">
            <input matInput (ngModelChange)="paginaInizioChanged(lul, $event)" [formControl]="numberFormControl" [errorStateMatcher]="matcher"
                  placeholder="{{lul.Paginainizio}}"  type="text" [value]="lul.Paginainizio"/>
          </td>
          -->
      </ng-container>

      <ng-container matColumnDef="Paginafine">
          <th mat-header-cell *matHeaderCellDef> Fine </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Paginafine}} </td>
          <!--
          <td mat-cell *matCellDef="let lul">
            <input matInput (ngModelChange)="paginaFineChanged(lul, $event)" [formControl]="numberFormControl" [errorStateMatcher]="matcher"
                  placeholder="{{lul.Paginafine}}"  type="text"  [value]="lul.Paginafine"  />
          </td>
          -->
      </ng-container>


<!--
      <ng-container matColumnDef="Codice">
          <th mat-header-cell *matHeaderCellDef> Codice </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Codice}} </td>
      </ng-container>
-->
      <ng-container matColumnDef="Nometemplate">
          <th mat-header-cell *matHeaderCellDef> Template
            <img src="./assets/images/sort.png" (click)="manageClick('Nometemplate')"/>
          </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Nometemplate}} </td>
      </ng-container>

      <ng-container matColumnDef="Materialename">
          <th mat-header-cell *matHeaderCellDef> Materiale
            <img src="./assets/images/sort.png" (click)="manageClick('Materialename')"/>
          </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Materialename}} </td>
      </ng-container>

      <ng-container matColumnDef="Materialefilename">
          <th mat-header-cell *matHeaderCellDef> Nome file
            <img src="./assets/images/sort.png" (click)="manageClick('Materialefilename')"/>
          </th>
          <td mat-cell *matCellDef="let lul"> {{lul.Materialefilename}} </td>
      </ng-container>

      <ng-container matColumnDef="AddMateriale">
          <th mat-header-cell *matHeaderCellDef> Materiale </th>
          <td mat-cell *matCellDef="let lul">
            <mat-icon color="primary" (click)="addMateriale(lul)">add_circle</mat-icon>
          </td>
      </ng-container>

      <ng-container matColumnDef="Modifica">
        <th mat-header-cell *matHeaderCellDef> Modifica </th>
        <td mat-cell *matCellDef="let lul">
          <mat-icon color="primary" (click)="editLul(lul)">create</mat-icon>
       </td>
      </ng-container>

      <ng-container matColumnDef="Analizza">
          <th mat-header-cell *matHeaderCellDef> Analizza </th>
          <td mat-cell *matCellDef="let lul">
            <mat-icon color="primary" *ngIf="lul.Codicetemplate>0 && lul.Materialeid!=undefined && lul.Materialeid>0 && !numberFormControl.hasError('pattern')" (click)="analize(lul)">play_circle_filled</mat-icon>
          </td>
      </ng-container>

      <ng-container matColumnDef="Indaga">
          <th mat-header-cell *matHeaderCellDef> Indaga </th>
          <td mat-cell *matCellDef="let lul">
            <mat-icon color="primary" *ngIf="lul.Materialeid!=undefined && lul.Materialeid>0 && !numberFormControl.hasError('pattern')" (click)="onlyparse(lul)">grid_on</mat-icon>
          </td>
      </ng-container>

      <ng-container matColumnDef="Elimina">
        <th mat-header-cell *matHeaderCellDef> Elimina </th>
        <td mat-cell *matCellDef="let lul">
          <mat-icon  color="primary" (click)="eliminaLul(lul)">delete</mat-icon>
       </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</form>
</mat-card-content>
<mat-card-actions>
       <button mat-raised-button  color="primary" (click)="newLul()">Nuovo LUL</button>
       <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
</mat-card-actions>



</mat-card>
