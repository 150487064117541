import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { PasswordData } from '../../models/passworddata';
import { User }  from '../../models/user';
import { AuthenticationService } from '../../service/authentication.service';

@Component({
  selector: 'app-cambiapassword',
  templateUrl: './cambiapassword.component.html',
  styleUrls: ['./cambiapassword.component.css']
})
export class CambiapasswordComponent implements OnInit {
IPAddress: string;
  Port: string;

  public oldpassword: string;
  public newpassword: string;
  public confirmpassword: string;
  public user: User;

  constructor(public dialogRef: MatDialogRef<CambiapasswordComponent>,
              private commonService: CommonService, private httpClient: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: PasswordData,
              public loginservice: AuthenticationService) {

      this.oldpassword ='';
      this.newpassword ='';
      this.confirmpassword ='';

   }

    ngOnInit(): void {
      if (environment.address.isLocal){
        this.IPAddress = environment.address.localIP;
        this.Port = environment.address.localPort;
      }else{
        this.IPAddress = environment.address.remoteIP;
        this.Port = environment.address.remotePort;
      }
  }

  savePassword(){
    console.log('BEGIN-Save password');
    console.log('User:'+this.data.username);
    //this.data.userid=sessionStorage.getItem("username");
    (this.loginservice.authenticate(this.data.username, this.oldpassword, this.commonService.currentIstanza.Codice).subscribe(
        data => {

            if ((this.newpassword!='') && (this.newpassword === this.confirmpassword)){
            this.data.newpassword=this.newpassword;
            this.commonService.savePassword(this.data).subscribe(data => {
              let rc: string;
              rc = JSON.stringify(data);
              console.log('RC:'+rc);
              if (rc === '0'){
                alert('Password salvata con successo');
              }else{
                // tslint:disable-next-line: quotemark
                alert("Errore salvataggio password");
              }
            });
          }else{
            alert('La password confermata è vuota o non è la stessa di quella inserita');
          }
        },
        error => {
            alert('Vecchia password valida');
        }
      )
      );
      console.log('END-Save password');



  }
}
