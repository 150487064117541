import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Azienda } from '../../models/azienda';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.css']
})
export class AziendeComponent implements OnInit {
  IPAddress:string;
  Port:string;
  Protocol: string;
  aziende: Azienda[]=[];
  displayedColumns: string[] = [
  "Nome",
  "Partitaiva",
  "Missione",
  "Codicefiscale",
  "Ragionesociale",
  "Attivita",
  "Numerodipendenti",
  "Rea",
  "Datarea",
  "Cameracommercio",
  "Isactive",
  "edit"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  dataSource = new MatTableDataSource<Azienda>(this.aziende);
  constructor(private httpClient: HttpClient, private router: Router, private commonService: CommonService) {}

  ngOnInit() {
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Partitaiva",true);
    this.sortedDirection.set("Missione",true);
    this.sortedDirection.set("Codicefiscale",true);
    this.sortedDirection.set("Ragionesociale",true);
    this.sortedDirection.set("Attivita",true);
    this.sortedDirection.set("Numerodipendenti",true);
    this.sortedDirection.set("Rea",true);
    this.sortedDirection.set("Datarea",true);
    this.sortedDirection.set("Cameracommercio",true);
    this.sortedDirection.set("Isactive",true);
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    if (this.commonService.currentUser===undefined || this.commonService.currentUser===null){
      const username=sessionStorage.getItem('username');
      this.commonService.getUser(username).subscribe(data => {
        this.commonService.currentUser=data;
        this.commonService.currentAziendaCodice=this.commonService.currentUser.Codiceazienda;
        this.aggiornaLista();
      });
    }else{
      this.aggiornaLista();
    }
  }

  cancel(){
    this.aggiornaLista();
  }

  deleteAzienda(azienda: Azienda): void {
    this.commonService.deleteAzienda(azienda).subscribe(data => {
      this.aziende = this.aziende.filter(a => a !== azienda);
    });
  }

  toggleEnable(azienda: Azienda){
    //selection.toggle(row);
    const aziendaData = new FormData();
//    aziendaData.append('codice', azienda.Codice); // METTERE UN CAMPO CHE IDENTIFICA L'AZIENDA
    this.httpClient.put(this.Protocol+"://"+this.IPAddress+":"+this.Port+"/api/aziende/toggle", aziendaData, { observe: 'response' })
    .subscribe((response) => {
        this.aggiornaLista();
    });
  }

  aggiornaLista(){
//    let nomeruolo:String="";
//    if (this.selectedValueRuolo!=undefined){
//     nomeruolo=this.selectedValueRuolo;
//    }
    this.commonService
      .getAllAziende()
      .subscribe(response => {

        let tempaziende:Azienda[]=response;
  //      let templistausers:User[]=[];
  //      tempusers.forEach(element => {
  //        const user:User=element;
  //        user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
          //console.log("Nome ruolo="+user.Nomeruolo);
  //        if (nomeruolo==="" || nomeruolo===user.Nomeruolo){
  //          templistausers.push(user);
  //        }
  //      });
        this.refreshLista(tempaziende);
      });
   }
   refreshLista(listaaziende:Azienda[]){
     this.aziende=listaaziende;
     this.dataSource = new MatTableDataSource<Azienda>(this.aziende);
   }
/*
  handleSuccessfulResponse(response) {

    let tempusers:Array<User>=response;
    tempusers.forEach(element => {
      const user:User=element;
      user.Nomeruolo=this.getNomeByCodiceruolo(element.Codiceruolo);
      this.users.push(user);
    });


  }
*/
  newAzienda(){
    this.router.navigate(['addazienda']);
  }
  editAzienda(azienda: Azienda): void {
      this.commonService.selectedAzienda=azienda;
      this.router.navigate(['addazienda']);
  }

  manageClick(attributo: string){
    //alert(ev.target)
/*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Azienda[];
    tempList=this.aziende;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Azienda>(this.aziende);

   }


}
