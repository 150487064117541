import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';
import { NotifyService } from '../../service/notify.service';
import { Item } from '../../models/item';
import { MatDialog } from '@angular/material/dialog';
import { DialogSampleComponent } from '../../common/dialog-sample/dialog-sample.component';
import { environment } from '../../../environments/environment';
import { startWith, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { MeseComponent } from './mese/mese.component';
import { AnnoComponent } from './anno/anno.component';
import { Progetto } from '../../models/progetto';
import { Attivita } from '../../models/attivita';
import { Progettoattivita } from '../../models/progettoattivita';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
/*
import {
    GanttBarClickEvent,
    GanttViewType,
    GanttDragEvent,
    GanttLoadOnScrollEvent,
    GanttLineClickEvent,
    GanttLinkDragEvent,
    GanttItem,
    GanttViewOptions,
    GanttDate,
    GanttPrintService
} from '@worktile/gantt';
*/
@Component({
  selector: 'cronoprogramma',
  templateUrl: './cronoprogramma.component.html',
  styleUrls: ['./cronoprogramma.component.css']
})
export class CronoprogrammaComponent implements OnInit {
@ViewChild('anno') anno: AnnoComponent;
@Input() valore:any;

projectPeriod:any;

mesi=[];

progetti: Progetto[]=[];
associatedattivita: Progettoattivita[]=[];
currentProgetto: Progetto;
selectedValueProgetto: string;
hide = true;
rc:number;

IPAddress:string;
Port:string;
Protocol:string;
registerForm: FormGroup;
submitted = false;


constructor(private httpClient: HttpClient, private commonService: CommonService,
  private router: Router, private formBuilder: FormBuilder) {
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

  }

ngOnInit(): void {
    let now:Date=new Date();

    const currentYear=now.getFullYear();
    const currentMonth=now.getMonth()+1;

    let yyyyStart=currentYear-1;
    let yyyyEnd=currentYear+1;
    let mmStart=currentMonth;
    let mmEnd=currentMonth-1;

    this.projectPeriod={ yyyyStart:yyyyStart, mmStart:mmStart, yyyyEnd:yyyyEnd, mmEnd:mmEnd };

    this.commonService.getAllProgetti().subscribe((res) => {
          this.progetti = res;
    });

}

manageChangeProgetto(){
  if (this.selectedValueProgetto===undefined || this.selectedValueProgetto===""){
   return;
  }
  this.associatedattivita=[];
//      this.items=[];
  this.progetti.forEach(element => {

    if (this.selectedValueProgetto === element.Nome){
        this.commonService.getAllAttivitaByProgettoid(element.Codice).subscribe((res) => {
          this.commonService.currentProgettiattivita = res;
          this.anno.refresh();
        });
      }

      /*
      this.commonService.getAllAttivitaByProgettoid(this.currentProgetto.Codice).subscribe((res) => {
        const r:Progettoattivita[]=res;
        r.forEach(element => {
          //console.log(element);
          this.associatedattivita.push(element as Progettoattivita);

        });
//        this.projectPeriod={ yyyyStart:2023, mmStart:11, yyyyEnd:2024, mmEnd:5 };

      });
      */

    });
}
newProgetto(){
  this.router.navigate(['addprogetto']);
}
  cancel(){
    this.selectedValueProgetto=undefined;
  }
}
