
<mat-card>
    <mat-card-header>
        <mat-card-title>Lista ancore</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">

      <form>

           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Template</mat-label>
             <mat-select [(ngModel)]="selectedValueTemplate" name="template" (selectionChange)="manageChangeTemplate()" class="text-sm">
               <mat-option *ngFor="let template of templates" [value]="template.Nome">
                 {{template.Nome}}
               </mat-option>
             </mat-select>

           </mat-form-field>

  <mat-error *ngIf="numberFormControl.hasError('pattern')"> <!-- && !numberFormControl.hasError('required')"> -->
    Inserisci un numero valido
  </mat-error>

   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

     <ng-container matColumnDef="Nometemplate">
         <th mat-header-cell *matHeaderCellDef> Template
           <img src="./assets/images/sort.png" (click)="manageClick('Nometemplate')"/>
         </th>
         <td mat-cell *matCellDef="let anchor"> {{anchor.Nometemplate}} </td>
     </ng-container>

      <ng-container matColumnDef="Tipo">
          <th mat-header-cell *matHeaderCellDef> Tipo
            <img src="./assets/images/sort.png" (click)="manageClick('Tipo')"/>
          </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Tipo}} </td>
      </ng-container>

      <ng-container matColumnDef="Testo">
          <th mat-header-cell *matHeaderCellDef> Testo
            <img src="./assets/images/sort.png" (click)="manageClick('Testo')"/>
          </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Testo}} </td>
      </ng-container>

      <ng-container matColumnDef="Duecifre">
          <th mat-header-cell *matHeaderCellDef> Due cifre </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Duecifre}} </td>
      </ng-container>

      <ng-container matColumnDef="X1">
          <th mat-header-cell *matHeaderCellDef> X1 </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.X1}} </td>
      </ng-container>

      <ng-container matColumnDef="Y1">
          <th mat-header-cell *matHeaderCellDef> Y1 </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Y1}} </td>
      </ng-container>

      <ng-container matColumnDef="X2">
          <th mat-header-cell *matHeaderCellDef> X2 </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.X2}} </td>
      </ng-container>

      <ng-container matColumnDef="Y2">
          <th mat-header-cell *matHeaderCellDef> Y2 </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Y2}} </td>
      </ng-container>

      <ng-container matColumnDef="Xoffset">
          <th mat-header-cell *matHeaderCellDef> Xoffset </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Xoffset}} </td>
      </ng-container>

      <ng-container matColumnDef="Yoffset">
          <th mat-header-cell *matHeaderCellDef> Yoffset </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Yoffset}} </td>
      </ng-container>

      <ng-container matColumnDef="Xdim">
          <th mat-header-cell *matHeaderCellDef> Xdim </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Xdim}} </td>
      </ng-container>

      <ng-container matColumnDef="Ydim">
          <th mat-header-cell *matHeaderCellDef> Ydim </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Ydim}} </td>
      </ng-container>

      <ng-container matColumnDef="X1v">
          <th mat-header-cell *matHeaderCellDef> X1v </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.X1v}} </td>
      </ng-container>

      <ng-container matColumnDef="Y1v">
          <th mat-header-cell *matHeaderCellDef> Y1v </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Y1v}} </td>
      </ng-container>

      <ng-container matColumnDef="X2v">
          <th mat-header-cell *matHeaderCellDef> X2v </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.X2v}} </td>
      </ng-container>

      <ng-container matColumnDef="Y2v">
          <th mat-header-cell *matHeaderCellDef> Y2v </th>
          <td mat-cell *matCellDef="let anchor"> {{anchor.Y2v}} </td>
      </ng-container>

      <ng-container matColumnDef="Modifica">
        <th mat-header-cell *matHeaderCellDef> Modifica </th>
        <td mat-cell *matCellDef="let anchor">
          <mat-icon color="primary" (click)="editAnchor(anchor)">create</mat-icon>
       </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</form>
</mat-card-content>
<mat-card-actions>
  <button mat-raised-button  color="primary" (click)="newAnchor()">Nuova ancora</button>
  <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
</mat-card-actions>



</mat-card>
