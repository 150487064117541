import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Gitem } from '../../models/gitem';
import { Progettoattivita } from '../../models/progettoattivita';
import {FormGroup, FormControl, FormBuilder} from '@angular/forms';

@Component({
  selector: 'ganttitem',
  templateUrl: './ganttitem.component.html',
  styleUrls: ['./ganttitem.component.css']
})

export class GanttitemComponent implements OnInit{
  @Input() progettoattivita:Progettoattivita=new Progettoattivita();
//  @Input() text: string;
//  @Output() navigate = new EventEmitter();
//  @Output() Datainizio: string;
  @Output() range:FormGroup;
  // = new FormGroup({
  //  Datainizio: new FormControl<Date | null>(null),
  //  Datafine: new FormControl<Date | null>(null),
  //});
//  @Output() onNavigazioneEvent = new EventEmitter<Materiale>();
  currentStato:string;
  stati=[
    { Nome:'Da iniziare' },
    { Nome:'In corso' } ,
    { Nome:'Completata' },
    { Nome:'Sospesa' }
  ];
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.range = this.formBuilder.group({
      Datainizio: [''],
      Datafine: [''],
    })
    if (this.progettoattivita.Datainizio!=undefined && this.progettoattivita.Datafine){
      this.range.get('Datainizio').patchValue(this.formatDate(this.progettoattivita.Datainizio));
      this.range.get('Datafine').patchValue(this.formatDate(this.progettoattivita.Datafine));
    }


 }
 private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
}
export interface IGanttitemComponent {

}
