import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { PersonaData } from '../../models/personadata';
import { Persona } from '../../models/persona';
import { User } from '../../models/user';

@Component({
  selector: 'app-addpersonatouser',
  templateUrl: './addpersonatouser.component.html',
  styleUrls: ['./addpersonatouser.component.css']
})
export class AddPersonaToUserComponent implements OnInit {
  IPAddress:string;
  Port:string;
  personaList: Array<Persona>;
  currentUser: User;
  selectedPersona: Persona;
  constructor(public dialogRef: MatDialogRef<AddPersonaToUserComponent>,
    private commonService: CommonService, private httpClient: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: PersonaData) {
      this.personaList=this.data.personaList;
      this.currentUser=this.data.user;
    }

    ngOnInit(): void {
      if (environment.address.isLocal){
        this.IPAddress=environment.address.localIP;
        this.Port=environment.address.localPort;
      }else{
        this.IPAddress=environment.address.remoteIP;
        this.Port=environment.address.remotePort;
      }
  }
  removePersonaFromUser(){
    this.currentUser.Codicepersona=0;
    this.currentUser.Firstname='';
    this.currentUser.Lastname='';
    this.currentUser.Fiscalcode='';
    this.updateUser();
  }

  addPersonaToUser(){
    const formData = new FormData();
      this.currentUser.Codicepersona=this.selectedPersona.Codice;
      this.updateUser();
  }

  updateUser(){
//    alert(this.currentUser.Codice+" "+this.currentUser.Codicepersona)
    this.commonService.addPersonaToUser(this.currentUser).subscribe(data => {
      let rc;
      rc=JSON.stringify(data)
      console.log("-->"+rc);
      if (rc!=-1){
  //      alert("Materiale salvato con successo");
      }else{
        alert("Errore salvataggio persona");
      }
    });
  }
}
