<div class="master-container">
  <div class="titolo"> Contenuto del corso </div>
  <div *ngIf="mimetype.includes('pdf')">
    <table>
      <tr>
        <!--
        <td>
          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">{{getProperyValue("labelMateriali")}}</mat-label>
            <mat-select [(ngModel)]="selectedValue" (ngModelChange)="manageChangeMateriale($event)" name="materiale" class="text-sm">
              <mat-option *ngFor="let materiale of materiali" [value]="materiale.Codice">
                {{materiale.Name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        -->
          <td>
            <div *ngIf="pdfData" class="container-fluid h-100 w-50">
              <button mat-mini-fab (click)="previousPage()">
                  <mat-icon>chevron_left</mat-icon>
              </button>
              <button mat-mini-fab (click)="nextPage()">
                  <mat-icon>chevron_right</mat-icon>
              </button>
              <button mat-mini-fab (click)="plusZoom()">
                  <mat-icon>zoom_in</mat-icon>
              </button>
              <button mat-mini-fab (click)="minusZoom()">
                  <mat-icon>zoom_out</mat-icon>
              </button>
              <button mat-mini-fab (click)="rotatedx()">
                  <mat-icon>rotate_right</mat-icon>
              </button>
              <button mat-mini-fab (click)="rotatesx()">
                  <mat-icon>rotate_left</mat-icon>
              </button>
            </div>
          </td>
        </tr>
      </table>
      <div class="container-fluid w-75">

        <pdf-viewer   [src]="pdfData"
                      [show-all]="false"
                      [(page)]="pageVariable"
                      [render-text]="true"
                      [zoom]="zoom"
                      [rotation]="angle"
                      [show-borders]="true"
                      (error)="errorTest($event)"
                      [original-size]="false"
                      (after-load-complete)="afterLoadComplete($event)"
                      (page-rendered)="pageRendered($event)"
                      (text-layer-rendered)="textLayerRendered($event)"
                      style="width=1000px; height: 750px">
          </pdf-viewer>

        </div>
    </div>
    <div> <!--class="container-fluid w-75"
          <!--
          <input type="text" [ngModel]="filepath" (ngModelChange)="valueChanged($event)">
          <button mat-raised-button  color="primary" (click)="loadVideo()">Load</button>

          <video type='video/mp4' *ngIf="mimetype.includes('video')" width="1000px" height="750px" src="{{srcvideo}}" controls></video>
-->
          <video controls (canplay)="pauseVideo(videoPlayer)" height="600"
            #videoPlayer>
          <source src="{{srcvideo}}" type="video/mp4">
            Your browser does not support the video tag.
          </video>

    </div>



<!--

<pdf-viewer [src]="pdfSrc"
     id="pdf-report-id"
     [show-all]="true"
     [(page)]="pageVariable"
     [render-text]="true"
     [zoom]="zoom"
     [rotation]="angle"
     [show-borders]="true"
     (error)="errorTest($event)"
     [original-size]="false"
     (after-load-complete)="afterLoadComplete($event)"
     (page-rendered)="pageRendered($event)"
     (text-layer-rendered)="textLayerRendered($event)"></pdf-viewer>




      <div id="outerContainer">
        <div class="pdf-container">
          <pdf-viewer

          ></pdf-viewer>
        </div>
      </div>

      <p *ngIf="isLoading">Caricamento...</p>
      <div class="viewer" id="pdfContainer"></div>

     <div>
        <iframe [src]="urlSafe" class="viewer" frameborder="0"></iframe>
      </div>

      <ngx-doc-viewer url="https://file-examples-com.github.io/uploads/2017/08/file_example_PPT_250kB.ppt" viewer="url"
        style="width:100%;height:93vh;">
      </ngx-doc-viewer>

  <ngx-doc-viewer [url]="pdfSrc" viewer="google"  style="width:100%;height:93vh;"
  (loaded)="contentLoaded()">
  <div>Loading</div>
  </ngx-doc-viewer>
-->


</div>
