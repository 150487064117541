import {Component, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from './service/authentication.service';
import { CommonService } from "./service/common.service";
import { EventEmitterService } from './service/event-emitter.service';
import { Istanza } from "./models/istanza";
import { MatDialog } from '@angular/material/dialog';
import { CambiapasswordComponent } from './dialog/cambiapassword/cambiapassword.component';
import {Master} from './models/master';
import {MenuItem} from './models/menuitem';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
//  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  @ViewChild('drawer') drawer;

  isLoggedIn$: Observable<boolean>;
  isOpen$: Observable<boolean>;
  title: any;
  private rolename:string;
  private drawerIsVisible:boolean;
  searchtext:string;
  username:string;
  items: MenuItem[] = [];
  masterList: Master[] = [];
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  isSidenavOpened: boolean=false;

  constructor(public loginService:AuthenticationService,
    private commonService: CommonService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private dialog: MatDialog) {
      console.log("START");
      console.log("-- "+router.parseUrl(router.url));
  }

  ngOnInit(): void {
    console.log("INIT");
    sessionStorage.clear();
    this.commonService.currentUser=null;
    this.commonService.currentRuolo=null;

  //  this.isLoggedIn$ = this.authService.isLoggedIn;
  //  this.isOpen$ = this.sidevanService.isSideNavOpen;
/*
  console.log("INIT CONTENT");
  if (this.eventEmitterService.subsVar2===undefined) {
    this.eventEmitterService.subsVar2 = this.eventEmitterService.
    invokeFirstComponentFunction.subscribe((name:string) => {
      console.log("SUBSCRIBE Content");
      this.retrieveData();
      //this.drawer.toggle();
      // alert('Toggle drawer');
      //this.firstFunction();
      this.commonService.getRuolo(this.username).subscribe(
        (response) => {
          this.commonService.currentRuolo=response;
          this.rolename=this.commonService.currentRuolo.Nome;
          sessionStorage.setItem("rolename", this.rolename);
          console.log("USER:"+this.username);
          console.log("ROLE:"+this.rolename);


          this.commonService.getMenu().subscribe((res) => {

          this.items = res;
        });
      });

    });

  }
*/
  console.log("INIT HEADER");
  if (this.eventEmitterService.subsVar===undefined) {
    console.log("Undefined emitter service");
    this.eventEmitterService.subsVar = this.eventEmitterService.invokeOnLogin.subscribe((name:string) => {
      console.log("OK LOGIN RECEIVED");
      this.username=sessionStorage.getItem("username");
      this.commonService.getUser(this.username).subscribe((u) => {
        if (u!=undefined && u!=null && u.Codiceistanza!=0){
          this.commonService.currentIstanza=new Istanza();
          this.commonService.currentIstanza.Codice=u.Codiceistanza;
          this.commonService.currentIstanza.Nome=u.Nomeistanza;
          this.commonService.getMenu().subscribe((res) => {
              this.items = res;
              this.commonService.getRuolo(this.username).subscribe((response) => {
                this.commonService.currentRuolo=response;
                this.rolename=this.commonService.currentRuolo.Nome;
                this.commonService.currentAziendaCodice=this.commonService.currentRuolo.Codiceazienda;
                sessionStorage.setItem("rolename", this.rolename);
                console.log("Ruolo:"+this.rolename);
                console.log("Codice istanza="+this.commonService.currentIstanza.Codice);
                console.log("Nome istanza="+this.commonService.currentIstanza.Nome);
                if (this.commonService.currentIstanza.Codice === 1){
                    if (this.rolename==='Courses'){
                      this.router.navigate(['courseshome']);
                    }else{
                      this.router.navigate(['masters']);
                    }
                }else if (this.commonService.currentIstanza.Codice === 2){
                    this.router.navigate(['lul']);
                }else if (this.commonService.currentIstanza.Codice === 3){
                    this.router.navigate(['persone']);
                }else if (this.commonService.currentIstanza.Codice === 4){
                    this.router.navigate(['persone']);
                }else if (this.commonService.currentIstanza.Codice === 5){
                    this.router.navigate(['iossa']);
                }else{
                    this.router.navigate(['dthink']);
                }
              });  // getRuolo
            }); // getMenu
          }
        }); // getUser
      });
    }
    if (this.eventEmitterService.subsVar3===undefined) {
      this.eventEmitterService.subsVar3 = this.eventEmitterService.
      invokeOnLogout.subscribe((name:string) => {
        console.log("SUBSCRIBE OnLogout");
        this.logoutFunction();
      });
  }

  if (this.commonService.currentIstanza===undefined){
      console.log("Istanza non definita");
    }else{
      console.log("Istanza:"+this.commonService.currentIstanza.Codice);
    }
  }

  getRole(){
    console.log("GET ROLE");
    this.commonService.getRuolo(this.username).subscribe(
      response => this.handleSuccessfulResponse(response)
    );
  }

  getUsername():string{
    console.log("USERNAME:"+this.username);
    return sessionStorage.getItem('username');
  }


  handleSuccessfulResponse(response) {
    this.commonService.currentRuolo=response;
    //console.log(this.role);
    this.rolename=this.commonService.currentRuolo.Nome;
    sessionStorage.setItem("rolename", this.rolename);
    console.log("ROLE:"+this.rolename);
  }

  onDrawerClick(){
    this.drawer.toggle();
    this.drawerIsVisible=!this.drawerIsVisible;
    //console.log("EMIT DRAWER CLICK");
    //this.eventEmitterService.onFirstComponentButtonClick();
  }


cambiaPassword(){
    const dialogRef = this.dialog.open(CambiapasswordComponent, {
      width: '450px',
      data: {title:'Cambia password',username:sessionStorage.getItem("username")}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){

      }
    });
}

cerca(){

}

entra(){
   this.router.navigate(['login']);
}

showvideoapp(){
   this.router.navigate(['videoapp']);
}

esci(){
   this.drawerIsVisible=false;
   this.eventEmitterService.onLogout();

//   if (this.drawerIsVisible){
//      this.onDrawerClick();
//      this.drawerIsVisible=false;
//   }
   this.router.navigate(['logout']);
 }

 getCurrentIstanza(){
   if (this.commonService.currentIstanza!=undefined){
     return this.commonService.currentIstanza.Codice;
   }else{
     return -1;
   }
 }

 public getFooterText(): String{
   if (this.commonService.currentIstanza!=undefined){
     if (this.commonService.currentIstanza.Codice===1){
       return "Antur Academy";
     }else if (this.commonService.currentIstanza.Codice===4){
       return "FBService";
     }else{
      return "";
     }
   }else{
     return "dThink S.r.l.: Via Dardanelli, 15 – 00195 Roma - C.F. E P.IVA 13326111005";
   }
 }

 retrieveData(): void {
   console.log("Retrieve data");
//    this.commonService.getMenu(sessionStorage.getItem("username")).subscribe((res) => {
   this.commonService.getMenu().subscribe((res) => {
       this.items = res;
     }
 );
 /*
 this.commonService.getMaster(sessionStorage.getItem("username")).subscribe((res) => {
   this.masterList=res;
   //  sessionStorage.setItem("masterList", this.masterList);
 });
 */
}

firstFunction() {
   this.retrieveData();
   this.drawer.toggle();
   // alert('Toggle drawer');
   }

 logoutFunction() {
     this.drawer.close();
     // alert('Toggle drawer');
 }

 mouseenter() {
   if (!this.isExpanded) {
     this.isShowing = true;
   }
 }

 mouseleave() {
  if (!this.isExpanded) {
    this.isShowing = false;
  }
 }

 goto(contesto:string) {

  this.router.navigate([contesto]);
 }

}
