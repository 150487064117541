<!--<pdf-viewer [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              style="width: 400px; height: 500px"
  ></pdf-viewer>

<app-visualizzatore>
</app-visualizzatore>
-->
<div class="firstContainer" >
<!-- Inizio parte header -->
      <div mat-icon-button *ngIf="getCurrentIstanza()>0" class="header">
            <div>
              <button mat-icon-button *ngIf="loginService.isUserLoggedIn()" (click)="onDrawerClick()">
               <mat-icon color="accent" class="white-icon">menu</mat-icon>
              </button>
            </div>
            <div *ngIf="getCurrentIstanza()===1">
                <img src="assets/images/logoAntur-60x60.png">
                <span class="brand">Antur Academy</span>
            </div>
            <div  *ngIf="getCurrentIstanza()===2">
                <img src="assets/images/logoAntur-60x60.png">
                <span class="brand">Antur</span>
            </div>
            <div *ngIf="getCurrentIstanza()===4">
                <img src="assets/images/fbserviceLogo-60x60.png">
                <span class="brand">FB Service</span>
            </div>
            <div *ngIf="getCurrentIstanza()===5">
                <img src="assets/images/iossaLogo-60x60.png">
                <span class="brand">Iossa</span>
            </div>

            <div class="example-spacer"></div>
    <!--    <button *ngIf="!loginService.isUserLoggedIn()" mat-raised-button  color="primary" (click)="entra()">Entra</button>
-->
            <div *ngIf="loginService.isUserLoggedIn()">
              <span class="user">{{getUsername()}}</span>

                <button mat-icon-button *ngIf="loginService.isUserLoggedIn()" [matMenuTriggerFor]="menu">
                <mat-icon color="accent" class="white-icon">account_circle</mat-icon>
                </button>

              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <mat-icon>security</mat-icon>
                  <a mat-button (click)="cambiaPassword()" class="nav-link">Cambia password</a>
                  </button>
                  <button mat-menu-item>
                  <mat-icon>exit_to_app</mat-icon>
                  <a mat-button (click)="esci()" class="nav-link">Esci</a>
                  </button>
              </mat-menu>
            </div>
      </div>

<!-- Fine parte header -->
<!-- Inizio parte contenuti -->

      <div class="content" >
        	  <mat-drawer-container hasBackdrop=false  class="drawer-container">
        	    <mat-drawer #drawer mode="side" class="drawer">
        	        <mat-list>
        	    <!--      <a class="active">Amministrazione</a> -->
        	          <mat-list-item *ngFor="let item of items">
                      <button mat-menu-item (click)="goto(item.Contesto)">
                        <mat-icon *ngIf="item.Icona!=undefined">{{item.Icona}}</mat-icon>
                        <span>{{item.Nome}}</span>
                      </button>


            	<!--           <button mat-menu-item (click)="goto(item.Contesto); drawer.toggle()">{{item.Nome}}</button>-->
       	          </mat-list-item>
        		<!--           <a class="active">Link utili</a>
        	          <mat-list-item class="listitem">
                       <a matLine href="https://www.dthink.it/" target="_blank" >dThink</a>
        	          </mat-list-item>-->
       	        </mat-list>
        	  	</mat-drawer>
        	    <mat-drawer-content>
        	  		  <router-outlet></router-outlet>
        	    </mat-drawer-content>
        	  </mat-drawer-container>
      </div>

<!-- Fine parte contenuti -->

  <!--    <div class="footer" fxLayoutAlign="space-around center">{{getFooterText()}}</div>-->
</div>
