  <mat-card>
      <mat-card-header>
          <mat-card-title>Dettaglio progetto</mat-card-title>
      </mat-card-header>
      <mat-card-content class="content">

        <form [formGroup]="registerForm" (ngSubmit)="save()">

          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">Sede</mat-label>
            <mat-select formControlName="Codicesede" (selectionChange)="manageChangeSede(registerForm.value.Codicesede)" class="text-sm">
              <mat-option></mat-option>
              <mat-option *ngFor="let sede of sedi" [value]="sede.Codice">
                {{sede.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">Stato</mat-label>
            <mat-select formControlName="Stato" class="text-sm">
              <mat-option></mat-option>
              <mat-option *ngFor="let stato of stati" [value]="stato.Nome">
                {{stato.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Nome</mat-label>
            <input matInput placeholder="Nome" formControlName="Nome" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Descrizione</mat-label>
            <input matInput placeholder="Descrizione" formControlName="Descrizione">
          </mat-form-field>

          <mat-form-field class="example-full-width" *ngIf="false">
            <mat-label class="text-sm">Codice</mat-label>
            <input matInput placeholder="Codice" formControlName="Codice">
          </mat-form-field>


        </form>
      </mat-card-content>
      <mat-card-actions>
        <table>
          <tr>
            <td>
              <button mat-raised-button color="primary" (click)="save()">Salva</button>
            </td>
            <td>
              <button mat-raised-button color="primary" (click)="cancel()">Cancella</button>
            </td>
          </tr>
        </table>
      </mat-card-actions>

  </mat-card>
