  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista attività</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->


  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let attivita"> {{attivita.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Descrizione">
          <th mat-header-cell *matHeaderCellDef> Descrizione
            <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
          </th>
          <td mat-cell *matCellDef="let attivita"> {{attivita.Descrizione}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Modifica </th>
          <td mat-cell *matCellDef="let attivita">
            <mat-icon color="primary" (click)="editAttivita(attivita)">create</mat-icon>
          </td>
      </ng-container>
<!--
      <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Elimina </th>
          <td mat-cell *matCellDef="let user"><button class="btn btn-danger" (click)="deleteUser(user)">Elimina</button> </td>
      </ng-container>
-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

<!-- </form> -->
</mat-card-content>
<mat-card-actions>
  <table>
    <tr>
      <td>
        <button mat-raised-button  color="primary" (click)="newAttivita()">Nuova attivita</button>
      </td>
      <td>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </td>
    </tr>
  </table>
</mat-card-actions>

</mat-card>
