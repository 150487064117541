<mat-card>
    <mat-card-header>
        <mat-card-title>Dettaglio Ruolo</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
      <form [formGroup]="registerForm" (ngSubmit)="save()">

           <mat-form-field appearance="fill">
             <mat-label class="text-sm">Istanza</mat-label>
              <mat-select formControlName="selectedValue" name="istanza" (selectionChange)="manageChangeIstanza()" class="text-sm">
               <mat-option *ngFor="let istanza of istanze" [value]="istanza.Nome">
                 {{istanza.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Nome</mat-label>
             <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
           </mat-form-field>

           <mat-form-field class="example-full-width">
             <mat-label class="text-sm">Livello</mat-label>
             <input matInput placeholder="Livello" formControlName="Livello" name="Livello">
           </mat-form-field>

           <mat-form-field class="example-full-width" [hidden]="true">
             <input matInput formControlName="Codice">
           </mat-form-field>

           <mat-form-field class="example-full-width" [hidden]="true">
             <input matInput formControlName="Codiceistanza">
           </mat-form-field>

      </form>

    </mat-card-content>
    <mat-card-actions>
      <table>
         <tr>
          <td>
            <button mat-raised-button  color="primary" (click)="save()">Salva</button>
          </td>
          <td>
            <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
          </td>
        </tr>
      </table>
    </mat-card-actions>

</mat-card>
