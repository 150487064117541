import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { MenuItem } from '../../models/menuitem';
import { Istanza } from '../../models/istanza';
import { Ruolo } from '../../models/ruolo';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {


  IPAddress:string;
  Port:string;
  Protocol: string;
  selectedValueIstanza:string;
  selectedValueRuolo:string;
  istanze: Istanza[];
  ruoli: Ruolo[]=[];
  currentIstanza:Istanza;
  currentRuolo:Ruolo;
//  exporting: boolean=false;
//  grouping: boolean=false;
  displayedColumns: string[] =
  ["Istanza",
  "Nome",
  "Livello",
  "Modifica"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();

  spinnervalue = 100;
//  analizing = false;
  busy = false;

  dataSource = new MatTableDataSource<Ruolo>(this.ruoli);
  constructor(private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog) {}

  ngOnInit() {

    this.sortedDirection.set("Istanza",true);
    this.sortedDirection.set("Nome",true);
    this.sortedDirection.set("Livello",true);

    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    //  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

    this.commonService.getAdminIstanze().subscribe((res) => {
      this.istanze = res;
      this.commonService.getAdminRoles().subscribe((res1) => {
        this.ruoli = res1;
        this.aggiornaLista();
      });
    });


  }

  getNomeByCodiceaistanza(codiceistanza: number):String{
    let nomeistanza:String="";
    this.ruoli.forEach(element => {
      if (element.Codice === codiceistanza){
        nomeistanza=element.Nome;
        return;
      }
    });
    return nomeistanza;
  }

  manageChangeIstanza(){
    if (this.selectedValueIstanza==undefined){
     return;
    }
    this.istanze.forEach(element => {
      if (this.selectedValueIstanza === element.Nome){
        this.currentIstanza=element;
        this.commonService.getRuoliByIstanza(this.currentIstanza.Codice).subscribe((res2) => {
            this.ruoli = res2;
            this.aggiornaLista();
        });
      }
    });
  }



  cancel(){
    this.selectedValueIstanza=undefined;
    this.commonService.getAdminRoles().subscribe((res1) => {
      this.ruoli = res1;
      this.aggiornaLista();
    });
  }



  aggiornaLista(){
    //console.log("Aggiorna lista");

       this.refreshLista(this.ruoli);
    }

   refreshLista(listaruoli:Ruolo[]){
     this.ruoli=listaruoli;
     this.dataSource = new MatTableDataSource<Ruolo>(this.ruoli);
   }
   manageClick(attributo: string){
     let tempList:Ruolo[];
     tempList=this.ruoli;
     let direction=this.sortedDirection.get(attributo);

     tempList.sort((a, b) => {
        var typeA = "";
        var typeB = "";
        if (a[attributo]!=null){
          if (isNaN(b[attributo]))
             typeA = a[attributo].toLowerCase();
          else
             typeA = a[attributo].toString();
        }
        if (b[attributo]!=null){
          if (isNaN(b[attributo]))
             typeB = b[attributo].toLowerCase();
           else
             typeB = b[attributo].toString();
        }
          if (direction){
            if (typeA > typeB){
              return -1;
            }else if (typeA < typeB){
               return 1;
            }else{
              return 0;
            }
          }else{
            if (typeA < typeB){
              return -1;
            }else if (typeA > typeB){
               return 1;
            }else{
              return 0;
            }
          }
      });
      direction=!direction;
      this.sortedDirection.set(attributo,direction);

      this.dataSource = new MatTableDataSource<Ruolo>(this.ruoli);

    }
    new():void{
      this.commonService.selectedRuolo=undefined;
      this.router.navigate(['addrole']);
    }

    edit(ruolo: Ruolo){
      this.commonService.selectedRuolo=ruolo;
      this.router.navigate(['addrole']);
    }

}
