<mat-card>
    <mat-card-header>
        <mat-card-title>Crea cartella</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <table class="example-full-width" cellspacing="0">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Nome cartella" [(ngModel)]="foldername" name="foldername" required>
                        </mat-form-field>
                    </td>
                </tr>

                <p *ngIf="error" class="error">
                    {{ error }}
                </p>
            </table>
        </form>

    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button (click)="salva()" color="primary">Salva</button>
        <button mat-raised-button (click)="cancella()" color="primary">Cancella</button>
    </mat-card-actions>

</mat-card>
