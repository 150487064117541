  <mat-card>
      <mat-card-header>
          <mat-card-title>Dettaglio persona</mat-card-title>
      </mat-card-header>
      <mat-card-content class="content">

        <form [formGroup]="registerForm" (ngSubmit)="save()">

                 <mat-form-field appearance="fill">
                   <mat-label class="text-sm">Azienda</mat-label>
                   <mat-select formControlName="selectedValueAzienda" name="azienda" (selectionChange)="manageChangeAzienda()" class="text-sm" required>
                     <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
                       {{azienda.Nome}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>
                 <mat-form-field [style.display]="getConfigurazione().Nomesede" appearance="fill">
                   <mat-label class="text-sm">Cantiere</mat-label>
                   <mat-select formControlName="selectedValueSede" name="sede" class="text-sm">
                     <mat-option></mat-option>
                     <mat-option *ngFor="let sede of sedi" [value]="sede.Nome">
                       {{sede.Nome}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>
                 <mat-form-field [style.display]="getConfigurazione().Nomestruttura" appearance="fill">
                   <mat-label class="text-sm">Cliente</mat-label>
                   <mat-select formControlName="selectedValueStruttura" name="struttura" class="text-sm">
                     <mat-option></mat-option>
                     <mat-option *ngFor="let struttura of strutture" [value]="struttura.Nome">
                       {{struttura.Nome}}
                     </mat-option>
                   </mat-select>
                 </mat-form-field>



                  <mat-form-field [style.display]="getConfigurazione().Nome" class="example-full-width">
                    <mat-label class="text-sm">Nome</mat-label>
                    <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Cognome" class="example-full-width">
                    <mat-label class="text-sm">Cognome</mat-label>
                    <input matInput placeholder="Cognome" formControlName="Cognome" name="Cognome" required>
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Codicefiscale" class="example-full-width">
                    <mat-label class="text-sm">Codice fiscale</mat-label>
                    <input matInput placeholder="Codice fiscale" formControlName="Codicefiscale" name="Codicefiscale" required>
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Pec" class="example-full-width">
                    <mat-label class="text-sm">PEC</mat-label>
                    <input matInput placeholder="Pec" formControlName="Pec" name="Pec">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Matricola" class="example-full-width">
                    <mat-label class="text-sm">Matricola</mat-label>
                    <input matInput placeholder="Matricola" formControlName="Matricola" name="Matricola">
                  </mat-form-field>


                  <mat-form-field [style.display]="getConfigurazione().Datanascita" class="example-full-width">
                    <mat-label class="text-sm">Data di nascita</mat-label>
                    <input matInput placeholder="Data di nascita" formControlName="Datanascita" name="Datanascita">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Dataassunzione" class="example-full-width">
                    <mat-label class="text-sm">Data di assunzione</mat-label>
                    <input matInput placeholder="Data di assunzione" formControlName="Dataassunzione" name="Dataassunzione">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Datalicenziamento" class="example-full-width">
                    <mat-label class="text-sm">Data di licenziamento</mat-label>
                    <input matInput placeholder="Data di licenziamento" formControlName="Datalicenziamento" name="Datalicenziamento">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Sesso" class="example-full-width">
                    <mat-label class="text-sm">Sesso</mat-label>
                    <input matInput placeholder="Sesso" formControlName="Sesso" name="Sesso">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Cittanascita" class="example-full-width">
                    <mat-label class="text-sm">Citta di nascita</mat-label>
                    <input matInput placeholder="Citta di nascita" formControlName="Cittanascita" name="Cittanascita">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Provincianascita" class="example-full-width">
                    <mat-label class="text-sm">Provincia di nascita</mat-label>
                    <input matInput placeholder="Provincia di nascita" formControlName="Provincianascita" name="Provincianascita">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Nazione" class="example-full-width">
                    <mat-label class="text-sm">Nazione</mat-label>
                    <input matInput placeholder="Nazione" formControlName="Nazione" name="Nazione">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Citta" class="example-full-width">
                    <mat-label class="text-sm">Città</mat-label>
                    <input matInput placeholder="Citta" formControlName="Citta" name="Citta">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Provincia" class="example-full-width">
                    <mat-label class="text-sm">Provincia</mat-label>
                    <input matInput placeholder="Provincia" formControlName="Provincia" name="Provincia">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Residenza" class="example-full-width">
                    <mat-label class="text-sm">Residenza</mat-label>
                    <input matInput placeholder="Residenza" formControlName="Residenza" name="Residenza">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Cap" class="example-full-width">
                    <mat-label class="text-sm">CAP</mat-label>
                    <input matInput placeholder="CAP" formControlName="Cap" name="Cap">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Telefono" class="example-full-width">
                    <mat-label class="text-sm">Telefono</mat-label>
                    <input matInput placeholder="Telefono" formControlName="Telefono" name="Telefono">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Codice" class="example-full-width">
                    <mat-label class="text-sm">Codice</mat-label>
                    <input matInput placeholder="Codice" formControlName="Codice" name="Codice">
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Tipopersona" appearance="fill">
                    <mat-label class="text-sm">Tipo persona</mat-label>
                    <mat-select formControlName="selectedValueTipopersona" name="tipopersona" class="text-sm">
                      <mat-option *ngFor="let tipopersona of tipipersona" [value]="tipopersona.Nome">
                        {{tipopersona.Nome}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field [style.display]="getConfigurazione().Tipocontratto" appearance="fill">
                    <mat-label class="text-sm">Tipo contratto</mat-label>
                    <mat-select formControlName="selectedValueTipocontratto" name="tipocontratto" class="text-sm">
                      <mat-option *ngFor="let tipocontratto of tipicontratto" [value]="tipocontratto.Nome">
                        {{tipocontratto.Nome}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>



        </form>
      </mat-card-content>
      <mat-card-actions>
        <table>
          <tr>
            <td>
              <button mat-raised-button  color="primary" (click)="save()">Salva</button>
            </td>
            <td>
              <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
            </td>
          </tr>
        </table>
      </mat-card-actions>

  </mat-card>
