import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Notaspese } from '../../models/notaspese';
import { Ricevuta } from '../../models/ricevuta';
import { Statons } from '../../models/statons';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { configurazione } from '../../../configurazioni/configurazione';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ricevute',
  templateUrl: './ricevute.component.html',
  styleUrls: ['./ricevute.component.css']
})
export class RicevuteComponent implements OnInit {

  IPAddress:string;
  Port:string;
  Protocol: string;

  currentNotaspese:Notaspese;
  ricevute:Ricevuta[]=[];
  rolename:string;
  displayedColumns: string[] = [
  "Giustificativo",
  "Data",
  "Importo",
  "Immagine"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();


  retrievedImage: any;
  base64Data: any;
  retrieveResonse: any;

  dataSource = new MatTableDataSource<Ricevuta>(this.ricevute);

  constructor(private httpClient: HttpClient, private router: Router, private dialog: MatDialog, private commonService: CommonService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.sortedDirection.set("Giustificativo",true);
    this.sortedDirection.set("Data",true);
    this.sortedDirection.set("Importo",true);
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }


    this.rolename=sessionStorage.getItem("rolename");
    this.aggiornaLista();


  }

  cancel(){
    this.router.navigate(['notespese']);
  }
  vediImmagine(ricevuta:Ricevuta): any {
    this.base64Data=undefined;
    this.retrievedImage=undefined;
    this.httpClient.get(this.Protocol+'://'+this.IPAddress+':'+this.Port+'/api/ricevute/picture/' + ricevuta.Codice)
      .subscribe(
        res => {
          this.retrieveResonse = res;
          if (this.retrieveResonse!=null && this.retrieveResonse.Picbyte!=null){
            this.base64Data = this.retrieveResonse.Picbyte;
            this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
          }
        }
      );
  }
  aggiornaLista(){
    this.commonService.getAllRicevuteByCodiceNotaspese(this.commonService.currentNotaspese.Codice).subscribe((res) => {
        this.ricevute = res;
        this.dataSource = new MatTableDataSource<Ricevuta>(this.ricevute);
    });
  }

  getConfigurazione(){
    return configurazione.persona.fb;
  }

  ngDoCheck(){
  //  alert("ngDoCheck");
  }
  ngAfterContentInit(){
  //  alert("ngAfterContentInit");
  }

  ngAfterContentChecked(){
//    alert("ngAfterContentChecked");
  }
  ngAfterViewInit(){
//    alert("ngAfterViewInit");
  }
  ngAfterViewChecked(){
//    alert("ngAfterViewChecked");
  }

  ngOnDestroy(){
    this.commonService.currentNotaspese=undefined;
  }
  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Ricevuta[];
    tempList=this.ricevute;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Ricevuta>(this.ricevute);

   }
}
