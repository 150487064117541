export class Materiale {
  Materialeid: number;
  Codice: number;
  Name: string;
  Type: string;
  Filename: string;
  Descrizione: string;
  Nomelezione: string;
  Abilitato: string;
  Visibile: string;
  Datapubblicazione: Date;
  Mimetype: string;
  Codicelezione: number;
  constructor(){
    this.Materialeid = 1;
    this.Codice = 1;
    this.Name = '/';
    this.Type = '';
    this.Filename = '';
    this.Descrizione = '';
    this.Nomelezione = '';
    this.Abilitato = 'YES';
    this.Visibile = 'YES';
    this.Datapubblicazione = undefined;
    this.Mimetype = '';
    this.Codicelezione = 0;
  
  }
}
