  <mat-card>
      <mat-card-header>
          <mat-card-title>Dettaglio cliente</mat-card-title>
      </mat-card-header>
      <mat-card-content class="content">

        <form [formGroup]="registerForm" (ngSubmit)="save()">

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Nome</mat-label>
            <input matInput placeholder="Nome" formControlName="Nome" name="Nome" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label class="text-sm">Descrizione</mat-label>
            <input matInput placeholder="Descrizione" formControlName="Descrizione" name="Descrizione">
          </mat-form-field>

          <mat-form-field class="example-full-width" *ngIf="false">
            <mat-label class="text-sm">Codice</mat-label>
            <input matInput placeholder="Codice" formControlName="Codice" name="Codice">
          </mat-form-field>

          <mat-form-field class="example-full-width" *ngIf="false">
            <mat-label class="text-sm">Codice Azienda</mat-label>
            <input matInput placeholder="Codiceazienda" formControlName="Codiceazienda" name="Codiceazienda">
          </mat-form-field>

        </form>
      </mat-card-content>
      <mat-card-actions>
        <table>
          <tr>
            <td>
              <button mat-raised-button color="primary" (click)="save()">Salva</button>
            </td>
            <td>
              <button mat-raised-button color="primary" (click)="cancel()">Cancella</button>
            </td>
          </tr>
        </table>
      </mat-card-actions>

  </mat-card>
