<mat-card class="matcardcontent">
    <mat-card-header>
        <mat-card-title>Lista dipendenti</mat-card-title>
    </mat-card-header>
    <mat-card-content>
<!--      <form>-->


           <mat-form-field appearance="fill" style="padding-right: 10px">
             <mat-label class="text-sm">Azienda</mat-label>
             <mat-select [(ngModel)]="selectedValueAzienda" name="azienda" (selectionChange)="manageChangeAzienda()" class="text-sm">
               <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
                 {{azienda.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>


  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nome">
          <th [style.display]="getConfigurazione().Nome" mat-header-cell *matHeaderCellDef>Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td [style.display]="getConfigurazione().Nome" mat-cell *matCellDef="let persona"> {{persona.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Cognome">
          <th [style.display]="getConfigurazione().Cognome" mat-header-cell *matHeaderCellDef> Cognome
            <img src="./assets/images/sort.png" (click)="manageClick('Cognome')"/>
          </th>
          <td [style.display]="getConfigurazione().Cognome" mat-cell *matCellDef="let persona"> {{persona.Cognome}} </td>
      </ng-container>

      <ng-container matColumnDef="Matricola">
          <th [style.display]="getConfigurazione().Matricola" mat-header-cell *matHeaderCellDef> Matricola
            <img src="./assets/images/sort.png" (click)="manageClick('Matricola')"/>
          </th>
          <td [style.display]="getConfigurazione().Matricola" mat-cell *matCellDef="let persona"> {{persona.Matricola}} </td>
      </ng-container>

      <ng-container matColumnDef="Pec">
          <th [style.display]="getConfigurazione().Pec" mat-header-cell *matHeaderCellDef> PEC
            <img src="./assets/images/sort.png" (click)="manageClick('Pec')"/>
          </th>
          <td [style.display]="getConfigurazione().Pec" mat-cell *matCellDef="let persona"> {{persona.Pec}} </td>
      </ng-container>

      <ng-container matColumnDef="Datanascita">
          <th [style.display]="getConfigurazione().Datanascita" mat-header-cell *matHeaderCellDef> Data nascita
            <img src="./assets/images/sort.png" (click)="manageClick('Datanascita')"/>
          </th>
          <td [style.display]="getConfigurazione().Datanascita" mat-cell *matCellDef="let persona"> {{persona.Datanascita}} </td>
      </ng-container>

      <ng-container matColumnDef="Dataassunzione">
          <th [style.display]="getConfigurazione().Dataassunzione" mat-header-cell *matHeaderCellDef> Data assunzione
            <img src="./assets/images/sort.png" (click)="manageClick('Dataassunzione')"/>
          </th>
          <td [style.display]="getConfigurazione().Dataassunzione" mat-cell *matCellDef="let persona"> {{persona.Dataassunzione}} </td>
      </ng-container>

      <ng-container matColumnDef="Datalicenziamento">
          <th [style.display]="getConfigurazione().Datalicenziamento" mat-header-cell *matHeaderCellDef> Data licenziamento
            <img src="./assets/images/sort.png" (click)="manageClick('Datalicenziamento')"/>
          </th>
          <td [style.display]="getConfigurazione().Datalicenziamento" mat-cell *matCellDef="let persona"> {{persona.Datalicenziamento}} </td>
      </ng-container>

      <ng-container matColumnDef="Sesso">
          <th [style.display]="getConfigurazione().Sesso" mat-header-cell *matHeaderCellDef> Sesso
            <img src="./assets/images/sort.png" (click)="manageClick('Sesso')"/>
          </th>
          <td [style.display]="getConfigurazione().Sesso" mat-cell *matCellDef="let persona"> {{persona.Sesso}} </td>
      </ng-container>

      <ng-container matColumnDef="Cittanascita">
          <th [style.display]="getConfigurazione().Cittanascita" mat-header-cell *matHeaderCellDef> Città nascita
            <img src="./assets/images/sort.png" (click)="manageClick('Cittanascita')"/>
          </th>
          <td [style.display]="getConfigurazione().Cittanascita" mat-cell *matCellDef="let persona"> {{persona.Cittanascita}} </td>
      </ng-container>

      <ng-container matColumnDef="Provincianascita">
          <th [style.display]="getConfigurazione().Provincianascita" mat-header-cell *matHeaderCellDef> Provincia nascita
            <img src="./assets/images/sort.png" (click)="manageClick('Provincianascita')"/>
          </th>
          <td [style.display]="getConfigurazione().Provincianascita" mat-cell *matCellDef="let persona"> {{persona.Provincianascita}} </td>
      </ng-container>

      <ng-container matColumnDef="Codicefiscale">
          <th [style.display]="getConfigurazione().Codicefiscale" mat-header-cell *matHeaderCellDef> Codice fiscale
            <img src="./assets/images/sort.png" (click)="manageClick('Codicefiscale')"/>
          </th>
          <td [style.display]="getConfigurazione().Codicefiscale" mat-cell *matCellDef="let persona"> {{persona.Codicefiscale}} </td>
      </ng-container>

      <ng-container matColumnDef="Citta">
          <th [style.display]="getConfigurazione().Citta" mat-header-cell *matHeaderCellDef> Città
            <img src="./assets/images/sort.png" (click)="manageClick('Citta')"/>
          </th>
          <td [style.display]="getConfigurazione().Citta" mat-cell *matCellDef="let persona"> {{persona.Citta}} </td>
      </ng-container>

      <ng-container matColumnDef="Provincia">
          <th [style.display]="getConfigurazione().Provincia" mat-header-cell *matHeaderCellDef> Provincia
            <img src="./assets/images/sort.png" (click)="manageClick('Provincia')"/>
          </th>
          <td [style.display]="getConfigurazione().Provincia" mat-cell *matCellDef="let persona"> {{persona.Provincia}} </td>
      </ng-container>

      <ng-container matColumnDef="Residenza">
          <th [style.display]="getConfigurazione().Residenza" mat-header-cell *matHeaderCellDef> Residenza
            <img src="./assets/images/sort.png" (click)="manageClick('Residenza')"/>
          </th>
          <td [style.display]="getConfigurazione().Residenza" mat-cell *matCellDef="let persona"> {{persona.Residenza}} </td>
      </ng-container>

      <ng-container matColumnDef="Cap">
          <th [style.display]="getConfigurazione().Cap" mat-header-cell *matHeaderCellDef> CAP
            <img src="./assets/images/sort.png" (click)="manageClick('Cap')"/>
          </th>
          <td [style.display]="getConfigurazione().Cap" mat-cell *matCellDef="let persona"> {{persona.Cap}} </td>
      </ng-container>

      <ng-container matColumnDef="Telefono">
          <th [style.display]="getConfigurazione().Telefono" mat-header-cell *matHeaderCellDef> Telefono
            <img src="./assets/images/sort.png" (click)="manageClick('Telefono')"/>
          </th>
          <td [style.display]="getConfigurazione().Telefono" mat-cell *matCellDef="let persona"> {{persona.Telefono}} </td>
      </ng-container>

      <ng-container matColumnDef="Tipopersona">
          <th [style.display]="getConfigurazione().Tipopersona" mat-header-cell *matHeaderCellDef> Tipo persona
            <img src="./assets/images/sort.png" (click)="manageClick('Tipopersona')"/>
          </th>
          <td [style.display]="getConfigurazione().Tipopersona" mat-cell *matCellDef="let persona"> {{persona.Tipopersona}} </td>
      </ng-container>

      <ng-container matColumnDef="Tipocontratto">
          <th [style.display]="getConfigurazione().Tipocontratto" mat-header-cell *matHeaderCellDef> Tipo contratto
            <img src="./assets/images/sort.png" (click)="manageClick('Tipocontratto')"/>
          </th>
          <td [style.display]="getConfigurazione().Tipocontratto" mat-cell *matCellDef="let persona"> {{persona.Tipocontratto}} </td>
      </ng-container>

      <ng-container matColumnDef="Nazione">
          <th [style.display]="getConfigurazione().Nazione" mat-header-cell *matHeaderCellDef> Nazione
            <img src="./assets/images/sort.png" (click)="manageClick('Nazione')"/>
          </th>
          <td [style.display]="getConfigurazione().Nazione" mat-cell *matCellDef="let persona"> {{persona.Nazione}} </td>
      </ng-container>

      <ng-container matColumnDef="Nomesede">
          <th [style.display]="getConfigurazione().Nomesede" mat-header-cell *matHeaderCellDef> Cantiere
            <img src="./assets/images/sort.png" (click)="manageClick('Nomesede')"/>
          </th>
          <td [style.display]="getConfigurazione().Nomesede" mat-cell *matCellDef="let persona"> {{persona.Nomesede}} </td>
      </ng-container>

      <ng-container matColumnDef="Mansione">
          <th [style.display]="getConfigurazione().Mansione" mat-header-cell *matHeaderCellDef> Mansione
            <img src="./assets/images/sort.png" (click)="manageClick('Mansione')"/>
          </th>
          <td [style.display]="getConfigurazione().Mansione" mat-cell *matCellDef="let persona"> {{persona.Mansione}} </td>
      </ng-container>

      <ng-container matColumnDef="Nomestruttura">
          <th [style.display]="getConfigurazione().Nomestruttura" mat-header-cell *matHeaderCellDef> Cliente
            <img src="./assets/images/sort.png" (click)="manageClick('Nomestruttura')"/>
          </th>
          <td [style.display]="getConfigurazione().Nomestruttura" mat-cell *matCellDef="let persona"> {{persona.Nomestruttura}} </td>
      </ng-container>
<!--
      <ng-container matColumnDef="Codicemansione">
          <th mat-header-cell *matHeaderCellDef> Codicemansione </th>
          <td mat-cell *matCellDef="let persona"> {{persona.Codicemansione}} </td>
      </ng-container>

      <ng-container matColumnDef="Codiceazienda">
          <th mat-header-cell *matHeaderCellDef> Codiceazienda </th>
          <td mat-cell *matCellDef="let persona"> {{persona.Codiceazienda}} </td>
      </ng-container>

      <ng-container matColumnDef="Codicetipopersona">
          <th mat-header-cell *matHeaderCellDef> Codicetipopersona </th>
          <td mat-cell *matCellDef="let persona"> {{persona.Codicetipopersona}} </td>
      </ng-container>

      <ng-container matColumnDef="Codicetipocontratto">
          <th mat-header-cell *matHeaderCellDef> Codicetipocontratto </th>
          <td mat-cell *matCellDef="let persona"> {{persona.Codicetipocontratto}} </td>
      </ng-container>

      <ng-container matColumnDef="Codicestruttura">
          <th mat-header-cell *matHeaderCellDef> Codicestruttura </th>
          <td mat-cell *matCellDef="let persona"> {{persona.Codicestruttura}} </td>
      </ng-container>

      <ng-container matColumnDef="Codicesede" hidden="true">
          <th mat-header-cell *matHeaderCellDef> Codicesede </th>
          <td mat-cell *matCellDef="let persona"> {{persona.Codicesede}} </td>
      </ng-container>
-->
      <ng-container matColumnDef="documents">
          <th mat-header-cell *matHeaderCellDef> Documenti </th>
          <td mat-cell *matCellDef="let persona">
            <mat-icon color="primary" (click)="viewDocumentiPersona(persona)">folder_shared</mat-icon>
          </td>
      </ng-container>

      <ng-container matColumnDef="newdocument">
          <th mat-header-cell *matHeaderCellDef> Allega </th>
          <td mat-cell *matCellDef="let persona">
            <mat-icon color="primary" (click)="newDocumentoPersona(persona)">attachment</mat-icon>
          </td>
      </ng-container>

      <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Modifica </th>
          <td mat-cell *matCellDef="let persona">
            <mat-icon color="primary" (click)="editPersona(persona)">create</mat-icon>
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>

<!-- </form> -->
</mat-card-content>
<mat-card-actions>

        <button mat-raised-button  color="primary" (click)="newPersona()">Nuova persona</button>

        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>

</mat-card-actions>

</mat-card>
