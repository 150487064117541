import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Permesso } from '../../models/permesso';
import { Persona } from '../../models/persona';
import { Azienda } from '../../models/azienda';
import { Statopermesso } from '../../models/statopermesso';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { configurazione } from '../../../configurazioni/configurazione';
import { AddcommentoComponent } from '../../dialog/addcommento/addcommento.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-permessi',
  templateUrl: './permessi.component.html',
  styleUrls: ['./permessi.component.css']
})
export class PermessiComponent implements OnInit {

  IPAddress:string;
  Port:string;
  Protocol: string;

  currentPermesso:Permesso;
  currentStatopermesso:Statopermesso;
  rolename:string;
  displayedColumns: string[] = [
  "Nomepersona",
  "Cognomepersona",
  "Commento",
  "Notaazienda",
  "Datainizio",
  "Datafine",
  "Orainizio",
  "Orafine",
  "Commenta",
  "Stato",
  "Approva",
  "Rigetta"];

  sortedDirection:Map<string, boolean>=new Map<string, boolean>();


  permessi: Permesso[]=[];
  cachedpermessi: Permesso[]=[];
  dataSource = new MatTableDataSource<Permesso>(this.permessi);
  persone:Persona[];
  aziende: Azienda[];
  currentAzienda:Azienda;
  selectedValueAzienda: string='';
  statipermesso:Statopermesso[];
  selectedValueStatopermesso: string='';

  @ViewChild('paginator') paginator: MatPaginator;

  constructor(private httpClient: HttpClient, private router: Router, private dialog: MatDialog, private commonService: CommonService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.sortedDirection.set("Nomepersona",true);
    this.sortedDirection.set("Cognomepersona",true);
    this.sortedDirection.set("Commento",true);
    this.sortedDirection.set("Notaazienda",true);
    this.sortedDirection.set("Datainizio",true);
    this.sortedDirection.set("Datafine",true);
    this.sortedDirection.set("Orainizio",true);
    this.sortedDirection.set("Orafine",true);
    this.sortedDirection.set("Commenta",true);
    if (environment.address.isLocal){
      this.IPAddress=environment.address.localIP;
      this.Port=environment.address.localPort;
      this.Protocol=environment.address.localProtocol;
    }else{
      this.IPAddress=environment.address.remoteIP;
      this.Port=environment.address.remotePort;
      this.Protocol=environment.address.remoteProtocol;
    }

    this.dataSource.paginator = this.paginator;

    this.commonService.getAllAziende().subscribe((res) => {
        this.aziende = res;
        if (this.aziende.length>0){
          this.currentAzienda=this.aziende[0];
          this.selectedValueAzienda=this.aziende[0].Nome;
        }
        this.commonService.getAllStatipermesso().subscribe((res) => {
            this.statipermesso = res;
        });
        this.aggiornaLista();
    });
/*
    this.commonService.getAllNotespese().subscribe((res) => {
        this.notespese = res;
    });
*/
    this.rolename=sessionStorage.getItem("rolename");



  }

  ngAfterInit(): void {
//    this.navigazione.goToRoot();
    this.paginator._intl.itemsPerPageLabel = 'Elementi per pagina';
  }

  manageChangeAzienda(){
    if (this.selectedValueAzienda===undefined){
     return;
    }
    this.aziende.forEach(element => {
      if (this.selectedValueAzienda === element.Nome){
        this.currentAzienda=element;
      }
    });
    this.aggiornaLista();
  }

  manageChangeStatopermesso(){
    if (this.selectedValueStatopermesso===undefined){
     return;
    }
    this.statipermesso.forEach(element => {

      if (this.selectedValueStatopermesso === element.Nome){
        this.currentStatopermesso=element;
      }
    });
    this.aggiornaLista();
  }

  cancel(){
    this.selectedValueStatopermesso=undefined;
    // this.permessi=[];
    this.permessi=[];
    this.aggiornaLista();
  }

  aggiornaLista(){

  //  if (this.commonService.selectedPersona===undefined){
  //    return;
  //  }

    let nomeazienda:String="";
    if (this.selectedValueAzienda!=undefined && this.selectedValueAzienda!=""){
     nomeazienda=this.selectedValueAzienda;
      if (this.rolename==="HR" || this.rolename==="Amministratore"){
        if (this.currentAzienda!=undefined){
          this.commonService.getAllPermessiByAziendaid(this.currentAzienda.Codice).subscribe((res) => {
            this.permessi=res;
            this.refreshLista();
          });
        }
      }
    }else{
  //    this.refreshLista(templistanotespese);
    }


  }

  refreshLista(){

     //console.log(this.currentStato.Nome);
     let temppermessi:Permesso[]=this.permessi;
     this.permessi=[];
     temppermessi.forEach(element => {
       if (this.selectedValueStatopermesso === element.Nomestatopermesso){
         this.permessi.push(element);

       }
     });

     this.dataSource = new MatTableDataSource<Permesso>(this.permessi);
     this.dataSource.paginator = this.paginator;
   }

  commentaPermesso(permesso: Permesso){
    //console.log("Open Permesso:"+permesso.Codice);
    const dialogRef = this.dialog.open(AddcommentoComponent, {
      width: '450px',
      data: {title:'Inserisci un commento',permesso:permesso}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){

      }
    });
  }



  ngDoCheck(){
  //  alert("ngDoCheck");
  }
  ngAfterContentInit(){
  //  alert("ngAfterContentInit");
  }

  ngAfterContentChecked(){
//    alert("ngAfterContentChecked");
  }
  ngAfterViewInit(){
//    alert("ngAfterViewInit");
  }
  ngAfterViewChecked(){
//    alert("ngAfterViewChecked");
  }

  ngOnDestroy(){

  }

  approvaPermesso(permesso: Permesso, valore: string){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: {title:'Attenzione',description:'Confermi'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        if (valore==='YES'){
          permesso.Nomestatopermesso="Approvato";
        }else{
          permesso.Nomestatopermesso="Rigettato";
        }
      //  const notaspeseData = new FormData();
      //  notaspeseData.append('codice', notaspeseData.Codice.toString());
        this.httpClient.put<Permesso>(this.Protocol+"://"+this.IPAddress+":"+this.Port+'/api/permessi/approva',  permesso)
        .subscribe((response) => {
            this.aggiornaLista();
        });
      }
    });
  }


  manageClick(attributo: string){
    //alert(ev.target)
  /*
    switch (_case) {
         case 'number':
           this.elements2.sort((a, b) => {
             return a.position - b.position;
           });
           break;
         case 'text':
  */
    let tempList:Permesso[];
    tempList=this.permessi;
    let direction=this.sortedDirection.get(attributo);

    tempList.sort((a, b) => {
       var typeA = "";
       var typeB = "";
       if (a[attributo]!=null){
         if (isNaN(b[attributo]))
            typeA = a[attributo].toLowerCase();
         else
            typeA = a[attributo].toString();
       }
       if (b[attributo]!=null){
         if (isNaN(b[attributo]))
            typeB = b[attributo].toLowerCase();
          else
            typeB = b[attributo].toString();
       }
         if (direction){
           if (typeA > typeB){
             return -1;
           }else if (typeA < typeB){
              return 1;
           }else{
             return 0;
           }
         }else{
           if (typeA < typeB){
             return -1;
           }else if (typeA > typeB){
              return 1;
           }else{
             return 0;
           }
         }
     });
     direction=!direction;
     this.sortedDirection.set(attributo,direction);

     this.dataSource = new MatTableDataSource<Permesso>(this.permessi);

   }

}
