// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  resetPasswordURL: '/forgot-password',
  websocket: {
    protocol: 'ws://',
    host: '80.211.132.144',
    port: 443,
    url: '/hp-ppt/wsocket/service'
  },
  backEnd: {
    baseUrl: 'https://vm.dthink.it',
    apiPath: '/hp-ppt/rest/ui/secured',
    authPath: '/hp-ppt/rest/ui/auth',
    resetPath: '/hp-ppt/rest/ui/auth/resetpwd',
    forgotPassword: '/hp-ppt/rest/ui/auth/forgotpwd',
    userRegistration: '/hp-ppt/rest/ui/auth/userRegistration',
    authHeader: 'Authorization'
  },
  address:{
    isLocal:false,
    remoteProtocol: 'https',
    remoteIP: 'anturapp.dthink.it',
    remotePort:'443',
    localProtocol: 'http',
    localIP: '127.0.0.1',
    localPort:'20080'
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
