import {Component, Inject, OnInit} from '@angular/core';
import { CommonService } from '../../service/common.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmData} from '../../models/confirmdata';

@Component({
  selector: 'app-endanalisilul',
  templateUrl: './endanalisilul.component.html',
  styleUrls: ['./endanalisilul.component.css']
})
export class EndanalisilulComponent{

 constructor(
    public dialogRef: MatDialogRef<EndanalisilulComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData) {

    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.dialogRef.close();
  }

}
