export class Persona {
  Nome: string;
  Cognome: string;
  Matricola: string;
  Datanascita: Date;
  Dataassunzione: Date;
  Datalicenziamento: Date;
  Sesso: string;
  Cittanascita: string;
  Provincianascita: string;
  Codicefiscale: string;
  Citta: string;
  Provincia: string;
  Residenza: string;
  Cap: string;
  Telefono: string;
  Tipopersona: string;
  Tipocontratto: string;
  Nazione: string;
  Pec: string;
  Nomesede: string;
  Mansione: string;
  Nomestruttura: string;
  Codice: number;
  Nomeazienda: String;
  Codicemansione: number;
  Codiceazienda: number;
  Codicetipopersona: number;
  Codicetipocontratto: number;
  Codicestruttura: number;
  Codicesede: number;
  Codiceistanza: number;

  constructor(){
      this.Nome='';
      this.Cognome='';
      this.Telefono='';
      this.Codicefiscale='';
      this.Matricola='';
  //            this.Mansione='';
      this.Datanascita=null;
      this.Dataassunzione=null;
      this.Datalicenziamento=null;
      this.Sesso='';
      this.Cittanascita='';
      this.Provincianascita='';
      this.Citta='';
      this.Provincia='';
      this.Residenza='';
      this.Cap='';
      this.Nazione='';
      this.Pec='';
      this.Codice=0;
  }
}
