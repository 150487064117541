  <mat-card>
    <mat-card-header>
        <mat-card-title>Lista note spese</mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
<!--      <form>-->

          <mat-form-field appearance="fill" style="padding-right: 10px">
            <mat-label class="text-sm">Azienda</mat-label>
            <mat-select [(ngModel)]="selectedValueAzienda" name="azienda" (selectionChange)="manageChangeAzienda()" class="text-sm">
              <mat-option *ngFor="let azienda of aziende" [value]="azienda.Nome">
                {{azienda.Nome}}
              </mat-option>
            </mat-select>
          </mat-form-field>

           <mat-form-field appearance="fill" style="padding-right: 10px">
             <mat-label class="text-sm">Stato</mat-label>
             <mat-select [(ngModel)]="selectedValueStatons" name="statons" (selectionChange)="manageChangeStatons()" class="text-sm">
               <mat-option *ngFor="let statons of statins" [value]="statons.Nome">
                 {{statons.Nome}}
               </mat-option>
             </mat-select>
           </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="Nomepersona">
          <th mat-header-cell *matHeaderCellDef> Nome
            <img src="./assets/images/sort.png" (click)="manageClick('Nomepersona')"/>
          </th>
          <td mat-cell *matCellDef="let notaspese"> {{notaspese.Nomepersona}} </td>
      </ng-container>

      <ng-container matColumnDef="Cognomepersona">
          <th mat-header-cell *matHeaderCellDef> Cognome
            <img src="./assets/images/sort.png" (click)="manageClick('Cognomepersona')"/>
          </th>
          <td mat-cell *matCellDef="let notaspese"> {{notaspese.Cognomepersona}} </td>
      </ng-container>

      <ng-container matColumnDef="Nome">
          <th mat-header-cell *matHeaderCellDef> Nota spese
            <img src="./assets/images/sort.png" (click)="manageClick('Nome')"/>
          </th>
          <td mat-cell *matCellDef="let notaspese"> {{notaspese.Nome}} </td>
      </ng-container>

      <ng-container matColumnDef="Descrizione">
          <th mat-header-cell *matHeaderCellDef> Descrizione
            <img src="./assets/images/sort.png" (click)="manageClick('Descrizione')"/>
          </th>
          <td mat-cell *matCellDef="let notaspese"> {{notaspese.Descrizione}} </td>
      </ng-container>

      <ng-container matColumnDef="Data">
        <th mat-header-cell *matHeaderCellDef> Data
          <img src="./assets/images/sort.png" (click)="manageClick('Data')"/>
        </th>
        <td mat-cell *matCellDef="let notaspese">{{notaspese.Data | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="Importo">
        <th mat-header-cell *matHeaderCellDef> Importo
          <img src="./assets/images/sort.png" (click)="manageClick('Importo')"/>
        </th>
        <td mat-cell *matCellDef="let notaspese">{{notaspese.Importo}}</td>
      </ng-container>

      <ng-container matColumnDef="Stato">
        <th mat-header-cell *matHeaderCellDef> Stato
          <img src="./assets/images/sort.png" (click)="manageClick('Stato')"/>
        </th>
        <td mat-cell *matCellDef="let notaspese">{{notaspese.Nomestatons}}</td>
      </ng-container>

      <ng-container matColumnDef="Ricevute">
        <th mat-header-cell *matHeaderCellDef> Ricevute </th>
        <td mat-cell *matCellDef="let notaspese">
          <mat-icon  color="primary" (click)="vediRicevute(notaspese)">receipt</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="Approva">
        <th mat-header-cell *matHeaderCellDef> Approva </th>
        <td mat-cell *matCellDef="let notaspese">
          <mat-icon color="primary" (click)="approvaNotaspese(notaspese, 'YES')" *ngIf="notaspese.Nomestatons==='Ricevuto'">check</mat-icon>
       </td>
      </ng-container>

      <ng-container matColumnDef="Rigetta">
        <th mat-header-cell *matHeaderCellDef> Rigetta </th>
        <td mat-cell *matCellDef="let notaspese">
          <mat-icon color="primary" (click)="approvaNotaspese(notaspese, 'NO')" *ngIf="notaspese.Nomestatons==='Ricevuto'">block</mat-icon>
       </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>

<!-- </form> -->
</mat-card-content>
<mat-card-actions>

        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>

</mat-card-actions>

</mat-card>
