import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Modulo } from '../../models/modulo';
import { Master } from '../../models/master';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addmodule',
  templateUrl: './addmodule.component.html',
  styleUrls: ['./addmodule.component.css']
})
export class AddmoduleComponent implements OnInit {
  modulo: Modulo;
  hide = true;
  selectedValue:string;
  moduli: Modulo[];
  masters: Master[];
  rc:String;
  modulotoedit: boolean;
  registerForm: FormGroup;
  submitted = false;

  constructor(private commonService: CommonService,
    private router: Router, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {

    if (this.registerForm===undefined){
    this.registerForm = this.formBuilder.group({
          selectedValue:['', Validators.required],
          Nome:['', Validators.required],
          Descrizione: [''],
          Abilitato: [''],
          Codice: [0],
          Codicemaster: [0]
      });
    }
    this.commonService.getAllMasters().subscribe((res) => {
        this.masters = res;

        if (this.commonService.selectedModulo!=undefined){
          this.modulo=this.commonService.selectedModulo;
          this.commonService.selectedModulo=undefined;
          this.registerForm.setValue({
                    selectedValue:this.modulo.Master.Nome,
                    Nome:this.modulo.Nome,
                    Descrizione:this.modulo.Descrizione,
                    Abilitato:this.modulo.Abilitato,
                    Codice:this.modulo.Codice,
                    Codicemaster:this.modulo.Master.Codice
          });

        }else{
          this.modulo= new Modulo();
        }
      });
/*
    this.commonService.getAllMasters().subscribe((res) => {
        this.masters = res;
        this.masters.forEach(element => {
          if (element.Codice === this.modulo.Master.Codice){
            this.selectedValue=element.Nome;
          }
        });
    });
    */
  }

  save() {
    this.submitted = true;
    if (this.registerForm.invalid) {
        alert("Errore: verifica i campi indicati con *");
        return;
    }else{
      this.commonService.saveModulo(this.registerForm.value).subscribe(data => {

        this.rc=JSON.stringify(data)
        if (this.rc==="0"){
          alert("Modulo salvato con successo");
          this.cancel();
        }else if (this.rc==="2"){
          alert("Modulo già esistente");
        }else{
          alert("Errore creazione modulo");
        }
      });
    }
  }
  cancel(){
    this.selectedValue=undefined;
    this.modulo=new Modulo();
    this.router.navigate(['modules']);
  }

  manageChangeMaster(){
    this.masters.forEach(element => {
      if (this.registerForm.value.selectedValue === element.Nome){
        this.modulo.Master=element;
        this.registerForm.setValue({
                  selectedValue:this.modulo.Master.Nome,
                  Nome:this.modulo.Nome,
                  Descrizione:this.modulo.Descrizione,
                  Abilitato:this.modulo.Abilitato,
                  Codice:this.modulo.Codice,
                  Codicemaster:this.modulo.Master.Codice
        });
      }
    });
  }
}
