<div fxFlex="100" fxLayoutAlign="space-around center">
<!--  <mat-toolbar>
    <mat-toolbar-row id="firstRow">
  -->


        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-icon-button *ngIf="loginService.isUserLoggedIn()" (click)="onDrawerClick()">
           <mat-icon color="accent" class="white-icon">menu</mat-icon>
          </button>
          <div *ngIf="getCurrentIstanza()===1"><img src="assets/images/logoAntur-60x60.png"></div>
          <div *ngIf="getCurrentIstanza()===2"><img src="assets/images/logoAntur-60x60.png"></div>
          <div *ngIf="getCurrentIstanza()===4"><img src="assets/images/fbserviceLogo-60x60.png"></div>
        </div>
        <div class="example-spacer"></div>
      <!--
        <div *ngIf="loginService.isUserLoggedIn()" fxLayout="row" fxLayoutAlign="space-between center">
          <input matInput [(ngModel)]="searchtext" name="searchtext">
          <button mat-icon-button (click)="cerca()">
            <mat-icon color="accent" class="white-icon">search</mat-icon>
          </button>
        </div>
  -->
          <button *ngIf="!loginService.isUserLoggedIn()" mat-raised-button  color="primary" (click)="entra()">Entra</button>
  <!--        <button mat-raised-button  color="primary" (click)="showvideoapp()">Video</button>
-->
          <div *ngIf="!loginService.isUserLoggedIn()">{{getUsername()}}</div>
          <button mat-icon-button *ngIf="loginService.isUserLoggedIn()" [matMenuTriggerFor]="menu">
            <mat-icon color="accent" class="white-icon">account_circle</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <mat-icon>security</mat-icon>
              <a mat-button (click)="cambiaPassword()" class="nav-link">Cambia password</a>
            </button>
            <button mat-menu-item>
              <mat-icon>exit_to_app</mat-icon>
              <a mat-button (click)="esci()" class="nav-link">Esci</a>
            </button>
          </mat-menu>


<!-- </mat-toolbar-row>
 </mat-toolbar> -->
</div>
