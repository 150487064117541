import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ItemDetails} from '../../models/item';

@Component({
  selector: 'app-dialog-sample',
  templateUrl: './dialog-sample.component.html',
  styleUrls: ['./dialog-sample.component.css']
})
export class DialogSampleComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogSampleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemDetails) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


}
