<!-- <div class="container">
    <div>
        User Name : <input type="text" name="username" [(ngModel)]="username"> Password : <input type="password" name="password" [(ngModel)]="password">
    </div>
    <button (click)=checkLogin() class="btn btn-success">
    Login
  </button>
</div> -->

<mat-card>
    <mat-card-header>
        <mat-card-title>Password dimenticata</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <table class="example-full-width" cellspacing="0">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Username" [(ngModel)]="Username" (keyup.enter)="sendRecupero()" name="Username" required>
                        </mat-form-field>
                    </td>
                </tr>


                <p *ngIf="error" class="error">
                    {{ error }}
                </p>

            </table>
        </form>



    </mat-card-content>
    <mat-card-actions>
        <table>
          <tr>
            <td>
              <button mat-raised-button [disabled]="Username===undefined || Username===''" (click)="sendRecupero()" color="primary">Invia richiesta</button>
            </td>
            <td>
              <button mat-raised-button (click)="cancel()" color="primary">Cancel</button>
            </td>
          </tr>

        </table>
    </mat-card-actions>
</mat-card>
