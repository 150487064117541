<!--<div class="container" fxLayout="row" fxLayoutAlign="center">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center">
    <button mat-mini-fab (click)="previousMonth()">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-mini-fab (click)="nextMonth()">
        <mat-icon>chevron_right</mat-icon>
    </button>

    <mese #mese [valore]="selectedPeriod"></mese>

  </div>
</div>
-->
<mat-card>
  <mat-card-header>
      <mat-card-title>Lista progetti</mat-card-title>
  </mat-card-header>
  <mat-card-content class="content">
<!--      <form>-->

  <mat-form-field appearance="fill">
    <mat-label class="text-sm">Progetto</mat-label>
    <mat-select [(ngModel)]="selectedValueProgetto" name="stato" (selectionChange)="manageChangeProgetto()" class="text-sm">
      <mat-option *ngFor="let progetto of progetti" [value]="progetto.Nome">
        {{progetto.Nome}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <anno #anno fxLayout="row" fxLayoutAlign="space-between center" [valore]="projectPeriod"></anno>

  </mat-card-content>
  <mat-card-actions>
  <table>
    <tr>
      <td>
        <button mat-raised-button  color="primary" (click)="newProgetto()">Nuovo progetto</button>
      </td>
      <td>
        <button mat-raised-button  color="primary" (click)="cancel()">Cancella</button>
      </td>
    </tr>
  </table>
  </mat-card-actions>

  </mat-card>
